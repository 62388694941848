import "./GameBoardActivities/MapMaze.css";
import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { createAdditionalSpace } from "./objects/createAdditionalSpace";
import { clearMazeBoard } from "./objects/clear_maze_board";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import { findDataObject } from "../../objects/CommonUse/find_data_object";
import { setPathInMaze } from "./objects/set_path_in_maze";
import { setBlackOutSpaces } from "./objects/set_black_out_spaces";
import { createGoodPath } from "./objects/createGoodPath_function";
import { createWiderPath } from "./objects/createWiderPath";
import { removeClearPath } from "./objects/create_clear_path";
import {displayFriendComment} from "./objects/display_friend_comment";
import {determineFriendComment} from "./objects/determine_friend_comment"

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 200,
    backgroundColor: theme.palette.common.white,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  palette: {
    primary: {
      dark: '#002884',
      contrastText: '#fff',
    },
  },
  userForm: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  
}));

function MazeGameModal(props) {
  const [gridWidth] = useState(props.width);
  const [gridHeight] = useState(props.height);
  const [pctBlocked] = useState(props.pctBlocked);
  const [mazeMemes] = useState(props.mazeParms);
  const [timeAllowed] = useState(props.timeAllowed * 1000);
  const [category]=useState(props.category);
  const [gameStarted, setGameStarted] = useState(false);
  const [disable, setDisable] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [objectiveMet, setObjectiveMet] = useState(false);
  const [avatar, setAvatar] = useState("1");
  const [objective, setObjective] = useState("1");
  const [splits, setSplits] = useState([]);
  const [playBoard, setPlayBoard] = useState([]);
  const [moves, setMoves] = useState(0);
  const [deliveries, setDeliveries] = useState(0);
  const [totalScore, setTotalScore] = useState(props.score.points);
  const [nbrGames, setNbrGames] = useState(props.nbrGames - props.score.gamesRemaining);
  const [maxNbrGames] = useState(props.nbrGames);
  const [score, setScore] = useState(findDataObject("activityScore").score);
  const [maxDeliveries, setMaxDeliveries] = useState(0);
  const [nbrPerfectGames, setNbrPerfectGames] = useState(0);
  const [scoreThisGame, setScoreThisGame] = useState(0);
  const [maxScore, setmaxScore] = useState(props.score.maxScore);
  const [backToBeginning, setBackToBeginning] = useState("1");
  const [commentNbr, setCommentNbr] = useState("0");
  const [displayPicture, setDisplayPicture] = useState(false);
  const [comment, setComment] = useState("");
  const [resetDone, setResetDone] = useState(false);
   
  const gameBoard = (w, h) => {
    let board = [];
    for (let idxH = 1; idxH <= h; idxH++) {
      for (let idxW = 1; idxW <= w; idxW++) {
        let comb = `${idxH}|${idxW}`;
        board.push(comb);
      }
    }
    return board;
  };

  const avatarPosition = (w, h) => {
    let widthMid;
    let heightMid;
    if (h === 1) {
      let newHeight = h + 1;
      heightMid = Math.floor(newHeight / 2);
    } else {
      heightMid = Math.floor(h / 2);
    }
    if (w === 1) {
      let newWidth = w + 1;
      widthMid = Math.floor(newWidth / 2);
    } else {
      widthMid = Math.floor(w / 2);
    }
    return heightMid + "|" + widthMid;
  };
 
  const startGame = () => {
    setDisable(true);
    setObjectiveMet(false);
    setResetDone(false);
    let newBoard = "";
    let avatarPos = "";
    let objectivePos = "";
    let splitsBoard = [];
    if (mazeMemes.topToBottom == true){
       let goodPath=createGoodPath(gridWidth, gridHeight, 1); 
       avatarPos = goodPath[0]
       setAvatar(avatarPos);
       setBackToBeginning(avatarPos);
       let lastEntry = goodPath.length - 1;
       objectivePos = goodPath[lastEntry]
       setObjective(goodPath[lastEntry]);
       let savePath = goodPath;
       goodPath=createWiderPath(goodPath); 
       let board = gameBoard(gridWidth, gridHeight);
       let activeBoard = setPathInMaze(board, goodPath, lastEntry, gridWidth, gridHeight, mazeMemes.blockOutImg);
       newBoard = [...activeBoard];
       _.remove([...newBoard], (n) => n === avatarPos);
       _.remove([...newBoard], (n) => n === objectivePos);
       splitsBoard = JSON.parse(JSON.stringify(newBoard));
       splitsBoard = removeClearPath(splitsBoard, savePath);
    }else{
      let avatarPos = avatarPosition(gridWidth, gridHeight);
      setAvatar(avatarPos);
      let board = gameBoard(gridWidth, gridHeight);
      let goodPath=createAdditionalSpace(gridWidth, gridHeight, [], pctBlocked, avatarPos); 
      // if cell is in goodPath, it will get a blackoutSquare
      let activeBoard = setBlackOutSpaces(board, goodPath, mazeMemes.blockOutImg, gridWidth, gridHeight);
      newBoard = [...activeBoard];
      _.remove([...newBoard], (n) => n === avatar);
      splitsBoard = newBoard;
    }
   
    setSplits(_.sampleSize(splitsBoard, gridHeight));
    // for dogwalker deliveries are bad 
    // they mean contact with a fire hydrant
    setMaxDeliveries(0);
    setDeliveries(0);
    setScoreThisGame(0);
    setPlayBoard(newBoard);
    setGameStarted(true);
    setMoves(0);
    props.startTimer();
    stateChange();
  };
    
  function stateChange() {
      setTimeout(function () {
      setDisable(true);
      setGameOver(true);
      setCurrentGameScore();
      let displayComment = displayFriendComment(category, props.friendInfo);
      if (displayComment == true){
            setDisplayPicture(true);
            getComment();
      }
     }, timeAllowed);
  }

  function endGame(){
      score.type = "pause";
      if (resetDone == false){
          const scoreObj = calculateScore();
          score.points = scoreObj.totalScore;
          score.maxScore = scoreObj.maxScore;
          score.nbrAttempted = scoreObj.nbrGames;
          score.gamesRemaining = maxNbrGames - scoreObj.nbrGames;
      }else{
          score.points = totalScore;
          score.maxScore = maxScore;
          score.nbrAttempted = nbrGames;
          score.gamesRemaining = maxNbrGames - nbrGames;
      }  
      score.newLevelEarned = false;
      setScore(score);
      props.onGameOver(score);
  }
  
  function getComment(){
    let commentTable = props.friendInfo.commentTable;
    let commentObj = determineFriendComment(commentTable, commentNbr);
    setComment(commentObj.comment);
    setCommentNbr(commentObj.commentNbr);
  }

  function calculateScore(){
    var cumulativeScore = 0;
    var gameCount = nbrGames + 1;
    var maximumPoints = maxScore + gridHeight;
   
    var perfectGameCount = nbrPerfectGames;
    if (mazeMemes.topToBottom == true){
       // for games with topToBottom of true (ex: dogWalker), the objective is to avoid the pointsObj
       cumulativeScore = (gridHeight - deliveries) + totalScore;
       if (deliveries <= 1){
            perfectGameCount += 1;
       }
    }else{
       if (deliveries >= maxDeliveries){
            perfectGameCount = nbrPerfectGames + 1;
       }
       cumulativeScore = totalScore + deliveries;
    }
    const scoreObj = {totalScore: cumulativeScore, perfectGameCount: perfectGameCount, maxScore: maximumPoints,
        nbrGames: gameCount};
    return scoreObj;
  }

  function restartGame(){
    setDisplayPicture(false);
    const scoreObj = calculateScore();
    setResetDone(true);
    setmaxScore(scoreObj.maxScore);
    setNbrPerfectGames(scoreObj.perfectGameCount);
    setTotalScore(scoreObj.totalScore);
    setNbrGames(scoreObj.nbrGames);

    if (scoreObj.nbrGames >= maxNbrGames){
        score.type = "finish";
        score.points = scoreObj.totalScore;
        score.gamesRemaining = maxNbrGames - scoreObj.nbrGames;
        score.maxScore = scoreObj.maxScore;
        if (nbrPerfectGames >= 4){
            score.newLevelEarned = true;
        }else{
            score.newLevelEarned = false;
        }
        score.nbrAttempted = scoreObj.nbrGames;
        setScore(score);
        props.onGameOver(score);
    }else{
        setDisable(false);
        setGameOver(false);
        setNbrGames(scoreObj.nbrGames);
        setMaxDeliveries(0);
        setScoreThisGame(0);
        let board = gameBoard(gridWidth, gridHeight);
        let newboard = clearMazeBoard(board, gridHeight, gridWidth, mazeMemes.blockOutImg, mazeMemes.pointsObj, 
            mazeMemes.avatar, mazeMemes.finishObj);
        setPlayBoard(newboard);
        setDeliveries(0);
        props.stopTimer();
  }
}

  
  const boardRun = (e) => {
    let newAvatarPos = 0
    if (maxDeliveries == 0){
          setMaxDeliveries(splits.length);
    }
    if (objectiveMet || gameOver){
          setGameStarted(false);
    }else{
      if (gameStarted){  
          newAvatarPos = moveAvatar (e);
          if (mazeMemes.topToBottom == true){
            if (newAvatarPos == objective){
                setObjectiveMet(true);
                setCurrentGameScore();
                if (category != "SideHustle" && 
                  props.friendInfo.commentTable != undefined){
                  getComment();
                }
            }
          }else{
              if (splits.length < 1){
                  setObjectiveMet(true);
                  setCurrentGameScore();
              }
          }
      }
    }
  }

  const setCurrentGameScore = () =>{
    if (mazeMemes.topToBottom == true){
         setScoreThisGame(gridHeight - deliveries);
    }else{
         setScoreThisGame(deliveries);
    }
  }
  
  const moveAvatar = (e) => {
    e = e || window.event;
    let separatorIdx = avatar.indexOf("|");
    let rowNbr = parseInt(avatar.substring(0,separatorIdx));
    let columnNbr = parseInt(avatar.substring(separatorIdx + 1, avatar.length));
    let newPosition = 0;
        
    if (e.keyCode === 38) {
      // up arrow
      let upMove = document.getElementById(`${avatar}`);
      if (upMove !== undefined &&
          upMove !== null &&
          playBoard.includes(avatar) &&
          rowNbr >= 2 )
      {
        newPosition = (rowNbr - 1).toString() + "|" + columnNbr.toString();
        if (playBoard.includes(newPosition)){
          upMove.classList.add("plain");
          upMove.classList.remove(mazeMemes.avatar);
          if (splits.length > 1) {
            setMoves(moves + 1);
          }
          setAvatar(newPosition);
        }
      }
    } else if (e.keyCode === 40) {
      // down arrow
      let downMove = document.getElementById(`${avatar}`);
      if (downMove !== undefined &&
          downMove !== null &&
          playBoard.includes(avatar) &&
          rowNbr < gridHeight)     
      {
        newPosition = (rowNbr + 1).toString() + "|" + columnNbr.toString();
        if (playBoard.includes(newPosition)){
          downMove.classList.add("plain");
          downMove.classList.remove(mazeMemes.avatar);
          if (splits.length > 1) {
            setMoves(moves + 1);
          }
          setAvatar(newPosition);
        }
      }  
    } else if (e.keyCode === 37) {
      // left arrow
      let leftMove = document.getElementById(`${avatar}`);
      if (leftMove !== undefined &&
          leftMove !== null &&
          playBoard.includes(avatar) &&
          columnNbr >= 2 )
      {
        newPosition = rowNbr.toString() + "|" + (columnNbr - 1).toString();
        if (playBoard.includes(newPosition)){
          leftMove.classList.add("plain");
          leftMove.classList.remove(mazeMemes.avatar);
          if (splits.length > 1) {
            setMoves(moves + 1);
          }
          setAvatar(newPosition);
        }
      }
    } else if (e.keyCode === 39) {
      // right arrow
      let rightMove = document.getElementById(`${avatar}`);
      if (rightMove !== undefined &&
          rightMove !== null &&
          playBoard.includes(avatar) &&
          columnNbr < gridWidth )
      {
        newPosition = rowNbr.toString() + "|" + (columnNbr + 1).toString();
        if (playBoard.includes(newPosition)){
            rightMove.classList.add("plain");
            rightMove.classList.remove(mazeMemes.avatar);
            if (splits.length > 1) {
                setMoves(moves + 1);
            }
            setAvatar(newPosition);
            }
        }
    }
    return newPosition;
  };

  document.onkeydown = boardRun;
   
  useEffect(() => {
    let getAvatar = document.getElementById(`${avatar}`);
    if (mazeMemes.bumpRestart == true){
      if (getAvatar !== undefined && getAvatar !== null) {
        // if avatar runs into pointObj, then move avatar back to the beginning
        if (getAvatar.classList.contains(mazeMemes.pointsObj)) {
            setAvatar(backToBeginning);
            let beginning = document.getElementById(`${backToBeginning}`);
            beginning.classList.add(mazeMemes.avatar);
            setDeliveries(deliveries + 1);
        }else{
          getAvatar.classList.remove("plain");
          getAvatar.classList.add(mazeMemes.avatar);
        }
      }
    }else{
      if (getAvatar !== undefined && getAvatar !== null) {
        if (getAvatar.classList.contains(mazeMemes.pointsObj)) {
            getAvatar.classList.remove(mazeMemes.pointsObj);
            setDeliveries(deliveries + 1);
            _.remove(splits, (n) => n === avatar);
        }
        getAvatar.classList.remove("plain");
        getAvatar.classList.add(mazeMemes.avatar);
      }
    }

    if (mazeMemes.topToBottom == true){
        let getObjective = document.getElementById(`${objective}`);
        if (getObjective !== undefined && getObjective !== null) {
            if (getObjective.classList.contains(mazeMemes.pointsObj)) {
              getObjective.classList.remove(mazeMemes.pointsObj);
              _.remove(splits, (n) => n === objective);
            }
            if (avatar == objective){
                getObjective.classList.remove(mazeMemes.finishObj);
                getObjective.classList.add(mazeMemes.avatar);
            }else{
              getObjective.classList.remove("plain");
              getObjective.classList.add(mazeMemes.finishObj);
            }
        }
    }
     
    setTimeout(() => {
      _.forEach(splits, (value) => {
        if (value !== avatar) {
          let split = document.getElementById(`${value}`);
          if (split!=undefined && split != null){
            if  (split.classList.contains(mazeMemes.avatar) ||
                split.classList.contains(mazeMemes.blockOutImg) ||
                split.classList.contains(mazeMemes.finishObj)){
                  // skip cell if has specified object
            }else{ 
                split.classList.remove("plain");
                split.classList.add(mazeMemes.pointsObj);
            }
          }
        }
      });
    }, 500);
  }, [avatar, splits]);
  
  
  return (
    <div    
       aria-labelledby="simple-modal-title"
       aria-describedby="simple-modal-description"
  >
            <div className="row">
            <div className="col-md-6">
              {gameOver ?
                  <span>
                    <Button  
                        type="button"
                        variant="contained"
                        color="primary" 
                        onClick={restartGame} >
                          Reset
                    </Button>
                   
                  </span>
                :
                    <Button   
                        type="button"
                        variant="contained"
                        color="primary" 
                        disabled={disable} 
                        onClick={startGame} >
                          Start
                    </Button>
              }
               <Button  className="ml-2"
                        type="button"
                        variant="contained"
                        color="primary" 
                        onClick={endGame} >
                          Finish
                </Button>
                
             </div>
            <div className="col-md-6">
                   <div>Cumulative Score: {totalScore}</div>
                   <div>Nbr Games: {nbrGames}</div>
              </div>
              </div>
              <div className={`root2`}>
                  <div className={`MapMaze`}>
                      <div className = "row">
                          <div className="col-md-6 mb-2">
                      </div>
                      <div className="col-md-6"> 
                 
                            {gameOver ? 
                                <div className=" font-weight-bold">
                                      <div>Game Over!!!!</div>
                                      <div>Click "Reset" to play again.</div>
                                </div>
                            : 
                                  <div className="font-weight-bold">
                                      <div>When you are ready to go</div>
                                      <div className="font-weight-bold">click the "Start" button. </div>
                                  </div>
                            }
                           
                      </div>           
              </div>
              {category != "SideHustle" && displayPicture  ?
                  <span>
                      <div>
                          <img src={props.friendInfo.friendImgURL}  className="friend-puzzle-position-img" 
                              alt="friend picture"/>
                      </div>  
                      <div className="speech-puzzle-activity right" >
                          {comment}
                      </div>
                  </span>
                :
                    null
                }
        </div>
        
        {Array.from({ length: gridHeight }, (_, h) => h + 1).map((hValue) => (
          <Grid key={hValue} container spacing={1} direction="row">
            {Array.from({ length: gridWidth }, (_, i) => i + 1).map((value) => (
              <Grid key={value} item>
                <Paper className={mazeMemes.plainCell}>
                  <span id={`${hValue}|${value}`} className={`plain`}>
                    {/* {`${hValue}${value}`} */}
                  </span>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ))}
      </div>
   
      
     
           
    </div>
  );
}

export default MazeGameModal;