import {Component} from 'react';
import "./Admin/admin.css";
import { editEventCondition } from './editEventConditionProcesses/editEventCondition';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import GameFeedbackDisplayModal from './game_feedback_display_modal';
import PlayerProfileParmInputModal from './player_profile_parm_input_modal';
import PlayerFeedbackDisplayModal from './player_feedback_display_modal';
import GameProfileDisplayModal from './game_profile_display_modal';
import LoanAndPMICalculatorModal from './loan_and_PMI_calculator_modal';
import JobListModal from './job_list_modal';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import combinePlayerChoices from '../../objects/CommonUse/combine_player_choices';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import ExportToExcelButton from './export_to_excel';
import SelectSkipConditionCheckModal from './select_skip_condition_check_modal';
import MoneyMgmtTestModal from './money_mgmt_test_modal';
import MoneyMgmtStudentTestModal from './money_mgmt_student_test_modal';

class ConditionReports extends Component {
    constructor(props) {
        super(props);
        this.adminInfo = this.props.adminInfo();
        this.state={
          headers: setHeadersForFetch(),  
          option1: '<option value="',
          option2: '">',
          loadGameFeedback: false,
          loadPlayerFeedback: false,
          playerFeedback: null,
          loadGameData: false,
          loadSavingsData: false,
          loadCreditCardData: false,
          gameChoicesData: null,
          getProfileParms: false,
          parmProcessComplete: false,
          openPMICalculator: false,
          loadJobListData: false,
          loadJobChoiceData: false,
          loadHomeChoiceData: false,
          loadCarChoiceData: false,
          subProcess: "ConditionReports",
          environment: Config.dataURL.currentEnv,
          exportToExcelButton: false,
          eventsForCondition: false,
          moneyMgmtTest: false,
          studentTest: false,
        };   
        this.waitState=false;
        this.gameFeedback = null;
        this.gameData = null;
        this.savingsData = null;
        this.creditCardData = null;
        this.jobList = null;
        this.moneyMgmtTestData = null;
    }

    editEventConditions(){
        var i;
        var errorArray=[];
        this.reportDataArr=[];
        let headerLine = {condName: "Condition Name", rcdNbr: "Record Number", triggeredBy: "Triggered By",
            fieldName: "Field Name", fieldValue: "FieldValue", errorMsg: "Error Message"};
        this.reportDataArr.push(headerLine);
        this.eventSelectAndTriggerConditions();
        for(i=0; i<this.adminInfo.conditionsData.length; i++){
            errorArray=editEventCondition(this.adminInfo.conditionsData[i], this.selectConditions, this.triggerConditions);
            for (let j=0; j<errorArray.length; j++){
                let reportLine = {condName: this.adminInfo.conditionsData[i].condName, rcdNbr: i + 1,
                      triggeredBy: this.adminInfo.conditionsData[i].triggeredBy, fieldName: errorArray[j].fieldName,
                      fieldValue: errorArray[j].fieldValue, errorMsg: errorArray[j].message};
                this.reportDataArr.push(reportLine);
                    
            }
            errorArray = [];
        }
        this.rptTitle = "Conditions Edit Report";
        this.rptLines = this.reportDataArr;
        this.exportReportsDataset();
    }

    eventSelectAndTriggerConditions(){
        this.selectConditions = [];
        this.triggerConditions = [];
        for (let i=0; i<this.adminInfo.eventsData.length; i++){
             let eventsData = this.adminInfo.eventsData[i];
             if (eventsData.conditionArr !== undefined){
                let conditionList = eventsData.conditionArr;
                for (let j=0; j<conditionList.length; j++ ){
                    this.selectConditions.push(conditionList[j]);
                }
             }
             if (eventsData.skipConditionArr !== undefined){
                let skipConditionList = eventsData.skipConditionArr;
                for (let j=0; j<skipConditionList.length; j++){
                    this.selectConditions.push(skipConditionList[j])
                }
             }
             for (let j=0; j<eventsData.responses.length; j++){
                let response = eventsData.responses[j];
                for (let k=0; k<response.possibleEffects.length; k++){
                    let possibleEffects = response.possibleEffects[k];
                    if (possibleEffects.triggerCondition !== undefined){
                        this.triggerConditions.push(possibleEffects.triggerCondition);
                    }
                }
             }
        }
    }

    conditionsList(){
        var i;
        this.conditionListArr=[];
        var conditionObj="";
        for (i=0; i < this.adminInfo.conditionsData.length; i++){
            if (i===0){
                this.conditionsListHeader();
            }
            this.conditionNbr = i + 1;
            conditionObj = this.adminInfo.conditionsData[i];
            this.buildConditionsListArr(conditionObj);
        }
        this.rptTitle = "Conditions List";
        this.rptLines =  this.conditionListArr;
        this.exportReportsDataset();
    }

    conditionsListHeader(){
        let conditionString = {condNbr: "Condition Nbr", condName: "Condition Name", condClass: "Condition Class",
            primaryField: "Primary Field Name", relationType: "Relation Type", compareValues: "Compare Values",
            triggeredBy: "Triggered By"};
        this.conditionListArr.push(conditionString);
    }

    buildConditionsListArr(conditionObj){
        let compareValues = conditionObj.compareValues.join();
        let conditionString = {condNbr: this.conditionNbr, condName: conditionObj.condName, 
            condClass: conditionObj.conditionClass, primaryField: conditionObj.primaryFieldName,
            relationType: conditionObj.relationType, compareValues: compareValues,
            triggeredBy: conditionObj.triggeredBy};
        this.conditionListArr.push(conditionString);
    }

    rebuildJobConditionsList(){
        //   I believe after the xcel is created you can just upload to MongoDB via the mongoose tool
        this.jobConditionsArr = [];
        var conditionObj = "";
        this.jobOptionsArr = [];
        for (let i=0; i< this.adminInfo.jobsData.length; i++){
            if (this.adminInfo.jobsData[i].jobName !== "Template"){
                let jobName = this.adminInfo.jobsData[i].jobName;
                let condNamelc = (jobName.toLowerCase());
                let condName = condNamelc.split(" ").join("-"); 
                conditionObj = {
                        condName: condName,
                        conditionClass: "job",
                        primaryFieldName: "careerID",
                        relationType: "EQ",
                        compareValues: "[" + this.adminInfo.jobsData[i].id + "]",
                        triggeredBy:  "jobChange"
                        }
                this.jobConditionsArr.push(conditionObj);
            }
        }
        this.rptTitle = "Job Conditions";
        this.rptLines = this.jobConditionsArr;
        this.exportReportsDataset();
    }

    feedbackReportModal(feedbackType){
        this.openModal = true;
        switch (feedbackType){
            case "Game":
                this.loadGameFeedback();
                break;
            case "Player":
                this.loadPlayerFeedback();
                break;
            case "gameProfile":
                this.setState({getProfileParms: true});
                break;
            case "jobList":
                this.loadJobList();
                break;
            case "eventsForCondition":
                this.setState({eventsForCondition: true});
                break;
            case "testAssess":
                this.loadStudentTests(feedbackType);
                break;
            case "studentTest":
                this.loadStudentTests(feedbackType);
                break;
            case "openPMICalculator":
                this.setState({openPMICalculator: true});
                break;
            default:
                break;
        }
    }

    closeFeedbackReportModal(modalType){
        this.openModal = false;
        switch (modalType){
            case "gameFeedback":
                this.setState({loadGameFeedback: false})
                break;
            case "playerFeedback":
                this.setState({loadPlayerFeedback: false});
                break;
            case "gameData":
                this.setState({loadGameData: false});
                break;
            case "playerProfile":
                this.setState({getProfileParms: false});
                break;
            case "jobList":
                this.setState({loadJobListData: false});
                break;
            case "eventsForCondition":
                this.setState({eventsForCondition: false});
                break;
            case "moneyMgmtTest":
                this.setState({moneyMgmtTest: false});
                break;
            case "moneyMgmtStudentTest":
                this.setState({moneyMgmtStudentTest: false});
                break;
            case "openPMICalculator":
                this.setState({openPMICalculator: false});
                break;
            default:
                break;
        }
    }
 
    exportReportsDataset() {
        this.setState({exportToExcelButton: true});    
    }

    removeExportButton(){
        this.setState({exportToExcelButton: false});
    }
           
    render() {
        if(this.state.loadJobChoiceData === true && this.state.loadHomeChoiceData === true &&
           this.state.loadCarChoiceData === true){
                this.allChoicesArr = combinePlayerChoices(this.gameChoiceArr, this.jobChoiceData,
                    this.homeChoiceData, this.carChoiceData);
                this.waitState = false;
                this.loadGameChoicesData = true;
        }
        if (this.state.getProfileParms === true &&
            this.state.loadGameData === true && this.state.loadCreditCardData === true &&
            this.state.loadSavingsData === true && this.loadGameChoicesData === true){
                this.waitState = false;
            }
    return <>
           <div className="col-sm-6 mb-5 input-page-base-card">
                <div className="card mt-3 mr-5 rounded-corners-lg height-max">
                    <h1 className="mt-3 p-0 text-center">Game Administration</h1>
                    <h4 className="text-center">Event Condition and Other Reports</h4>
                    <table className="row-100  ml-2 mt-3 overflow-scroll">
                        <tbody className="w-40">
                            <tr>
                                <td className = "col-sm-4 text-center">  
                                    <button className="btn-group-wide" 
                                        onClick ={()=> this.editEventConditions()}>
                                           Edit Event Conditions
                                    </button>
                                </td>
                                <td className="col-sm-4 text-center">
                                    <button className="btn-group-wide" 
                                        onClick ={()=> this.feedbackReportModal("Game")}>
                                            Game Feedback
                                    </button>
                                </td>
                                <td className="col-sm-4 text-center">
                                    <button className="btn-group-wide" 
                                        onClick ={()=> this.conditionsList()}>
                                         Conditions List 
                                    </button>
                                </td>
                            </tr>
                            <tr >
                             
                                <td className="col-sm-4 text-center">
                                    <button className="btn-group-wide mt-3" 
                                        onClick ={()=> this.feedbackReportModal("Player")}>
                                            Player Comments
                                    </button>
                                </td>
                                <td className="col-sm-4 text-center">
                                    <button className="btn-group-wide mt-3" 
                                        onClick ={()=> this.feedbackReportModal("gameProfile")}>
                                            Game Profiles
                                    </button>
                                </td>
                                <td className="col-sm-4 text-center">
                                    <button className="btn-group-wide mt-3" 
                                         onClick ={()=> this.feedbackReportModal("jobList")}>
                                           Job List
                                    </button>
                                </td>
                            </tr>
                            <tr >
                                <td className="col-sm-4 text-center">
                                    <button className="btn-group-wide mt-3"
                                     onClick ={()=> this.feedbackReportModal("eventsForCondition")} >
                                        Events For Specified Condition
                                    </button>
                                </td>
                                <td className="col-sm-4 text-center">
                                    <button className="btn-group-wide mt-3" 
                                        onClick ={()=> this.feedbackReportModal("openPMICalculator")}>
                                            Loan and PMI Calculator
                                    </button>
                                </td>
                                <td className="col-sm-4 text-center">
                                    <button className="btn-group-wide mt-3" 
                                        onClick ={()=> this.rebuildJobConditionsList()}>
                                             Rebuild Job Condition Dataset
                                    </button>
                                </td>
                            </tr>
                            <tr >
                                <td className="col-sm-4 text-center" >
                                    <button className="btn-group-wide mt-3" 
                                        onClick ={()=> this.feedbackReportModal("testAssess")}>
                                            Test Assessment
                                    </button>
                                </td>
                                <td className="col-sm-4 text-center" >
                                    <button className="btn-group-wide mt-3" 
                                      onClick ={()=> this.feedbackReportModal("studentTest")}>
                                            Student Test Results
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>

                    {this.waitState ?
                      <div className="text-center mt-3">
                            <WaitTimeSpinner/>
                            <div className="mt-3 text-white">testing</div>
                            <h3 className="mt-5">This may take a few minutes to load.</h3>
                        </div>
                    :
                        null
                    }

                    {this.state.loadGameFeedback ?
                            <GameFeedbackDisplayModal openModal={this.openModal} 
                                onClose={() => this.closeFeedbackReportModal("gameFeedback")} 
                                gameFeedback={this.gameFeedback}
                             
                            />
                    :
                        null
                    }

                    {this.state.loadPlayerFeedback ?
                            <PlayerFeedbackDisplayModal openModal={this.openModal} 
                                onClose={() => this.closeFeedbackReportModal("playerFeedback")} 
                                commentsOnly={false}
                                playerFeedback={this.playerFeedback}
                             
                            />
                    :
                        null
                    }

                    {this.state.getProfileParms ?
                          <PlayerProfileParmInputModal openModal={this.openModal} 
                            onClose={() => this.closeFeedbackReportModal("playerProfile")} 
                            parmsComplete={(playerArr) => this.loadGamesForAccount(playerArr)} 
                          />
                    :
                        null
                    }

                    {this.state.loadGameData && this.state.loadSavingsData && this.state.loadCreditCardData 
                        && this.loadGameChoicesData ?
                            <GameProfileDisplayModal openModal={this.openModal} 
                                onClose={() => this.closeFeedbackReportModal("gameData")} 
                                gameData={this.gameData}
                                savingsData={this.savingsData}
                                creditCardData={this.creditCardData}
                                gameChoicesData={this.allChoicesArr}
                                screenSize={this.props.screenSize}
                                fromProcess={"admin"}
                            />
                    :
                        null
                    }

                    {this.state.openPMICalculator  ?
                         <LoanAndPMICalculatorModal openModal={this.openModal} 
                            onClose={() => this.closeFeedbackReportModal("openPMICalculator")} 
                          
                       />
                    :
                        null
                    }

                    {this.state.loadJobListData ?
                        <JobListModal
                            openModal={this.openModal}
                            jobListArr={this.jobList}
                            onClose={() => this.closeFeedbackReportModal("jobList")} 
                        />
                    :
                        null
                    }
                    
                    {this.state.moneyMgmtTest ?
                        <MoneyMgmtTestModal
                            openModal={this.openModal}
                            moneyMgmtTestArr={this.moneyMgmtTestData}
                            onClose={() => this.closeFeedbackReportModal("moneyMgmtTest")} 
                        />
                    :
                        null
                    }
                          
                    {this.state.studentTest ?
                        <MoneyMgmtStudentTestModal
                            openModal={this.openModal}
                            moneyMgmtTestArr={this.moneyMgmtTestData}
                            onClose={() => this.closeFeedbackReportModal("studentTest")} 
                        />
                    :
                        null
                    }

                    {this.state.eventsForCondition ?
                        <SelectSkipConditionCheckModal
                            openModal={this.openModal}
                            eventsData={this.adminInfo.eventsData}
                            onClose={() => this.closeFeedbackReportModal("eventsForCondition")} 
                        />
                    :
                        null
                    }
                                                         
                    </div>
                    {this.state.exportToExcelButton ?
                        <ExportToExcelButton
                            fileName={this.rptTitle}
                            rptHeader={this.rptHeader}
                            data={this.rptLines}
                            exportComplete={() => this.removeExportButton()}
                        />
                    :
                        null
                    }

                   
                </div>        
            </div>
        </>
    }

loadGameFeedback(){
    const funcName = "loadGameFeedback";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.gameFeedback = output.body;
                        this.setState({loadGameFeedback: true})
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
}


loadPlayerFeedback(){
    const funcName = "loadPlayerFeedback";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
       {method:'GET', headers: this.state.headers})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.sortPlayerFeedback(output.body);
                    this.setState({loadPlayerFeedback: true})
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
}

sortPlayerFeedback(playerFeedback){
    this.playerFeedback = [];
    for (let i=0; i<playerFeedback.length; i++){
        let sortKey = playerFeedback[i].user + playerFeedback[i].dateAdded + playerFeedback[i].timeAdded;
        let feedbackObj = {...playerFeedback[i], sortKey: sortKey};
        this.playerFeedback.push(feedbackObj);
    }
    (this.playerFeedback).sort((a, b) => (a.sortKey > b.sortKey) ? 1 : -1)
}


loadGamesForAccount(playerArr){
    this.setState({loadGameFeedback: false});
    this.setState({waitState: true});
    let userNameArr = (playerArr).join(',');
    const funcName = "loadGamesForAccount";
    const urlParm = "?userNameArr=" + userNameArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        (output.body).sort((a, b) => (a.user > b.user) ? 1 : -1)
                        this.gameData = output.body;
                        this.setGameIdArr();
                        this.loadSavings();
                        this.loadCreditCardInfo();
                        this.loadGameChoices();
                        this.setState({loadGameData: true})
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
}

setGameIdArr(){
    this.gameIdArr = [];
    this.gameChoiceArr = [];
    for (let i=0; i<this.gameData.length; i++){
          this.gameIdArr.push(this.gameData[i]._id);
          let gameChoiceObj = {gameID:this.gameData[i]._id, jobID:this.gameData[i].jobInfo.careerID,
            homeID:this.gameData[i].homeInfo.homeID, carID:this.gameData[i].carInfo.carID};
          this.gameChoiceArr.push(gameChoiceObj);
    }
}

loadSavings() {
    let gameID = (this.gameIdArr).join(',');
    const funcName = "loadSavings";
    const urlParm = "?gameID=" + gameID + "&returnType=Multiple"; 
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET', headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                   this.savingsData = output.body;
                    this.setState({loadSavingsData: true});
                   
                }else{
                    if (statusCode === 250){
                       this.savingsData = [];
                        this.setState({loadSavingsData: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                }
            });
        };
    });
}


loadCreditCardInfo() {
    let gameID = (this.gameIdArr).join(',');
    const funcName = "loadCreditCardInfo";
    const urlParm = "?gameID=" + gameID + "&returnType=Multiple"; 
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
   
    {method:'GET', headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                   this.creditCardData = output.body;
                    this.setState({loadCreditCardData: true});
                }else{
                    if (statusCode === 250){
                       this.creditCardData = [];
                        this.setState({loadCreditCardData: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                }
            });
        };
    });
}


loadGameChoices(){
    this.loadJobChoices();
    this.loadHomeChoices();
    this.loadCarChoices();
  }


loadJobChoices(){
    let jobIdArr=[];
    for (let i=0; i<this.gameChoiceArr.length; i++){
          jobIdArr.push(this.gameChoiceArr[i].jobID);
    }
    const jobIdParm = jobIdArr.join(',');
    const funcName = "jobInfo";
    const urlParm = "?jobId=" + jobIdParm + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', 
        headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.jobChoiceData = output.body;
                    this.setState({loadJobChoiceData: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
  }

  
loadHomeChoices(){
    let homeIdArr=[];
    for (let i=0; i<this.gameChoiceArr.length; i++){
          homeIdArr.push(this.gameChoiceArr[i].homeID);
    }
    const homeIdParm = homeIdArr.join(',');
    const funcName = "homeInfo";
    const urlParm = "?homeId=" + homeIdParm + "&returnType=Multiple";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
        {method:'GET', 
        headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.homeChoiceData = output.body;
                    this.setState({loadHomeChoiceData: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
  }
    
loadCarChoices(){
    let carIdArr=[];
    for (let i=0; i<this.gameChoiceArr.length; i++){
          carIdArr.push(this.gameChoiceArr[i].carID);
    }
    const carIdParm = carIdArr.join(',')
    const funcName = "carInfo";
    const urlParm = "?carId=" + carIdParm + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', 
        headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.carChoiceData = output.body;
                    this.setState({loadCarChoiceData: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
}

  loadJobList(){
    this.setState({waitState: true});
    const funcName = "jobListAll";
    const urlParm = ""; 
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                       this.jobList = output.body;
                       this.jobList = 
                           this.jobList.sort((a, b) => (a.jobName > b.jobName) ? 1 : -1)
                        this.setState({loadJobListData: true});
                        this.waitState = false;
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
  }
  
  loadStudentTests(reportType){
    this.setState({waitState: true});
    const funcName = "loadMoneyMgmtTest";
    const urlParm = ""; 
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                       this.moneyMgmtTestData = output.body;
                       this.moneyMgmtTestData = 
                           this.moneyMgmtTestData.sort((a, b) => (a.user + a.dateAdded > b.user + b.dateAdded) ? 1 : -1);
                        if (reportType === "testAssess"){
                            this.setState({moneyMgmtTest: true});
                        }else{
                            this.setState({studentTest: true});
                        }
                        this.waitState = false;
                    }else{
                        if (statusCode === 250){
                            alert("There is no student test data for this parent/teacher.")
                                        
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                    status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
            };
        });
  }
 

}

export default ConditionReports;