const MoneyMgmtTestDataset = {currentVersion: 1.0, questionList: [
   {questionNbr: 1, questionText: "Which of these is considered good <<money management>> advice?", 
      answerType: "multiple", version: 1.0, answerList: [
      {checked: false, answer: "a", correct: false,
         label: "The best <<investments>> are risky because they offer the highest return." 
      },
      {checked: false, answer: "b", correct: false,
         label: "You can spend as much as you want each month as long as you don’t spend more than what you make."
      },
      {checked: false, answer: "d", correct: true,
         label: "If possible, save some money each month."
      },
      {checked: false, answer: "e", correct: true,
         label: "It is a good idea to know how much money you have each month to buy things that you might want."
      }
   ]},
   {questionNbr: 2, questionText: "What is the best advice about saving money?", 
      answerType: "single", version: 1.0, answerList: [
      {checked: false, answer: "a", correct: false,
         label: "Saving money is not really that important. As long as you make enough to cover " +
            "monthly <<expenses>>, all is well."}, 
      {checked: false, answer: "b", correct: false,
         label: "Saving is the most important thing. Even if you can't pay off your credit card in a " +
            "given month, you should still stick to your <<monthly savings amount>>."
      },
      {checked: false, answer: "c", correct: false,
         label: "Start saving money when you are within 10 years of retirement so " +
         " you will have money for fun items when you are young." 
      },
      {checked: false, answer: "d", correct: true,
         label: "Start saving when you are young and save as much as you can each month."
      },
   ]},
   {questionNbr: 3, questionText: "What is the best way to manage your credit card?", 
      answerType: "single", version: 1.0, answerList: [
      {checked: false, answer: "a", correct: false,
          label: "At the end of the month, pay what you can as long as it is more than " +
            "the <<minimum payment>> amount."
      }, 
      {checked: false, answer: "b", correct: false,
         label: "It is best if you just pay the <<minimum payment>> each month."
      },
      {checked: false, answer: "c", correct: false,
         label: "You should never get a credit card."
      },
      {checked: false, answer: "d", correct: true,
         label: "Pay off the entire balance each month."
      },
   ]},
   {questionNbr: 4, questionText: "Which of the following affect the amount of the monthly <<loan payment>>?", 
      answerType: "multiple", version: 1.0, answerList: [
      {checked: false, answer: "a", correct: true,
         label: "The <<interest rate>> that is charged on the loan."
      }, 
      {checked: false, answer: "b", correct: true,
         label: "The amount of time that you have to pay back the loan (<<loan term>>)."
      },
      {checked: false, answer: "c", correct: true,
         label: "The total amount of the loan."
      },
      {checked: false, answer: "d", correct: false,
         label: "The amount of money (salary) that the borrower makes each month."   
      },
   ]},
   {questionNbr: 5, questionText: "Why is it a good idea to create a <<monthly budget>>?", 
      answerType: "multiple", version: 1.0, answerList: [
      {checked: false, answer: "a", correct: true,
         label: "It helps you identify items that you can change to improve your money status."
      }, 
      {checked: false, answer: "b", correct: true,
         label: "You will have a good idea of how much money you can spend each month on " +
            "extra (non-mandatory) items."
      },
      {checked: false, answer: "c", correct: false,
         label: "It increases your <<salary>>."
      },
      {checked: false, answer: "d", correct: false,
         label: "Having a monthly budget will impress your family and friends."            
      },
   ]},
   {questionNbr: 6, questionText: "Which of the following is something you really should consider when " +
      "choosing a career.", 
      answerType: "multiple", version: 1.0, answerList: [
      {checked: false, answer: "a", correct: true,
         label: "The education requirements for the job."
      }, 
      {checked: false, answer: "b", correct: true,
         label: "Whether or not the job seems to match your skills and interests."
      },
      {checked: false, answer: "c", correct: true,
         label: "The <<salary>> that you would make."
      },
      {checked: false, answer: "d", correct: false,
         label: "Your friends think the job sounds cool."
      },
   ]},
   {questionNbr: 7, questionText: "You have $100 left after <<expenses>> this month.  How should you spend " +
      "the money?", 
      answerType: "single", version: 1.0, answerList: [
      {checked: false, answer: "a", correct: true,
         label: "Purchase food at the grocery store."
      }, 
      {checked: false, answer: "b", correct: false,
         label: "Buy some more clothes for work."   
      },
      {checked: false, answer: "c", correct: false,
         label: "Go golfing with your best friend."
      },
      {checked: false, answer: "d", correct: false,
         label: "Pay a plumber to fix the leaking faucet in your bathroom."
      },
   ]},
   {questionNbr: 8, questionText: "Which one of the following is considered to be a <<fixed expense>>?", 
      answerType: "single", version: 1.0, answerList: [
      {checked: false, answer: "a", correct: true,
         label: "Car <<loan payment>>."
      }, 
      {checked: false, answer: "b", correct: false,
         label: "Food at a grocery store."
      },
      {checked: false, answer: "c", correct: false,
         label: "Cost of heating and electricity."   
      },
      {checked: false, answer: "d", correct: false,
         label: "Clothes for your job (your current wardrobe isn't appropriate for your job)."
      },
   ]},
   {questionNbr: 9, questionText: "Which of the following are part of a <<monthly budget>>?", 
      answerType: "multiple", version: 1.0, answerList: [
      {checked: false, answer: "a",  correct: true,
         label: "<<Salary>> amount."
      }, 
      {checked: false, answer: "b", correct: true,
         label: "<<Loan payment>> amounts."
      },
      {checked: false, answer: "c", correct: true,
         label: "The <<monthly savings amount>>."
      },
      {checked: false, answer: "d", correct: false,
         label: "Paying an unexpected medical bill."
      },
   ]},
   {questionNbr: 10, questionText: "What is the primary purpose of a <<401K savings account>>?", 
      answerType: "single", version: 1.0, answerList: [
      {checked: false, answer: "a", correct: true,
         label: "To save for retirement."
      }, 
      {checked: false, answer: "b", correct: false,
         label: "To invest in the stock market."
      },
      {checked: false, answer: "c", correct: false,
         label: "To save for a downpayment on a house."
      },
      {checked: false, answer: "d", correct: false,
         label: "To have an emergency fund for unexpected expenses."
      },
   ]},

]};

export default MoneyMgmtTestDataset;