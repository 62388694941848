
export const setPathInMaze = (activeBoard, goodPath, lastEntry, gridWidth, gridHeight, blockOutImg) => {
    var elementCheck;
    var cell = "";

    let houseLocation = goodPath[lastEntry];
    let rowColumn = houseLocation.split('|');
    let houseRowPrev = +rowColumn[0] - 1;
    let houseColumnPrev = +rowColumn[1] - 1;
    let houseColumnAfter = +rowColumn[1] + 1;
   
    for (let row=1; row <= gridHeight; row++){
        for (let column = 1; column <= gridWidth; column++){
          if (row == houseRowPrev &&  
             (column == houseColumnPrev || column == houseColumnAfter)) {
                // skip boxes that are on either side of the house in the previous row
          }else{
              cell = row + "|" + column;
              if (!goodPath.includes(cell)){
                elementCheck = document.getElementById(`${cell}`);
                if (elementCheck !== undefined &&
                    elementCheck !== null ){
                      elementCheck.classList.add(blockOutImg);
                      let removeIdx = activeBoard.indexOf(cell);
                      activeBoard.splice(removeIdx,1);
                }
              }
          }
        }
    }
    return activeBoard;
   } 
    


