const EventOnLitList =[
  {
    "category": "cash",
    "value": "cash",
    "selection": "Update Current Cash"
  },
  {
    "category": "cash",
    "value": "cashOnly",
    "selection": "Update Cash-do not apply to Credit Card"
  },
  {
    "category": "asset",
    "value": "Job Salary",
    "selection": "Update Asset Salary"
  },
  {
    "category": "expense",
    "value": "Apartment Rent",
    "selection": "Update Expense-Rent"
  },
  {
    "category": "expense",
    "value": "Health Insurance",
    "selection": "Expense-Health Ins"
  },
  {
    "category": "expense",
    "value": "Renter's Insurance",
    "selection": "Expense-Rent Insurance"
  },
  {
    "category": "expense",
    "value": "Home Owners Insurance",
    "selection": "Expense-Home Insurance"
  },
  {
    "category": "expense",
    "value": "Automobile Insurance",
    "selection": "Expense-Auto Insurance"
  },
  {
    "category": "expense",
    "value": "Transportation",
    "selection": "Expense-Transportation"
  },
  {
    "category": "expense",
    "value": "Pet Expenses",
    "selection": "Expense-Pet Expenses"
  },
  {
    "category": "expense",
    "value": "Boat Expenses",
    "selection": "Expense-Boat Expenses"
  },
  {
    "category": "expense",
    "value": "Horse Expenses",
    "selection": "Expense-Horse Expenses"
  },
  {
    "category": "expense",
    "value": "Misc Expenses",
    "selection": "Expense-Misc Expense"
  },
  {
    "category": "expense",
    "value": "Phone",
    "selection": "Update Expense-Phone"
  },
  {
    "category": "expense",
    "value": "Streaming Movies",
    "selection": "Update Expense-Movies"
  },
  {
    "category": "loan",
    "value": "College Loan",
    "selection": "Update Loan-College"
  },
  {
    "category": "loan",
    "value": "Car Loan",
    "selection": "Update Loan-Car"
  },
  {
    "category": "loan",
    "value": "Other Loan",
    "selection": "Update Loan-Other"
  },
  
];

export default EventOnLitList;