import {Component} from 'react';
import { withRouter } from "react-router";
import MoneyMgmtTestDataset from './Datasets/money_mgmt_test_dataset';
import TestQuestionAnswers from './test_question_answers';
import TestButtonDisplay from './test_button_display';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import shuffleArray from '../../objects/CommonUse/shuffleArray';
import { buildUrl} from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import ConfigFile from '../../datasets/config_file';
import MoneyMgmtJokes from './Datasets/money_mgmt_jokes';
import calcRandomNumberWithMax from '../../objects/CommonUse/calc_random_number_with_max';

class MoneyMgmtConceptTest extends Component {
  constructor(props) {
    super(props);
    this.state={  
        archivedGamesLoaded: false,
        warningMsg: false,
        nextButton: true,
        slctDone: false,
        animationComplete: true,
        displayResults: false,
        jokeResponse: false,
        subProcess: "MoneyMgmtTest",
        environment: Config.dataURL.currentEnv,
        headers: setHeadersForFetch()
    }    
    this.allQuestions = MoneyMgmtTestDataset.questionList;
    this.testVersion = MoneyMgmtTestDataset.currentVersion;
    this.allJokesArr = MoneyMgmtJokes;
    this.questionNbr = 0;
    this.jokeQuestionNbrArr = [2, 4, 7, 8, 10];
    this.buttonText = "Start";
    this.errorMsg = "";
    this.answerSet = false;
    this.getArchivedGameData();
    this.getTestQuestionList();
    this.determineJokeNbrs();
}

getTestQuestionList(){
    this.testQuestions = [];
    for (let i=0; i<this.allQuestions.length; i++){
        if (this.allQuestions[i].version == this.testVersion){
            let questionObj = this.allQuestions[i];
            questionObj.answerList = shuffleArray(questionObj.answerList);
            this.testQuestions.push(questionObj);
        }
    }
    this.testQuestions = shuffleArray(this.testQuestions);
    this.totalNbrQuestions = this.testQuestions.length;
  
}

determineJokeNbrs(){
    const jokeLength = this.allJokesArr.length;
    this.jokeArr = [];
    while (this.jokeArr.length <= this.jokeQuestionNbrArr.length){
        let randomNbr = calcRandomNumberWithMax(jokeLength, true);
        if (this.jokeArr.includes(randomNbr)){
            //  this random number is already in the array
        }else{
            this.jokeArr.push(randomNbr);
        }
    }
}

getNextQuestion(){
    if (this.buttonText == "Return to HomePage"){
        this.props.history.push("/");  
    }else{
        if (this.buttonText == "Finish"){
            this.finishTest();
        }else{
            this.questionNbr += 1;
            if (this.questionNbr == this.totalNbrQuestions){
                this.buttonText = "Finish";
            }else{
                this.buttonText = "Next";
            }
            this.setQuestionText();
        }
    }
   
}

getPreviousQuestion(){
    this.questionNbr = this.questionNbr - 1;
    this.displayJoke = true;
    this.setQuestionText();
}

setQuestionText(){
    this.determineIfJoke();
    let indx = this.questionNbr - 1;
    this.questionObj = this.testQuestions[indx];
    const answerObj = this.questionObj.answerList.find(elem => elem.checked === true);
    if (answerObj != undefined){
        this.setState({nextButton: true});
    }else{
        this.setState({nextButton: false});
    }
}


determineIfJoke(){
    this.displayJoke = false;
    for (let i=0; i<this.jokeQuestionNbrArr.length; i++){
        if (this.jokeQuestionNbrArr[i] === this.questionNbr){
            const jokeIndx = this.jokeArr[i];
            this.jokeObj = this.allJokesArr[jokeIndx];
            this.displayJoke = true;
        }
    }
    this.setState({jokeResponse: false});
}

answerSelected(answerObj){
    // reset the answer list so all entries are false if only 1 answer is allowed
    if (this.questionObj.answerType == "single"){
        for (let i=0; i<this.questionObj.answerList.length; i++){
            this.questionObj.answerList[i].checked = false;
        }
    }
    let answer = answerObj.answer;
    const answerIndx = this.questionObj.answerList.findIndex(elem => elem.answer === answer);
    this.questionObj.answerList[answerIndx].checked = answerObj.checked;
    this.answerError = false;
    this.errorMsg = "";
    this.editAnswerArr();
    if (this.answerError == false){
        this.answerSet = true;
        this.setState({nextButton: true});
    }else{
        this.setState({nextButton: false});
    }
    
}

editAnswerArr(){
  let answerCount = 0;
  for (let i=0; i<this.questionObj.answerList.length; i++){
       let answerList = this.questionObj.answerList[i];
       if (answerList.checked == true){
            answerCount += 1;
       }
  }
  if (answerCount == 0){
      this.answerError = true;
      this.errorMsg = "You Must Select at least 1 answer.";
  }
  if (this.answerError == false){
      if (this.questionObj.answerType == "single" && answerCount > 1){
          this.answerError = true;
          this.errorMsg = "Only 1 answer can be selected for this question.";
      }
  }
}

finishTest(){
    //  'grade' test and save to mongodb - save questions, answers and correct/incorrect
    this.gradeTest();
    this.saveTestResults();
    this.questionNbr = 99;
    this.buttonText = "Return to HomePage";
    this.setState({displayResults: true});
}

gradeTest(){
    this.nbrCorrect = 0;
    for (let i=0; i<this.testQuestions.length; i++){
        let question = this.testQuestions[i];
        let totalRightAnswers = 0;
        let nbrCorrectAnswers = 0;
        let nbrIncorrectAnswers = 0;
        for (let j=0; j<question.answerList.length; j++){
            let answer = question.answerList[j];
            if (answer.checked == answer.correct && answer.correct == true){
                nbrCorrectAnswers += 1;
            }else{
                if (answer.checked == true){
                    nbrIncorrectAnswers += 1;
                }
            }
            if (answer.correct == true){
                totalRightAnswers += 1;
            }
        }
        let nbrCorrectCheckMarks = 0;
        if (nbrCorrectAnswers >= nbrIncorrectAnswers){
            nbrCorrectCheckMarks = nbrCorrectAnswers - nbrIncorrectAnswers;
        }
        const pctCorrect = +(nbrCorrectCheckMarks / totalRightAnswers).toFixed(2);
        this.nbrCorrect += pctCorrect;
        this.testQuestions[i] = {...this.testQuestions[i], answerCorrectPct: pctCorrect};
    }
    this.pctCorrect = +((this.nbrCorrect / this.testQuestions.length) * 100).toFixed(2);
    
    this.starClass = "super-star-info";
    if (+this.pctCorrect >= 90){
        this.starClass = this.starClass + " star-spin-around"
    }
    this.setFinishComment();
    this.pctCorrect = this.pctCorrect + "%";
}

setFinishComment(){
    switch (true){
        case (this.pctCorrect >= 90):
            this.comment = "You did an amazing job, you are a Money Management Superstar!!";
            break;
        case (this.pctCorrect >= 80):
            this.comment = "You did a great job, you are well on your way to being a Money Management Superstar!!"
            break;
        case (this.pctCorrect >= 60):
            this.comment = "You did a good job, keep playing the game and you will become a Money Management Superstar."
            break;
        default:
            this.comment = "You did not do very well on the test this time, but keep playing the game and you will " +
                "become a Money Management Superstar.";
            break;
    }
    let checkNbr = +this.nbrCorrect.toFixed(0);
    this.partialCredit = false;
    if (this.nbrCorrect !== checkNbr){
        this.partialCredit = true;
    }
}

setAnimationComplete(){
    this.setState({animationComplete: true});
}


getJokeQuestion(){
    this.setState({jokeResponse: false});
}

getJokeResponse(){
    this.setState({jokeResponse: true});
}
   
render() {
    let screenParms = setScreenSizeParameters(this.props.screenSize, "extraCredit",
        "mb-5 mt-4 input-page-base-card");
    this.className = screenParms.columnClass;
    this.textClass = screenParms.textSize;

return <>
    <div className={this.className}>
        <div className="card mb-0 rounded-corners-lg height-max">
            <button className="pages-back-button ml-4 mt-4" 
                onClick ={()=> this.props.goBack()}>
                                    Back
            </button>
        <h3 className="mt-0 text-center">Extra Credit</h3>
        <h5 className="text-center">Are You a Money Management Superstar?</h5>

        {!this.state.archivedGamesLoaded ?
            <WaitTimeSpinner />
        :
            <span>
            <div>
                {!this.state.displayResults ?
                    <span>
                        {this.questionNbr > 0  ?
                            <span>
                                <TestQuestionAnswers
                                    testQuestion = {this.questionObj}
                                    screenSize={this.props.screenSize}
                                    displayJoke={this.displayJoke}
                                    jokeResponse={this.state.jokeResponse}
                                    jokeObj={this.jokeObj}
                                    onSelect={(answerObj)=> this.answerSelected(answerObj)}
                                    getJokeQuestion={()=> this.getJokeQuestion()}
                                    getJokeResponse={()=> this.getJokeResponse()}
                                
                                />
                                <div className="text-right font-weight-bold mr-4 mb-2">
                                    Question Nbr {this.questionNbr} / {this.totalNbrQuestions} Total Questions
                                </div>
                            </span>
                   
                        :   
                            <span className="row">
                                <div className="col-sm-8 mt-4">
                                    <div className="ml-4 comic-sans-font medium-large-text">
                                        <b> How much do you know about money management?
                                        There are just {this.testQuestions.length} questions in this assessment.
                                        Click on the "Start" button to begin.</b></div>
                                    <div className="mt-2 text-white">blank</div>
                                    <div className="mt-2 ml-4 comic-sans-font "><b>Note:</b> You can click on text
                                        that is displayed in blue to display a definition of
                                        the word or term.
                                    </div>
                                    <div className="mt-2 text-white">blank</div>
                                </div>
                                <div className="col-sm-4 mt-2">
                                    <img className="super-star-info2"
                                        src="./assets/testPictures/superStar.png"  alt="superStar"
                                    />
                                </div>
                               
                                
                            </span>
                        }
                    </span>
                :           
                    <span className="row mt-4">
                        <div className="col-sm-6 mt-4 ml-4">
                            <div><b>Total Questions correct:</b> {this.nbrCorrect}</div>
                            <div><b>Percentage Correct:</b>&nbsp;&nbsp;&nbsp; {this.pctCorrect}</div>
                            {this.partialCredit ?
                                <div className="font-weight-bold mt-4">
                                    Note:  You have received partial credit for some of the questions with
                                    multiple answers.
                                </div>
                            :
                                null
                            }
                            <div className="font-weight-bold mt-4">{this.comment}</div>
                          
                        </div>
                        <div className="col-sm-4 mt-4">
                            <img className={this.starClass} style={{opacity: this.pctCorrect}}
                                    src="./assets/testPictures/superStar.png"  alt="superStar"
                                    />
                        </div>
                    </span>
                }
            </div>
          
            <div className="text-danger font-weight-bold mt-0">{this.errorMsg}</div>
                    
            <TestButtonDisplay
                    questionNbr={this.questionNbr}
                    answerSet={this.answerSet}
                    nextButton={this.state.nextButton}
                    buttonText={this.buttonText}
                    onSubmit={()=>this.answerSubmitted()}
                    getNextQuestion={()=> this.getNextQuestion()}
                    getPreviousQuestion={()=> this.getPreviousQuestion()}
            />

            
            </span>
            
        }
        </div>
     </div>
  </>
}

getArchivedGameData()
{
    const funcName = "archivedGameData";
    const totalGameMonths = ConfigFile.gameStartItems.totalNbrGameMonths;
    const urlParm = "?totalGameMonths=" + totalGameMonths + "&rqstType=CountOfGames";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'GET', headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.archivedGames = output.body;
                    this.setState({archivedGamesLoaded: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.processErrorReturn(errorObj);
                }
            });
        };
    });
}

saveTestResults(){
    this.buildQuestionAnswerArr();
    let gameNbrMonths = (+this.props.currentGameMonths + +this.archivedGames.nbrGameMonths).toString();
    let nbrGames = (+this.archivedGames.nbrGames + 1).toString();
    const user = sessionStorage.getItem("username");
    let testVersion = this.testVersion.toString();
    let nbrCorrect = this.nbrCorrect.toString();
    let nbrQuestions = (this.testQuestions.length).toString();
    const bodyObj = {user: user, testVersion: testVersion, nbrGames: nbrGames, gameNbrMonths: gameNbrMonths,
                     nbrCorrect: nbrCorrect, nbrQuestions: nbrQuestions, 
                     questionAnswerArr: this.questionAnswerArr
                    };
    const funcName = "saveMoneyMgmtTest";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
         {method:'POST', 
          headers: this.state.headers,
          body: JSON.stringify(bodyObj)})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                           // update was successful");
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: bodyObj};
                            this.props.processError(errorObj);
                        }
                    });
                };
            });
}


buildQuestionAnswerArr(){
   this.questionAnswerArr = []; 
   for (let i=0; i<this.testQuestions.length; i++){
        let answerArr = [];
        const question = this.testQuestions[i];
        let nbrRightAnswers = 0;
        for (let j=0; j<question.answerList.length; j++){
            if (question.answerList[j].checked == true){
                const answerObj = {answer: question.answerList[j].answer, correct: question.answerList[j].correct};
                answerArr.push(answerObj);
            }
            if (question.answerList[j].correct == true){
                nbrRightAnswers += 1;
            }
        }
        let questionObj = {questionNbr: question.questionNbr, text: question.questionText, 
            nbrRightAnswers: nbrRightAnswers, answerArr: answerArr, correct: question.answerCorrectPct}
        this.questionAnswerArr.push(questionObj);
   }
}

}

  export default withRouter(MoneyMgmtConceptTest);