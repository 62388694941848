import React from 'react';
import { withRouter } from "react-router";
import LinkCard from '../shared-components/link_card';
import LivingBelowYourMeans from './living_below_your_means'
import LearningAboutLoans from './learning_about_loans';
import SavingMoney from './saving_money';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';

class Level2Challenges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextProcess: "",
            modalOpen: false,
            prevProcess: this.props.prevProcess,
            category1Complete: false,
            category2Complete: false,
            category3Complete: false,
            category1Name: "LivingBelowYourMeans",
            category2Name: "SavingMoney",
            category3Name: "LearningAboutLoans",
                }  
        this.setCompleted();  
    }

setCompleted(){
    let exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.state.category1Name);
    if (exerciseInfo != undefined && exerciseInfo.complete == true){
        this.state.category1Complete = true;
    }
    exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.state.category2Name);
    if (exerciseInfo != undefined && exerciseInfo.complete == true){
        this.state.category2Complete = true;
    }
    exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.state.category3Name);
    if (exerciseInfo != undefined && exerciseInfo.complete == true){
        this.state.category3Complete = true;
    }
}


selectLivingBelowChallenge() {
    if (this.state.category1Complete == false){
        this.state.nextProcess = this.state.category1Name;
        this.state.modalOpen = true;
    }
}

selectSavingMoneyChallenge() {
    if (this.state.category2Complete == false){
        this.state.nextProcess = this.state.category2Name;
        this.state.modalOpen = true;
    }
}

selectLearningAboutLoansChallenge() {
    if (this.state.category3Complete == false){
        this.state.nextProcess = this.state.category3Name;
        this.state.modalOpen = true;
    }
}

closeModal(){
    this.state.modalOpen = false;
}


render() {
        if (this.state.nextProcess != ""){
            sessionStorage.setItem("GameViewSubProcess", this.state.nextProcess)
        }
        let screenParms = setScreenSizeParameters(this.props.screenSize, "extraCredit");
        this.className = screenParms.columnClass;
        this.linkSize = screenParms.linkSize;
        
return <>
           <div className={this.className}>
           <div className="card mt-3 rounded-corners-lg h-100 text-center">
                <button className="pages-back-button ml-4 mt-4" 
                            onClick ={()=> this.props.goBack()}>
                                    Back
                </button>
               <h3 className="mt-0 text-center">Next Level Challenges</h3>
           
            <span>
            <span className="row w-100 mx-0 ml-4">
                <span/>
               
                {this.state.category1Complete ?
                    <span className="col-sm-5 mx-2 mt-4 buttonDisabledImage" >
                        <div>
                        <LinkCard name="Living Below Your Means" imgSrc='./assets/ExtraCredit/livingBelowMeans.png' 
                            inSize={this.linkSize} alt=""/>
                         </div>
                    </span>
                :
                    <span className="col-sm-5 mx-2 mt-4" onClick={() => this.selectLivingBelowChallenge()}>
                        <div>
                        <LinkCard name="Living Below Your Means" imgSrc='./assets/ExtraCredit/livingBelowMeans.png' 
                            inSize={this.linkSize} alt=""/>
                        </div>
                    </span>
                }
         
            {this.state.category2Complete ?
                    <span className="col-sm-5 mx-2 mt-4 buttonDisabledImage">
                        <div>
                            <LinkCard name="Saving Money" imgSrc='./assets/ExtraCredit/saveMoney.png'
                                inSize={this.linkSize} alt=""
                        />
                        </div>
                    </span>
                :
                    <span className="col-sm-5 mx-2 mt-4"
                        onClick={() => this.selectSavingMoneyChallenge()}>
                        <div>
                        <LinkCard name="Saving Money" imgSrc='./assets/ExtraCredit/saveMoney.png'
                            inSize={this.linkSize} alt=""
                            />
                        </div>
                    </span>
                }
            </span>
  
            <span className="row w-100 mx-0 ml-4">
            {this.state.category3Complete ?
                    <span className="col-sm-6 mx-2 mt-4 buttonDisabledImage" >
                        <div>
                        <LinkCard name="Learning About Loans" imgSrc='./assets/ExtraCredit/learningAboutLoans.png' 
                            inSize={this.linkSize} alt=""/>
                         </div>
                    </span>
                :
                    <span className="col-sm-6 mx-2 mt-4" onClick={() => this.selectLearningAboutLoansChallenge()}>
                        <div>
                        <LinkCard name="Learning About Loans" imgSrc='./assets/ExtraCredit/learningAboutLoans.png' 
                            inSize={this.linkSize} alt=""/>
                        </div>
                    </span>
                }
            </span>
            </span>
       
          
             {this.state.nextProcess == this.state.category1Name ?
               <LivingBelowYourMeans
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.state.category1Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
              {this.state.nextProcess == this.state.category2Name ?
                <SavingMoney
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.state.category2Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
            {this.state.nextProcess == this.state.category3Name ?
               <LearningAboutLoans 
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.state.category3Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
        </div>
       
    </div>   
</>
  }
  
    challengeCompleted(processName) {
        this.props.updateAmounts();
        if (processName == this.state.category1Name){
            this.state.category1Complete = true;
        }
        if (processName == this.state.category2Name){
            this.state.category2Complete = true;
        }
        if (processName == this.state.category3Name){
            this.state.category3Complete = true;
        }
        
        if (this.state.category1Complete == true && this.state.category2Complete == true 
            && this.state.category3Complete == true){
            this.props.completed(); 
            this.props.history.push('/extra-credit-main');
        }else{
            this.state.modalOpen = false;
        }
    }

}

export default withRouter(Level2Challenges);