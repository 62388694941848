import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { doubleToStringMonthly } from "../../../objects/CommonUse/double_functions";
import { applyToCreditCard } from "./apply_to_credit_card";
import { checkIfCreditCard } from "./check_if_credit_card";

export const updateRecurringGameDataObjects = (objectType, objectName, updateType, updateValue, duration,
    objectDesc, replaceAmtSw, objectTable, currentCashAmt, currentGameDate, unlockedFeatures,
    creditCardInfo, intRate, loanTerm, totalLoanAmt, downPayment) => {

    var objectFound = false;
    var itemType = "";
    var itemDesc = "";  
    var itemAmt = 0;
    var itemNbrMonths = 0;
    var itemStartDate = 0;
    var newSalaryAmt = 0;
    var newCashAmt = currentCashAmt; 
    var cashOrCredit = "";
    var spendAmt = 0;
    var i;
  
    // Loop through objects in the Table to find a match for the search name
    for (i=0; i<objectTable.length && objectFound == false; i++)
    {
        switch (objectType) {
            case "Asset": {
                itemType = objectTable[i].assetItem;
                itemDesc = objectTable[i].assetDesc;
                itemAmt = objectTable[i].assetAmt;
                itemNbrMonths = objectTable[i].assetNbrMonths;
                itemStartDate = objectTable[i].assetStartDate;
                objectFound = true;
                break;
            }
        case "Expense": {
                itemType = objectTable[i].expItem;
                itemDesc = objectTable[i].expDesc;
                itemAmt = objectTable[i].expAmount;
                itemNbrMonths = objectTable[i].expNbrMonths;
                itemStartDate = objectTable[i].expStartDate;
                objectFound = true;
                break;
            }
        case "Loan": {
                itemType = objectTable[i].loanItem;
                itemDesc = objectTable[i].loanDesc;
                itemAmt = objectTable[i].loanAmt;
                itemNbrMonths = objectTable[i].loanNbrMonths;
                objectFound = true;
                break;
            }
        }

        // Determine exact update value if not flat
        if (itemType === objectName && itemNbrMonths != 0 && itemStartDate <= currentGameDate){
            if (updateType === "percent" && itemNbrMonths == "-1")
            {
                updateValue = (+itemAmt * +updateValue / 100).toFixed(2);
            }
            if (duration === "-1")
            {
                objectFound = true;
                if (replaceAmtSw == true){
                    itemAmt = +updateValue;
                    itemDesc = objectDesc;                    
                }else{
                    // If duration is -1 (permanent), update the object directly
                    itemAmt = (+itemAmt + +updateValue).toFixed(2);
                    if (itemType == "Job Salary") {
                        newSalaryAmt = +itemAmt;
                        let salaryDescString =  doubleToStringMonthly((+itemAmt * 12).toFixed(0));
                        let initialStringLength = +itemDesc.search("Annual Salary") + 15;
                        let newItemDesc = itemDesc.substr(0, initialStringLength) + salaryDescString; 
                        itemDesc = newItemDesc;  
                    }
                } 
                if (objectType == "Asset"){
                    objectTable[i].assetDesc = itemDesc;
                    objectTable[i].assetAmt = itemAmt;
                }
                if (objectType == "Expense"){
                    objectTable[i].expDesc = itemDesc;
                    objectTable[i].expAmount = itemAmt;
                }
                if (objectType == "Loan"){
                    objectTable[i].loanDesc = itemDesc;
                    objectTable[i].loanAmt = itemAmt;
                }
            }
            else 
                if (duration === "1")
                {                
                    objectFound = true; 
                    let chargeItemToCreditCard = checkIfCreditCard(unlockedFeatures, updateValue,
                        creditCardInfo, objectName);
                    spendAmt = +updateValue * -1;
                    if (chargeItemToCreditCard == true && +updateValue < 0){
                        creditCardInfo = applyToCreditCard(creditCardInfo, updateValue, objectDesc);
                        cashOrCredit = "credit";
                    }else{
                        newCashAmt = +currentCashAmt + +updateValue;
                        cashOrCredit = "cash";
                    }
                }else{
                    // If duration is temporary, add as a new asset
                    objectFound = false;
                }
            }
           
    }
    var newMoneyObj;
    if (objectFound == false){
        switch (objectType){
            case "Asset":{
                newMoneyObj = {
                    assetItem: objectName,
                    assetDesc: objectDesc,
                    assetAmt: updateValue,
                    assetStartDate: currentGameDate,
                    assetNbrMonths: duration
                }
                break;
            }
            case "Expense":{
                newMoneyObj = {
                    expItem: objectName,
                    expDesc: objectDesc,
                    expAmount: updateValue,
                    expStartDate: currentGameDate,
                    expNbrMonths: duration
                }
                break;
            }
            case "Loan": {
                newMoneyObj = {
                    loanType: objectName,
                    loanDesc: objectDesc,
                    loanAmt: updateValue,
                    loanStartDate: currentGameDate,
                    loanNbrMonths: +duration,
                    loanTotAmt: totalLoanAmt,
                    loanIntRate: intRate,
                    loanTerm: +loanTerm,
                }
                break;
            }
            default: {
                newCashAmt = +currentCashAmt + +downPayment;
                cashOrCredit = "cash";
                break;
            }
        }
        objectTable.push(newMoneyObj);
    }

    let returnObj = {objectTable: objectTable, newCashAmt: newCashAmt, newSalaryAmt: newSalaryAmt,
        creditCardInfo: creditCardInfo, applyType: cashOrCredit, spendAmt: spendAmt};
            
    return returnObj;
   
}





