import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {monthlyLoanAmt} from '../../objects/AssetsExpenses/loanAmtCalc_function';
import SixColumnDisplayCard from '../shared-components/six_column_display_card';

class LoanCalculatorModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         entryTab: [],
         loanType: "General",
         message: " ",
    };    
  }

  calculateLoanAmounts(){
    var loanAmtParse = parseFloat(this.loanAmount.value);
    var nbrYearsParse = parseFloat(this.nbrYears.value);
    var intRateParse = parseFloat(this.intRate.value);
    var monthlyLnAmt = monthlyLoanAmt(loanAmtParse, intRateParse, nbrYearsParse, 
           this.state.loanType)
    var totalLoanMonths = nbrYearsParse * 12;
    var totalAmtPaid = monthlyLnAmt * totalLoanMonths;
    let intRateLit = (+intRateParse * 100).toFixed(0);
    intRateLit = intRateLit + "%"
    var loanTermLit = (nbrYearsParse + " years")
    var loanInfoString={loanAmt: loanAmtParse, intRate: intRateLit, loanTerm: loanTermLit,
               monthlyAmt: monthlyLnAmt, totalPaid: totalAmtPaid}       
    this.state.entryTab.push(loanInfoString);
    if (this.state.entryTab.length > 5){
      this.state.entryTab.splice(0,1);
      this.state.message = 
        "Max of 5 calculations, the first entry has rolled off.  Click on the 'Clear/Reset' " +
          " button if you wish to clear all entries and start again."
}
  }

  onClear(){
    var i;
    for (i=0; i<=this.state.entryTab.length; i++){
        delete this.state.entryTab[i];
    }
    this.state.entryTab.length = 0;
  }
 
  render() { 
     this.gutters = "";
     if (this.props.screenSize == "narrow" || this.props.screenSize == "medium"){
        this.gutters = "modal-95";
     }
     this.summaryRecordCounts = this.state.entryTab.map((counts,index) =>
     <SixColumnDisplayCard key={index} tableInfo={counts} index={index} tableType={"loans"}
         />
   );  
  return <>
    <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="lg" scrollable="true"
       dialogClassName={this.gutters} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Loan Calculator</Modal.Title> 
      </Modal.Header>
      <Modal.Body>
        {this.props.calculatorSw == false  ?
        <span>
          <div className="row-100 ml-2 mr-2 mt-1">
            <p>There are 3 key factors that impact what you have to pay on loans.
               The factors are the total amount of the loan, the cost of the loan (interest rate) 
               and the length of the loan (also called the loan term).  Interest rates
               are the amount that the lender charges in order to make the loan.  This is always
               a percentage of the total loan amount.  These three factors affect
               the amount you have to pay each month and the total amount that you will pay
               over the life of the loan. </p>
            <p>  
               Use this loan calculator to experiment with how these factors affect the loan.
               Click the "Close" button when you are done and ready to tackle the loan
               challenges.  Here are some specific things to understand (scroll down to see the 
               result of the selections that you make.)
            </p>
            <ol className="text-left m-2" type="1">
               <li>If the amount of the loan increases, how much does the monthly pay amount increase?</li>
               <li>If the interest rate increases or decreases, how does this affect the total amount
                   that will be paid on the loan?
               </li>
               <li>If the loan term (nbr years) of the loan increases, how does this impact both the monthly 
                   pay amount and the total amount that will be paid on the loan?
               </li>
             </ol>
          </div>  
        </span>
        :
                <p></p>
         }
        <span className="row w-100 mt-1 ml-0 mr-0">
            <div className="col-sm p-0">
              <div>Loan Amount: 
              <select id = "dropdown" ref = {(input)=> this.loanAmount = input}>
                  <option value="2000">$2000</option>
                  <option value="5000">$5000</option>
                  <option value="10000">$10,000</option>
                  <option value="15000">$15,000</option>
                  <option value="20000">$20,000</option>
                  <option value="50000">$50,000</option>
                  <option value="75000">$75,000</option>
                  <option value="85000">$85,000</option>
                  <option value="100000">$100,000</option>
                  <option value="150000">$150,000</option>
                  <option value="200000">$200,000</option> 
                  <option value="250000">$250,000</option>
                  <option value="300000">$300,000</option>
                  <option value="350000">$350,000</option>
                  <option value="400000">$400,000</option>
                  <option value="450000">$450,000</option>
               </select> </div>
            </div>
              
            <div className="col-sm p-0">  
              <p>Interest Rate:
              <select id = "dropdown" ref = {(input)=> this.intRate = input}>
                  <option value=".03">3%</option>
                  <option value=".04">4%</option>
                  <option value=".05">5%</option>
                  <option value=".06">6%</option>
                  <option value=".07">7%</option>
                  <option value=".08">8%</option>
                  <option value=".10">10%</option>
                  <option value=".20">20%</option>
              </select></p>
            </div>

            <div className="col-sm p-0">
              <p>Loan Term:
              <select id = "dropdown" ref = {(input)=> this.nbrYears = input}>
                  <option value="1">1 Year</option>
                  <option value="2">2 Years</option>
                  <option value="5">5 Years</option>
                  <option value="10">10 Years</option>
                  <option value="15">15 Years</option>
                  <option value="20">20 Years</option>
                  <option value="30">30 Years</option>
              </select></p>
            </div>  
          </span> 
          <table className="table table-hover">
            <thead>
              <tr className="thead-light">
                <th></th>
                <th className="p-0 text-right" scope="col">Total Loan Amount</th>
                <th className="p-0 text-right" scope="col">Interest Rate</th>
                <th className="p-0 text-right" scope="col">Loan Term</th>
                <th className="p-0 text-right" scope="col">Monthly Amount</th>
                <th className="p-0 text-right" scope="col">Total Amount Paid</th>
              </tr>
            </thead>
          <tbody>
              {this.summaryRecordCounts}
          </tbody>
        </table>

        </Modal.Body>
        <Modal.Footer>
          <p className="text-right">{this.state.message}</p>
          <Button variant="info"  onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="secondary"   onClick={() => this.onClear()}>
              Clear/Reset
          </Button>
          <Button variant="primary"  onClick={() => this.calculateLoanAmounts()}>
              Calculate
          </Button>
        </Modal.Footer>
      </Modal>

      
    </>
  }

 
}
export default LoanCalculatorModal;