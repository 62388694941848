import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DragNDropModal from './drag_n_drop_modal';
import PrioritiesChallenge1InitialText from '../../dragNDropText/priorities_challenge1_initial_text';
import PrioritiesChallenge1Dataset from './Datasets/priorities_challenge1_dataset';
import PrioritiesChallenge2InitialText from '../../dragNDropText/priorities_challenge2_initial_text';
import PrioritiesChallenge2Dataset from './Datasets/priorities_challenge2_dataset';
import { createColumnArray } from '../../objects/DragNDrop/createColumnArray_function';
import { calcItemScore } from '../../objects/DragNDrop/calcItemScore_function';
import { calcColumnScore } from '../../objects/DragNDrop/calcColumnScore_function';
import { calcColumnAmt } from '../../objects/DragNDrop/calcColumnAmount_function';
import { dragNDropBefore } from '../../objects/DragNDrop/drag_n_drop_before_function';
import ExtraCreditConfigFile from './Datasets/extra_credit_config_file';
import PrioritiesChallenge1HintsText from './text/priorities_challenge1_hints_text';
import PrioritiesChallenge2HintsText from './text/priorities_challenge2_hints_text';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';

class Priorities extends Component {
    
    constructor(props) { 
        super(props);
        this.state = {
            currentStatus: "DisplayChallenge1",
            dragNDrop: {},
            openSw: false,
            challenge1Score: 0,
            modalMessage: "",
            challenge2MaxScore: 1920,
            challenge2Score: 0,
            averageScore: 0,
            hintTable: [],
            hintNbr: 0,
            displayAlert: false,
            title: ExtraCreditConfigFile.general.title,
        }
    }
      
    openPrioritiesChallenge1() {
        this.state.currentStatus = "OpenChallenge1";
        this.state.openSw = true;

        this.state.dragNDrop = dragNDropBefore("Prioritize the Purchases",
            PrioritiesChallenge1InitialText, "med", "", "",
            PrioritiesChallenge1Dataset, "PrioritiesChallenge1", 0)
        this.state.hintTable = PrioritiesChallenge1HintsText;
    }
    
    closePrioritiesChallenge1() {
        this.state.openSw = false;
        this.state.currentStatus = "DisplayChallenge1";
    }
    
    savePrioritiesChallenge1() {
        var columnArray=createColumnArray(this.state.dragNDrop.cookieNamePfx, 1);
        this.state.challenge1Score = calcItemScore(columnArray);
        // Getting 1 wrong results in a score of 60...
        if (this.state.challenge1Score > 85) {
            this.state.modalMessage = "";
            sessionStorage.setItem("prioritiesChallenge1Score", this.state.challenge1Score);
            this.state.openSw = false;
            this.state.currentStatus = "DisplayChallenge2";
        } else {
            this.state.modalMessage= "Not quite right... Try again.";
            this.state.displayAlert = true;
            if (this.state.hintNbr < this.state.hintTable.length){
                 this.state.hintNbr+=1;
            }
        } 
     }
  
    openPrioritiesChallenge2() {
        this.state.currentStatus = "OpenChallenge2";
        this.state.openSw = true;
        this.state.modalMessage = "";
        this.state.hintNbr = 0;
        var initialText = PrioritiesChallenge2InitialText;
        this.maxAmount = PrioritiesChallenge2Dataset.maxAmount;
        this.minAmount = PrioritiesChallenge2Dataset.minAmount;
        initialText = initialText.replace("||minAmount||", this.minAmount);
        initialText = initialText.replace("||maxAmount||", this.maxAmount);
        this.state.dragNDrop = dragNDropBefore("What Should you buy with Limited Dollars?",
            initialText, "", "", "",
            PrioritiesChallenge2Dataset, "PrioritiesChallenge2", 0, true);   
        this.state.hintTable = JSON.parse(JSON.stringify(PrioritiesChallenge2HintsText));
    }

    closePrioritiesChallenge2() {
        this.state.openSw = false;
        this.state.currentStatus = "DisplayChallenge2"
    }
    
    savePrioritiesChallenge2() {
        this.columnArray=createColumnArray(this.state.dragNDrop.cookieNamePfx, 2);
        this.scoreChallenge2Column2();
        if (this.state.challenge2Score >= 100) {
            this.state.modalMessage = "";
            this.state.openSw = false;
            this.finishPriorities();
        } else {
            this.state.modalMessage= "Not quite right... Try again.";
            this.state.displayAlert = true;
            if (this.state.hintNbr < this.state.hintTable.length){
                 this.state.hintNbr+=1;
            }
            this.hintLine1 = JSON.parse(JSON.stringify(PrioritiesChallenge2HintsText[1]));
            let totalAmtFmt = doubleToStringMonthly(this.totalColumnAmt);
            this.hintLine1 = (this.hintLine1).replace("||dollarAmt||", totalAmtFmt);
            let maxAmount = doubleToStringMonthly(this.maxAmount);
            this.hintLine1 = (this.hintLine1).replace("||maxAmount||", maxAmount);
            let minAmount = doubleToStringMonthly(this.minAmount);
            this.hintLine1 = (this.hintLine1).replace("||minAmount||", minAmount);
            this.state.hintTable[1] = this.hintLine1;
        } 
     }

    scoreChallenge2Column2(){
        this.state.challenge2Score = calcColumnScore(this.columnArray);
        if (this.state.dragNDrop.inputData.minAmount != undefined &&
            this.state.dragNDrop.inputData.maxAmount != undefined){
                this.minAmount = this.state.dragNDrop.inputData.minAmount;
                this.maxAmount = this.state.dragNDrop.inputData.maxAmount;
                this.totalColumnAmt = calcColumnAmt(this.columnArray, "column-1");
                if (this.totalColumnAmt > this.maxAmount ||
                    this.totalColumnAmt < this.minAmount){
                        this.state.challenge2Score = 0;
                }
        }
        
    }
    
    getPriorityScores() {
        var challenge1Score = parseFloat(sessionStorage.getItem("prioritiesChallenge1Score"));
        if (!isNaN(challenge1Score)) {
           this.state.challenge1Complete = true;
           this.state.challenge1Score = challenge1Score;           
        }
    }

    closeAlert(){
        this.state.displayAlert = false;
        this.setState({displayAlert: false});
    }
    
    render() {
        this.getPriorityScores();
        if (this.state.challenge2Complete == true) {
            this.finishPriorities()
        }
        this.gutters = "";
        if (this.state.dragNDrop.modalSize == "xl" && 
           (this.props.screenSize == "narrow" || this.props.screenSize == "medium")){
             this.gutters = "modal-95";
        }
    return <>
         <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" scrollable="true"
            animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Extra Credit - Priorities</Modal.Title> 
          </Modal.Header>
          <Modal.Body>
            {this.state.currentStatus == "DisplayChallenge1" ?
                <span>
                <div className="text-center">
                    <p className="text-left m-4">One of the most important skills that you can develop 
                        to be good at money management is to understand how to prioritize your purchases.
                        Typically, the first thing you must consider is whether the purchase is a 
                        'need' or a 'want'.  In a strict sense, needs would be defined as any 
                        item that you must have in order to survive.  In practice, there are many
                        other factors that play into the decision.  Sometimes an item that might
                        seem like a 'want' on first consideration, can become much more important
                        depending on the circumstances.  Click on the "Challenge #1" button 
                        below to see if you can master this concept.</p>
                </div>         
                <div className="text-center">
                    <button className="btn btn-primary w-75 mt-4" 
                        onClick ={()=> this.openPrioritiesChallenge1()}>
                          Priorities Challenge #1
                    </button>
                </div>
                </span> 
            :
                <div></div>
            }
            {this.state.currentStatus == "OpenChallenge1"  ?
                <DragNDropModal show={this.state.openSw} 
                    gutters={this.gutters}
                    onClose={() => this.closePrioritiesChallenge1()} 
                    onSave={() => this.savePrioritiesChallenge1()}
                    closeAlert={() => this.closeAlert()}
                    inputDataset={this.state.dragNDrop.inputData}
                    modalTitle={this.state.dragNDrop.modalTitle}
                    introText={this.state.dragNDrop.introText}
                    cookieNamePfx={this.state.dragNDrop.cookieNamePfx}
                    buttonType={this.state.dragNDrop.buttonType}
                    message={this.state.modalMessage}
                    hintTable={this.state.hintTable}
                    hintNbr={this.state.hintNbr}
                    displayAlert={this.state.displayAlert}
                    title={this.state.title}
                />
            :
                <div></div>
            }
            
            {this.state.currentStatus == "DisplayChallenge2" ?
                <span>
                <p className="mt-4 ml-4 p-2">Congratulations, you have successfully completed the first
                    challenge, would you like to try another one?  Complete this second challenge
                    to update your score in the game.
                </p>
                <div className="text-center">
                    <button className="btn btn-primary w-75 mt-6" 
                        onClick ={()=> this.openPrioritiesChallenge2()}>
                            Priorities Challenge #2
                    </button>
                </div>
                </span> 
                :
                    <p></p>
            }
         
            {this.state.currentStatus == "OpenChallenge2"  ?
                <DragNDropModal show={this.state.openSw} 
                    gutters={this.gutters}
                    onClose={() => this.closePrioritiesChallenge2()} 
                    onSave={() => this.savePrioritiesChallenge2()}
                    closeAlert={() => this.closeAlert()}
                    inputDataset={this.state.dragNDrop.inputData}
                    modalTitle={this.state.dragNDrop.modalTitle}
                    modalSize={this.state.dragNDrop.modalSize}
                    introText={this.state.dragNDrop.introText}
                    cookieNamePfx={this.state.dragNDrop.cookieNamePfx}
                    buttonType={this.state.dragNDrop.buttonType}
                    message={this.state.modalMessage}
                    amountSw={this.state.dragNDrop.amountSw}
                    hintTable={this.state.hintTable}
                    hintNbr={this.state.hintNbr}
                    displayAlert={this.state.displayAlert}
                    title={this.state.title}
                />
            :
                <div></div>
            }
              </Modal.Body>
          <Modal.Footer className="text-right">
          <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"/>
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
               Close
            </Button>
          </Modal.Footer>(
          </Modal>
        </>
    }

    finishPriorities() {       
        this.state.averageScore = (this.state.challenge1Score + +this.state.challenge2Score) / 2; 
        this.calculatePriorityPoints();
        sessionStorage.setItem("previousExtraCreditProcess", "Priorities");
        this.props.onCompleted(); 
    }

    calculatePriorityPoints(){
        var earnedAmt = 0;
        let processName = "Priorities";
        let earningsObj = ExtraCreditConfigFile.exercises.find(elem => elem.category === processName);
        earnedAmt = (this.state.averageScore/100 * +earningsObj.amt).toFixed(2);
                    
        const prioritiesObj = {processName: processName, score: this.state.averageScore,
            assetType: earningsObj.type, amount: earnedAmt, level: earningsObj.level};
        sessionStorage.setItem("extraCreditCompleted", JSON.stringify(prioritiesObj));
    }
}

export default withRouter(Priorities);