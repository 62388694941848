import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import '../components/game-views/GameViews/gameViews.css';
import ReactTooltip from "react-tooltip";
import LinkTab from '../components/shared-components/link_tab';
import GameBoard from '../components/game-views/game-board';
import ChangeGameChoices from '../components/game-views/change_game_choices';
import ChangeSkillsSurvey from '../components/game-views/change_skills_survey';
import ChangeCareer from '../components/game-views/change_career';
import HomeChange from '../components/game-views/home_change';
import CarChange from '../components/game-views/car_change';
import UnlockedFeatures from '../components/game-views/unlocked_features';
import StatusDisplay from '../components/game-views/status_display';
import EndGame from '../components/end-game/end_game';
import CommentModal from '../components/shared-components/comment_modal';
import BadgesRibbon from '../components/game-views/badgesRibbon';
import SpinningBadge from '../components/game-views/spinning_badge';
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import LeaderBoardModal from '../components/game-views/leader_board_modal';
import MoodRetirementStatusModal from '../components/game-views/mood_retirement_status_modal';
import WaitTimeSpinner from '../components/shared-components/wait_time_spinner';

import GameChoicePictures from '../components/game-views/game_choice_pictures';
import screenSizeFunction from '../objects/CommonUse/screen_size_function';
import { calculateMoodRetirementScores } from '../components/game-views/objects/calculate_mood_retirement_scores';
import BadgesInfoDataset from '../components/game-views/badgesDatasets/badges_info_dataset';
import EarnBadge from '../components/game-views/earn_badge';
import {buildFunStuffPictureObj} from '../components/game-views/objects/build_fun_stuff_picture_obj';
import { setHeadersForFetch } from '../objects/CommonUse/set_headers_for_fetch';
import {buildUrl } from '../objects/CommonUse/build_URL';
import Config from '../config';


class GameView extends React.Component {
    constructor(props) {
        super(props);

        this.inputSelected = (inputID) => {
            for (const input of this.state.inputArr) {
                input.selected = false;
            }
            this.state.inputArr[inputID].selected = true;
            this.setState({inputArr: this.state.inputArr});
            if (inputID ==3){
                this.statusProcess = "";
            }
        };

        this.prevIndex = 0;
        this.openCommentModal = this.openCommentModal.bind(this);
        this.closeCommentModal = this.closeCommentModal.bind(this);

        this.state = {
            inputArr: [
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                }
            ],
            inputCompleted: this.inputCompleted,
            inputSelected: this.inputSelected,
            nbrMonthsOfPlay: 0,
            modalOpen: false,
            commentIcon: "./assets/ContactUs/pencilPaper.png",
            commentModalOpen: false,
            fromProcessName: "GameView",
            displayLeaderboard: false,
            prevProcess: "",
            username: null,
            moodRotation: -45,
            retirementRotation: 45,
            moodRetirementOpen: false,
            screenSize: sessionStorage.getItem("GameScreenSize"),
            badgeArr: [],
            nextBadge: 0,
            screenWidth: screenSizeFunction().screenWidth,
            screenHeight: screenSizeFunction().screenHeight,
            initialLeft: 0,
            initialTop: 0,
            gameLoaded: false,
            eventConditionsLoaded: false,
            creditCardLoaded: false,
            savingsLoaded: false,
            funStuffLoaded: false,
            gamePurchasesLoaded: false,
            friendsLoaded: false,
            allDataLoaded: false,
            headers: setHeadersForFetch(),
            environment: Config.dataURL.currentEnv,
            subProcess: "gameView",
            gameInfo: {
                loading: true,
                gameData: {},
                cardList: [],
                currentCard: {},
                currentCardLoaded: false,
                eventConditions: [],
                pictureInfo: "",
                backgroundURL: "",
                funStuffInfo: "",
                savingsInfo: "",
                creditCardInfo: {},
                gamePurchases: "",
                friendInfo: {},
                activityTracker: {},
                moodEmoji: "",
                nextEventNbr: "",
                displayType: "none",
                gamePhase: "",  
                btnText: "",
            }
        };

        switch(props.selected) {
            case "gameBoard":
                this.inputSelected(0);
                break;
            case "gameChoices":
                this.inputSelected(1);
                break;
            case "unlockedFeatures":
                this.inputSelected(2);
                break;
            case "statusDisplay":
                this.inputSelected(3);
                break;
            case "endGame":
                this.inputSelected(4);
                break;
        }
      }
    
      render() {
            if (this.state.gameLoaded === true && this.state.friendsLoaded === true &&
                this.state.creditCardLoaded === true && this.state.eventConditionsLoaded === true &&
                this.state.funStuffLoaded === true && this.state.savingsLoaded === true &&
                this.state.gamePurchasesLoaded === true){
                    if (this.state.gameInfo.gameData.currentCash != undefined){
                        this.calcMoodRetirementScores();
                    }
                    this.gameInfoDisplay=this.getGameInfo();
                    this.state.allDataLoaded = true;
                }
           
            this.setScreenSize();
            if (this.state.screenSize == "narrow"){
                 this.badgeRibbonClass = "badge-ribbon-position-narrow";
                 this.firstColumnClass = "col-sm-1 mt-4 ml-3"
            }else{
                if (this.state.screenSize == "medium"){
                    this.badgeRibbonClass = "badge-ribbon-position-medium";
                    this.firstColumnClass = "col-sm-1 mt-4 ml-3"
                }else{
                    this.badgeRibbonClass = "badge-ribbon-position";
                    this.firstColumnClass = "col-sm-2 mt-4 ml-3"
                }
            }
           
            let badgeList = this.state.badgeArr.map((badge, index) =>
                <BadgesRibbon key={index} badgeInfo={badge} index={index} 
                    initialLeft={this.state.initialLeft} initialTop={this.state.initialTop}
                    openBadge={(index) =>this.openBadgeDisplay(index)}/>
            );
        return <>
            <span className="container m-0 p-0">
            <span className="row w-100 mx-0">
                    <div className={this.firstColumnClass}>
                        <div>
                            {this.state.allDataLoaded ?
                                <div>
                                    <GameChoicePictures 
                                        screenSize={this.state.screenSize}
                                        gameInfo = {() => this.getGameInfo()} 
                                        setGameInfo = {(value, propertyName) => 
                                            this.setGameInfo(value, propertyName)}
                                        processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                    />
                                </div>
                            :
                                    null
                            }
                        </div>
                    </div>
                    {!this.state.allDataLoaded ? 
                        <div className={this.waitSpinnerClass}>
                            <WaitTimeSpinner/>
                        </div>
                    :
                        null
                    }

                        {this.state.moodRetirementOpen  ?
                            <div>
                                <MoodRetirementStatusModal 
                                    screenSize={this.state.screenSize}
                                    modalOpen={this.state.moodRetirementOpen} 
                                    onClose={() => this.closeMoodRetirementModal()}
                                    gameInfo = {() => this.getGameInfo()}
                                />
                            </div> 
                        :
                            null
                        }

                        <div>
                            <a target="_blank" data-tip="Click to provide feedback about game." rel="noopener noreferrer">
                                <ReactTooltip globalEventOff="click" />
                                    <img src={this.state.commentIcon} 
                                        className="comment-icon-position comment-img cursor-is-pointer" alt="comment"
                                        onClick={this.openCommentModal}/>
                            </a>
                        </div> 
                        
                        {this.state.commentModalOpen ?
                            <CommentModal modalOpen={this.state.commentModalOpen} 
                                onClose={() => this.closeCommentModal()}
                                gamePart={this.state.fromProcessName}
                                processName={this.props.selected}
                                gameInfo = {() => this.getGameInfo()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                        :
                            null
                        }

                        <div className={this.badgeRibbonClass}>
                            {badgeList}
                        </div>

                        {this.state.openBadge ?
                            <div >
                                <SpinningBadge
                                    show={this.state.openBadge}
                                    parameters={this.state.badgeArr[this.badgeIndx]}
                                    pictureURL={this.pictureURL}
                                    awardType={this.awardType}
                                    leftStyle={this.leftStyle}
                                    modalClass={this.modalClass}
                                    textClass={this.badgeTextClass}
                                    badgeImgClass={this.badgeImgClass}
                                    onClose={() => this.closeBadge()}/>
                            </div>
                        :
                            null
                        }

                        {this.props.selected === "gameBoard"  &&
                            this.state.allDataLoaded ? 
                            <GameBoard completed={() => this.inputCompleted(0)}
                                onOpen ={()=> this.inputSelected(0)} 
                                onEnd={(endGameReason) => this.endGame(endGameReason)}
                                homeChangeEnable = {()=> this.inputUncompleted(1)}
                                carChangeEnable= {()=> this.inputUncompleted(2)}
                                openMoodRetirement={this.state.moodRetirementOpen}
                                closeMoodRetirementModal={()=> this.closeMoodRetirementModal()}
                                gameInfo = {() => this.getGameInfo()} 
                                moodEmoji = {this.state.moodEmoji}
                                screenSize={this.state.screenSize}
                                nextBadge={this.state.nextBadge}
                                loadBadges = {(badgesEarned) => this.updateBadgeArr(badgesEarned)}
                                setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            /> : <span/>}
                        
                            {this.props.selected === "gameChoices"  && 
                                this.state.allDataLoaded ? 
                                    <ChangeGameChoices 
                                    screenSize={this.state.screenSize}
                                    completed={() => this.inputCompleted(1)}
                                    onOpen ={()=> this.inputSelected(1)} 
                                    gameInfo = {() => this.getGameInfo()}
                                    setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                    />
                            : <span/>}
                            
                    
                            {this.props.selected === "changeSkillsSurvey" &&
                                this.state.allDataLoaded ? 
                                <ChangeSkillsSurvey 
                                    gameInfo = {()=> this.getGameInfo()}
                                    setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                    /> 
                            : <span/>}
                            {this.props.selected === "changeCareer"  &&
                                this.state.allDataLoaded ?  
                                <ChangeCareer
                                    screenSize={this.state.screenSize}
                                    onOpen={()=>this.inputSelected(1)}
                                    onChangeComplete ={()=> this.setPrevProcess("jobChange")} 
                                    gameInfo = {() => this.getGameInfo()} 
                                    setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                    />
                            : <span/> }                               
                        {this.props.selected === "changeHome" ? <HomeChange 
                                screenSize={this.state.screenSize}
                                onChangeComplete ={()=> this.setPrevProcess("homeChange")} 
                                gameInfo = {() => this.getGameInfo()}
                                setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}

                                />
                        : <span/>}
                        {this.props.selected === "changeCar" &&
                            this.state.allDataLoaded ?  
                            <CarChange 
                                screenSize={this.state.screenSize}
                                onChangeComplete ={()=> this.setPrevProcess("carChange")} 
                                gameInfo = {() => this.getGameInfo()} 
                                setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}

                                />
                            : <span/>}
                        
                            {((this.props.selected === "unlockedFeatures" || this.props.selected === "setupSavings" ||
                               this.props.selected === "setupCreditCard"  || this.props.selected === "setupFunStuff")  &&
                               this.state.allDataLoaded) ?  
                                <UnlockedFeatures 
                                    screenSize={this.state.screenSize}
                                    selected={this.props.selected}
                                    completed={() => this.inputCompleted(1)}
                                    onOpen ={()=> this.inputSelected(1)} 
                                    gameInfo = {() => this.getGameInfo()}
                                    setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                />
                                : <span/>}
                                                
                            {(this.props.selected === "statusDisplay" || 
                              this.props.selected == "monthlyStatus")  &&
                              this.state.allDataLoaded ?  
                                <StatusDisplay
                                    screenSize={this.state.screenSize}
                                    selected={this.props.selected}
                                    onOpen ={()=> this.inputSelected(3)}
                                    prevProcess={this.state.prevProcess}
                                    gameInfo = {() => this.getGameInfo()} 
                                    clearPrevProcess ={()=> this.setPrevProcess("clear")}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                    /> 
                                : <span/>}
                            {this.props.selected ==="endGame"   &&
                                this.state.allDataLoaded ? 
                                <EndGame
                                    screenSize={this.state.screenSize}
                                    endGameReason={this.endGameReason}
                                    onOpen ={()=> this.inputSelected(4)}
                                    completed={() => this.inputCompleted(4)}
                                    gameInfo = {() => this.getGameInfo()} 
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                />
                                : <span/>    
                            }

                            {this.props.selected ==="earnBadge"  ?
                                <EarnBadge
                                    screenSize={this.state.screenSize}
                                    backgroundURL={this.state.gameInfo.backgroundURL}
                                    parameters={this.state.badgeArr[this.state.nextBadge - 1]}
                                    nextBadge={this.state.nextBadge}
                                    gameInfo = {() => this.getGameInfo()} 
                                    updateBadgeArr={(badgeObj) => this.updateBadgeArr(badgeObj)}
                                    openBadge={(badgeNbr) => this.openBadgeDisplay(badgeNbr)}
                                    setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}

                                />
                                : <span/>    
                            }
                        
                            {!this.props.selected ? 
                                <div className="col-sm-6 ml-0 mb-5 input-page-base-card">
                                <div className="card mt-3 mr-5 rounded-corners-lg text-center"></div></div> 
                            : <span/>}
                            {this.state.displayLeaderboard === true ?
                                <LeaderBoardModal modalOpen={true}
                                    onClose={() => this.closeOutGame()} 
                                    username={this.state.username}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                    />
                                :
                                <span/>
                            }
                    
                        <div className="col-sm-2 ml-5 ">
                            <div className={this.moodRetirementClass}
                                onClick={() => this.openMoodRetirementModal()}>
                                    <img src={'./assets/arrow.png'} 
                                        className={this.moodArrowClass}  alt={'arrow'}
                                        style={{transform: `rotate(${this.state.moodRotation}deg)`}}/>
                                    <img src={'./assets/arrow.png'}
                                        className={this.retirementArrowClass} alt={'arrow'}
                                        style={{transform: `rotate(${this.state.retirementRotation}deg)`}}/>
                            </div>
                                                            
                            {!this.state.inputArr[0].completed ?
                                <Link to="game-board" onClick={() => this.handleClick(0)}>
                                    <LinkTab inputArr={this.state.inputArr[0]} name="Game Board" validToComplete="true" 
                                        screenSize={this.state.screenSize}/>
                                </Link>
                            :
                                <LinkTab inputArr={this.state.inputArr[0]} name="Game Board" 
                                    screenSize={this.state.screenSize}/>
                            }           
                             {!this.state.inputArr[1].completed ?
                                <Link to="game-choices" onClick={() => this.handleClick(1)}>
                                    <LinkTab inputArr={this.state.inputArr[1]} name="Change Game Choices" validToComplete="true" 
                                        screenSize={this.state.screenSize}/>
                                </Link>
                            :
                                <LinkTab inputArr={this.state.inputArr[1]} name="Change Game Choices"
                                        screenSize={this.state.screenSize}/>
                            }                                       
                            {!this.state.inputArr[2].completed ?
                                <Link to="unlocked-features" onClick={() => this.handleClick(2)}>
                                    <LinkTab inputArr={this.state.inputArr[2]} name="Unlocked Features" validToComplete="true" 
                                        screenSize={this.state.screenSize}/>
                                </Link>
                            :
                                <LinkTab inputArr={this.state.inputArr[2]} name="Unlocked Features" 
                                    screenSize={this.state.screenSize}/>
                            }                 
                            {!this.state.inputArr[3].completed ?
                                <Link to="status-display" onClick={() => this.handleClick(3)}>
                                    <LinkTab inputArr={this.state.inputArr[3]} name="How am I Doing?" validToComplete="true" 
                                        screenSize={this.state.screenSize}/>
                                </Link>
                            :
                                <LinkTab inputArr={this.state.inputArr[3]} name="How am I Doing?"
                                     screenSize={this.state.screenSize}/>
                            }                                     
                                                   
                            <Link to="end-game" onClick={() => this.endGame()}>
                                <LinkTab inputArr={this.state.inputArr[4]} name="Finish Game" validToComplete="true"
                                     screenSize={this.state.screenSize}/>
                            </Link>
                        </div>
                    </span>
                   
                </span>
        </>
    }

    componentDidMount(){
        this.setState({allDataLoaded: false});
        this.refreshGameInfo();
    }

    setScreenSize(){  
        this.className = "mt-5 mx-3";
        this.textClass = "mx-4 mt-4 homepage-text decorative";
        this.imgClass = "mt-5 ml-3";
        this.moodRetirementClass = "mood_retirement_icon_container mt-2 cursor-is-pointer";
        switch (this.state.screenSize){
            case "narrow":
                this.className = "col-sm-1 " + this.className;
                this.imgClass = this.imgClass + " medium_size_img2";
                this.moodRetirementClass = this.moodRetirementClass + " mood_retirement_icon_narrow_size";
                this.retirementArrowClass = "retirement_arrow_position_narrow";
                this.moodArrowClass = "mood_arrow_position_narrow";
                this.state.initialLeft = (.504 * this.state.screenWidth) - 253;
                this.state.initialTop = (-.08 * this.state.screenHeight) + 660;
                this.waitSpinnerClass="col-sm-8 mb-5 input-page-base-card";
                break;
            case "medium":
                this.className = "col-sm-1 " + this.className;
                this.imgClass = this.imgClass + " medium_size_img2";
                this.moodRetirementClass = this.moodRetirementClass + " mood_retirement_icon_medium_size";
                this.retirementArrowClass = "retirement_arrow_position_medium";
                this.moodArrowClass = "mood_arrow_position_medium";
                this.state.initialLeft = (.389 * this.state.screenWidth) - 215.55;
                this.state.initialTop = (-.11 * this.state.screenHeight) + 639.08;
                this.waitSpinnerClass="col-sm-8 mb-5 input-page-base-card";
                break;
            default:    
                this.className = "col-sm-3 " + this.className;
                this.moodRetirementClass = this.moodRetirementClass + " mood_retirement_icon_wide_size";
                this.retirementArrowClass = "retirement_arrow_position_wide";
                this.moodArrowClass = "mood_arrow_position_wide";
                this.state.initialLeft = (.504 * this.state.screenWidth) - 320.45;
                this.state.initialTop = (-.21 * this.state.screenHeight) + 739.08;
                this.waitSpinnerClass="col-sm-7 mb-5 input-page-base-card";
                break;
        }
        //  the following is calculating initial (left) and height for badges ribbon
       
    }

    calcMoodRetirementScores(){
        let savings = 0;
        if (this.state.gameInfo.savingsInfo.totalSavings != undefined){
             savings = this.state.gameInfo.savingsInfo.totalSavings;
        }
        let rotationObj = calculateMoodRetirementScores(this.state.gameInfo.gameData.currentMoodPoints,
            this.state.gameInfo.gameData.currentCash, savings, this.state.gameInfo.gameData.spendingHistory, 
            this.state.gameInfo.gameData.gameNbrMonths);
        this.state.moodRotation = rotationObj.moodRotation;
        this.state.retirementRotation = rotationObj.retirementRotation;
        this.state.moodEmoji = rotationObj.emojiURL;
     //   this.state.moodEmoji = "./assets/moodFaceIndifferent.png"
    }

    openCommentModal(){
        this.state.commentModalOpen = true;
    }

    closeCommentModal(){
        this.state.commentModalOpen = false;
        this.setState({commentModalOpen: false});
    }

    updateBadgeArr(badgesEarned){
        this.state.badgeArr = BadgesInfoDataset;
        for (let i=0; i<badgesEarned.length; i++){
            const badgeIndx = this.state.badgeArr.findIndex(elem => elem.badgeCode === 
                badgesEarned[i].badgeCode);
            if (badgeIndx != -1){
                 this.state.badgeArr[badgeIndx] = {...this.state.badgeArr[badgeIndx], 
                    awardType: badgesEarned[i].awardType,
                    awardAmount: badgesEarned[i].awardAmount};
                 this.state.badgeArr[badgeIndx].badgeEarned = true;
            }
         }
         for (let i=0; i<this.state.badgeArr.length; i++){
             if (this.state.badgeArr[i].badgeEarned == false){
                  this.state.nextBadge = (i + 1);
                  break;
             }
         }
    }

    openBadgeDisplay(index){
        this.badgeName = this.state.badgeArr[index].badgeName;
        this.pictureURL = "./assets/Badges/" + this.state.badgeArr[index].pictureName;
        let awardType = "";
        this.badgeIndx = index;
        if (this.state.badgeArr[index].awardType == undefined){
             awardType = "cash";
             this.awardAmount = 100;
        }else{
             awardType = this.state.badgeArr[index].awardType;
        }
        this.awardType = awardType.charAt(0).toUpperCase() + awardType.slice(1).toLowerCase();
        let offset = 45;
        //  the left and top properties control where the badge card is displayed within
        //  the game board
        let left = (this.state.initialLeft + (index * offset) - 135).toFixed(2);
        let top = (this.state.initialTop - 440).toFixed(2);
        this.modalClass = "badgeModal"
        this.badgeTextClass = "mx-2 text-center";
        this.badgeImgClass = "spinPictureAround badgeImage";
        if (this.state.screenSize == "medium"){
            top = (this.state.initialTop - 400).toFixed(2);
        }
        if (this.state.screenSize == "narrow"){
            this.modalClass = "badgeModalNarrow";
            left += 20;
            top  -= 20;
            this.badgeTextClass = this.badgeTextClass + " badge-small-text";
            this.badgeImgClass = "spinPictureAround badgeImage-narrow";
        }
        this.leftStyle = {left: left + "px", top: top + "px"};
        this.setState({openBadge: true});
    }
     
    closeBadge(){
        this.setState({openBadge: false});
    }

    handleClick = index => {
        this.state.inputSelected(index);
    }

    configurationAvailable()
    {
        return false;
    }

    setPrevProcess(type)
    {
        if (type == "jobChange" || type == "carChange" || type == "homeChange"){
            this.state.prevProcess = "ChangeGameChoices";
        }else{
            this.state.prevProcess = "";
        }
    }
    
    openMoodRetirementModal(){
        this.state.moodRetirementOpen = true;
    }

    closeMoodRetirementModal(){
        this.state.moodRetirementOpen = false;
    }

    endGame(endGameReason)
    {
        this.handleClick(4);
        this.endGameReason = endGameReason;
        if (!this.endGameReason){
            this.endGameReason = "PlayersChoice"
        }
        if (this.endGameReason === "NegativeCash" ||
            this.endGameReason === "NegativeMoodPoints" ||
            this.endGameReason === "TimeElapsed"){
            var i;
            for (i=0; i<4; i++){
                this.state.inputArr[i].completed = true;
            }
        }
        this.props.history.push('/end-game');
    }

    closeOutGame()
    {
        // Migrate current cash position to leaderboard (need to set up Node endpoint for this)
        // right now this code will never be executed
        this.props.history.push('/');
    }

    getGameInfo()
    {
        return this.state.gameInfo;
    }

    setGameInfo(value, propertyName = null)
    {
        if (propertyName)
        {
            this.state.gameInfo[propertyName] = value;
            this.setState({gameInfo: this.state.gameInfo});
        }
            else{ this.setState({gameInfo: value});
        }
        if (propertyName == "currentCard"){
            this.state.gameInfo["btnText"] = undefined;
            this.setState({gameInfo: this.state.gameInfo});
        }
    } 

    //  add initial fetches required for game processes....

    refreshGameInfo(){
        this.state.gameLoaded = false;
        this.state.eventConditionsLoaded = false;
        this.state.savingsLoaded = false;
        // rework this refresh so does not do load of 'related data' when brand new game
        // rework this process so that it is done periodically (perhaps every 10 turns???)
        this.state.creditCardLoaded = false;
        this.state.funStuffLoaded = false;
        this.state.friendsLoaded = false;
        this.state.gamePurchasesLoaded = false;
        this.loadGame();
        this.loadEventConditions();
        
        
    }

    loadGame() {
        const headers = this.state.headers;
        this.state.username = sessionStorage.getItem("username");
        const funcName = "loadGame";
        const urlParm = "?mode=fullLoad";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
        { method: 'GET', headers: this.state.headers })
            .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.gameData = output.body;
                        this.setGameInfo(output.body, 'gameData');
                        this.state.gameDataLoaded = true;
                        this.processBadgeInfo();
                        this.checkForUnlockedFeatures();
                        this.loadFriends();
                        this.setState({gameLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
    }
    
    processBadgeInfo(){
        if (this.gameData.badgesEarned == undefined) {
            this.gameData.badgesEarned = [];
        }
        this.updateBadgeArr(this.gameData.badgesEarned);
    }

    checkForUnlockedFeatures(){
        let funStuffLoaded = false;
        let savingsLoaded = false;
        let creditCardLoaded = false;
        let gamePurchasesLoaded = false;
        for (let i=0; i<this.gameData.unlockedFeatures.length; i++){
            let unlockedFeature = this.gameData.unlockedFeatures[i];
            if (unlockedFeature.featureName == "funStuff"){
                funStuffLoaded = true;
                this.loadFunStuff(this.gameData._id);
            }
            if (unlockedFeature.featureName == "savings"){
                savingsLoaded = true;
                this.loadSavings(this.gameData._id);
            }
            if (unlockedFeature.featureName == "creditCard"){
                creditCardLoaded = true;
                this.loadCreditCard(this.gameData._id);
            }
        }
      
        if (funStuffLoaded == false){
            this.setState({funStuffLoaded: true});
        }
        if (savingsLoaded == false){
            this.setState({savingsLoaded: true});
        }
        if (creditCardLoaded == false){
            this.setState({creditCardLoaded: true});
        }
        this.loadGamePurchases(this.gameData._id);
    }
    
    loadEventConditions() {
        const funcName = "eventConditionsList";
        const urlParm = "?triggeredByList=monthly,event,funStuff";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.setGameInfo(output.body, 'eventConditions');
                        this.setState({eventConditionsLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            });
    }

    loadSavings(gameID) {
        const funcName = "loadSavings";
     //   let gameID = sessionStorage.getItem("gameID");
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        let savings = output.body;
                        this.setGameInfo(savings, "savingsInfo");
                        this.setState({savingsLoaded: true});
                    }else{
                        if (statusCode == 250){
                            // there is no savings for this player;
                            this.setState({savingsLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                        }
                    }
                });
            });
    }
    
    loadCreditCard(gameID) {
        const funcName = "loadCreditCardInfo";
      //  let gameID = sessionStorage.getItem("gameID");
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        let creditCard = output.body;
                        this.setGameInfo(creditCard, "creditCardInfo")
                        this.setState({creditCardLoaded: true});
                    }else{
                        if (statusCode == 250){
                             // there is no credit card record for this player
                             this.setState({creditCardLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                        }
                    }
                });
            });
    }
            
    loadFunStuff(gameID) {
        const funcName = "loadFunStuff";
     //   const gameID = sessionStorage.getItem("gameID");
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        if (output.body.message == "No Record Found"){
                            // there is no credit card record for this player
                        }else{
                            let funStuff = output.body;
                            //if (this.state.gameInfo.funStuffInfo.itemArr != undefined) {
                            //    this.addFunStuffToPictureInfo();
                            //}
                            this.setGameInfo(funStuff, "funStuffInfo");
                            this.setState({funStuffLoaded: true});
                        }
                    }else{
                        if (statusCode == 250){
                               // there is no credit card record for this player
                               this.setState({funStuffLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                        }
                    }
                });
            });
    }
    
    loadGamePurchases(gameID) {
        const funcName = "loadGamePurchases";
      //  let gameID = sessionStorage.getItem("gameID");
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        let gamePurchases = output.body;
                        this.setGameInfo(gamePurchases, "gamePurchases")
                        this.setState({gamePurchasesLoaded: true});
                    }else{
                        if (statusCode == 250){
                             // there is no credit card record for this player
                             this.setState({gamePurchasesLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                        }
                    }
                });
            });
    }

    addFunStuffToPictureInfo() {
        //   this just makes sure that the funstuff picture info has been updated (if not done in game choice pictures)
        if (this.state.gameInfo.pictureInfo.funStuffInfo == undefined ||
            this.state.gameInfo.pictureInfo.funStuffInfo == false) {
            let funStuffPictureObject = buildFunStuffPictureObj(this.state.gameInfo.funStuffInfo);
            let pictureInfoObj = {};
            if (this.state.gameInfo.pictureInfo.livingRoomImg == undefined) {
                pictureInfoObj = { ...funStuffPictureObject, funStuffInfo: true };
            } else {
                pictureInfoObj = {
                    ...this.state.gameInfo.pictureInfo, ...funStuffPictureObject,
                    funStuffInfo: true
                };
            }
            this.setGameInfo(pictureInfoObj, 'pictureInfo');
        }
    }

    loadFriends() {
        const funcName = "loadFriends";
        let friendArr = [];
        const friendList = this.gameData.friendList;
        for (let i = 0; i < friendList.length; i++) {
            let friendName = friendList[i].name;
            friendArr.push(friendName);
        }
        const friendParm = friendArr.join(',');
        const urlParm = "?friendArr=" + friendParm;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.buildFriendInfoArr(output.body);
                        this.setState({friendsLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message}
                        this.processErrorReturn(errorObj);
                    }
                });
            });
    }
    
    buildFriendInfoArr(friendData) {
        let friendArr = [];
        let startIdx = friendData.length - 1;
        for (let i = startIdx; i >= 0; i--) {
            let friendImgURL = './assets/friends/' + friendData[i].friendImg
            let friendObj = {
                friendName: friendData[i].friendName, friendImgURL: friendImgURL,
                weightedOdds: friendData[i].weightedOdds
            };
            friendArr.push(friendObj);
        }
        this.setGameInfo(friendArr, "friendInfo");
    }

    processErrorReturn(errorObj){
        this.addRecordToLogfile(errorObj);
        if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
            alert ("Uh oh!  Something unexpected has occurred " +
                "error has been logged and will be addressed. " +
                "For now click OK, then return to the process " +
                "where you were and try again.");
            this.props.history.push('/');
        }
    }

    addRecordToLogfile(errorObj){
        const headers=setHeadersForFetch();
        if (errorObj.status == 250){
            errorObj.message = "No record found";
        }
        let subProcess = "";  
        if (errorObj.subProcess == undefined){
            subProcess = "unknown"
        }else{
            subProcess = errorObj.subProcess;
        }
        if (errorObj.errorObject == undefined){
            errorObj.errorObject = {};
        }
        const logFileBody =  {
            mainProcess: "GameView",
            subProcess: subProcess,
            function: errorObj.function,
            status: errorObj.status,
            message: errorObj.message,
            errorObj: errorObj.errorObject
        }
        console.log("logFileBody is: ", logFileBody)
        const urlParm = "";
        const uri = buildUrl(this.state.environment, "saveLogFile", urlParm);
        fetch (uri,  
            {method:'POST',
                headers: headers,
                body: JSON.stringify(logFileBody)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            console.log("logfile create was successful");
                        }else{
                            console.log("Response from save log file not valid!" +
                            " status is: ", output.statusCode,
                            " message is: ", output.body);
                           alert ("Response from save log file not valid!");
                               
                        }
                });
                
            }
        });
      
    }
    
}

export default withRouter(GameView);