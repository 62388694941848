import { rollForwardDate } from "./roll_forward_date_function";

export const rollForwardCash = (assetArray, expenseArray, loanArray, nbrMonths, gameDate) => {

    var i;
    var j;
    var totalAssetAmt = 0;
    var totalExpenseAmt = 0;
    var totalLoanPayments = 0;
    var checkDate = gameDate;
          
    for (i=0; i<nbrMonths; i++) {
          checkDate = rollForwardDate(checkDate, 1);
          for (j=0; j< assetArray.length; j++) {
               if (assetArray[j].assetNbrMonths != 0 &&
                   assetArray[j].assetStartDate <= checkDate ) {
                    totalAssetAmt = totalAssetAmt + +assetArray[j].assetAmt;
                    if (assetArray[j].assetNbrMonths != -1) {
                          assetArray[j].assetNbrMonths -= 1;
                    }
               }
          }
          for (j=0; j< expenseArray.length; j++) {
               if (expenseArray[j].expNbrMonths != 0 &&
                   expenseArray[j].expStartDate <= checkDate ) {
                    totalExpenseAmt = totalExpenseAmt + parseFloat(expenseArray[j].expAmount);
                    if (expenseArray[j].expNbrMonths != -1) {
                         expenseArray[j].expNbrMonths -= 1; 
                    }
               }
          }
          for (j=0; j< loanArray.length; j++) {
               if (loanArray[j].loanNbrMonths != 0 &&
                   loanArray[j].loanStartDate <= checkDate ) {
                    totalLoanPayments = totalLoanPayments + parseFloat(loanArray[j].loanAmt)
                    if (loanArray[j].loanNbrMonths != -1) {
                         loanArray[j].loanNbrMonths -= 1;
                    }
               }
          }
    }
          
    let totalNetAmount = totalAssetAmt - totalExpenseAmt - totalLoanPayments;
    let totalAmtObj = {totalAssetAmt: totalAssetAmt, totalExpenseAmt: totalExpenseAmt, 
               totalLoanPayments: totalLoanPayments, totalNetAmt: totalNetAmount,
               newGameDate: checkDate
              };
    return totalAmtObj;
}

 


