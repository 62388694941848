import {Component} from 'react';
import { withRouter } from "react-router";
import { Button } from 'react-bootstrap';
import TableRowCard from '../shared-components/table_row_card';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import RelatedPlayerText from './text/related_player_text';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';

class RelatedPlayersDisplay extends Component {
    constructor(props) { 
        super(props);
        this.parentInfo = this.props.parentInfo();
        this.state = {
            headers: setHeadersForFetch(),
            authCodeArr: [],
            relatedPlayerArr: [],
            nbrPlayersAllowed: 0,
            nbrPlayersWithSignon: 0,
            nbrPrimaryAllowed: 0,
            nbrPrimaryWithSignon: 0,
            userAccount: null,
            accountLoaded: false,
            userListLoaded: false,
            userList: [],
            playerRoleOK: false,
            userEmailAddress: null,
            accountNbr: null,
            displayAccountInfo: false,
            displayAuthCodeInfo: false,
            configLoaded: false,
            subProcess: "relatedPlayersDisplay",
            environment: Config.dataURL.currentEnv,
        }
    }

    componentDidMount(){
          this.checkForConfigs();
    }
   
    setShortLongText(){
        let textArr = RelatedPlayerText;
        for (let i=0; i< textArr.length; i++){
            if (textArr[i].type == "AccountInfo"){
                 if (this.parentInfo.relatedPlayers.length == 0){
                    this.accountShortText = textArr[i].shortText0;
                 }else{
                    if (this.parentInfo.relatedPlayers.length == 1){
                        this.accountShortText = textArr[i].shortText1;
                    }else{
                        this.accountShortText = textArr[i].shortText2;
                    }
                 }
                 this.accountLongText = textArr[i].text;
            }
            if (textArr[i].type == "AuthCodeInfo"){
                this.authCodeShortText = textArr[i].shortText;
                this.authCodeLongText = textArr[i].text;
            } 
        }
    }


    determineNbrPlayersRqstd(){
        let rqstdUsersArr = this.parentInfo.accountInfo.rqstdUsersArr;
        this.state.nbrPrimaryAllowed = 0;
        this.state.nbrPlayersAllowed = 0;
        for (let i=0; i<rqstdUsersArr.length; i++){
             if (rqstdUsersArr[i].role == "player"){
                 this.state.nbrPlayersAllowed += +rqstdUsersArr[i].nbrUsersRqstd;
             }else{
                 let primaryRole = rqstdUsersArr[i].role;
                 this.primaryRole = primaryRole.charAt(0).toUpperCase() + primaryRole.slice(1) + "s";
                 this.state.nbrPrimaryAllowed += +rqstdUsersArr[i].nbrUsersRqstd;
             }
        }
        
    }


    reformatAuthCodeTable(){
        let authCodeArr = this.parentInfo.accountInfo.authCodeArr;
        this.state.authCodeArr = [];
        let authIndx = null;
        for (let i=0; i<authCodeArr.length; i++){
            let roleFound = false;
            for (let j=0; j<this.state.authCodeArr.length; j++){
                   let tableArr = this.state.authCodeArr[j];
                   if (authCodeArr[i].role == tableArr.column1 && 
                        tableArr.nbrFound < 4){
                        authIndx = j;
                        roleFound = true;
                        break;
                   }
            }
            if (roleFound == true){
                this.state.authCodeArr[authIndx].column2 = 
                            this.state.authCodeArr[authIndx].column2 + ", " + authCodeArr[i].authCode;
                this.state.authCodeArr[authIndx].nbrFound += 1;
            }else{
                let tableObj = {index: "", column1: authCodeArr[i].role, column2: authCodeArr[i].authCode,
                    nbrFound: 1};
                this.state.authCodeArr.push(tableObj)
            }
        }
    }
    
    reformatRelatedPlayers(){
        this.reformatRelatedPlayerArr = [];
        if (this.state.relatedPlayerArr.length == 0){
            this.playersMessage = "There are currently no players who have sign-in ids for this account."
        }else{
            this.state.relatedPlayerArr.sort((a, b) => (a.user > b.user) ? 1 : -1);
            for (let i=0; i<this.state.relatedPlayerArr.length; i++){
                let textString = "";
                if (this.state.relatedPlayerArr[i].configAvail == true){
                    textString = "Game has been Started.";
                }else{
                    textString = "No active game for this signon.";
                }
                let tableObj = {index: i+1, column1: this.state.relatedPlayerArr[i].user, column2: textString};
                this.reformatRelatedPlayerArr.push(tableObj);
            }
            this.playersMessage = "";
        }
    }

    collapseAccountInfoDisplay(){
        this.state.displayAccountInfo = false;
    }

    expandAccountInfoDisplay(){
        this.state.displayAccountInfo = true;
    }
    
    collapseAuthCodeInfoDisplay(){
        this.state.displayAuthCodeInfo = false;
    }

    expandAuthCodeInfoDisplay(){
        this.state.displayAuthCodeInfo = true;
    }

 
    render() {
        if (this.parentInfo.accountLoaded == true && this.parentInfo.userListLoaded == true){
            this.state.userEmailAddress = this.parentInfo.accountInfo.emailAddress;
            this.state.accountNbr = this.parentInfo.accountInfo.accountNbr;
            this.setShortLongText();
            this.determineNbrPlayersRqstd();
            this.state.nbrPlayersWithSignon = this.parentInfo.relatedPlayers.length;
            this.state.nbrPrimaryWithSignon = this.parentInfo.nbrPrimarySignons;
        }

        if (this.parentInfo.accountLoaded == true){
            this.reformatAuthCodeTable();
            if (this.state.authCodeArr.length == 0){
                this.authCodeMessage = "All auth codes have been used.";
            }else{
                this.authCodeMessage = "";
                this.authCodeList = this.state.authCodeArr.map((tableRows,index) =>
                    <TableRowCard tableInfo={tableRows} key={index}  />
                    );
            }
        }

        if (this.parentInfo.configsLoaded == true){
            this.reformatRelatedPlayers();
            this.relatedPlayersList = this.reformatRelatedPlayerArr.map((tableRows,index) =>
                <TableRowCard tableInfo={tableRows} key={index}/>
            );
        }

        let screenParms = setScreenSizeParameters(this.props.screenSize, "forParents");
        this.className = screenParms.columnClass;
        this.textClass = screenParms.textSize + " mx-4 text-left mt-4";
        this.textClass2 = screenParms.textSize + " mt-4 text-left";
        this.textClass3 = screenParms.textSize + " mt-2 mx-4 text-left"
       
           
    return <>
        <div className={this.className}>
        <div className="card mt-3 rounded-corners-lg text-center game-board height-max">
        <button className="pages-back-button ml-4 mt-4" 
                        onClick ={()=> this.props.goBack()}>
                                Back
        </button>
        <h3 className="mt-0 text-center">List of Related Players</h3>
        <div className="overflow-scroll mr-3 ml-3 mb-5">
                {this.state.displayAccountInfo ?
                    <span>
                        <div className={this.textClass}>
                            {this.accountShortText}
                            {this.accountLongText}
                            <Button variant="outline-info"  className="p-0" size="sm"
                                onClick={() => this.collapseAccountInfoDisplay()}>
                                Collapse 
                            </Button>
                        </div>
                    </span>
                :
                    <span>
                        <div className={this.textClass}>
                            {this.accountShortText}
                            <Button variant="outline-info" className="p-0" size="sm"
                                onClick={() => this.expandAccountInfoDisplay()}>
                                  Learn More
                            </Button>
                        </div>
                    </span>
                }

                <span>
                    <div className={this.textClass3}>
                        If any of this information does not appear correct to you, please let us know 
                        (click on the 'Contact Us' link that is in the header row of this site).
                    </div>
                    <table className={this.textClass2}>
                        <tbody>
                            <tr>
                                <th>Account Number:</th>  
                                <td>{this.state.accountNbr}</td>
                            </tr>
                            <tr>
                                <th>Account Email Address:</th>  
                                <td>{this.state.userEmailAddress}</td>
                            </tr>
                            <tr className="ml-4">
                                <th>Number {this.primaryRole} Allowed:&nbsp;&nbsp; </th>
                                <td>{this.state.nbrPrimaryAllowed}</td>
                                <th>Nbr {this.primaryRole} With Signon:&nbsp;&nbsp; </th>
                                <td>{this.state.nbrPrimaryWithSignon}</td>
                            </tr> 
                            <tr className="ml-4">
                                <th>Number Players Allowed:&nbsp;&nbsp; </th>
                                <td>{this.state.nbrPlayersAllowed}</td>
                                <th>Nbr Players With Signon:&nbsp;&nbsp; </th>
                                <td>{this.state.nbrPlayersWithSignon}</td>
                            </tr> 
                        </tbody>  
                    </table>
                    <h5 className="table-header-background mt-4">AuthCodes Available for Use</h5>
                    {this.state.displayAuthCodeInfo ?
                    <span>
                        <div className={this.textClass3}>
                            {this.authCodeShortText}
                            {this.authCodeLongText}
                           <Button variant="outline-info" className="p-0" size="sm"
                                onClick={() => this.collapseAuthCodeInfoDisplay()}>
                                Collapse 
                            </Button>
                        </div>
                    </span>
                :
                    <span>
                        <div className={this.textClass3}>
                            {this.authCodeShortText} 
                            <Button variant="outline-info" className="p-0" size="sm"
                                onClick={() => this.expandAuthCodeInfoDisplay()}>
                                  Learn More
                            </Button>
                        </div>
                    </span>
                }
                    {this.parentInfo.accountLoaded ?
                        <span>
                        <table className={this.textClass3}>
                            <tbody className="mt-4">
                                <tr className="text-center row-100">
                                    <th className="col-sm-2"></th>
                                    <th className="col-sm-4 text-left">Game Role</th>
                                    <th className="col-sm-6 text-center">Auth Code List for This Role</th>
                                </tr>
                                {this.authCodeList}
                            </tbody>
                        </table>
                        <div>{this.authCodeMessage}</div>
                        <h5 className="table-header-background mt-4">Related Players List</h5>
                        <table className={this.textClass3}>
                            <tbody className="mt-4">
                             <tr className="text-center row-100">
                                 <th className="col-sm-2 p-0"></th>
                                 <th className="col-sm-4 text-left p-0">Player Name</th>
                                 <th className="col-sm-6 text-center p-0">Game Status</th>
                             </tr>
                                {this.relatedPlayersList}
                             </tbody>
                        </table>
                        <div>{this.playersMessage}</div>
                     </span>
                    :
                        <WaitTimeSpinner />
                    }
                                      
                </span>
           </div>
        </div>    
       </div>
    </>
    }

    
 checkForConfigs()
 {
    let playerArr = (this.parentInfo.relatedPlayers).join(',');
    const funcName = "checkGameConfigs";
    const urlParm = "?userNameArr=" + playerArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.state.relatedPlayerArr = output.body;
                        this.props.setParentInfo(true, "configsLoaded");
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
    }
}

export default withRouter (RelatedPlayersDisplay);