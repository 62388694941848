import { Component } from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DragNDropModal from './drag_n_drop_modal';
import SavingMoneyChallenge1InitialText from '../../dragNDropText/saving_money_challenge1_initial_text';
import SavingMoneyChallenge1HintsText from './text/saving_money_challenge1_hints_text';
import SavingMoneyChallenge1Dataset from './Datasets/saving_money_challenge1_dataset';
import SavingsOverTimeModal from './savings_over_time_modal';
import SavingMoneyChallenge2InitialText from '../../dragNDropText/saving_money_challenge2_initial_text';
import SavingMoneyChallenge2HintsText from './text/saving_money_challenge2_hints_text';
import SavingMoneyChallenge1ResultsText from './text/saving_money_challenge1_results_text';
import SavingMoneyChallenge2Dataset from './Datasets/saving_money_challenge2_dataset';
import { createColumnArray } from '../../objects/DragNDrop/createColumnArray_function';
import { calcColumnScore } from '../../objects/DragNDrop/calcColumnScore_function';
import { createResultsArray } from './objects/createResultsArray_function';
import { dragNDropBefore } from '../../objects/DragNDrop/drag_n_drop_before_function';
import ExtraCreditConfigFile from './Datasets/extra_credit_config_file';

class SavingMoney extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dragNDrop: {},
            modalMessage: null,
            hintTable: [],
            hintNbr: 0,
            displayAlert: false,
            tableObject: [],
            tableNbrRows: 0,
            freezeFlag: false,
            currentStatus: "",
            calculatorStatus: "DisplayCalculator",
            previousStatus: "",
            openCalculatorSw: false,
            openSw: false,
            challenge1Score: 0,
            challenge2Score: 0,
            averageScore: 0,
            col1Text: "",
            calculateOnly: false,
        }
        this.getSavingMoneyChallenge1Score();
    }


    getSavingMoneyChallenge1Score() {
        var challenge1Score = parseFloat(sessionStorage.getItem("SavingMoneyChallenge1Score"));
        if (!isNaN(challenge1Score)) {
            this.state.calculatorStatus = "";
            this.state.currentStatus = "DisplayChallenge2";
            this.state.challenge1Score = challenge1Score;
        }
    }

    openSavingsCalculator() {
        this.state.openCalculatorSw = true;
        this.state.calculatorStatus = "OpenCalculator"
        if (this.state.currentStatus == "OpenChallenge1" ||
            this.state.currentStatus == "OpenChallenge2") {
            this.state.calculateOnly = true;
            this.initialAmt = false;
        } else {
            this.state.calculateOnly = false;
        }
    };

    closeSavingsCalculator() {
        this.state.openCalculatorSw = false;
        this.setState({ calculatorStatus: "CloseCalculator" });
        if (this.state.currentStatus != "OpenChallenge1") {
            this.setState({ currentStatus: "DisplayChallenge1" })
        }
    }

    openSavingMoneyChallenge1() {
        this.state.currentStatus = "OpenChallenge1";
        this.state.openSw = true;

        this.state.dragNDrop = dragNDropBefore("Categorize the Savings Scenarios",
            SavingMoneyChallenge1InitialText, "", "DoneCalculator", "Savings",
            SavingMoneyChallenge1Dataset, "SavingMoneyChallenge1", 1);

        this.state.hintTable = SavingMoneyChallenge1HintsText;
    }

    closeSavingMoneyChallenge1() {
        this.state.openSw = false;
        this.state.currentStatus = "DisplayChallenge1"
    }

    saveSavingMoneyChallenge1() {
        var columnArray = createColumnArray(this.state.dragNDrop.cookieNamePfx, 5);
        this.state.challenge2Score = calcColumnScore(columnArray);
        if (this.state.challenge2Score > 85) {
            this.state.modalMessage = "";
            this.state.tableObject = createResultsArray(columnArray);
            sessionStorage.setItem("SavingMoneyChallenge1Score", this.state.challenge1Score);
            this.state.dragNDrop.introText = SavingMoneyChallenge1ResultsText;
            this.state.col1Text = [
                "Total Amt Out-of-Pocket", "Total Amt of Savings"
            ]
            this.state.tableNbrRows = 2;
            this.state.dragNDrop.buttonType = "Continue";
            this.state.freezeFlag = true;
        } else {
            this.state.modalMessage = "Not quite right... Try again.";
            this.state.displayAlert = true
            if (this.state.hintNbr < this.state.hintTable.length) {
                this.state.hintNbr += 1;
            }
        }
    }

    finishChallenge1() {
        this.state.openSw = false;
        this.state.currentStatus = "DisplayChallenge2";
        this.state.freezeFlag = false;
    }

    openSavingMoneyChallenge2() {
        this.state.currentStatus = "OpenChallenge2";
        this.state.openSw = true;
        this.state.modalMessage = "";
        this.state.dragNDrop = dragNDropBefore("Review Saving Advice",
            SavingMoneyChallenge2InitialText, "", "DoneCalculator", "Savings",
            SavingMoneyChallenge2Dataset, "SavingMoneyChallenge2", 99)
        this.state.tableNbrRows = 0;
        this.state.hintTable = SavingMoneyChallenge2HintsText;
    }

    saveSavingMoneyChallenge2() {
        var columnArray = createColumnArray(this.state.dragNDrop.cookieNamePfx, 2);
        if (columnArray[1].items.length == 0) {
            this.state.modalMessage = "Drag items from the Considerations column to the Advantages column."
        } else {
            this.state.challenge1Score = calcColumnScore(columnArray);
            if (this.state.challenge1Score > 90) {
                this.finishSavingMoney();
            } else {
                this.state.modalMessage = "Not quite right... Try again.";
                this.state.displayAlert = true;
                if (this.state.hintNbr < this.state.hintTable.length) {
                    this.state.hintNbr += 1;
                }
            }

        }
    }

    closeSavingMoneyChallenge2() {
        this.state.openSw = false;
        this.state.currentStatus = "DisplayChallenge2";
     
    }



    closeAlert() {
        this.state.displayAlert = false;
        this.setState({ displayAlert: false });
    }

    render() {

        this.gutters = "";
        if (this.state.dragNDrop.modalSize == "xl" &&
            (this.props.screenSize == "narrow" || this.props.screenSize == "medium")) {
            this.gutters = "modal-95";
        }
      
        return <>

            <Modal show={this.props.show} onHide={() => this.props.onClose()} size="med" scrollable="true">
                <Modal.Header closeButton>
                    <Modal.Title>Extra Credit - Saving Money</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.state.calculatorStatus == "DisplayCalculator" ?
                        <span>
                            <div className="m-4">
                                <h6 className="text-center">Savings Calculator</h6>
                                <p>Click on the "Savings Calculator" button to learn how the savings amount,
                                    and interest rate impact the amount that you can save over a period
                                    of time.  Once you understand the basic concepts, you can tackle the next
                                    challenge exercise that will be available.</p>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary w-75 mt-4"
                                    onClick={() => this.openSavingsCalculator()}>
                                    Savings Calculator
                                </button>
                            </div>
                        </span>
                        :
                        <div></div>
                    }

                    {this.state.calculatorStatus == "OpenCalculator" ?
                        <SavingsOverTimeModal show={this.state.openCalculatorSw}
                            gutters={this.gutters}
                            onClose={() => this.closeSavingsCalculator()}
                            calculatorSw={this.state.calculateOnly} 
                            initialAmt={this.initialAmt}/>
                        :
                        <div></div>
                    }

                    {this.state.currentStatus == "DisplayChallenge1" ?
                        <span>
                            <div className="m-4">
                                <p>Now that you've had a chance to experiment with the savings calculator,
                                    click on the "Challenge #1" button to check your understanding of
                                    how the key factors affect the amount you can save over time.
                                </p>
                            </div>

                            <div className="text-center">
                                <button className="btn btn-primary w-75 mt-4"
                                    onClick={() => this.openSavingMoneyChallenge1()}>
                                    Saving Money Challenge #1
                                </button>
                            </div>
                        </span>
                        :
                        <div></div>
                    }
                    {this.state.currentStatus == "OpenChallenge1" ?
                        <DragNDropModal show={this.state.openSw}
                            gutters={this.gutters}
                            onClose={() => this.closeSavingMoneyChallenge1()}
                            onSave={() => this.saveSavingMoneyChallenge1()}
                            onCalculator={() => this.openSavingsCalculator()}
                            onContinue={() => this.finishChallenge1()}
                            closeAlert={() => this.closeAlert()}
                            inputDataset={this.state.dragNDrop.inputData}
                            modalSize={this.state.dragNDrop.modalSize}
                            modalTitle={this.state.dragNDrop.modalTitle}
                            buttonType={this.state.dragNDrop.buttonType}
                            calcType={this.state.dragNDrop.calculatorType}
                            introText={this.state.dragNDrop.introText}
                            cookieNamePfx={this.state.dragNDrop.cookieNamePfx}
                            maxNbr={this.state.dragNDrop.maxNbrPerColumn}
                            tableNbrRows={this.state.tableNbrRows}
                            tableCol1Text={this.state.col1Text}
                            tableObjectRow={this.state.tableObject}
                            freezeFlag={this.state.freezeFlag}
                            message={this.state.modalMessage}
                            hintTable={this.state.hintTable}
                            hintNbr={this.state.hintNbr}
                            displayAlert={this.state.displayAlert}
                        />
                        :
                        <div></div>
                    }

                    {this.state.currentStatus == "DisplayChallenge2" ?
                        <span>
                            <div className="m-4" >
                                <p>Great job!  You successfully completed the first challenge.  Click
                                    on the "Challenge2" button to test your understanding of key
                                    concepts about saving money.
                                </p>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary w-75 mt-4"
                                    onClick={() => this.openSavingMoneyChallenge2()}>
                                    Saving Money Challenge #2
                                </button>
                            </div>
                        </span>
                        :
                        <div></div>
                    }
                    {this.state.currentStatus == "OpenChallenge2" ?
                        <DragNDropModal show={this.state.openSw}
                            gutters={this.gutters}
                            onClose={() => this.closeSavingMoneyChallenge2()}
                            onSave={() => this.saveSavingMoneyChallenge2()}
                            onCalculator={() => this.openSavingsCalculator()}
                            onContinue={() => this.finishChallenge1()}
                            closeAlert={() => this.closeAlert()}
                            inputDataset={this.state.dragNDrop.inputData}
                            modalSize={this.state.dragNDrop.modalSize}
                            modalTitle={this.state.dragNDrop.modalTitle}
                            introText={this.state.dragNDrop.introText}
                            cookieNamePfx={this.state.dragNDrop.cookieNamePfx}
                            maxNbr={this.state.dragNDrop.maxNbrPerColumn}
                            buttonType={this.state.dragNDrop.buttonType}
                            calcType={this.state.dragNDrop.calculatorType}
                            message={this.state.modalMessage}
                            tableNbrRows={this.state.tableNbrRows}
                            tableCol1Text={this.state.col1Text}
                            tableObjectRow={this.state.tableObject}
                            freezeFlag={this.state.freezeFlag}
                            hintTable={this.state.hintTable}
                            hintNbr={this.state.hintNbr}
                            displayAlert={this.state.displayAlert}
                        />
                        :
                        <div></div>
                    }
                </Modal.Body>
                <Modal.Footer className="text-right">
                    <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5" />
                    <Button variant="secondary" onClick={() => this.props.onClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    }

    finishSavingMoney() {
        this.state.averageScore = (+this.state.challenge1Score + +this.state.challenge2Score) / 2;
        this.calculateSavingMoneyPoints();
        sessionStorage.setItem("previousExtraCreditProcess", "Living Below Means");
        this.props.onCompleted();
    }

    calculateSavingMoneyPoints() {
        var earnedAmt = 0;
        let processName = "SavingMoney";
        let earningsObj = ExtraCreditConfigFile.exercises.find(elem => elem.category === processName);
        earnedAmt = ((this.state.averageScore * +earningsObj.amt) / 100).toFixed(2);

        const SavingMoneyMeansObj = {
            processName: processName, score: this.state.averageScore,
            assetType: earningsObj.type, amount: earnedAmt, level: earningsObj.level
        }

        sessionStorage.setItem("extraCreditCompleted", JSON.stringify(SavingMoneyMeansObj));
    }

}


export default withRouter(SavingMoney);