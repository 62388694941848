import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import { withRouter } from "react-router";
import { TimerReturn } from "./objects/timerReturn";
import MazeGameModal from './maze_game_modal';
import WordPuzzleModal from './word_puzzle_modal';
import GroceryStoreShopperInitialText from './text/grocery_store_shopper_initial_text';
import DragNDropModalGrocery from './drag_n_drop_modal_grocery';
import DragNDropCommonModal from './drag_n_drop_modal_common';
import {dragNDropBefore} from '../../objects/DragNDrop/drag_n_drop_before_function';
import FoodBankDragNDropInitialText from './text/food_bank_drag_n_drop_initial_text';
import CoatDriveInitialText from './text/coat_drive_initial_text';
import GoToZooInitialText from './text/go_to_zoo_initial_text';
import MazeActivityParameters from './Datasets/maze_activity_parameters';
import FoodBankDynamicDataset from './Datasets/food_bank_dynamic_dataset';
import JigsawPuzzleModal from './jigsaw_puzzle_modal';
import ComedyClubModal from './comedy_club_modal';
import UndercoverPuzzleModal from './undercover_puzzle_modal';
import setModalSize from '../../objects/CommonUse/set_modal_size';

class TimerDisplayModal extends Component {
     
    constructor(props) {
        super(props);
        this.state = {
            startTimer: false,
            gameOver: false,
            disabled: false,
            scoreDisplay: false,
            cumulativeScore: 0,
            allScores: "",
            nbrGames: 0,
            dragNDrop: {},
            openSw: false,
            pictureSw: false,
            dragNDropScore: 0,
            timerIsOff: false,
            restart: false,
            stopActivity: false,
            mazeParm: "",
        }
    }

    determineActivityParameters(){
        switch(this.props.activityType) {
            case "maze":
                this.determineGifs();
                break;
            case "dragNdrop":
                this.determineDragNDropParameters();
                break;
        }
    }

    determineGifs(){
        this.mazeParameters = MazeActivityParameters;
        let mazeParmObject = MazeActivityParameters.find(elem => elem.gameName === this.props.modalType);
        if (mazeParmObject != undefined){
            this.state.mazeParm = mazeParmObject.parms;
        }
    }

    determineDragNDropParameters(){
        this.state.scoreDisplay = true;
        if (this.props.modalType == "GroceryShopper"){
            this.groceryShopperDataset = "";
            this.correctMsg = "All items are correct.  Click 'Start' to fill another order."
            this.state.dragNDrop = dragNDropBefore ("Fill Shopper Orders",
                GroceryStoreShopperInitialText, "md", "", "", this.groceryShopperDataset,
                this.props.modalType, 99);
        }
        if (this.props.modalType == "FoodDrive"){
            this.foodBankDragNDropDataset = FoodBankDynamicDataset;
            switch (this.props.screenSize){
                case "narrow":
                    this.itemClass = "food_drive_thumbnail_img_small ";
                    break;
                case "medium":
                    this.itemClass = "food_drive_thumbnail_img_medium ";
                    break;
                default:
                    this.itemClass = "food_drive_thumbnail_img";
                    break;
            }
            this.columnClass = "drag_n_drop_basic_column drag_n_drop_grocery_cart no-column-gutters ";
            this.columnTextClass = "text-center";
            this.multipleColumns = "multiple-columns";
            this.scoreType = "columnOnly";
            this.pictureDir = "./assets/Activities/Grocery/";
            this.backgroundClass = "";
            this.completedLit = "Food Carts Sorted";
            this.correctMsg = "All items have been sorted correctly.  Click 'Start' to get another batch of items."
            this.state.dragNDrop = dragNDropBefore ("Sort Donated Food into the Correct Basket",
                FoodBankDragNDropInitialText, "", "", "", this.foodBankDragNDropDataset,
                "FoodDrive", 99);
        }
        if (this.props.modalType == "CoatDrive"){
            this.state.openCoatDriveDragNDrop = true;
            let coatDriveDataset = this.props.dndDataset;
            this.itemClass = "drag_n_drop_coat_img";
            this.columnClass = "drag_n_drop_coats_column drag_n_drop_blue_column";
            this.multipleColumns = "";
            this.columnTextClass = "text-center text-white";
            this.scoreType = "itemOrder";
            this.pictureDir = "./assets/Activities/Volunteer/Coats/";
            this.backgroundClass = "drag_n_drop_warehouse_background mt-2";
            this.completedLit = "Coats Assembled ";
            this.correctMsg = "All coats have been sorted correctly.  Click 'Start' to get another bin of coats.";
            this.state.dragNDrop = dragNDropBefore ("Put Coats Back Together",
                CoatDriveInitialText, "", "", "", coatDriveDataset, "CoatDrive", 99);
        }
        if (this.props.modalType == "GoToZoo"){
            this.state.openCoatDriveDragNDrop = true;
            let zooAnimalsDataset = this.props.dndDataset;
            if (this.props.screenSize == "narrow" || this.props.screenSize == "medium"){
                this.itemClass = "drag_n_drop_zoo_animal_img_small ";
            }else{
                this.itemClass = "drag_n_drop_zoo_animal_img";
            }
            this.columnClass = "drag_n_drop_zoo_column drag_n_drop_blue_column no-column-gutters text-center";
            this.multipleColumns = "";
            this.columnTextClass = "text-center text-white";
            this.scoreType = "itemOrder";
            this.pictureDir = "./assets/Activities/HaveFun/zooPictures/";
            this.backgroundClass = "drag_n_drop_zoo_background mt-2";
            this.completedLit = "Animals Found";
            this.correctMsg = "All animals have been found.  Click 'Start' to hunt for more animals."
            this.state.dragNDrop = dragNDropBefore ("Put Zoo Animals Back Together",
                GoToZooInitialText, "", "", "", zooAnimalsDataset, "GoToZoo", 99);
        }
    }
  
    startTimer(){   
        this.state.startTimer = true;
        this.state.stopActivity = false;
        this.nbrSeconds = JSON.parse(JSON.stringify(this.props.timerInfo.nbrSeconds));
        this.nbrMinutes = JSON.parse(JSON.stringify(this.props.timerInfo.nbrMinutes));
    }

    stopTimer(){
        this.state.startTimer = false;
    }

    getTimeRemaining(time){
        this.remainingTime = time; 
    }

    endGame(score){
        this.state.startTimer = false;  
        score.timeRemaining = this.remainingTime;
        this.props.onClose(score);
    }

    turnOffTimer(){
        this.state.stopActivity = true;
    }

    render() {
        this.determineActivityParameters();
        this.gutters = setModalSize(this.props.screenSize);
      
    return <>
    <Modal show={this.props.show}  size={this.props.modalSize} dialogClassName={this.gutters} animation={false}>
        <Modal.Body>
            {this.props.timerInfo.timerValid ? 
                <div className="row">
                    <div className="col-md-8">    
                        {this.state.startTimer ?
                            <TimerReturn initialMinute={this.props.timerInfo.nbrMinutes} 
                                initialSeconds={this.props.timerInfo.nbrSeconds}
                                postTime={(time)=> this.getTimeRemaining(time)}
                                clearInterval={() => this.turnOffTimer()}/>
                        :
                            <div className="mt-5"></div>
                        }
                        <div className="font-weight-bold">{this.props.activityName} - Level {this.props.levelNbr}</div>
                        <p>{this.props.rulesText}</p>
                    </div> 
                {this.props.image != ""  ?
                    <div className="col-md-4 text-right">
                         <img src={this.props.image} className="small_size_img" alt="activity picture"/>
                    </div>
                :        
                    <div className="col-md-4">
                        <div className="font_color_white">xxxxxxx</div>
                        <div className="font_color_white">xxxxxxxx</div>
                    </div>
                }
                </div>
            :
                null    
            }    
            
            {this.props.activityType == "maze"  ?
                <MazeGameModal className="modal"
                    width={this.props.mazeParameters.width}
                    height={this.props.mazeParameters.height}
                    pctBlocked={this.props.mazeParameters.pctBlocked}
                    category={this.props.activityCategory}
                    modalType={this.props.modalType}
                    mazeParms={this.state.mazeParm}
                    timeAllowed={this.props.timerInfo.nbrSeconds}
                    nbrGames={this.props.nbrGames}
                    score={this.props.score}
                    friendInfo={this.props.friendInfo}
                    startTimer={() => this.startTimer()}
                    stopTimer={() => this.stopTimer()}
                    onGameOver={(score) => this.endGame(score)}  />
            :
                    null
            }

            {this.props.activityType == "wordPuzzle" ?
                <WordPuzzleModal className="modal"
                    wordList={this.props.wordList}
                    score={this.props.score}
                    modalType={this.props.modalType}
                    activityCategory={this.props.activityCategory}
                    stopActivity={this.state.stopActivity}
                    friendInfo={this.props.friendInfo}
                    onGameOver={(score) => this.endGame(score)}
                    startTimer={() => this.startTimer()}/>
           :
                    null
            } 
            
            {this.props.modalType == "GroceryShopper" ?
              <DragNDropModalGrocery 
                    modalType={this.props.modalType}
                    modalTitle={this.state.dragNDrop.modalTitle}
                    introText={this.state.dragNDrop.introText}
                    correctMsg={this.correctMsg}
                    cookieNamePfx={this.state.dragNDrop.cookieNamePfx}
                    buttonType={this.state.dragNDrop.buttonType}
                    DNDParameters={this.props.DNDParameters}
                    nbrGames={this.props.nbrGames}
                    friendInfo={this.props.friendInfo}
                    score={this.props.score}
                    startTimer={() => this.startTimer()}
                    onGameOver={(score) => this.endGame(score)} />
            :
                    null
            }  

            {this.props.modalType == "CoatDrive" || this.props.modalType == "FoodDrive" 
                || this.props.modalType == "GoToZoo"  ?
              <DragNDropCommonModal
                    modalType={this.props.modalType}
                    modalTitle={this.state.dragNDrop.modalTitle}
                    levelNbr={this.props.levelNbr}
                    introText={this.state.dragNDrop.introText}
                    cookieNamePfx={this.state.dragNDrop.cookieNamePfx}
                    itemClass={this.itemClass}
                    columnClass={this.columnClass}
                    columnTextClass={this.columnTextClass}
                    multipleColumns={this.multipleColumns}
                    pictureDir={this.pictureDir}
                    backgroundClass={this.backgroundClass}
                    completedLit={this.completedLit}
                    incrementNbr={this.incremenNbr}
                    correctMsg={this.correctMsg}
                    scoreType={this.scoreType}
                    dataset={this.state.dragNDrop.inputData}
                    DNDParameters={this.props.DNDParameters}
                    nbrGames={this.props.nbrGames}
                    score={this.props.score}
                    friendInfo={this.props.friendInfo}
                    startTimer={() => this.startTimer()}
                    onGameOver={(score) => this.endGame(score)} />       
            :
                    null
            }  
           
            {this.props.activityType == "jigsawPuzzle" ?  
              <JigsawPuzzleModal
                    screenSize={this.props.screenSize}
                    puzzleArr={this.props.jigsawParameters.puzzleArr}
                    pictureDir={this.props.jigsawParameters.pictureDir}
                    score={this.props.score}
                    friendInfo={this.props.friendInfo}
                    stopActivity={this.state.stopActivity}
                    startTimer={() => this.startTimer()}
                    stopTimer={() => this.stopTimer()}
                    onGameOver={(score) => this.endGame(score)} />
                    
            :
                    null
            }  
             
            {this.props.activityType == "comedyClub" ?  
              <ComedyClubModal
                    jokeList={this.props.comedyClubJokes}
                    activityCategory={this.props.activityCategory}
                    score={this.props.score}
                    friendInfo={this.props.friendInfo}
                    stopActivity={this.state.stopActivity}
                    startTimer={() => this.startTimer()}
                    stopTimer={() => this.stopTimer()}
                    onGameOver={(score) => this.endGame(score)} />
                    
            :
                    null
            }  
              
            {this.props.activityType == "pictureReveal" ?
              <div>
                  <div className="mt-4 text-white">xxxxx</div> 
                  <div className="mt-4 text-white">xxxxx</div>
                  <div className="mt-4 text-white">xxxx</div>
                  <div className="mt-4 text-white">xxxxx</div> 
                  <div className="mt-4 text-white">xxxxx</div>
                  <div className="mt-4 text-white">xxxx</div>
                  <div className="mt-4 text-white">xxxxx</div> 
                  <div className="mt-4 text-white">xxxxx</div>
                  <div className="mt-4 text-white">xxxx</div>
                  <div className="mt-4 mb-5 text-white">xxxxx</div> 
                
            
                <UndercoverPuzzleModal
                    screenSize={this.props.screenSize}
                    imageList={this.props.imageList}
                    score={this.props.score}
                    friendInfo={this.props.friendInfo}
                    activityCategory={this.props.activityCategory}
                    stopActivity={this.state.stopActivity}
                    startTimer={() => this.startTimer()}
                    stopTimer={() => this.stopTimer()}
                    onGameOver={(score) => this.endGame(score)} />
               </div>     
            :
                    null
            }  
                
        </Modal.Body>
    </Modal>
        
    </>
    }
}

export default withRouter(TimerDisplayModal);