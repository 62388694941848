import {Component} from 'react';
import { withRouter } from "react-router";
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import GameFeedbackDisplayModal from '../admin/game_feedback_display_modal';
import PlayerFeedbackDisplayModal from '../admin/player_feedback_display_modal';
import GameProfileDisplayModal from '../admin/game_profile_display_modal';
import JobListModal from '../admin/job_list_modal';
import SavingsOverTimeModal from '../extra_credit/savings_over_time_modal';
import LoanCalculatorModal from '../extra_credit/loan_calculator_modal';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import LeaderBoardModal from '../game-views/leader_board_modal';
import DiscussionGameData from './datasets/discussion_game_data';
import DiscussionSavingsData from './datasets/discussion_savings_data';
import DiscussionCreditCardData from './datasets/discussion_credit_card_data';
import MoneyTrain_Report_Discussion_Topics from './ForParents/Player_Reports_Discussion_Guide.pdf'
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';
import combinePlayerChoices from '../../objects/CommonUse/combine_player_choices';
import MoneyMgmtStudentTestModal from '../admin/money_mgmt_student_test_modal';
import MoneyMgmtTestModal from '../admin/money_mgmt_test_modal';
import CreditCardCalculatorModal from '../extra_credit/credit_card_calculator_modal';

class PlayerReports extends Component {
    constructor(props) {
        super(props);
        this.parentInfo = this.props.parentInfo();
        this.state={
          headers: setHeadersForFetch(),  
          option1: '<option value="',
          option2: '">',
          openFeedbackModal: false,
          loadGameFeedback: false,
          gameFeedback: null,
          loadPlayerFeedback: false,
          playerFeedback: null,
          loadGameData: false,
          gameData: null,
          loadSavingsData: false,
          savingsData: null,
          loadCreditCardData: false,
          creditCardData: null,
          loadGameChoicesData: false,
          gameChoicesData: null,
          waitState: false,
          parmProcessComplete: false,
          userListLoaded: false,
          displaySelectUserModal: false,
          openSelectUserModal: true,
          playerListArr: [],
          gameFeedback: null,
          loadGameFeedback: false,
          openModal: true,
          jobList: null,
          loadJobListData: false,
          openProcessName: "",
          calculateOnly: true,
          initialAmt: true,
          modalOpen: true,
          rerender: false,
          loadJobChoiceData: false,
          loadHomeChoiceData: false,
          loadCarChoiceData: false,
          loadStudentTest: false,
          studentTest: false,
          moneyMgmtTest: false,
          moneyMgmtTestData: null,
          subProcess: "playerReports",
          environment: Config.dataURL.currentEnv,
        };   
    }

    reportSelected(reportType){
        this.state.openFeedbackModal = true;
        switch (reportType){
            case "gameProfile":
                this.state.loadGameChoicesData = false;
                this.loadGamesForAccount();
                break;
            case "leaderBoard":
                this.setState({rerender: true});
                this.state.modalOpen = true;
                this.accountNbr = sessionStorage.getItem("userAccountNbr");
                this.state.openProcessName = "leaderBoard";
                break;
            case "savings":
                this.setState({rerender: true});
                this.state.modalOpen = true;
                this.state.openProcessName = "savingsCalculator";
                break;
            case "loans":
                this.setState({rerender: true});
                this.state.modalOpen = true;
                this.state.openProcessName = "loanCalculator";
                break;
            case "Game":
                this.loadGameFeedback();
                break;
            case "Player":
                this.loadPlayerFeedback();
                break;
            case "jobList":
                this.loadJobList();
                break;
            case "discussion":
                this.setDiscussionData();
                break;
            case "creditCard":
                this.state.modalOpen = true;
                this.state.openProcessName = "creditCardCalculator";
                break;
            case "studentTest":
                this.loadStudentTests(reportType);
                break;
            case "testAssess":
                this.loadStudentTests(reportType);
                break;
            default:
                break;
            
        }
    }
    
    closeReportModals(){
        this.state.loadGameFeedback = false;
        this.state.loadPlayerFeedback = false;
        this.state.loadGameData = false;
        this.state.getProfileParms = false;
        this.state.loadJobListData = false;
        this.state.modalOpen = false;
        this.state.openProcessName = "";
        this.state.studentTest = false;
        this.state.moneyMgmtTest = false;
    }

    closeModal(){
        this.setState({modalOpen: false});
    }
  
    render() {
        if (this.state.loadJobChoiceData == true && this.state.loadCarChoiceData == true &&
            this.state.loadHomeChoiceData == true && this.state.loadGameChoicesData == false){
                this.allChoicesArr = combinePlayerChoices(this.gameChoiceArr, this.jobChoiceData,
                    this.homeChoiceData, this.carChoiceData);
                this.state.waitState = false;
                this.state.loadGameChoicesData = true;
        }
        this.gutters = "";
        if  (this.props.screenSize == "narrow"){
            this.gutters = "modal-95";
        }
        let screenParms = setScreenSizeParameters(this.props.screenSize, "forParents");
        this.className = screenParms.columnClass;
        if (this.parentInfo.loading == false && this.parentInfo.savingsLoaded == true){
              this.state.playerDataLoaded = true;
        }
                    
    return <>
           <div className={this.className}>
                <div className="card mt-3 mr-5 rounded-corners-lg height-max">
                    <button className="pages-back-button ml-4 mt-4" 
                        onClick ={()=> this.props.goBack()}>
                                Back
                    </button>
                    <h3 className="my-0 text-center">Player Reports</h3>
                    <h5 className="text-center">Discussion and Player Feedback Reports</h5>
                    <div className="mx-4 mt-2">
                          The reports available on this page can be displayed to view the
                          status and key information about the game ...OR... in conjunction
                          with the discussion guide (link provided below).
                    </div>
                    <table className="mt-2 overflow-scroll">
                    <tbody className="row-100" >
                        <tr >
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("gameProfile")}>
                                            Game Profiles
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("leaderBoard")}>
                                           Leader Board for Class
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("jobList")}>
                                           Job List
                                </button>
                            </td>
                           
                        </tr>
                        <tr>
                        <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("savings")}>
                                           Savings Calculator
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("loans")}>
                                           Loan Calculator
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("creditCard")}>
                                           Credit Card Calculator
                                </button>
                            </td>
                           
                        </tr>
                        <tr>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("Player")}>
                                            Player Comments
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("Game")}>
                                            Player Game Feedback
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("discussion")}>
                                           Game Profiles for Discussion
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("studentTest")}>
                                            Student Test Results
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("testAssess")}>
                                            Test Assessment
                                </button>
                            </td>
                        </tr>
                           
                    </tbody>
                    </table>
                    <div className="mt-3 text-center">
                  
                    {this.state.waitState ?
                          <h3 className="mt-5">This may take a few minutes to load.</h3>
                    :
                        null
                    }
                </div>
                  
                    {this.state.waitState ?
                       <WaitTimeSpinner/>
                    :
                        <span>
                         {this.state.openProcessName == "leaderBoard" ?
                            <LeaderBoardModal modalOpen={this.state.modalOpen} 
                                accountNbr={this.accountNbr}
                                playerSelected={false}
                                onClose={() => this.closeModal()} 
                                gameInfo = {() => this.parentInfo}
                            />
                        :
                            <div></div>
                        }

                        {this.state.openProcessName == "savingsCalculator" ?
                            <SavingsOverTimeModal show={this.state.modalOpen}
                                gutters={this.gutters}
                                onClose={() => this.closeReportModals()}
                                calculatorSw={this.state.calculateOnly} 
                                initialAmt={this.state.initialAmt}/>
                            :
                            <div></div>
                        }

                        {this.state.openProcessName == "loanCalculator"  ?
                            <LoanCalculatorModal show={this.state.modalOpen} 
                                screenSize={this.props.screenSize}
                                onClose={() => this.closeReportModals()} 
                                calculatorSw ={this.state.calculateOnly}/>
                        :
                            <div></div>
                        }

                        {this.state.openProcessName == "creditCardCalculator"  ?
                            <CreditCardCalculatorModal show={this.state.modalOpen} 
                                screenSize={this.props.screenSize}
                                onClose={() => this.closeReportModals()} 
                            />
                        :
                            <div></div>
                        }

                        {this.state.loadPlayerFeedback ?
                            <PlayerFeedbackDisplayModal openModal={this.state.openFeedbackModal} 
                                commentsOnly={true}
                                onClose={() => this.closeReportModals()} 
                                playerFeedback={this.state.playerFeedback}
                                
                            />
                        :
                            null
                        }

                        {this.state.loadGameData && this.state.loadSavingsData && this.state.loadCreditCardData 
                            && this.state.loadGameChoicesData ?
                                <GameProfileDisplayModal openModal={this.state.openFeedbackModal} 
                                    onClose={() => this.closeReportModals()} 
                                    parentInfo = {() => this.props.parentInfo()} 
                                    setParentInfo = {(value, propertyName) => 
                                        this.props.setParentInfo(value, propertyName)}
                                    gameData={this.state.gameData}
                                    savingsData={this.state.savingsData}
                                    creditCardData={this.state.creditCardData}
                                    gameChoicesData={this.allChoicesArr}
                                    screenSize={this.props.screenSize}
                                />
                        :
                            null
                        }

                        {this.state.loadGameFeedback ?
                            <GameFeedbackDisplayModal openModal={this.state.openFeedbackModal} 
                                onClose={() => this.closeReportModals()} 
                                gameFeedback={this.state.gameFeedback}
                             
                            />
                        :
                            null
                        }

                        {this.state.loadJobListData ?
                            <JobListModal
                                openModal={this.state.openModal}
                                jobListArr={this.state.jobList}
                                onClose={() => this.closeReportModals()} 
                            />
                        :
                            null
                        }

                        {this.state.studentTest ?
                            <MoneyMgmtStudentTestModal
                                openModal={this.state.openModal}
                                moneyMgmtTestArr={this.state.moneyMgmtTestData}
                                onClose={() => this.closeReportModals()} 
                            />
                        :
                            null
                        }

                        {this.state.moneyMgmtTest ?
                            <MoneyMgmtTestModal
                                openModal={this.state.openModal}
                                moneyMgmtTestArr={this.state.moneyMgmtTestData}
                                onClose={() => this.closeReportModals()} 
                            />
                        :
                            null
                        }

                        </span>
                    }
                      <div className="mt-2 text-center">
                        <a className="decorative light-blue-color text-pointer" 
                            href={MoneyTrain_Report_Discussion_Topics} target="_blank" 
                            rel="noreferrer">
                                View Player Reports Discussion Guide (PDF)
                        </a>
                    </div>
                                      
                </div>        
            </div>
        </>
    }


nextSelection(){
    if (this.parentInfo.relatedPlayers.length == 1){
        this.props.onInput(0);
        this.props.history.push('/for-parents-main');
     }else{
        this.state.selectPlayer = true;
        this.state.playerDataLoaded = false;
        this.props.onReset();
 
        this.state.message = ""
    }
    this.state.confirmSelectionDone = false;
}
      
loadPlayerFeedback(){
    this.setState({waitState: true});
    let playerArr = (this.parentInfo.relatedPlayers).join(',');
    const funcName = "loadPlayerFeedback";
    const urlParm = "?userList=" + playerArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
       {method:'GET', headers: this.state.headers})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.sortPlayerFeedback(output.body);
                    this.setState({loadPlayerFeedback: true});
                    this.setState({waitState: false});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
}

sortPlayerFeedback(playerFeedback){
    this.state.playerFeedback = [];
    for (let i=0; i<playerFeedback.length; i++){
        let sortKey = playerFeedback[i].user + playerFeedback[i].dateAdded + playerFeedback[i].timeAdded;
        let feedbackObj = {...playerFeedback[i], sortKey: sortKey};
        this.state.playerFeedback.push(feedbackObj);
    }
    (this.state.playerFeedback).sort((a, b) => (a.sortKey > b.sortKey) ? 1 : -1)
}


loadGamesForAccount(){
    this.setState({waitState: true});
    this.state.getProfileParms = false;
    this.state.loadJobChoiceData = false;
    this.state.loadCarChoiceData = false;
    this.state.loadHomeChoiceData = false;
    let playerArr = (this.parentInfo.relatedPlayers).join(',');
    const funcName = "loadGamesForAccount";
    const urlParm = "?userNameArr=" + playerArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        (output.body).sort((a, b) => (a.user > b.user) ? 1 : -1)
                        this.state.gameData = output.body;
                        this.setGameIdArr();
                        this.loadSavings();
                        this.loadCreditCardInfo();
                        this.loadJobChoices();
                        this.loadHomeChoices();
                        this.loadCarChoices();
                        this.setState({loadGameData: true})
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
}

setGameIdArr(){
    this.gameIdArr = [];
    this.gameChoiceArr = [];
     for (let i=0; i<this.state.gameData.length; i++){
          this.gameIdArr.push(this.state.gameData[i]._id);
          let gameData = this.state.gameData[i];
          let gameChoiceObj = {gameID: gameData._id, jobID: gameData.jobInfo.careerID,
            homeID: gameData.homeInfo.homeID, carID: gameData.carInfo.carID};
          this.gameChoiceArr.push(gameChoiceObj);
    }
}

loadSavings() {
    let gameID = (this.gameIdArr).join(',');
    const funcName = "loadSavings";
    const urlParm = "?gameID=" + gameID + "&returnType=" + "Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.state.savingsData = output.body;
                        this.setState({loadSavingsData: true});
                    }else{
                        if (statusCode == 250){
                            this.state.savingsData = [];
                            this.setState({loadSavingsData: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
            };
        });
}

loadCreditCardInfo() {
    let gameID = (this.gameIdArr).join(',');
    const funcName = "loadCreditCardInfo";
    const urlParm = "?gameID=" + gameID + "&returnType=" + "Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
       {method:'GET', headers: this.state.headers})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.state.creditCardData = output.body;
                    this.setState({loadCreditCardData: true});
                }else{
                    if (statusCode == 250){
                        this.state.creditCardData = [];
                        this.setState({loadCreditCardData: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                }
            });
        };
    });
}

loadJobChoices(){
    let jobIdArr=[];
    for (let i=0; i<this.gameChoiceArr.length; i++){
          jobIdArr.push(this.gameChoiceArr[i].jobID);
    }
    const jobIdParm = jobIdArr.join(',');
    const funcName = "jobInfo";
    const urlParm = "?jobId=" + jobIdParm + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', 
        headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.jobChoiceData = output.body;
                    this.setState({loadJobChoiceData: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
  }

  
loadHomeChoices(){
    let homeIdArr=[];
    for (let i=0; i<this.gameChoiceArr.length; i++){
          homeIdArr.push(this.gameChoiceArr[i].homeID);
    }
    const homeIdParm = homeIdArr.join(',');
    const funcName = "homeInfo";
    const urlParm = "?homeId=" + homeIdParm + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', 
        headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.homeChoiceData = output.body;
                    this.setState({loadHomeChoiceData: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
  }
    
loadCarChoices(){
    let carIdArr=[];
    for (let i=0; i<this.gameChoiceArr.length; i++){
          carIdArr.push(this.gameChoiceArr[i].carID);
    }
    const carIdParm = carIdArr.join(',')
    const funcName = "carInfo";
    const urlParm = "?carId=" + carIdParm + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', 
        headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.carChoiceData = output.body;
                    this.setState({loadCarChoiceData: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
}

loadGameFeedback(){
    this.setState({waitState: true});   
    const playerArr = (this.parentInfo.relatedPlayers).join(',');  
    const funcName = "loadGameFeedback";
    const urlParm = "?userList=" + playerArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,   
       {method:'GET', headers: this.state.headers})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.state.gameFeedback = output.body;
                    this.setState({loadGameFeedback: true});
                    this.setState({waitState: false});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
}

loadJobList(){
    this.setState({waitState: true});
    const funcName = "jobListAll";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.state.jobList = output.body;
                        this.state.jobList = 
                            this.state.jobList.sort((a, b) => (a.jobName > b.jobName) ? 1 : -1)
                        this.setState({loadJobListData: true});
                        this.setState({waitState: false});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
  }

  
  loadStudentTests(reportType){
    this.setState({waitState: true});
    const funcName = "loadMoneyMgmtTest";
    const playerArr = (this.parentInfo.relatedPlayers).join(',');  
    const urlParm = "?userArr=" + playerArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.state.moneyMgmtTestData = output.body;
                        this.state.moneyMgmtTestData = 
                        this.state.moneyMgmtTestData.sort((a, b) => (a.user + a.dateAdded > b.user + b.dateAdded) ? 1 : -1);
                        if (reportType == "testAssess"){
                            this.setState({moneyMgmtTest: true});
                        }else{
                            this.setState({studentTest: true});
                        }
                        this.setState({loadStudentTest: true});
                        this.setState({waitState: false});
                    }else{
                        if (statusCode == 250){
                            this.setState({waitState: false});
                            alert ("There is no student test data for this parent/teacher.")
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                    status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
            };
        });
  }

setDiscussionData(){
    this.setState({waitState: true});
    this.state.loadGameChoicesData = false;
    this.state.getProfileParms = false;
    this.state.loadJobChoiceData = false;
    this.state.loadCarChoiceData = false;
    this.state.loadHomeChoiceData = false;
    this.state.gameData = DiscussionGameData;
    this.state.savingsData = DiscussionSavingsData;
    this.state.creditCardData = DiscussionCreditCardData;
    this.setState({loadGameData: true});
    this.setState({loadSavingsData: true});
    this.setState({loadCreditCardData: true});
    this.setGameIdArr();
    this.loadJobChoices();
    this.loadHomeChoices();
    this.loadCarChoices();
}


}

export default withRouter(PlayerReports);