import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Component} from 'react';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import {rollForwardDate} from './objects/roll_forward_date_function';

class TwoColumnTableCard extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, selected: false};
    }


    render() {
  
        switch (this.props.infoType){
              case "date-amount":
                  this.reformatStartDate = reformatDateToDisplay(this.props.outInfo.loanStartDate);
                  this.endDate = rollForwardDate(this.props.outInfo.loanStartDate, this.props.outInfo.loanNbrMonths);
                  this.reformatEndDate = reformatDateToDisplay(this.endDate);
                  if (this.props.outInfo.amount == undefined){
                      this.outAmount = this.props.outInfo.loanAmt;
                  }else{
                      this.outAmount = this.props.outInfo.amount;
                  }
                  break;
              case "label-date":
                  this.class = "text-left p-0 ";
                  if (this.props.outInfo.class != undefined){
                       this.class = this.class + this.props.outInfo.class;
                  }
                  this.reformatEndDate = reformatDateToDisplay(this.props.outInfo.loanEndDate);
                  break;
              case "label-amount":
                   if (this.props.className == undefined){
                        this.className = "p-0 text-right" 
                   }else{ 
                        this.className =  this.props.className;
                   } 
                   if (this.props.outInfo.color == "red"){
                        this.className = this.className + " text-danger";  
                   }
                   break;
              case "label-number":
                    this.className = "p-0 text-right " 
                    break;
              default: 
                  break;
            }
                  
    return <>
       {this.props.infoType == "label-date" ?
            <tr>
              <td className="text-left p-0">{this.props.outInfo.label}</td>
              <td className="p-0 text-right">{this.reformatEndDate}</td>
            </tr>
       :
            null
       }
       {this.props.infoType == "date-amount"  ?
            <tr>
              <td className={this.class}>{this.reformatStartDate}-{this.reformatEndDate}</td>
              <td className="p-0 text-right">{doubleToStringMonthly(this.outAmount)}</td>
            </tr>
       :
          null
       }
       {this.props.infoType == "label-amount" ?
          <tr>
              <td className="text-left p-0">{this.props.outInfo.label}</td>
              <td className={this.className}>{doubleToStringMonthly(this.props.outInfo.amount)}</td>
          </tr>
       :
          null
       }
       {this.props.infoType == "label-number" ?
          <tr>
              <td className="text-left p-0">{this.props.outInfo.label}</td>
              <td className={this.className}>{this.props.outInfo.number}</td>
          </tr>
       :
          null
       }
        
        
    </>
    }
}

export default TwoColumnTableCard;