import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LoanCalculatorModal from './loan_calculator_modal';
import DragNDropModal from './drag_n_drop_modal';
import LoanChallenge1Dataset from './Datasets/loan_challenge1_dataset';
import LoanChallenge1InitialText from '../../dragNDropText/loan_challenge1_initial_text';
import LoanChallenge1HintsText from './text/loan_challenge1_hints_text';
import LoanChallenge1ResultsText from './text/loan_challenge1_results_text';
import LoanChallenge2Dataset from './Datasets/loan_challenge2_dataset';
import LoanChallenge2InitialText from '../../dragNDropText/loan_challenge2_initial_text';
import LoanChallenge2HintsText from './text/loan_challenge2_hints_text';
import { createColumnArray } from '../../objects/DragNDrop/createColumnArray_function';
import { calcColumnScore } from '../../objects/DragNDrop/calcColumnScore_function';
import { createResultsArray } from './objects/createResultsArray_function';
import ExtraCreditConfigFile from './Datasets/extra_credit_config_file';
import {dragNDropBefore} from '../../objects/DragNDrop/drag_n_drop_before_function';

class LearningAboutLoans extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            dragNDrop: {},
            hintTable: [],
            hintNbr: 0,
            displayAlert: false,
            modalMessage: null,
            tableObject: [],
            tableNbrRows: 0,
            freezeFlag: false,
            currentStatus: "",
            calculatorStatus: "DisplayCalculator",
            openCalculatorSw: false,
            openSw: false,
            challenge1Score: 0,
            challenge2Score: 0,
            averageScore: 0,
            calculateOnly: false,
            col1Text: "",
        }
    }
       
    openLoanCalculator () {
        this.state.openCalculatorSw = true;
        this.state.calculatorStatus = "OpenCalculator"
        if (this.state.currentStatus == "OpenChallenge1" || 
                this.state.currentStatus == "OpenChallenge2") {
            this.state.calculateOnly = true;
        }else{
            this.state.calculateOnly = false;
        }
    };

    closeLoanCalculator() {
        this.state.openCalculatorSw = false;
        this.state.calculatorStatus = "CloseCalculator";
        if (this.state.currentStatus != "OpenChallenge1" &&
            this.state.currentStatus != "OpenChallenge2") {
             this.state.currentStatus = "DisplayChallenge1"
        }
    }

    openLoanChallenge1() {
        this.state.currentStatus = "OpenChallenge1";
        this.state.openSw = true;

        this.state.dragNDrop = dragNDropBefore("Categorize the Loan Parameters",
            LoanChallenge1InitialText, "", "DoneCalculator", "Loan", LoanChallenge1Dataset,
            "LoanChallenge1", 1);
         this.state.hintTable = LoanChallenge1HintsText; 
                  
    }

    closeLoanChallenge1() {
        this.state.openSw = false;
        this.state.currentStatus = "DisplayChallenge1";
        this.state.freezeFlag = false;
    }

    saveLoanChallenge1() {
        var columnArray=createColumnArray(this.state.dragNDrop.cookieNamePfx, 5);
        this.state.challenge1Score = calcColumnScore(columnArray);
        if (this.state.challenge1Score > 85) {
            this.state.modalMessage = "";
            sessionStorage.setItem("loanChallenge1Score", this.state.challenge1Score);
            this.state.dragNDrop.introText = LoanChallenge1ResultsText;
            this.state.tableObject=createResultsArray(columnArray);
            this.state.col1Text=["Calculated Amount"];
            this.state.tableNbrRows = 1;
            this.state.dragNDrop.buttonType = "Continue";
            this.state.freezeFlag = true;
        } else {
            this.state.modalMessage= "Not quite right... Try again.";
            this.state.displayAlert = true;
            if (this.state.hintNbr < this.state.hintTable.length){
                this.state.hintNbr+=1;
            }
        }   
    }

    finishChallenge1() {
        this.state.openSw = false;
        this.state.currentStatus = "DisplayChallenge2";

    }

    openLoanChallenge2() {
        this.state.currentStatus = "OpenChallenge2"
        this.state.openSw = true;
        
        this.state.dragNDrop = dragNDropBefore ("Detrmine Which Loan Requests Should Be Approved", 
            LoanChallenge2InitialText, "", "DoneCalculator", "Loan", LoanChallenge2Dataset, "loanChallenge2", 
            "");
       
        this.state.freezeFlag = false; 
        this.state.tableNbrRows = 0;
        this.state.tableObject = [];
        this.state.hintTable = LoanChallenge2HintsText;
    }

    closeLoanChallenge2() {
        this.state.openSw = false;
        this.state.currentStatus = "DisplayChallenge2"
    }

    saveLoanChallenge2() {
        var columnArray=createColumnArray(this.state.dragNDrop.cookieNamePfx, 3);
        if (columnArray[0].items.length != 0) {
            this.state.modalMessage = 
                "Drag all items from the Loan Request to the Approve or Denied columns."
        }else{
            this.state.challenge2Score = calcColumnScore(columnArray);
            if (this.state.challenge2Score >= 85) {
               this.finishLoans();
            } else {
                this.state.modalMessage= "Not quite right... Try again."
                this.state.displayAlert = true;
                if (this.state.hintNbr < this.state.hintTable.length){
                    this.state.hintNbr+=1;
                }
            }
        }
    }
   
    getLoanChallenge1Score() {
        var challenge1Score = parseFloat(sessionStorage.getItem("loanChallenge1Score"));
        if (!isNaN(challenge1Score) && this.state.currentStatus == "") {
           this.state.currentStatus = "DisplayChallenge2" 
           this.state.challenge1Score = challenge1Score;
        }
    }
    
    closeAlert(){
        this.state.displayAlert = false;
        this.setState({displayAlert: false});
    }
    
    
    render() {
        this.getLoanChallenge1Score();
        this.gutters = "";
        if (this.props.screenSize == "narrow" || this.props.screenSize == "medium"){
                this.gutters = "modal-95";
        }
        
               
    return <>
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" scrollable="true"
            animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Extra Credit - Learning About Loans</Modal.Title> 
          </Modal.Header>
          <Modal.Body>
           
                {this.state.calculatorStatus == "DisplayCalculator" ?
                    <span>
                        <div className="mt-2, mx-4">
                            <p>Click on the "Loan Calculator" button to learn how the loan parameters 
                            impact the amount that you must pay each month and the total amount you will 
                            pay.  Once you understand the basic concepts, you can tackle the
                            challenge exercises that will be available.</p>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary w-75 mt-2" 
                                onClick ={()=> this.openLoanCalculator()}>
                                    Loan Calculator
                            </button>
                        </div>
                    </span>
                :
                    <div></div>
                }
                {this.state.calculatorStatus == "OpenCalculator" ?
                    <LoanCalculatorModal show={this.state.openCalculatorSw} 
                        screenSize={this.props.screenSize}
                        onClose={() => this.closeLoanCalculator()} 
                        calculatorSw ={this.state.calculateOnly}/>
                :
                    <div></div>
                }
                {this.state.currentStatus == "DisplayChallenge1" ?
                    <span>
                         <div className="m-4" >
                             <p>Now that you've had a chance to experiment with the loan calculator,
                                click on the "Challenge #1" button to check your understanding of
                                how the key loan factors affect the payment amounts.</p>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary w-75 mt-4" 
                            onClick ={()=> this.openLoanChallenge1()}>
                                Loan Challenge #1
                            </button>
                        </div>
                    </span>
                :
                    <div></div>
                }

                {this.state.currentStatus == "OpenChallenge1" ?
                    <DragNDropModal show={this.state.openSw} 
                        gutters={this.gutters}
                        onClose={() => this.closeLoanChallenge1()} 
                        onSave={() => this.saveLoanChallenge1()}
                        onCalculator={() => this.openLoanCalculator()}
                        onContinue={() => this.finishChallenge1()}
                        closeAlert={() => this.closeAlert()}
                        inputDataset={this.state.dragNDrop.inputData}
                        modalSize={this.state.dragNDrop.modalSize}
                        modalTitle={this.state.dragNDrop.modalTitle}
                        buttonType={this.state.dragNDrop.buttonType}
                        calcType={this.state.dragNDrop.calculatorType}
                        introText={this.state.dragNDrop.introText}
                        cookieNamePfx={this.state.dragNDrop.cookieNamePfx}
                        maxNbr={this.state.dragNDrop.maxNbrPerColumn}
                        message={this.state.modalMessage}
                        tableNbrRows={this.state.tableNbrRows}
                        tableCol1Text={this.state.col1Text}
                        tableObjectRow={this.state.tableObject}
                        freezeFlag={this.state.freezeFlag}
                        hintTable={this.state.hintTable}
                        hintNbr={this.state.hintNbr}
                        displayAlert={this.state.displayAlert}

                        />
                :
                    <div></div>
                }

                {this.state.currentStatus  == "DisplayChallenge2" ?
                    <span>
                        <p className="mt-4 ml-4 p-2">Congratulations, you have successfully completed 
                            the first challenge, would you like to try another one?  When you successfully
                            complete the second challenge, you will earn points for your game. 
                        </p>
                        <div className="text-center">
                            <button className="btn btn-primary w-75 mt-6" 
                                onClick ={()=> this.openLoanChallenge2()}>
                                    Challenge #2
                            </button>
                        </div>
                    </span> 
                :
                    <div></div>
                }
                   
                {this.state.currentStatus == "OpenChallenge2" ?
                    <DragNDropModal show={this.state.openSw} 
                        gutters={this.gutters}
                        onClose={() => this.closeLoanChallenge2()} 
                        onSave={() => this.saveLoanChallenge2()}
                        onCalculator={() => this.openLoanCalculator()}
                        closeAlert={() => this.closeAlert()}
                        inputDataset={this.state.dragNDrop.inputData}
                        modalSize={this.state.dragNDrop.modalSize}
                        modalTitle={this.state.dragNDrop.modalTitle}
                        introText={this.state.dragNDrop.introText}
                        cookieNamePfx={this.state.dragNDrop.cookieNamePfx}
                        buttonType={this.state.dragNDrop.buttonType}
                        calcType={this.state.dragNDrop.calculatorType}
                        message={this.state.modalMessage}
                        hintTable={this.state.hintTable}
                        hintNbr={this.state.hintNbr}
                        displayAlert={this.state.displayAlert}
                        />
                    :
                        <div></div>
                    }
                </Modal.Body>
          <Modal.Footer className="text-right">
                <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"/>
                <Button variant="secondary"   onClick={() => this.props.onClose()}>
                    Close
                </Button>
          </Modal.Footer>
          </Modal>
       </>
    }

    finishLoans() {
        this.state.averageScore = (+this.state.challenge1Score + +this.state.challenge2Score) / 2; 
        this.calculateLoanPoints();
        sessionStorage.setItem("previousExtraCreditProcess", "Learning About Loans"); 
        this.props.onCompleted(); 
    }

    calculateLoanPoints(){

        var earnedAmt = 0;
        let processName = "LearningAboutLoans";
        let earningsObj = ExtraCreditConfigFile.exercises.find(elem => elem.category === processName);
        earnedAmt = (this.state.averageScore/100 * earningsObj.amt).toFixed(2);
                    
        const loansObj = {processName: processName, score: this.state.averageScore,
            assetType: earningsObj.type,  amount: earnedAmt, level: earningsObj.level};
        sessionStorage.setItem("extraCreditCompleted", JSON.stringify(loansObj));
    }
}

export default withRouter (LearningAboutLoans);