import { dragNDropCreateDataset } from "../../../objects/DragNDrop/drag_n_drop_create_dataset";

export const createNewDragNDropDataset = (DNDParameters, dataset, pictureDir) => {
    let nbrColumns = DNDParameters.nbrColumns;
    let dragNDropReturnDataset = "";

    if (DNDParameters.nbrItems != 0){
        let nbrItemsInColumn1 = DNDParameters.nbrItems;
        dragNDropReturnDataset = dragNDropCreateDataset(nbrColumns, dataset, pictureDir, nbrItemsInColumn1);  
            
    }else{
        dragNDropReturnDataset = dragNDropCreateDataset(nbrColumns, dataset, pictureDir);  
    }
   
    return dragNDropReturnDataset;
   
}

