import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../getting-started/GettingStarted/gettingStarted.css";
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {Component} from 'react';
import {doubleToStringAnnualDollars} from '../../objects/CommonUse/double_functions';

class CareerCard extends Component {
    constructor(props) {
        super(props);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
        this.state = {open: false, selected: false};
    }

    toggleExpanded(e) {
        this.setState({open: !this.state.open});
    }

    toggleSelected(e) {
        this.setState({selected: !this.state.selected});
        this.props.onSelected();
    }
    
    render() {
        const pictureImgSrc = './assets/Jobs/' + this.props.jobProfile.pictureImg;        
        const likesList = this.props.jobProfile.likes.map((like, key) =>
        <li key = {key}>{like}</li>
        );
        const dislikesList = this.props.jobProfile.dislikes.map((dislike, key) =>
        <li key = {key}>{dislike}</li>
        );
    return <>
        <div className=
        {this.props.selectedJobID == this.props.jobProfile.id ?
            "card mx-3 my-2 link-tab-selected"
            :
            "card mx-3 my-2 drop-shadow"
        }
        >
        <div className="card-header text-left">
        <span className="container m-0 p-0">
            <span className="row w-100 h-30 mx-0" onClick={this.toggleExpanded}>
                <div className="col-sm-6 p-0">
                    <img src={pictureImgSrc} className="job_profile_thumbnail_img" alt="job picture"/>
                </div>
                <div className="w-75 col-sm-5 p-0">
                    <p className="mb-0 font-weight-bold">{this.props.jobProfile.jobName}</p>
                    <p className="mb-0 w-100">Annual Salary Range: {doubleToStringAnnualDollars(this.props.jobProfile.minSalary)}
                    -{doubleToStringAnnualDollars(this.props.jobProfile.maxSalary)}</p>
                </div>
                <div className="text-right w-25 col-sm-1">
                {this.state.open ?
                    <FontAwesomeIcon icon={faCaretDown} className="career_card_caret" />
                    :
                    <FontAwesomeIcon icon={faCaretRight} className="career_card_caret" />
                }
                </div>
            </span>
        </span>
        </div>
        {this.state.open ?
            <div className="card-body">
                <div className="text-left"  onClick={this.toggleExpanded}>
                    <h6>Job Description</h6>
                    <p>{this.props.jobProfile.description}</p>
                    <h6>Post High-School Education Required</h6>
                    <p>{this.props.jobProfile.degreeType}-{this.props.jobProfile.degreeSpecialty} &nbsp;
                            ({this.props.jobProfile.nbrYearsCollege}&nbsp;years)</p>
                    <h6>Likes</h6>
                    <ul>{likesList}</ul>
                    <h6>Dislikes</h6>
                    <ul>{dislikesList}</ul>
                    <h6>Salary</h6>
                    <ul className="m-0">
                        <li>Starting salary: {doubleToStringAnnualDollars(this.props.jobProfile.minSalary)}</li>
                        <li>Average salary: {doubleToStringAnnualDollars(this.props.jobProfile.avgSalary)}</li>
                        <li>Maximum salary: {doubleToStringAnnualDollars(this.props.jobProfile.maxSalary)}</li>
                    </ul>
                </div>
                <div className="text-right">
                    <button className="btn btn-primary" onClick={this.toggleSelected}>
                        Select Job
                     </button>
                </div>
            </div>
        : <span/>}
        </div>
    </>
    }
}

export default CareerCard;