const ActivityLevelsDataset = [
        
        {activityName: "DeliveryDriver",  timerValid: true, levels:
                [{secondsAllowed: "7", gridHeight: 5, gridWidth: 5, pctBlocked: 0.15, modalSize: "md", maxNbrGames: 5},
                 {secondsAllowed: "6", gridHeight: 5, gridWidth: 5, pctBlocked: 0.15, modalSize: "md", maxNbrGames: 5},
                 {secondsAllowed: "6", gridHeight: 5, gridWidth: 5, pctBlocked: 0.25, modalSize: "md", maxNbrGames: 5},
                 {secondsAllowed: "9", gridHeight: 7, gridWidth: 7, pctBlocked: 0.15, modalSize: "md", maxNbrGames: 5},
                 {secondsAllowed: "8", gridHeight: 7, gridWidth: 7, pctBlocked: 0.25, modalSize: "md", maxNbrGames: 5},
                 {secondsAllowed: "10", gridHeight: 9, gridWidth: 9, pctBlocked: 0.15, modalSize: "lg", maxNbrGames: 5},
                 {secondsAllowed: "12", gridHeight: 9, gridWidth: 9, pctBlocked: 0.30, modalSize: "lg", maxNbrGames: 5},
                 {secondsAllowed: "13", gridHeight: 10, gridWidth: 10, pctBlocked: 0.15, modalSize: "lg", maxNbrGames: 4},
                 {secondsAllowed: "11", gridHeight: 10, gridWidth: 10, pctBlocked: 0.15, modalSize: "lg", maxNbrGames: 4},
                 {secondsAllowed: "12", gridHeight: 10, gridWidth: 10, pctBlocked: 0.30, modalSize: "lg", maxNbrGames: 4},
                ]
        },
        {activityName: "GroceryShopper",  timerValid: false, levels:
                [{modalSize: "lg", nbrItems: "9", nbrColumns: "3",  nbrInList: "3", maxNbrGames: 5},
                 {modalSize: "lg", nbrItems: "12", nbrColumns: "3", nbrInList: "5", maxNbrGames: 5},
                 {modalSize: "lg", nbrItems: "15", nbrColumns: "3", nbrInList: "7", maxNbrGames: 5},
                ]
        },
        {activityName: "TutorGrammar", timerValid: true, levels:
                [{minutesAllowed: "1", secondsAllowed: "0", wordList: "nouns", modalSize: "lg"},
                 {minutesAllowed: "1", secondsAllowed: "0", wordList: "verbs", modalSize: "lg"},
                 {minutesAllowed: "1", secondsAllowed: "0", wordList: "adjectives", modalSize: "lg"},
                 {minutesAllowed: "1", secondsAllowed: "0", wordList: "adverbs", modalSize: "lg"},
                ]
        },
        {activityName: "DogWalker",  timerValid: true, levels:
                [{secondsAllowed: "6", gridHeight: 8, gridWidth: 6, pctBlocked: 0.15, modalSize: "md", maxNbrGames: 5},
                 {secondsAllowed: "5", gridHeight: 8, gridWidth: 6, pctBlocked: 0.25, modalSize: "md", maxNbrGames: 5},
                 {secondsAllowed: "6", gridHeight: 10, gridWidth: 6, pctBlocked: 0.20, modalSize: "md", maxNbrGames: 5},
                 {secondsAllowed: "6", gridHeight: 10, gridWidth: 8, pctBlocked: 0.25, modalSize: "md", maxNbrGames: 5},  
                 {secondsAllowed: "6", gridHeight: 10, gridWidth: 9, pctBlocked: 0.35, modalSize: "md", maxNbrGames: 5}, 
                ]
        },              
        {activityName: "BuildHome", timerValid: true, levels:
                [{minutesAllowed: "1", secondsAllowed: "0", wordList: "homeBuilding", modalSize: "lg", 
                        wordListDesc: "All words will be tools.", maxNbrGames: 7},
                {minutesAllowed: "1", secondsAllowed: "0", wordList: "homeBuildingMaterials", modalSize: "lg", 
                        wordListDesc: "All words will be building materials.", maxNbrGames: 7},
                {minutesAllowed: "1", secondsAllowed: "0", wordList: "homeBuildingJobs", modalSize: "lg", 
                        wordListDesc: "All words will be jobs that help to build a home.", maxNbrGames: 7},
                ]
        },
        {activityName: "SoupKitchen", timerValid: true, levels:
                [{minutesAllowed: "1", secondsAllowed: "0", wordList: "ingredients", modalSize: "lg", 
                        wordListDesc: "All words will be common cooking ingredients.", maxNbrGames: 7},
                {minutesAllowed: "1", secondsAllowed: "0", wordList: "kitchenUtensils", modalSize: "lg", 
                        wordListDesc: "All words will be utensils and appliances used in a kitchen.", maxNbrGames: 7},
                {minutesAllowed: "1", secondsAllowed: "0", wordList: "bakingIngredients", modalSize: "lg", 
                        wordListDesc: "All words will be common ingredients used when baking.", maxNbrGames: 7},
                ]
        },
        {activityName: "FoodDrive",  timerValid: false, levels:
                [{modalSize: "xl", nbrItems: "14", nbrColumns: "3",  nbrInList: "1", maxNbrGames: 3},
                 {modalSize: "xl", nbrItems: "16", nbrColumns: "3", nbrInList: "1", maxNbrGames: 3},
                 {modalSize: "xl", nbrItems: "18", nbrColumns: "4", nbrInList: "1", maxNbrGames: 3},
                 {modalSize: "xl", nbrItems: "20", nbrColumns: "4", nbrInList: "1", maxNbrGames: 4},
                 {modalSize: "xl", nbrItems: "20", nbrColumns: "4", nbrInList: "1", maxNbrGames: 5},
                ]
        },
        {activityName: "CoatDrive",  timerValid: false, levels:
                [{modalSize: "xl", nbrItems: "9", nbrColumns: "3",  nbrInList: "3", maxNbrGames: 3, gameName: "CoatDrive1"},
                 {modalSize: "xl", nbrItems: "9", nbrColumns: "3",  nbrInList: "3", maxNbrGames: 4, gameName: "CoatDrive1"},
                 {modalSize: "xl", nbrItems: "9", nbrColumns: "3",  nbrInList: "3", maxNbrGames: 5, gameName: "CoatDrive1"},
                 {modalSize: "xl", nbrItems: "12", nbrColumns: "4", nbrInList: "3", maxNbrGames: 3, gameName: "CoatDrive1"},
                 {modalSize: "xl", nbrItems: "12", nbrColumns: "4", nbrInList: "3", maxNbrGames: 4, gameName: "CoatDrive1"},
                 {modalSize: "xl", nbrItems: "12", nbrColumns: "4", nbrInList: "3", maxNbrGames: 5, gameName: "CoatDrive1"},
                ]
        },
        {activityName: "Circus",  timerValid: true, pictureDir: "circusPuzzlePieces", levels:
                [{modalSize: "lg", nbrPieces: "6", maxNbrGames: "3", secondsAllowed: "8"},
                 {modalSize: "lg", nbrPieces: "9", maxNbrGames: "3", secondsAllowed: "15"},
                 {modalSize: "xl", nbrPieces: "12", maxNbrGames: "3", secondsAllowed: "27"},
                 {modalSize: "xl", nbrPieces: "16", maxNbrGames: "3", secondsAllowed: "30"},
                 {modalSize: "xl", nbrPieces: "20", maxNbrGames: "3", secondsAllowed: "40"}
                ]
        },
        {activityName: "ComedyClub",  timerValid: true, levels:
                [{modalSize: "md", minutesAllowed: "0", secondsAllowed: "30", maxNbrGames: 10},
        ]
        },
        {activityName: "Skiing",  timerValid: true, levels:
                [{secondsAllowed: "7", gridHeight: 8, gridWidth: 6, pctBlocked: 0.20, modalSize: "md", maxNbrGames: 5},
                {secondsAllowed: "5", gridHeight: 8, gridWidth: 6, pctBlocked: 0.20, modalSize: "md", maxNbrGames: 5},
                {secondsAllowed: "7", gridHeight: 8, gridWidth: 6, pctBlocked: 0.60, modalSize: "md", maxNbrGames: 5},
                {secondsAllowed: "6", gridHeight: 10, gridWidth: 6, pctBlocked: 0.60, modalSize: "md", maxNbrGames: 5},
        ]
        },
        {activityName: "Rafting",  timerValid: true, levels:
               [{secondsAllowed: "7", gridHeight: 8, gridWidth: 6, pctBlocked: 0.20, modalSize: "md", maxNbrGames: 5},
                {secondsAllowed: "5", gridHeight: 8, gridWidth: 6, pctBlocked: 0.20, modalSize: "md", maxNbrGames: 5},
                {secondsAllowed: "7", gridHeight: 8, gridWidth: 6, pctBlocked: 0.60, modalSize: "md", maxNbrGames: 5},
                {secondsAllowed: "6", gridHeight: 10, gridWidth: 6, pctBlocked: 0.60, modalSize: "md", maxNbrGames: 5},
        ]
        },
        {activityName: "GoToZoo",  timerValid: false, levels:
                [{modalSize: "xl", nbrItems: "9", nbrColumns: "3",  nbrInList: "3", maxNbrGames: 3, gameName: "GoToZoo1"},
                {modalSize: "xl", nbrItems: "12", nbrColumns: "3", nbrInList: "3", maxNbrGames: 3, gameName: "GoToZoo1"},
                {modalSize: "xl", nbrItems: "12", nbrColumns: "4", nbrInList: "3", maxNbrGames: 3, gameName: "GoToZoo1"},
                {modalSize: "xl", nbrItems: "9", nbrColumns: "3",  nbrInList: "4", maxNbrGames: 3, gameName: "GoToZoo2"},
                {modalSize: "xl", nbrItems: "12", nbrColumns: "3", nbrInList: "4", maxNbrGames: 3, gameName: "GoToZoo2"},
                {modalSize: "xl", nbrItems: "12", nbrColumns: "4", nbrInList: "4", maxNbrGames: 3, gameName: "GoToZoo2"}
        ]
        },
        // vacation options
        {activityName: "Paris",  timerValid: false, levels: [{ modalSize: "xl", maxNbrGames: 6}] },
        {activityName: "SanDiego",  timerValid: false, levels: [{ modalSize: "xl", maxNbrGames: 6}] },
        {activityName: "Yellowstone",  timerValid: false, levels: [{ modalSize: "xl", maxNbrGames: 6}] },
        {activityName: "WashingtonDC",  timerValid: false, levels: [{ modalSize: "xl", maxNbrGames: 6}] },


        
]
 
export default ActivityLevelsDataset;