import ConfigFile from "../../datasets/config_file";
import { calcInflationAmt } from "../CommonUse/calc_inflation_amt";
import { calcNbrMonthsBtwnDates } from "../CommonUse/nbr_months_btwn_dates_calc";

export const healthInsCalcAmt = (jobHealthInsInd, currentGameDate, startGameDate) => {
     var expenseString=" ";
     var healthInsDesc = " ";  

     let nbrMonths = calcNbrMonthsBtwnDates(startGameDate, currentGameDate);
     let nbrYears = (nbrMonths/12).toFixed(0);
     var healthInsAmt = calcInflationAmt(ConfigFile.insurance.healthInsAmt, nbrYears);
   
     var healthInsEmployeeAmt = 0; 
     if (jobHealthInsInd == "true") {
          healthInsEmployeeAmt = (healthInsAmt * ConfigFile.insurance.healthInsEmployeePct).toFixed(2);
          healthInsDesc = "Health Insurance 70% Paid by Employer";
     } else {
          healthInsDesc = "Health Insurance 100% paid by employee";
          healthInsEmployeeAmt = parseFloat(healthInsAmt);
     }
    
     expenseString = {expItem: "Health Insurance", expDesc: healthInsDesc, 
          expAmount: healthInsEmployeeAmt, expStartDate: currentGameDate, expNbrMonths: "-1"}   ; 
    
     return expenseString;
 }

