import React from 'react';
import { withRouter } from "react-router";
import { Button } from 'react-bootstrap';
import FinishActivityDisplayCard from './finish_activity_display_card';
import {setHeadersForFetch} from '../../objects/CommonUse/set_headers_for_fetch';
import {getHomePicture} from '../../components/game-views/objects/get_home_picture';
import {findDataObject} from '../../objects/CommonUse/find_data_object';
import ConfigFile from '../../datasets/config_file';
import { setEventTrackerObj } from '../../objects/CommonUse/set_event_tracker_obj';
import { getCurrentDateTime } from '../../objects/CommonUse/get_current_date_time';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';

class FinishActivity extends React.Component {
    constructor(props) {
        super(props);
        props.onOpen();
        this.activityInfo = this.props.activityInfo();
        this.state = {
            headers: setHeadersForFetch(),
            eventLogging: ConfigFile.gameBoard.eventLogging,
            activitySelected: "", 
            activityCategory: "",
            activityName: "",
            homeData: null,
            loadingHomeData: true,
            preProcessComplete: false,
            cashLevelBoost: +ConfigFile.activities.levelCashBoost,
            openModal: true,
            test: true,
            newLevelEarned: false,
            categoryInfo: findDataObject("activityTracker").categoryInfo,
            subProcess: "finishActivity",
            environment: Config.dataURL.currentEnv,
        }    
        this.cumulativePoints = 0;
        this.possiblePoints = 0;
        this.outputArr = [];
  }

  preDisplayProcess(){
       this.getHomeData();
       if (this.state.loadingHomeData == false){
            this.getActivityTypeName();
            this.greatJob = "";
            if (this.activityInfo.activityScore.gamesRemaining == 0){
                this.greatJob = "Great Job!  ";
            }
            if (this.cumulativePoints > 0){
                this.determineScorePct();
                this.calculateCashAmount();
                this.calculateMoodPoints();
            }else{
                this.cashAmtEarned = 0;
                this.cashAmtSpent = 0;
                this.moodPointsEarned = 0;
            }
            this.updateGameInfoForActivity();
            this.updateOutputArr();
            this.state.preProcessComplete = true;
       }
  }  
  
  getHomeData(){
    let homeID = this.activityInfo.gameData.homeInfo.homeID;
    const funcName = "homeInfo";
    const urlParm = "?homeId=" + homeID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.setState({homeData:  output.body});
                        this.processHomeData();
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
  }

  processHomeData(){         
    if (this.state.homeData != null) {
        this.state.loadingHomeData = false;
        if (this.state.homeData.homeType == "apartment" && this.state.homeData.bedrooms == 1){
            this.bedroomInfo = getHomePicture(this.state.homeData.directory, 
              this.state.homeData.additionalPhotosArr, "Bedroom", true);
        }else{
            this.bedroomInfo = getHomePicture(this.state.homeData.directory, 
              this.state.homeData.additionalPhotosArr, "Main Bedroom", true);
        }  
        this.bedroomArr = this.bedroomInfo.split('|');
        this.bedroomImg = this.bedroomArr[0];
        this.bedroomTextType = this.bedroomArr[1];
        if (this.bedroomTextType != "undefined" &&
            this.bedroomTextType == "white"){
                this.textClass = "text-center text-white"
        }else{
                this.textClass = "text-center text-body"
        }
    }
  }

  getActivityTypeName(){
      this.state.activityCategory = this.activityInfo.slctActivity.activityCategory;
      this.state.activityName = this.activityInfo.slctActivity.activityName;
      this.cumulativePoints = +this.activityInfo.activityScore.points;
      this.possiblePoints = +this.activityInfo.activityScore.maxScore;
      this.newLevel = +this.activityInfo.levelInfo.levelNbr;
      if (this.activityInfo.activityScore.newLevelEarned == true){
          if (this.activityInfo.levelInfo.maxLevels > this.activityInfo.levelInfo.levelNbr){
                this.newLevel += 1;
                this.newLevelText = "Bravo! You leveled-up - New Level: " + this.newLevel;
                this.state.newLevelEarned = true;
          }else{
                this.newLevelText = "Bravo! You beat the maximum level for this activity:"
          }
      }else{
            this.newLevelText = "";
      }
  }    

  determineScorePct(){
        let pctCorrect = 0;
        if (this.activityInfo.activityScore.maxScore != 0){
            pctCorrect = this.activityInfo.activityScore.points / this.activityInfo.activityScore.maxScore;
        }
        this.gamesPct = 0;
        if (this.activityInfo.activityScore.gamesRemaining != 0){
            let totalGames = this.activityInfo.activityScore.gamesRemaining + 
                this.activityInfo.activityScore.nbrAttempted;
            this.gamesPct = this.activityInfo.activityScore.nbrAttempted / totalGames;
        }else{
            this.gamesPct = 1;
        }
        this.scorePct = (pctCorrect * this.gamesPct).toFixed(2);
    }
    
    calculateCashAmount(){
        let levelBoost = this.state.cashLevelBoost * (this.activityInfo.levelInfo.levelNbr - 1);  
        this.cashAmtEarned = ((this.activityInfo.slctActivity.maxEarnedAmt + levelBoost) * this.scorePct).toFixed(2);
        let discountAmt = 0
        if (this.activityInfo.slctActivity.maxCost == 0){
            this.cashAmtSpent = 0
        }else{
            this.cashAmtSpent = this.activityInfo.slctActivity.maxCost;
            if (this.scorePct == 100){
                discountAmt = this.activityInfo.slctActivity.maxCost * .10;
                this.cashAmtSpent = (this.cashAmtSpent - discountAmt).toFixed(2);
            }
        }
        this.cashAmtEarned = +this.cashAmtEarned - +this.cashAmtSpent;
    }

    calculateMoodPoints(){
        if (this.activityInfo.slctActivity.maxMoodPoints > 0){
            this.moodPointsEarned = (this.activityInfo.slctActivity.maxMoodPoints * this.scorePct).toFixed(0);
        }else{
            // these are sideHustle activities where mood points is less than 0
            if (this.scorePct == 100){
                let moodPointDiscount = +this.activityInfo.slctActivity.maxMoodPoints * .10;
                this.moodPointsEarned = +this.activityInfo.slctActivity.maxMoodPoints + 
                    moodPointDiscount;
            }else{
                this.moodPointsEarned = +this.activityInfo.slctActivity.maxMoodPoints;
            }            
        }
        this.moodBoost = 1
        this.scoreText = "";
        if (this.state.activityCategory == "SideHustle"){
            this.scoreText = "You did very well with this activity but your mood points " +
            "are reduced because it frustrates you to have to do a side hustle. " +
            "You think that there are a lot of other things you could have done with " +
            "the time that you spent working this second job.";
        }else{
            if (this.activityInfo.friendInfo != ""){
                this.calculateMoodBoost();
                if (this.moodBoost > 1){
                    this.scoreText = "Mood points increased since you brought a friend."
                }else{
                    this.scoreText = "Activity mood points decreased due to friend's attitude. "  +
                    "(This friend doesn't enjoy this type of activity)."
                }
            }
        }
        this.moodPointsEarned = (this.moodPointsEarned * this.moodBoost).toFixed(0);
    }

    calculateMoodBoost(){
        let activityPreference = +this.activityInfo.friendInfo.activityPreference;
        let friendName = this.activityInfo.friendInfo.friendName;
        let friendIndx = this.activityInfo.gameData.friendList.findIndex(elem => elem.name === friendName);
        let rltnshpScore = 0;
        if (friendIndx != -1){
            rltnshpScore = +this.activityInfo.gameData.friendList[friendIndx].rltnshpScore;
        }
        let increaseRltnshpScore = 0;
        if (activityPreference >= 4){
            this.moodBoost = 1.5;
            increaseRltnshpScore = 3;
        }else{
            if (activityPreference >= 3){
                this.moodBoost = 1.25;
                increaseRltnshpScore = 2;
            }else{
                this.moodBoost = .75;
                increaseRltnshpScore = 1;
            }
        }
        if (friendIndx != 99){
            this.activityInfo.gameData.friendList[friendIndx].rltnshpScore = rltnshpScore + 
                increaseRltnshpScore;
        }
    }

    updateGameInfoForActivity(){
        if (this.cumulativePoints > 0){
            if (this.activityInfo.activityTracker == "noData"){
                // this is the first time for this type of activity
                this.buildInitialActivityTracker();
            }
            this.updateActivityInfoInTracker();
            this.updateCashMoodRltnshp();
            if (this.state.eventLogging == true){
                this.updateEventTracker();
            }
        }
    }

    updateOutputArr(){
        const outputObj={category: this.state.activityCategory, 
            cumulativePoints: this.cumulativePoints, possiblePoints: this.possiblePoints,
            cashAmtEarned: this.cashAmtEarned, cashAmtSpent: this.cashAmtSpent, moodPointsEarned: this.moodPointsEarned, 
            scoreText: this.scoreText, newLevelText: this.newLevelText, 
            gamesAttempted: this.activityInfo.activityScore.nbrAttempted,
            gamesRemaining: this.activityInfo.activityScore.gamesRemaining};
        this.outputArr.push(outputObj);
    }


    render() {
        if (this.state.preProcessComplete == false){
            this.preDisplayProcess();
        }
        this.backgroundURL = this.bedroomImg;
        let screenParms = setScreenSizeParameters(this.props.screenSize, "activity");
        this.className = screenParms.columnClass;
        if (this.outputArr.length > 0){
            this.finishAmounts = this.outputArr.map((lineItems,index) =>
                <FinishActivityDisplayCard key={index} tableInfo={lineItems} />
                );  
        }
                                      
    return <>
        <div className={this.className}>
        { this.state.loadingHomeData ?
                <WaitTimeSpinner />
           : 
            <div className="card mt-3 rounded-corners-lg text-center activity-board height-max"
                style={{backgroundImage: `url(${process.env.PUBLIC_URL + this.backgroundURL })`}}>
            <div className="mt-2"></div>  
            <div className="p-0"></div>  
                <div className="table-background-lightgrey finish-title-position this.textClass">    
                    <h3>Activities</h3>
                    <h5 >{this.state.activityName}</h5>
                    <h5>{this.greatJob} You have completed this activity.</h5>
                </div>
                            
                <span className="mt-4 ml-4">
                    <table  className="table-background-lightgrey
                        table-bottom-of-page ml-4 mb-4 text-body" >
                        <tbody>
                            {this.finishAmounts}
                        </tbody>
                    </table>
                                  
                    <div className="btn-bottom-of-page mt-4">
                        <Button variant="primary"  onClick={() => this.finishActivity()}>
                            Finish
                        </Button>
                    </div>
                </span>
                    
        </div>
        }
        </div>
    
    </>
    }
   
    finishActivity(){
        this.props.history.push({
            pathname: '/game-board',
            search: '?query=abc',
            state: { detail: "returning-from-activity" }
        })
       // this.props.history.push('/game-board');
    }
    
    buildInitialActivityTracker(){
        let gameID = this.activityInfo.gameData._id;
        let dateAdded = this.activityInfo.gameData.currentGameDate;
        this.activityInfo.activityTracker = {gameID: gameID, dateAdded: dateAdded, category:  this.state.activityCategory,
            activityTable: []};
    }

    updateActivityInfoInTracker(){
        let shortName = this.activityInfo.slctActivity.shortName;
        this.activityTable = this.activityInfo.activityTracker.activityTable;
        let activityFound = this.activityTable.some(elem => elem.activityName === shortName)
        if (activityFound == false){
            this.createNewActivityTableEntry();
        }
        for (let i=0; i<this.activityTable.length; i++){
            if (this.activityTable[i].activityName == shortName){
                activityFound = true;
                let activityInfo = this.activityTable[i];
                activityInfo.nbrTimesPlayed = +activityInfo.nbrTimesPlayed + 1;
                activityInfo.lastTimePlayed = this.activityInfo.gameData.currentGameDate;
                if (this.state.newLevelEarned == true &&
                    this.activityInfo.levelInfo.maxLevels > this.activityInfo.levelInfo.levelNbr){
                        activityInfo.level = +activityInfo.level + 1;
                }
                if (this.newLevel != activityInfo.level){
                    alert("level numbers are not the same... max is " +
                        this.activityInfo.levelInfo.maxLevels + " levelNbr is " +
                        this.activityInfo.levelInfo.levelNbr + " new level " + this.newLevel)
                }
                this.activityTable[i] = activityInfo;
                break;
            }
        }
        this.saveActivityTracker();
    }

    createNewActivityTableEntry(){  
        let shortName = this.activityInfo.slctActivity.shortName;    
        let activityObj = findDataObject("activityTracker").activityInfo;
        activityObj.nbrTimesPlayed = 0;
        activityObj.activityName = shortName;
        activityObj.level = 1;
        this.activityTable.push(activityObj);
    }

    saveActivityTracker(){
        const funcName = "saveGameActivityTracker";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        this.bodyObj = {
            "gameID": this.activityInfo.activityTracker.gameID,
            "dateAdded": this.activityInfo.activityTracker.dateAdded,
            "category": this.activityInfo.activityTracker.category,
            "activityTable": this.activityTable
        };
        fetch (uri, 
            {method:'post', 
             headers: this.state.headers,
             body: JSON.stringify(this.bodyObj)})
             .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                           // save of activity tracker was successful
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                            this.props.processError(errorObj);
                        }
                    });
                };
            });
    }
  
    async updateCashMoodRltnshp(){
        let newCashAmt = (+this.activityInfo.gameData.currentCash + +this.cashAmtEarned).toFixed(2);
        if (this.state.activityCategory == "SideHustle" ){
             if (newCashAmt > 0){
                 let startIdx = this.activityInfo.gameData.iconTable.length - 1;
                 for (let i=startIdx; i>=0; i--){
                     if (this.activityInfo.gameData.iconTable[i].iconName == "negativeCash"){
                            this.activityInfo.gameData.iconTable.splice(i,1);
                     }
                 }
             }
        }
        let errorDesc = "";
        if (isNaN(+newCashAmt)){
            newCashAmt = this.activityInfo.gameData.currentCash;
            errorDesc = "new Cash Amount is NaN";
            this.generalErrorLog(errorDesc);
        }
        let newMoodPoints = (+this.activityInfo.gameData.currentMoodPoints + +this.moodPointsEarned).toFixed(0);
        if (isNaN(+newMoodPoints)){
            newMoodPoints = this.activityInfo.gameData.currentMoodPoints;
            errorDesc = "new Mood Points is NaN";
            this.generalErrorLog(errorDesc);
        }
        let newFriendList = this.activityInfo.gameData.friendList
        this.activityInfo.gameData.currentCash = newCashAmt;
        this.activityInfo.gameData.currentMoodPoints = newMoodPoints;
        this.activityInfo.gameData.newFriendList = newFriendList;
        this.activityInfo.gameData.moodPointHistory.currentMonthMoodPoints = 
            (+this.activityInfo.gameData.moodPointHistory.currentMonthMoodPoints + +this.moodPointsEarned).toFixed(0);
        this.props.setActivityInfo(this.activityInfo.gameData, "gameData");
        const funcName = "updateCashMoodFriendList";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
   
        this.bodyObj = {
            "cash":   newCashAmt,
            "moodPoints": newMoodPoints,
            "friendList": newFriendList,
            "moodPointHistory": this.activityInfo.gameData.moodPointHistory,
            "iconTable": this.activityInfo.gameData.iconTable
        };
        await fetch(uri,
            {method:'PUT', headers: this.state.headers,
            body: JSON.stringify(this.bodyObj)})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                          //   update Cash Mood Friend list successful
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                            this.props.processError(errorObj);
                        }
                    });
                };
            });
    }

updateEventTracker(){
    
    let activityResponse = {category: "cash", on: "cash", type: "flat", duration: 1, value: this.cashAmtEarned,
        moodPoints: this.moodPointsEarned};
    let eventTitle = this.state.activityCategory + "-" + this.state.activityName;
    let eventObj = setEventTrackerObj(this.activityInfo.gameData, activityResponse, eventTitle, 1000);
    let eventArr = [eventObj];
    let currDateTime = getCurrentDateTime();
    const funcName = "saveEventTracker";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    let dataObject = {
        player: this.activityInfo.gameData.user,
        gameID: this.activityInfo.gameData._id,
        eventType: "GameActivity",
        date: currDateTime.date,
        time: currDateTime.time,
        process: "GameActivities",
        events: eventArr,
    };

    fetch(uri,
        {
            method:'post',
            headers: this.state.headers,
            body: JSON.stringify(dataObject)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                       // save event tracker successful
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: dataObject};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
}

generalErrorLog(errorDesc){
    let dataObject = { activity: this.activityInfo.slctActivity,
        currentCash:     this.activityInfo.gameData.currentCash,
        activityScore:   this.activityInfo.activityScore,
        friendInfo:      this.activityInfo.friendInfo,
        cashEarned:      this.activityInfo.activityScore };
    dataObject = JSON.stringify(dataObject);
    const message = errorDesc + dataObject;
    const errorObj={function: "GeneralError", subProcess: this.state.subProcess, 
        status: 999, message: message, silentAlert: true};
    this.props.processError(errorObj);
}

}

export default withRouter(FinishActivity);