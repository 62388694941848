import {Component} from 'react';
import "./Admin/admin.css";
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import {rptEventsWithConditions} from './objects/rpt_events_with_conditions';
import {rptMoodPointsByEvent} from './objects/rpt_mood_points_by_event';
import {rptEventsAudit} from './objects/rpt_events_audit';
import EventReportsInputModal from './event_reports_input_modal';
import Config from '../../config';
import ExportToExcelButton from './export_to_excel';
import rptEditEvents from './objects/rpt_edit_events';

class EventReports extends Component {
    constructor(props) {
        super(props);
        this.adminInfo = this.props.adminInfo();
        this.state={
            headers: setHeadersForFetch(),  
            openRptParameterModal: false,
            subProcess: "EventReports",
            environment: Config.dataURL.currentEnv,
            exportToExcelButton: false,
        };   
        this.sortEventTitles = [];
    }

    editEventsCollection(){
       
        this.reportDataArr=[];
        this.sortConditionTableByCondName();
        this.reportDataArr = rptEditEvents(this.adminInfo.eventsData, this.conditionSortByCondName,
            this.careerConditionsSortByCondName)
        this.checkForDuplicateTitles();
        this.rptTitle = "EventsEditRpt";
        this.rptLines = this.reportDataArr;
        this.exportReportDataset();
    }

    sortConditionTableByCondName(){
        var i;
        var condName="";
        var condNameArr=[];
        var careerCondNameArr=[];
        for (i=0; i<this.adminInfo.conditionsData.length; i++){
            // convert condName from index to string
            condName = JSON.parse(JSON.stringify(this.adminInfo.conditionsData[i].condName));
            if (this.adminInfo.conditionsData[i].conditionClass === "career"){
                careerCondNameArr.push(condName);
            }else{
                condNameArr.push(condName);
            }
        }
        this.conditionSortByCondName = condNameArr.sort();
        this.careerConditionsSortByCondName = careerCondNameArr.sort();
    }

    checkForDuplicateTitles()
    {
        var i;
        var j;
        this.sortEventsByTitle();
        // check for situations where two events have the same title
        for (i=0; i < this.sortEventTitles.length - 1; i++){
            j=i+1
            if (this.sortEventTitles[i] === this.sortEventTitles[j] &&
                this.sortEventTitles[i] !== "Fun Fact"){
                let errorObj = {fieldClass: "Complex Edit", fieldName: "event title",
                    fieldValue: this.sortEventTitles[i],
                    message: "This title has been found on multiple events"};
                this.reportDataArr.push(errorObj)
            }
        }
    } 
        
    sortEventsByTitle(){
        var i;
        this.eventTitles=[];
        for (i=0; i<this.adminInfo.eventsData.length; i++){
             this.eventTitles.push(this.adminInfo.eventsData[i].title);
        }
        this.sortEventTitles = this.eventTitles.sort();
    }
    
    rptEventHeaderList(){
        this.eventHeaderListArr = []
        for (let i=0; i<this.adminInfo.eventsData.length; i++){
            if (i === 0){
                this.eventHeaderListHeading();
            }
            this.eventNbr = i + 1;
            let eventObj = this.adminInfo.eventsData[i];
            this.buildEventHeaderListObj(eventObj);
        }
        this.rptTitle = "EventHeaderList";
        this.rptLines = this.eventHeaderListArr;
        this.exportReportDataset();
    }

    eventHeaderListHeading(){
        let headerObj = {eventNbr: "Event Nbr", eventTitle: "Event Title", description: "Event Description",
            frequency: "Frequency", condition: "Select Condition(s)", conditionConnector: "Condition Connector",
            skipCondition: "Skip Condition", selectOnCondition: "Select Cond Switch", background: "Background",
            gamePhase: "Game Phase", eventCategory: "Event Category", careerTag: "Career Tag", 
            imageDirectory: "Image Directory"};
        this.eventHeaderListArr.push(headerObj);
    }
       
    buildEventHeaderListObj(eventObj){
        let eventSelectOnCondition = false;
        let conditions = "";
        let skipConditions = "";
        let conditionConnector = "";
        if ((eventObj.conditionArr !== undefined && eventObj.conditionArr.length > 0) ||
            (eventObj.skipConditionArr !== undefined & eventObj.skipConditionArr.length > 0)){ 
                 eventSelectOnCondition = true;
                 conditions = eventObj.conditionArr.join(",");
                 skipConditions = eventObj.skipConditionArr.join(",");
                 if (eventObj.conditionArr.length > 0){
                    conditionConnector = eventObj.conditionConnector;
                 }
        }
        let eventString = {eventNbr: this.eventNbr, eventTitle: eventObj.title, description:  eventObj.description, 
                    frequency: eventObj.frequency, condition: conditions, conditionConnector: conditionConnector,
                    skipCondition: skipConditions, selectOnCondition: eventSelectOnCondition,
                    background: eventObj.backgroundType, gamePhase: eventObj.gamePhase, 
                    eventCategory: eventObj.eventCategory, careerTag: eventObj.careerTag, 
                    imageDirectory: eventObj.imageDir};                 
        this.eventHeaderListArr.push(eventString);
    }

    rptEventsList(){
        this.eventListArr = []
        var i;
        for (i=0; i<this.adminInfo.eventsData.length; i++){
            if (i === 0){
                this.eventsListHeading();
            }
            this.eventNbr = i + 1;
            let eventObj = this.adminInfo.eventsData[i];
            this.buildEventListObj(eventObj);
        }
        this.rptTitle = "EventsList";
        this.rptLines = this.eventListArr;
        this.exportReportDataset();
    }

    eventsListHeading(){
        let eventString = {eventNbr: "Event Nbr", title: "Event Title", description: "Event Description",
            frequency: "Frequency", condition: "Condition", skipCondition: "Skip Condition", 
            selectCondSwitch: "Select Cond Switch", buttonTxt: "Button Text", closureTxt: "Closure Text",
            category: "Category", onField: "On Field", type: "Type", duration: "Duration", odds: "Odds",
            replaceAmtSw: "Replace Amt Sw", triggerCond: "Trigger Condition", deleteCond: "Delete Condition",
            deleteClass: "Delete Class", resultCondSwitch: "Result Cond Switch", values: "Values"};
        this.eventListArr.push(eventString);
    }
       
    buildEventListObj(eventObj){
        var i;
        var j;
        var eventSelectOnCondition = false;
        var eventResponseCondition = false;
        for (i=0; i<eventObj.responses.length; i++){
            let response = eventObj.responses[i];
            for (j=0; j<response.possibleEffects.length; j++){
                let possible = response.possibleEffects[j]; 
                if ((eventObj.condition !== undefined && eventObj.condition !== "") ||
                    (eventObj.skipCondition !== undefined & eventObj.skipCondition !== "")){ 
                        eventSelectOnCondition = true;
                }
                if ((possible.triggerCondition !== undefined && possible.triggerCondition !== "") ||
                    (possible.deleteCondition !== undefined && possible.deleteCondition !== "") ||
                    (possible.deleteClass !== undefined && possible.deleteClass !== "")){
                        eventResponseCondition = true;
                }
                let eventString = {eventNbr: this.eventNbr, title: eventObj.title, description: eventObj.description,
                    frequency: eventObj.frequency, condition: eventObj.condition, skipCondition: eventObj.skipCondition, 
                    selectCondSwitch: eventSelectOnCondition, buttonTxt: response.btnText, closureTxt: possible.closureText,
                    category: possible.category, onField: possible.on, type: possible.type, duration: possible.duration,
                    odds: possible.odds, replaceAmtSw: possible.replaceAmtSw, triggerCond: possible.triggerCondition, 
                    deleteCond: possible.deleteCondition, deleteClass: possible.deleteClass, 
                    resultCondSwitch: eventResponseCondition, values: possible.possibleValues.join()};
                this.eventListArr.push(eventString);
            }
        }
    }

    //   this report no longer supported.
    rptEventsByPlayerTitle(){
       
    } 

    createEventsAuditRpt(rptParameters){
        this.setState({openRptParameterModal: false});
        this.rptArr = rptEventsAudit(this.adminInfo.eventsTracker, this.adminInfo.eventsData, rptParameters);
        this.rptTitle = "Events Audit Rpt";
        this.rptLines = this.rptArr;
        this.exportReportDataset();
    }

    createRptEventsWithConditions(){
        this.rptArr = rptEventsWithConditions(this.adminInfo.conditionsData, this.adminInfo.eventsData )
        this.rptTitle = "Events With Conditions";
        this.rptLines = this.rptArr;
        this.exportReportDataset();
    }

    createRptMoodPointsByEventRpt(){
        //  select if the mood points are either less than the low point or greater than the high point
        let moodPointLow = -5;
        let moodPointHigh = 5;
        this.rptArr = rptMoodPointsByEvent(this.adminInfo.eventsData, moodPointLow, moodPointHigh);
        this.rptTitle = "Mood Points by Event";
        this.rptLines = this.rptArr;
        this.exportReportDataset();
    }

    createRptCommentsAndErrorSummary(){
       
    }

    exportReportDataset() {
        this.setState({exportToExcelButton: true});    
    }

   removeExportButton(){
        this.setState({exportToExcelButton: false});
   }

   getRptParameters(rptTitle){
       this.setState({openRptParameterModal: true});
       this.rptTitle = rptTitle;
   }

   closeParameterModal(){
       this.setState({openRptParameterModal: false});
   }
         
    render() {
    return <>
        <div className="col-sm-6 mb-5 input-page-base-card">
                <div className="card mt-3 mr-5 rounded-corners-lg height-max">
                    <h1 className="mt-2 p-0 text-center">Game Administration</h1>
                    <h4 className="text-center">Event Collection Reports</h4>
                    <table className="row-100  ml-4 mt-4 mb-0 overflow-scroll">
                        <tbody className="mt-4 w-40">
                            <tr className="mt-4">
                                <td className = "col-sm-5 mx-4">   
                                    <button className="btn-group-medium text-center mt-4 ml-4" 
                                        onClick ={()=> this.editEventsCollection()}>
                                             Edit Events Collection
                                    </button>
                                </td>
                                <td  className="col-sm-5">
                                    <button className="btn-group-medium mt-4 ml-4" 
                                        onClick ={()=> this.rptEventHeaderList()}>
                                             Event Header List
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td  className="col-sm-5">
                                    <button className="btn-group-medium mt-4 ml-4" 
                                        onClick ={()=> this.rptEventsList()}>
                                             Events List With Responses
                                    </button>
                                </td>
                                <td className="col-sm-5">
                                    <button className="btn-group-medium mt-4 ml-4" 
                                        onClick ={()=> this.getRptParameters("Events By Player Audit Rpt")}>
                                            Events by Player Audit Report
                                    </button>
                                </td>
                               
                            </tr>
                            <tr>
                               
                                <td className="col-sm-5">                       
                                    <button className="btn-group-medium mt-4 ml-4" 
                                        onClick ={()=> this.createRptEventsWithConditions()}>
                                            Events With Conditions
                                    </button>
                                </td>
                                <td className="col-sm-5">
                                    <button className="btn-group-medium mt-4 ml-4" 
                                        onClick ={()=> this.createRptMoodPointsByEventRpt()}>
                                            Mood Points by Event
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td  className="col-sm-5">
                                    <button className="btn-group-medium mt-4 ml-4" 
                                        onClick ={()=> this.rptEventsByPlayerTitle()}>
                                            Button Available for Future Reports
                                    </button>
                                </td>
                                <td className="col-sm-5">                       
                                    <button className="btn-group-medium mt-4 ml-4" 
                                        onClick ={()=> this.createRptCommentsAndErrorSummary()}>
                                            Button Available for future use
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {this.state.openRptParameterModal ?
                            <EventReportsInputModal openModal={this.state.openRptParameterModal} 
                                onClose={() => this.closeParameterModal()} 
                                onSubmit={(rptParameters) => this.createEventsAuditRpt(rptParameters)}
                                rptTitle={this.rptTitle}
                            />
                    :
                        null
                    }
                    {this.state.exportToExcelButton ?
                        <ExportToExcelButton
                            fileName={this.rptTitle}
                            rptHeader={this.rptHeader}
                            data={this.rptLines}
                            exportComplete={() => this.removeExportButton()}
                        />
                    :
                        null
                    }
                   
                </div>        
            </div>
        </>
    }

}

export default EventReports;