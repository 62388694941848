const CircusJigsawPuzzleDataset = [
   {puzzleName: "ferrisWheel.png", level: 1, rows: 3, columns: 2, 
    imageList: [{imageURL: "ferrisWheel1.png", row: 1, column: 1}, 
                  {imageURL: "ferrisWheel2.png", row: 1, column: 2}, 
                  {imageURL: "ferrisWheel3.png", row: 2, column: 1}, 
                  {imageURL: "ferrisWheel4.png", row: 2, column: 2}, 
                  {imageURL: "ferrisWheel5.png", row: 3, column: 1}, 
                  {imageURL: "ferrisWheel6.png", row: 3, column: 2}, 
                  ],
   },
   {puzzleName: "clown1.png", level: 1, rows: 3, columns: 2, 
   imageList: [{imageURL: "clown1-001.png", row: 1, column: 1}, 
                 {imageURL: "clown1-002.png", row: 1, column: 2}, 
                 {imageURL: "clown1-003.png", row: 2, column: 1}, 
                 {imageURL: "clown1-004.png", row: 2, column: 2}, 
                 {imageURL: "clown1-005.png", row: 3, column: 1}, 
                 {imageURL: "clown1-006.png", row: 3, column: 2}, 
                 ],
  },
   {puzzleName: "rollerCoaster.png", level: 1, rows: 2, columns: 3, 
   imageList: [{imageURL: "rollerCoaster1.png", row: 1, column: 1}, 
                 {imageURL: "rollerCoaster2.png", row: 1, column: 2}, 
                 {imageURL: "rollerCoaster3.png", row: 1, column: 3}, 
                 {imageURL: "rollerCoaster4.png", row: 2, column: 1}, 
                 {imageURL: "rollerCoaster5.png", row: 2, column: 2}, 
                 {imageURL: "rollerCoaster6.png", row: 2, column: 3}, 
                 ],
  },

   {puzzleName: "circusPuzzle1.png", level: 2, rows: 3, columns: 3, 
    imageList: [{imageURL: "circusPuzzle1-001.png", row: 1, column: 1}, 
                  {imageURL: "circusPuzzle1-002.png", row: 1, column: 2}, 
                  {imageURL: "circusPuzzle1-003.png", row: 1, column: 3}, 
                  {imageURL: "circusPuzzle1-004.png", row: 2, column: 1}, 
                  {imageURL: "circusPuzzle1-005.png", row: 2, column: 2}, 
                  {imageURL: "circusPuzzle1-006.png", row: 2, column: 3}, 
                  {imageURL: "circusPuzzle1-007.png", row: 3, column: 1}, 
                  {imageURL: "circusPuzzle1-008.png", row: 3, column: 2}, 
                  {imageURL: "circusPuzzle1-009.png", row: 3, column: 3}
                  ],
   },
   {puzzleName: "circusPuzzle2.png", level: 2, rows: 3, columns: 3,
    imageList: [{imageURL: "circusPuzzle2-001.png", row: 1, column: 1}, 
               {imageURL: "circusPuzzle2-002.png", row: 1, column: 2}, 
               {imageURL: "circusPuzzle2-003.png", row: 1, column: 3}, 
               {imageURL: "circusPuzzle2-004.png", row: 2, column: 1}, 
               {imageURL: "circusPuzzle2-005.png", row: 2, column: 2}, 
               {imageURL: "circusPuzzle2-006.png", row: 2, column: 3}, 
               {imageURL: "circusPuzzle2-007.png", row: 3, column: 1}, 
               {imageURL: "circusPuzzle2-008.png", row: 3, column: 2}, 
               {imageURL: "circusPuzzle2-009.png", row: 3, column: 3}
               ],
   },
   {puzzleName: "circusPuzzle3.png", level: 2, rows: 3, columns: 3,
      imageList: [{imageURL: "circusPuzzle3-001.png", row: 1, column: 1}, 
                  {imageURL: "circusPuzzle3-002.png", row: 1, column: 2}, 
                  {imageURL: "circusPuzzle3-003.png", row: 1, column: 3}, 
                  {imageURL: "circusPuzzle3-004.png", row: 2, column: 1}, 
                  {imageURL: "circusPuzzle3-005.png", row: 2, column: 2}, 
                  {imageURL: "circusPuzzle3-006.png", row: 2, column: 3}, 
                  {imageURL: "circusPuzzle3-007.png", row: 3, column: 1}, 
                  {imageURL: "circusPuzzle3-008.png", row: 3, column: 2}, 
                  {imageURL: "circusPuzzle3-009.png", row: 3, column: 3}
                  ],
   },
  
   {puzzleName: "circusPuzzle5.png", level: 3, rows: 4, columns: 3,
   imageList: [{imageURL: "circusPuzzle5-001.png", row: 1, column: 1}, 
               {imageURL: "circusPuzzle5-002.png", row: 1, column: 2}, 
               {imageURL: "circusPuzzle5-003.png", row: 1, column: 3}, 
               {imageURL: "circusPuzzle5-004.png", row: 2, column: 1}, 
               {imageURL: "circusPuzzle5-005.png", row: 2, column: 2}, 
               {imageURL: "circusPuzzle5-006.png", row: 2, column: 3}, 
               {imageURL: "circusPuzzle5-007.png", row: 3, column: 1}, 
               {imageURL: "circusPuzzle5-008.png", row: 3, column: 2}, 
               {imageURL: "circusPuzzle5-009.png", row: 3, column: 3},
               {imageURL: "circusPuzzle5-010.png", row: 4, column: 1}, 
               {imageURL: "circusPuzzle5-011.png", row: 4, column: 2}, 
               {imageURL: "circusPuzzle5-012.png", row: 4, column: 3}
               ],
   },
   {puzzleName: "circusPuzzle6.png", level: 3, rows: 4, columns: 3,
   imageList: [{imageURL: "circusPuzzle6-001.png", row: 1, column: 1}, 
               {imageURL: "circusPuzzle6-002.png", row: 1, column: 2}, 
               {imageURL: "circusPuzzle6-003.png", row: 1, column: 3}, 
               {imageURL: "circusPuzzle6-004.png", row: 2, column: 1}, 
               {imageURL: "circusPuzzle6-005.png", row: 2, column: 2}, 
               {imageURL: "circusPuzzle6-006.png", row: 2, column: 3}, 
               {imageURL: "circusPuzzle6-007.png", row: 3, column: 1}, 
               {imageURL: "circusPuzzle6-008.png", row: 3, column: 2}, 
               {imageURL: "circusPuzzle6-009.png", row: 3, column: 3},
               {imageURL: "circusPuzzle6-010.png", row: 4, column: 1}, 
               {imageURL: "circusPuzzle6-011.png", row: 4, column: 2}, 
               {imageURL: "circusPuzzle6-012.png", row: 4, column: 3}
               ],
   }, 
   {puzzleName: "circusPuzzle7.png", level: 3, rows: 3, columns: 4,
   imageList: [{imageURL: "circusPuzzle7-001.png", row: 1, column: 1}, 
               {imageURL: "circusPuzzle7-002.png", row: 1, column: 2}, 
               {imageURL: "circusPuzzle7-003.png", row: 1, column: 3}, 
               {imageURL: "circusPuzzle7-004.png", row: 1, column: 4}, 
               {imageURL: "circusPuzzle7-005.png", row: 2, column: 1}, 
               {imageURL: "circusPuzzle7-006.png", row: 2, column: 2}, 
               {imageURL: "circusPuzzle7-007.png", row: 2, column: 3}, 
               {imageURL: "circusPuzzle7-008.png", row: 2, column: 4}, 
               {imageURL: "circusPuzzle7-009.png", row: 3, column: 1},
               {imageURL: "circusPuzzle7-010.png", row: 3, column: 2}, 
               {imageURL: "circusPuzzle7-011.png", row: 3, column: 3}, 
               {imageURL: "circusPuzzle7-012.png", row: 3, column: 4}
               ],
   },
   
   {puzzleName: "ferrisWheel02.png", level: 4, rows: 4, columns: 3,
   imageList: [{imageURL: "ferrisWheel02-001.png", row: 1, column: 1}, 
               {imageURL: "ferrisWheel02-002.png", row: 1, column: 2}, 
               {imageURL: "ferrisWheel02-003.png", row: 1, column: 3}, 
               {imageURL: "ferrisWheel02-004.png", row: 2, column: 1}, 
               {imageURL: "ferrisWheel02-005.png", row: 2, column: 2}, 
               {imageURL: "ferrisWheel02-006.png", row: 2, column: 3}, 
               {imageURL: "ferrisWheel02-007.png", row: 3, column: 1}, 
               {imageURL: "ferrisWheel02-008.png", row: 3, column: 2}, 
               {imageURL: "ferrisWheel02-009.png", row: 3, column: 3},
               {imageURL: "ferrisWheel02-010.png", row: 4, column: 1}, 
               {imageURL: "ferrisWheel02-011.png", row: 4, column: 2}, 
               {imageURL: "ferrisWheel02-012.png", row: 4, column: 3}
               ],
   },    
   {puzzleName: "amusement1.png", level: 4, rows: 4, columns: 4,
   imageList: [{imageURL: "amusement1-001.png", row: 1, column: 1}, 
               {imageURL: "amusement1-002.png", row: 1, column: 2}, 
               {imageURL: "amusement1-003.png", row: 1, column: 3}, 
               {imageURL: "amusement1-004.png", row: 1, column: 4}, 
               {imageURL: "amusement1-005.png", row: 2, column: 1}, 
               {imageURL: "amusement1-006.png", row: 2, column: 2}, 
               {imageURL: "amusement1-007.png", row: 2, column: 3}, 
               {imageURL: "amusement1-008.png", row: 2, column: 4}, 
               {imageURL: "amusement1-009.png", row: 3, column: 1},
               {imageURL: "amusement1-010.png", row: 3, column: 2}, 
               {imageURL: "amusement1-011.png", row: 3, column: 3}, 
               {imageURL: "amusement1-012.png", row: 3, column: 4},
               {imageURL: "amusement1-013.png", row: 4, column: 1},
               {imageURL: "amusement1-014.png", row: 4, column: 2}, 
               {imageURL: "amusement1-015.png", row: 4, column: 3}, 
               {imageURL: "amusement1-016.png", row: 4, column: 4}
               ],
   },      
   {puzzleName: "amusement2.png", level: 4, rows: 4, columns: 4,
   imageList: [{imageURL: "amusement2-001.png", row: 1, column: 1}, 
               {imageURL: "amusement2-002.png", row: 1, column: 2}, 
               {imageURL: "amusement2-003.png", row: 1, column: 3}, 
               {imageURL: "amusement2-004.png", row: 1, column: 4}, 
               {imageURL: "amusement2-005.png", row: 2, column: 1}, 
               {imageURL: "amusement2-006.png", row: 2, column: 2}, 
               {imageURL: "amusement2-007.png", row: 2, column: 3}, 
               {imageURL: "amusement2-008.png", row: 2, column: 4}, 
               {imageURL: "amusement2-009.png", row: 3, column: 1},
               {imageURL: "amusement2-010.png", row: 3, column: 2}, 
               {imageURL: "amusement2-011.png", row: 3, column: 3}, 
               {imageURL: "amusement2-012.png", row: 3, column: 4},
               {imageURL: "amusement2-013.png", row: 4, column: 1},
               {imageURL: "amusement2-014.png", row: 4, column: 2}, 
               {imageURL: "amusement2-015.png", row: 4, column: 3}, 
               {imageURL: "amusement2-016.png", row: 4, column: 4}
               ],
   },

   {puzzleName: "amusement3.jpg", level: 5, rows: 4, columns: 5,
   imageList: [{imageURL: "amusement3-001.jpg", row: 1, column: 1}, 
               {imageURL: "amusement3-002.jpg", row: 1, column: 2}, 
               {imageURL: "amusement3-003.jpg", row: 1, column: 3}, 
               {imageURL: "amusement3-004.jpg", row: 1, column: 4}, 
               {imageURL: "amusement3-005.jpg", row: 1, column: 5}, 
               {imageURL: "amusement3-006.jpg", row: 2, column: 1}, 
               {imageURL: "amusement3-007.jpg", row: 2, column: 2}, 
               {imageURL: "amusement3-008.jpg", row: 2, column: 3}, 
               {imageURL: "amusement3-009.jpg", row: 2, column: 4},
               {imageURL: "amusement3-010.jpg", row: 2, column: 5}, 
               {imageURL: "amusement3-011.jpg", row: 3, column: 1}, 
               {imageURL: "amusement3-012.jpg", row: 3, column: 2},
               {imageURL: "amusement3-013.jpg", row: 3, column: 3},
               {imageURL: "amusement3-014.jpg", row: 3, column: 4}, 
               {imageURL: "amusement3-015.jpg", row: 3, column: 5}, 
               {imageURL: "amusement3-016.jpg", row: 4, column: 1},
               {imageURL: "amusement3-017.jpg", row: 4, column: 2},
               {imageURL: "amusement3-018.jpg", row: 4, column: 3}, 
               {imageURL: "amusement3-019.jpg", row: 4, column: 4}, 
               {imageURL: "amusement3-020.jpg", row: 4, column: 5},
               ],
   },
   {puzzleName: "circusPuzzle8.jpg", level: 5, rows: 4, columns: 5,
   imageList: [{imageURL: "circusPuzzle8-001.jpg", row: 1, column: 1}, 
               {imageURL: "circusPuzzle8-002.jpg", row: 1, column: 2}, 
               {imageURL: "circusPuzzle8-003.jpg", row: 1, column: 3}, 
               {imageURL: "circusPuzzle8-004.jpg", row: 1, column: 4}, 
               {imageURL: "circusPuzzle8-005.jpg", row: 1, column: 5}, 
               {imageURL: "circusPuzzle8-006.jpg", row: 2, column: 1}, 
               {imageURL: "circusPuzzle8-007.jpg", row: 2, column: 2}, 
               {imageURL: "circusPuzzle8-008.jpg", row: 2, column: 3}, 
               {imageURL: "circusPuzzle8-009.jpg", row: 2, column: 4},
               {imageURL: "circusPuzzle8-010.jpg", row: 2, column: 5}, 
               {imageURL: "circusPuzzle8-011.jpg", row: 3, column: 1}, 
               {imageURL: "circusPuzzle8-012.jpg", row: 3, column: 2},
               {imageURL: "circusPuzzle8-013.jpg", row: 3, column: 3},
               {imageURL: "circusPuzzle8-014.jpg", row: 3, column: 4}, 
               {imageURL: "circusPuzzle8-015.jpg", row: 3, column: 5}, 
               {imageURL: "circusPuzzle8-016.jpg", row: 4, column: 1},
               {imageURL: "circusPuzzle8-017.jpg", row: 4, column: 2},
               {imageURL: "circusPuzzle8-018.jpg", row: 4, column: 3}, 
               {imageURL: "circusPuzzle8-019.jpg", row: 4, column: 4}, 
               {imageURL: "circusPuzzle8-020.jpg", row: 4, column: 5},
               ],
   },
   {puzzleName: "amusement4.jpg", level: 5, rows: 4, columns: 5,
   imageList: [{imageURL: "amusement4-001.jpg", row: 1, column: 1}, 
               {imageURL: "amusement4-002.jpg", row: 1, column: 2}, 
               {imageURL: "amusement4-003.jpg", row: 1, column: 3}, 
               {imageURL: "amusement4-004.jpg", row: 1, column: 4}, 
               {imageURL: "amusement4-005.jpg", row: 1, column: 5}, 
               {imageURL: "amusement4-006.jpg", row: 2, column: 1}, 
               {imageURL: "amusement4-007.jpg", row: 2, column: 2}, 
               {imageURL: "amusement4-008.jpg", row: 2, column: 3}, 
               {imageURL: "amusement4-009.jpg", row: 2, column: 4},
               {imageURL: "amusement4-010.jpg", row: 2, column: 5}, 
               {imageURL: "amusement4-011.jpg", row: 3, column: 1}, 
               {imageURL: "amusement4-012.jpg", row: 3, column: 2},
               {imageURL: "amusement4-013.jpg", row: 3, column: 3},
               {imageURL: "amusement4-014.jpg", row: 3, column: 4}, 
               {imageURL: "amusement4-015.jpg", row: 3, column: 5}, 
               {imageURL: "amusement4-016.jpg", row: 4, column: 1},
               {imageURL: "amusement4-017.jpg", row: 4, column: 2},
               {imageURL: "amusement4-018.jpg", row: 4, column: 3}, 
               {imageURL: "amusement4-019.jpg", row: 4, column: 4}, 
               {imageURL: "amusement4-020.jpg", row: 4, column: 5},
               ],
   },

        
]

export default CircusJigsawPuzzleDataset;