
export const createGoodPath = (nbrColumns, nbrRows, startRow) => {
    var goodPath = [];
    let columnAdj = nbrColumns - 4;
    let randomColumn = Math.floor(Math.random() * columnAdj);
    let startColumn = randomColumn + 2;
    var currentRow = startRow;
    var currentColumn = startColumn;
    var direction = "down";
    var nbrSteps = 2;
    let startPos = startRow + "|" + startColumn;
    goodPath.push(startPos);
    var nextStep = 0;
    var rightBumper = nbrColumns - 2;
    var leftBumper = 2;
    var randomMax = 0;

    for (let i=0; currentRow <= nbrRows - 1; i++){
        switch (direction){
            case "down":
                currentRow += 1;
                break;
            case "up":
                currentRow -=1;
                break;
            case "left":
                currentColumn -=1;
                break;
            case "right":
                currentColumn +=1;
                break;
        }
        goodPath.push(currentRow + "|" + currentColumn);
        nbrSteps -= 1;
        if (nbrSteps == 0){
            // if last direction was down or up, then next will be left or right
            if (direction == "up" || direction == "down"){
                if (currentColumn <= leftBumper){
                    direction = "right";
                }else{
                    if(currentColumn >= rightBumper){
                        direction = "left";
                    }else{
                        nextStep = Math.floor((Math.random() * 2) + 1);
                        if (nextStep == 1){
                            direction = "right";
                        }else{
                            direction = "left";
                        }
                    }
                }
                if (direction == "right"){
                    randomMax = rightBumper - currentColumn;
                    nbrSteps = Math.floor((Math.random() * randomMax) + 1);
                }else{
                    randomMax = currentColumn - leftBumper;   
                    nbrSteps = Math.floor((Math.random() * randomMax) + 1);
                }
            // if last direction was left or right, then next will be up or down
            }else{
                 direction = "down";
                 nbrSteps = 2;
            }
        }
       
    }

return goodPath;
}

