import {Component} from 'react';
import {standardNumberFormat} from '../../objects/CommonUse/double_functions';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';



class FinishActivityDisplayCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }


    render() {
      
        this.totalGames = this.props.tableInfo.gamesAttempted + this.props.tableInfo.gamesRemaining;
               
    return <>
        <tr>
            <th className="col-md-4 text-left">Max Possible Pts: </th>
            <td className="col-md-2 text-right">{this.props.tableInfo.possiblePoints}</td>
            <th className="col-md-4 text-left">Points Earned:</th>
            <td className="col-md-2 text-right">{this.props.tableInfo.cumulativePoints} </td>
        </tr>

        <tr>
            <th className="col-md-4 text-left">Max Nbr Games:</th> 
            <td className="col-md-2 text-right">{this.totalGames}</td>
            <th className="col-md-4 text-left">Games Played:</th>
            <td className="col-md-2 text-right">{this.props.tableInfo.gamesAttempted}</td>
        </tr>
        {this.props.tableInfo.category == "SideHustle" ?
            <tr>
                <th className="col-md-8 text-left" colSpan="2">Total Amt Added to Cash:</th>
                <td className="col-md-4 text-right" colSpan="2">{doubleToStringMonthly(this.props.tableInfo.cashAmtEarned)}</td>
            </tr>
        :
            null
        }
        <tr>
            <th className="col-md-8 text-left" colSpan="2">Total Mood Points Earned:</th>
            <td className="col-md-4 text-right" colSpan="2">{this.props.tableInfo.moodPointsEarned}</td>
        </tr>
        {this.props.tableInfo.category == "HaveFun" || this.props.tableInfo.category == "Vacation" ?
            <tr>
                <th className="col-md-8 text-left" colSpan="2">Total Cost of Activity:</th>
                <td className="col-md-4 text-right" colSpan="2">{doubleToStringMonthly(this.props.tableInfo.cashAmtSpent)}</td>
            </tr>
         :
            null
        }
        <tr className="mt-4">
            <td className="text-left" colSpan="4">{this.props.tableInfo.scoreText}</td>
        </tr>
        <tr>
            <th className="text-success font-weight-bold" colSpan="4">{this.props.tableInfo.newLevelText}</th> 
        </tr>


    </>
    }
}

export default FinishActivityDisplayCard;