const iconTableInfoDataset =[
  {
    iconName: "negativeNetIncome",
        iconCategory: "warning",
        className: "small_sign_thumbnail_img warning-sign-position",
        imageSrc: "warningSign1.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "negativeCash",
        iconCategory: "warning",
        className: "small_sign_thumbnail_img warning-sign-position",
        imageSrc: "warningSign0.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "negativeMoodPoints",
        iconCategory: "warning",
        className: "small_sign_thumbnail_img warning-sign-position2",
        imageSrc: "moodFaceSad.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "pendingJob",
        iconCategory: "goodNews",
        className: "small_sign_thumbnail_img good-news-college-position",
        imageSrc: "CollegeSign.png",
        validInd: true,
        firstTime: true,
  },
  // setting validInd to false will result in a one-time notification
  {
    iconName: "jobChange",
        iconCategory: "goodNews",
        className: "small_sign_thumbnail_img good-news-college-position",
        imageSrc: "graduationHat.png",
        validInd: false,
        firstTime: true,
  },
  {
    iconName: "funStuffUnlocked",
        iconCategory: "goodNews",
        className: "small_sign_thumbnail_img good-news-funStuff-position",
        imageSrc: "FunStuff.jpg",
        validInd: true,
        firstTime: true,
  }, 
  {
    iconName: "funStuffNewLevel",
        iconCategory: "goodNews",
        className: "small_sign_thumbnail_img good-news-funStuff-position",
        imageSrc: "FunStuff.jpg",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "funStuffReminder",
        iconCategory: "goodNews",
        className: "small_sign_thumbnail_img good-news-funStuff-position",
        imageSrc: "FunStuff.jpg",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "savingsUnlocked",
        iconCategory: "goodNews",
        className: "small_sign_thumbnail_img good-news-savings-position",
        imageSrc: "savings.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "savingsReminder",
        iconCategory: "information",
        className: "small_sign_thumbnail_img good-news-savings-position",
        imageSrc: "savings.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "savingsGTIncome",
        iconCategory: "warning",
        className: "small_sign_thumbnail_img warning-sign-position3",
        imageSrc: "WarningSign2.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "savingsTransfer",
        iconCategory: "warning",
        className: "small_sign_thumbnail_img warning-sign-position",
        imageSrc: "WarningSign3.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "creditCardUnlocked",
        iconCategory: "goodNews",
        className: "small_sign_thumbnail_img good-news-creditCard-position",
        imageSrc: "creditCard.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "creditCardNewLevel",
        iconCategory: "goodNews",
        className: "small_sign_thumbnail_img good-news-creditCard-position",
        imageSrc: "creditCard.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "creditLimitExceeded",
        iconCategory: "warning",
        className: "small_sign_thumbnail_img warning-sign-position3",
        imageSrc: "WarningSign2.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "creditCardReminder",
        iconCategory: "information",
        className: "small_sign_thumbnail_img good-news-creditCard-position",
        imageSrc: "creditCard.png",
        validInd: true,
        firstTime: true,
  },
  {
    iconName: "purchaseAHome",
        iconCategory: "goodNews",
        className: "small_sign_thumbnail_img good-news-house-position",
        imageSrc: "house.png",
        validInd: true,
        firstTime: true,
  },
   
];

export default iconTableInfoDataset;