import assetExpLoanShortnameDataset from "../../datasets/asset_exp_loan__shortname_dataset";

export const homeRentAmt = (homeCost, roommates, bedrooms, homeName, currentYYYYMM) => {
     var expenseString = " ";

        
     let fullHomeDesc = "";
     var roommateLit = " roommates";
     if (roommates == 1) {
           roommateLit = " roommate"
     };
     var bedroomLit = " bedrooms, ";
     if (bedrooms == 1) {
          bedroomLit = " bedroom, "
     }; 
     fullHomeDesc = homeName + "-" + bedrooms + bedroomLit + roommates + roommateLit;
     
     
     let expenseNameTable = assetExpLoanShortnameDataset;
     let fullName = "";
     for (let i=0; i<expenseNameTable.length; i++){
          if (expenseNameTable[i].shortName == "rent"){
               fullName = expenseNameTable[i].fullName;
          }
     }
      
     let homeCostParse = parseFloat(homeCost);
     var homeCostCalc = 0
     if (!isNaN(homeCostParse)) 
     {
          homeCostCalc = homeCostParse;
     } 
     let roommatesParse = parseFloat(roommates);
     var roommateCalc = 0
     if (!isNaN(roommatesParse)) {
          roommateCalc = roommatesParse + 1;
     }
     var homeCostAmt = 0
     if (roommateCalc !== 0) {
          homeCostAmt = homeCostCalc / roommateCalc
     }

     expenseString = {expItem: fullName, expDesc: fullHomeDesc, expAmount: homeCostAmt,
                    expStartDate: currentYYYYMM, expNbrMonths: "-1"}; 
       
     return expenseString;
 }

