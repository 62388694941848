const ChoosingCareerChallenge2HintsText =
    ["Individuals are usually most successful in their career " +
        "if their skills/interests match the career that they choose.",
     "While making a lot of money is important for individuals " +
        "it won't matter if you can't find a job because there is little " +
        "demand for persons in this career.",
     "Opinions of parents and friends should not be discounted. " +
        "This might not be a primary factor but sometimes others know you " +
        "well and can make a good recommendation for a career."]
   

export default ChoosingCareerChallenge2HintsText;