import {Component} from 'react';
import {cashStrMask} from './objects/game_view_mask_functions';
import ConfigFile from '../../datasets/config_file';
import setupTextReplace from './objects/setup_text_replace';

class GameCardFaceUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responseReceived: false, 
            responseInd: null};
    }

    componenDidMount()
    {
        this.setState({responseReceived: false, responseInd: null});
    }

    determineTextSize(){
        let textLength = this.props.currentCard.description.length;
        var totalBtnLines = 0;
        let btnTable = [];
        if (this.props.currentCard.responses == undefined){
            btnTable = this.props.currentCard.lastCardButtons;
            for (let i=0; i<btnTable.length; i++){
                let nbrLinesInBtn = ((btnTable[i].length / 15) + .4).toFixed(0);
                totalBtnLines += +nbrLinesInBtn;
            }
        }else{
            btnTable = this.props.currentCard.responses;
            for (let i=0; i<btnTable.length; i++){
                let nbrLinesInBtn = (((btnTable[i].btnText).length / 15) + .4).toFixed(0);
                totalBtnLines += +nbrLinesInBtn;
            }
        }
              
        if (textLength < 70 || 
           (textLength < 170 && totalBtnLines <= 1) ||
           (textLength < 140 && totalBtnLines <= 2) ||
           (textLength < 120 && totalBtnLines <= 3) || 
           (textLength < 100 && totalBtnLines <= 4) ||
           (textLength < 90  && totalBtnLines <= 5) ||
           (textLength < 70  && totalBtnLines <= 6))
          {
                this.class = "";
        }else{
                this.class = "small-text"; 
        }
    }

    render() {
        let left = 15;
        let bottom = 60;
        let btnList;
        if (this.props.screenSize == "narrow"){
             left = 10;
        }
        
        if (this.props.currentCard.responses)
        {   
            btnList = this.props.currentCard.responses.map((key, index) =>
                <div className="btn-small-special btn-primary w-80 mx-4 mt-2" key={index} 
                    onClick={()=>this.onSelected(index)}>
                    {this.props.currentCard.responses[index].btnText}
                </div>
            );
            if (this.state.responseReceived == false){
                this.determineTextSize();
            }
        }
        else
        {
            btnList = this.props.currentCard.lastCardButtons.map((key, index) =>
                <div className="btn-small-special btn-primary w-75 mx-4 mt-2" key={index} 
                    onClick={()=>this.props.onLastCard(this.props.currentCard.lastCardButtons[index].type,
                        this.props.currentCard.lastCardButtons[index].activity)}>
                    {this.props.currentCard.lastCardButtons[index].btnText}
                </div>
            );
            if (this.state.responseReceived == false){
                this.determineTextSize();
            }
        }
        let cardClass="";
        if (this.props.currentCard.color == "green"){
            cardClass = cardClass + "faceup-game-card game-card-green";
        }else{
            cardClass = cardClass + "faceup-game-card game-card-blue";
        }
      
   
    return <>
        {!this.state.responseReceived ?
            <div className={cardClass} style={{left: left, bottom: bottom}}>
                <h5>{this.props.currentCard.title}</h5>
                <p className={this.class}>{this.props.currentCard.description}</p>
                <div className="ml-0">
                {btnList}
                </div>
            </div>
            :
            <div className={cardClass} style={{left: left, bottom: bottom}}>
                <h5>{this.props.currentCard.title}</h5>
                {this.props.currentCard.responses[this.state.responseInd].closureText.length < 180 ?
                    <p>{this.props.currentCard.responses[this.state.responseInd].closureText}</p>
                    :
                    <p className="small-text">
                        {this.props.currentCard.responses[this.state.responseInd].closureText}</p>
                }
                <div className="btn-sm btn-primary w-75 mt-2 ml-4" 
                    onClick={()=>this.props.onComplete(this.state.responseInd)}>OK</div>
            </div>
        }
    </>
    }

    onSelected(index)
    {
        if (!this.state.responseReceived)
        {
            this.props.onResultDisplay();
            let replaceValue = +this.props.calcResponseValue(index);
            if (replaceValue < 0){
                  replaceValue = -1 * replaceValue;
            }
            var replaceText;
            if (replaceValue == 0){
                  replaceText = "nothing";
            }else{
                  replaceText = cashStrMask(replaceValue);
            }
            this.props.currentCard.responses[index].closureText =
                this.props.currentCard.responses[index].closureText.replaceAll(
                    "$value", replaceText);
            this.moodPointMultiplier = ConfigFile.gameBoard.moodPointMultiplier;
            if (this.props.currentCard.responses[index].moodPoints != undefined &&
                +this.props.currentCard.responses[index].moodPoints != 0){
                    let fullMoodPoints = +this.props.currentCard.responses[index].moodPoints * this.moodPointMultiplier;
                    let moodText = "";
                    if (fullMoodPoints == 0){
                        moodText = "There is no change to your mood score."
                    }else{
                        if (fullMoodPoints > 0){
                            moodText = "Mood score increased by ";
                        }else{
                            moodText = "Mood score decreased by ";
                            fullMoodPoints = fullMoodPoints * -1;
                        }
                    }
                    moodText = moodText + fullMoodPoints + ".";
                    this.props.currentCard.responses[index].closureText = this.props.currentCard.responses[index].closureText +
                        " " + moodText;
            }
            if (this.props.currentCard.responses[index].closureText.includes ('||')){
                this.props.currentCard.responses[index].closureText = 
                    setupTextReplace(this.props.currentCard.responses[index].closureText, +replaceValue, 
                        this.props.currentCard.friendName, this.props.itemName )
            }
            if (this.props.currentCard.responses[index].linkTo == undefined ||
                this.props.currentCard.responses[index].linkTo == ""){
                    this.setState({responseReceived: true, responseInd: index});
            }else{
                this.props.onComplete(index);
            }

        }
    }
    
}

export default GameCardFaceUp;