import LinkTab from '../components/shared-components/link_tab';
import React from 'react';
import { withRouter } from "react-router";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ExtraCreditMain from '../components/extra_credit/extra_credit_main';
import Level1Challenges from '../components/extra_credit/level1_challenges';
import Level2Challenges from '../components/extra_credit/level2_challenges';
import CommentModal from '../components/shared-components/comment_modal';
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import ExtraCreditConfigFile from '../components/extra_credit/Datasets/extra_credit_config_file';
import MoneyMgmtConceptTest from '../components/extra_credit/money_mgmt_concept_test';
import ConfigFile from '../datasets/config_file';
import { setHeadersForFetch } from '../objects/CommonUse/set_headers_for_fetch';
import { createTaxAmtExpense } from '../objects/AssetsExpenses/create_tax_amt_expense';
import { addChangeSalaryAsset } from '../objects/AssetsExpenses/add_change_salary_asset';
import { setEventTrackerObj } from '../objects/CommonUse/set_event_tracker_obj';
import { getCurrentDateTime } from '../objects/CommonUse/get_current_date_time';
import {buildUrl } from '../objects/CommonUse/build_URL';
import Config from '../config';
import WaitTimeSpinner from '../components/shared-components/wait_time_spinner';

class ExtraCredit extends React.Component {
    constructor(props) {
        super(props);
          
    this.inputCompleted = (inputID) => {
        this.state.inputArr[inputID].completed = true;
        this.setState({inputArr: this.state.inputArr}); 
    };

    this.inputSelected = (inputID) => {
        for (const input of this.state.inputArr) {
            input.selected = false;
        }
        this.state.inputArr[inputID].selected = true;
        this.setState({inputArr: this.state.inputArr});  
    };
    this.openCommentModal = this.openCommentModal.bind(this);
    this.closeCommentModal = this.closeCommentModal.bind(this);

     this.state = {
        inputArr: [
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            }
        ],
        inputCompleted: this.inputCompleted,
        inputSelected: this.inputSelected,
        headers: setHeadersForFetch(),
        doneLoading: false,
        data: null,
        extraCreditCompleted: [],
        eventArr: [],
        eventCount: 0,
        eventLogging: ConfigFile.gameBoard.eventLogging,
        commentIcon: "./assets/ContactUs/pencilPaper.png",
        commentModalOpen: false,
        fromProcessName: "ExtraCredit",
        screenSize: sessionStorage.getItem("GameScreenSize"),
        exerciseLevelArr: [],
        prevMatches: null, 
        environment: Config.dataURL.currentEnv,
        subProcess: "extraCredit",
        gameDataLoading: true,
        extraCreditLoading: true,
        gameInfo: {
            gameExists: false,
            gameData: {},
            extraCredit: [],
            extraCreditExists: null,
            playerRole: null,
        }
        
    };

    switch(props.selected) {
        case "extraCreditMain":
            this.inputSelected(0);
            break;
        case "level1Challenges":
            this.inputSelected(1);
            break;
        case "level2Challenges":
            this.inputSelected(2);
            break;
        case "moneyMgmtTest":
            this.inputSelected(3);
            break;
   
        }
        this.openModal = true;
        this.currentGameMonths = 0;
        this.startingGameMonths = +ConfigFile.gameStartItems.totalNbrGameMonths;
    }

    saveTestResults(){
        // this will eventually move to the actual test page
    }
       
    render(){
            this.gameInfoDisplay=this.getGameInfo(); 
            this.className = "mt-5 mx-3";
            if (this.state.screenSize == "wide"){
                this.commentIconClass = "comment-icon-position comment-img";
            }else{
                this.commentIconClass = "comment-icon-position-medium comment-img"; 
            }
                                           
    return <>
        <span className="container m-0 p-0">
        {this.state.gameDataLoading || this.state.extraCreditLoading ?
                <div className="mt-5">
                        <WaitTimeSpinner/>  
                </div>
            :
            <span className="row w-100 mx-0">
                {this.state.screenSize == "wide" ?
                    <div className="col-sm-2 mt-4 ml-3">
                        <div>
                            <img className="train-track-img" src={'./assets/ExtraCredit.png'} 
                                alt="MoneyTrain Picture"/>
                        </div>   
                    </div>
                :
                    null
                }
                        <div>
                            <a target="_blank" data-tip="Click to provide feedback about game." rel="noopener noreferrer">
                                <ReactTooltip globalEventOff="click" />
                                    <img src={this.state.commentIcon} 
                                        className={this.commentIconClass} alt="comment"
                                    onClick={this.openCommentModal}/>
                            </a>
                        </div>

                        {this.state.commentModalOpen ?
                            <CommentModal modalOpen={this.state.commentModalOpen} 
                                onClose={() => this.closeCommentModal()}
                                gamePart={this.state.fromProcessName}
                                processName={this.props.selected}
                                gameInfo = {() => this.getGameInfo()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                        :
                            null
                        }

                    {this.props.selected === "extraCreditMain" ? 
                        <ExtraCreditMain 
                            screenSize={this.state.screenSize}
                            playerRole = {this.gameInfoDisplay.playerRole}
                            onOpen ={()=> this.inputSelected(0)} 
                            gameInfo = {() => this.getGameInfo()} 
                            goBack = {() => this.goToHomePage()}
                            setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            /> 
                        : <span/>}
                   
                    {this.props.selected === "level1Challenges"? 
                        <Level1Challenges
                            screenSize={this.state.screenSize}
                            extraCreditCompleted={this.state.exerciseLevelArr}
                            onOpen ={()=> this.inputSelected(1)}
                            completed={() => this.inputCompleted(1)} 
                            goBack = {() => this.goToHomePage()}
                            updateAmounts={() => this.updateAmounts()}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        />
                        :<span/>}
                         
                    {this.props.selected === "level2Challenges"? 
                        <Level2Challenges
                            screenSize={this.state.screenSize}
                            goBack = {() => this.goToHomePage()}
                            extraCreditCompleted={this.state.exerciseLevelArr}
                            onOpen ={()=> this.inputSelected(2)}
                            completed={() => this.inputCompleted(2)} 
                            updateAmounts={() => this.updateAmounts()}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        />
                        :<span/>}
                                              
                    {this.props.selected === "moneyMgmtTest"? 
                        <MoneyMgmtConceptTest
                            currentGameMonths={this.currentGameMonths}
                            screenSize={this.state.screenSize}
                            goBack = {() => this.goToHomePage()}
                            completed={() => this.inputCompleted(3)} 
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        />
                        :<span/>}


                    {this.gameInfoDisplay.loading  && this.gameInfoDisplay.playerRole != null ?
                                null
                            :
                            <div className="col-sm-2 ml-5"> 
                                <Link to="extra-credit-main" onClick={() => this.handleClick(0)}>
                                    <LinkTab inputArr={this.state.inputArr[0]} name="Main Page" validToComplete="true"
                                       screenSize={this.state.screenSize}/>
                                </Link>

                            {this.state.inputArr[1].completed  ?
                                <LinkTab inputArr={this.state.inputArr[1]} name="Level 1 Challenges"   screenSize={this.state.screenSize}/>   
                            :
                                <Link to="level1-challenges" onClick={() => this.handleClick(1)}>
                                    <LinkTab inputArr={this.state.inputArr[1]} name="Level 1 Challenges" validToComplete="true"
                                       screenSize={this.state.screenSize}/>
                                </Link>
                            }
                       
                            {this.state.inputArr[2].completed == true  ?
                                <LinkTab inputArr={this.state.inputArr[2]} name="Level 2 Challenges"
                                    screenSize={this.state.screenSize}/>
                                :
                                <Link to="level2-challenges" onClick={() => this.handleClick(2)}>
                                    <LinkTab inputArr={this.state.inputArr[2]} name="Level 2 Challenges" validToComplete="true"
                                        screenSize={this.state.screenSize}/>
                                </Link>
                            }
                             
                            <Link to="money-mgmt-test" onClick={() => this.handleClick(3)}>
                                <LinkTab inputArr={this.state.inputArr[3]} name="Are You a Money Mgmt Superstar?" 
                                    validToComplete="true"
                                    screenSize={this.state.screenSize}/>
                            </Link>
                                            
                        </div>
                    }  
                
                </span>
            }
            </span>
                       
    </>
}

componentDidMount(){
    this._isMounted = true;
    this.loadGame();
    this.loadExtraCredit();
}


componentWillUnmount(){
    if (this.state.eventArr.length > 0 && this.state.eventLogging == true){
        this.saveEventTracker();
        this.state.eventArr = [];
    }
}


openCommentModal(){
    this.state.commentModalOpen = true;
}

closeCommentModal(){
    this.state.commentModalOpen = false;
    this.setState({commentModalOpen: false});
}


handleClick = index => {
    this.state.inputSelected(index);
    this.index = index;
}


loadGame()
{
    this.currentGameMonths = 0;
    const funcName = "loadGame";
    const urlParm = "?mode=fullLoad";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
         {method:'GET', headers: this.state.headers})
         .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        let gameData = output.body;
                        this.currentGameMonths += (this.startingGameMonths - +gameData.gameNbrMonths);
                        this.setGameInfo(output.body, 'gameData');
                        this.setGameInfo(true, 'gameExists');
                        this.setState({gameDataLoading: false});
                    }else{
                        if (statusCode == 250){
                            this.setGameInfo(output.body, 'gameData');
                            this.setGameInfo(false, 'gameExists');
                            this.setState({gameDataLoading: false});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
            };
        })
}


loadExtraCredit()
{
    let dateTimeObj = getCurrentDateTime();
    let newTime = dateTimeObj.time;
    const funcName = "loadExtraCreditInfo";
    const urlParm = "?currentTime=" + newTime;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.setGameInfo(output.body, 'extraCredit');
                        this.setGameInfo(true, 'extraCreditExists');
                        this.setCompletedValues();
                        this.setState({extraCreditLoading: false});
                    }else{
                        if (statusCode == 250){
                            this.setGameInfo(false, 'extraCreditExists');
                            this.setState({extraCreditLoading: false});    
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
            };
        })
}

setCompletedValues(){
    this.state.exerciseLevelArr = [];
    if (this.gameInfoDisplay.extraCreditExists == false){
        for (let i=0; i<this.state.inputArr.length; i++){
            this.state.inputArr[i].completed = false;
        }
    }else{
        this.determineCompletedLevels();
        for (let i=0; i<this.levelCompleteArr.length; i++){
            let indx = +this.levelCompleteArr[i];
            this.state.inputArr[indx].completed = true;
        }
    }
    this.setState({inputArr: this.state.inputArr}); 
}

determineCompletedLevels(){
    let levels = ExtraCreditConfigFile.exercises;
    let extraCreditComplete = this.gameInfoDisplay.extraCredit.extraCreditTable;
   
    for (let i=0; i<levels.length; i++){
         let levelsPlusComplete = {category: levels[i].category, level: levels[i].level, complete: false};
         for (let j=0; j<extraCreditComplete.length; j++){
             if (extraCreditComplete[j].category == levels[i].category){
                    levelsPlusComplete.complete = true;
                    break;
             }
         }
         this.state.exerciseLevelArr.push(levelsPlusComplete);
    }
    this.state.exerciseLevelArr.sort((a, b) => (a.level > b.level) ? 1 : -1);
    let levelNbr = "";
    let levelComplete = false;
    this.levelCompleteArr = [];
    for (let i=0; i<this.state.exerciseLevelArr.length; i++){
        if (levelNbr != this.state.exerciseLevelArr[i].level){
            if (levelNbr != ""){
                 if (levelComplete == true){
                      this.levelCompleteArr.push(levelNbr);
                 }
            }
            levelComplete = true;
            levelNbr = this.state.exerciseLevelArr[i].level;
        }
        if (this.state.exerciseLevelArr[i].complete == false){
            levelComplete = false;
        }
    }
    if (levelComplete == true){
        this.levelCompleteArr.push(levelNbr);
    }
}

updateAmounts(){
    let processObj = JSON.parse(sessionStorage.getItem("extraCreditCompleted"));
    let processName = processObj.processName;
    this.assetType = processObj.assetType;
    this.amount = processObj.amount;
    if (this.assetType == "salary"){
        if (this.gameInfoDisplay.gameExists == true){
            let newAssetObj = addChangeSalaryAsset(this.gameInfoDisplay.gameData.assetTable,
                "ExtraCreditBonus", this.gameInfoDisplay.gameData.currentGameDate, this.amount,
                processName);
            this.gameInfoDisplay.gameData.assetTable = newAssetObj.assetTable;
            this.newSalaryAmt = newAssetObj.totalSalary;
            // update the taxes for the new salary amount
            this.updateExpenseTable();
        }else{
            this.currentSalary = +ExtraCreditConfigFile.general.defaultSalary;
            this.newSalaryAmt = (+this.currentSalary * +this.amount).toFixed(2);
            this.newSalaryAmt = this.newSalaryAmt.toString();
        }

    }else{
        if(processObj.assetType == "cash"){
            if (this.gameInfoDisplay.gameExists == true){
                let newCashAmt = (+this.gameInfoDisplay.gameData.currentCash + +this.amount).toFixed(2)
                this.gameInfoDisplay.gameData.currentCash = newCashAmt;
            }
        }
    }
    this.state.extraCreditObj = {category: processName, score: processObj.score,
        assetType: processObj.assetType, amount: processObj.amount, level: processObj.level};
    this.state.extraCreditCompleted.push(processName);
    if (this.gameInfoDisplay.gameExists == true){
        this.addToEventArray();
        this.updateCashExpenseAsset();
    }
    this.updateExtraCreditTable();
}

updateExpenseTable(){
    let revisedExpenseTable = createTaxAmtExpense(this.gameInfoDisplay.gameData.expenseTable,  
            this.gameInfoDisplay.gameData.assetTable, 
            this.gameInfoDisplay.gameData.currentGameDate);
    this.gameInfoDisplay.gameData.expenseTable = revisedExpenseTable;
}

addToEventArray(){
    this.state.eventCount += 1;
    if (this.assetType == "salary"){
        this.category = "salary";
        this.onItem = "Job Salary";
        this.duration = "-1";
    }else{
        this.category = "cash";
        this.onItem = "cash";
        this.duration = "1";
    }
    let response = {category: this.category, on: this.onItem, 
        duration: this.duration, value: this.amount};
    let eventTitle = "extra credit-" + this.state.extraCreditObj.category;
    let eventObj = setEventTrackerObj(this.gameInfoDisplay.gameData, response, eventTitle, this.state.eventCount);
    this.state.eventArr.push(eventObj);
    this.saveEventTracker();
}

updateCashExpenseAsset(){
                
    const bodyObj = {"currentCash": this.gameInfoDisplay.gameData.currentCash,
                     "assetTable": JSON.parse(JSON.stringify(this.gameInfoDisplay.gameData.assetTable)) ,
                     "expenseTable": JSON.parse(JSON.stringify(this.gameInfoDisplay.gameData.expenseTable)),
                    };
    const funcName = "updateCashAssetExpense";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'PUT', headers: this.state.headers,
          body: JSON.stringify(bodyObj)})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                           // update was successful");
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: bodyObj};
                            this.processErrorReturn(errorObj);
                        }
                    });
                };
            });
}

updateExtraCreditTable(){
    if (this.gameInfoDisplay.extraCreditExists == true){
        this.updateType = "PUT";
    }else{
        this.updateType = "POST";
        this.gameInfoDisplay.extraCredit.extraCreditTable = [];
    }
    this.gameInfoDisplay.extraCredit.extraCreditTable.push(this.state.extraCreditObj);
    if (this.updateType == "PUT"){
        this.putExtraCredit();
    }else{
        this.postExtraCredit();
        this.setGameInfo (true, "extraCreditExists");
    }
    this.determineCompletedLevels();
    this.setGameInfo (this.gameInfoDisplay.extraCredit, "extraCredit");
}

putExtraCredit(){
    const currDateTime = getCurrentDateTime();  
    // Update extra credit table in Mongo
    const bodyObj = {
        "extraCreditTable":JSON.parse(JSON.stringify(this.gameInfoDisplay.extraCredit.extraCreditTable)),
        "dateUpdated": currDateTime.date};
    const funcName = "updateExtraCredit";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
          {method:'PUT', headers: this.state.headers,
          body: JSON.stringify(bodyObj)})
          .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                       // update was successful;
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: bodyObj};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
}

postExtraCredit() {
    const currDateTime = getCurrentDateTime();
    let userName = "";
    if (this.gameInfoDisplay.gameExists == true){
        userName = this.gameInfoDisplay.gameData.user;
    }else{
        userName = sessionStorage.getItem("username");
    }
    const bodyObj = {
        "user": userName,
        "userRole": this.gameInfoDisplay.playerRole,
        "extraCreditTable":JSON.parse(JSON.stringify(this.gameInfoDisplay.extraCredit.extraCreditTable)),
        "dateAdded": currDateTime.date,
        "dateUpdated": currDateTime.date
    };
    const funcName = "saveExtraCredit";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {
        method:'post',
        headers: this.state.headers,
        body: JSON.stringify(bodyObj)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                       // save of extra credit was successful
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: bodyObj};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
}

saveEventTracker() {
    const currDateTime = getCurrentDateTime();
          
    let dataObject = {
        "player": this.gameInfoDisplay.gameData.user,
        "gameID": this.gameInfoDisplay.gameData._id,
        "eventType": "ExtraCredit",
        "date": currDateTime.date,
        "time": currDateTime.time,
        "process": "ExtraCredit",
        "events": this.state.eventArr,
    };
    
    const funcName = "saveEventTracker";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {
        method:'post',
        headers: this.state.headers,
        body: JSON.stringify(dataObject)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                       // save event tracker was successful
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: dataObject};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
}

getGameInfo()
{
    return this.state.gameInfo;
}

setGameInfo(value, propertyName = null)
{
    if (propertyName)
    {
    this.state.gameInfo[propertyName] = value;
    this.setState({gameInfo: this.state.gameInfo});
    }
    else{ this.setState({gameInfo: value});
    }
         
}

goToHomePage(){
    this.props.history.push('/');
}

processErrorReturn(errorObj){
    this.addRecordToLogfile(errorObj);
    if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
        alert ("Uh oh!  Something unexpected has occurred " +
            "error has been logged and will be addressed. " +
            "For now click OK, then return to the process " +
            "where you were and try again.");
        this.props.history.push('/');
    }
}

addRecordToLogfile(errorObj){
    const headers=setHeadersForFetch();
    if (errorObj.status == 250){
        errorObj.message = "No record found";
    }
    let subProcess = "";  
    if (errorObj.subProcess == undefined){
        subProcess = "unknown"
    }else{
        subProcess = errorObj.subProcess;
    }
    if (errorObj.errorObject == undefined){
        errorObj.errorObject = {};
    }
    const logFileBody =  {
        mainProcess: "ExtraCredit",
        subProcess: subProcess,
        function: errorObj.function,
        status: errorObj.status,
        message: errorObj.message,
        errorObj: errorObj.errorObject
    }
    console.log("logFileBody is: ", logFileBody)
    const urlParm = "";
    const uri = buildUrl(this.state.environment, "saveLogFile", urlParm);
    fetch (uri,  
        {method:'POST',
            headers: headers,
            body: JSON.stringify(logFileBody)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        console.log("logfile create was successful");
                    }else{
                        console.log("Response from save log file not valid!" +
                         " status is: ", output.statusCode,
                         " message is: ", output.body);
                        alert ("Response from save log file not valid!");
                           
                    }
            });
        }
    });
  
}
 

}

export default withRouter(ExtraCredit);