import {Component} from 'react';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';


class CalculatorLineDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }   
}
  
render() {
       this.lineNbr = this.props.index + 1;          
return <>
      <tr>
          <td className="text-right py-0">{this.lineNbr}.</td>
          <th className="text-right py-0">{doubleToStringMonthly(this.props.calcLineInfo.beginCreditCardBalance)}</th>
          {this.props.calcLineInfo.payOption == "min" || this.props.calcLineInfo.payOption == "full" ?
               <th className="text-right py-0">avg: {doubleToStringMonthly(this.props.calcLineInfo.monthlyPayAmt)}</th>
          :
               <th className="text-right py-0">{doubleToStringMonthly(this.props.calcLineInfo.monthlyPayAmt)}</th>
          }
          <th className="text-right py-0">{doubleToStringMonthly(this.props.calcLineInfo.monthlyChargeAmt)}</th>
          <th className="text-center py-0">{this.props.calcLineInfo.nbrYears}</th>
          <td className="text-right py-0">{doubleToStringMonthly(this.props.calcLineInfo.totalNewChargeAmt)}</td>
          <td className="text-right py-0">{doubleToStringMonthly(this.props.calcLineInfo.totalInterest)}</td>
          <td className="text-right py-0">{doubleToStringMonthly(this.props.calcLineInfo.outOfPocket)}</td>
          <td className="text-right py-0">{doubleToStringMonthly(this.props.calcLineInfo.endCreditCardBalance)}</td>
      </tr>         
           
   </>
  }
}

export default CalculatorLineDisplayCard;