import './ExtraCredit/extraCredit.css';
import React from 'react'
import styled from 'styled-components'
import DragNDropItems from './drag_n_drop_item'
import { Droppable, Draggable } from 'react-beautiful-dnd'

const Container = styled.div`
    margin: .5rem;
    border: 0px solid black
    border-radius: 5px;
    width:500px;
    display:flex;
    flex-direction: column;
    white-space: pre-wrap;
    background-color: #002F8E;
`;
const Title = styled.h3`
    padding: 0 1rem;
    margin: 1rem 0; 
`;
const ItemList = styled.div`
    padding: 1rem;
    background-color: ${props => (props.isDraggingOver ? '#d5f3ff' : 'inherit')};
    min-height:100px;
`;

function DragNDropColumn(props) {
    
    var cookieName = props.cookieNamePfx + props.column.id
    var columnObject = {process: props.cookieNamePfx, column: props.column.id, items: props.items}
    sessionStorage.setItem(cookieName, JSON.stringify(columnObject));
    var textClass = "text-center text-white";
    if (props.screenSize == "narrow"){
        textClass = "small-text " + textClass;
    } 

          
    return (
        <Draggable draggableId={props.column.id} index={props.index}>
            {(provided) => (
                <Container
                    ref={provided.innerRef}
                    {...provided.draggableProps}>
                    <h6 className={textClass} {...provided.dragHandleProps}>{props.column.title}</h6>
                    <Droppable droppableId={props.column.id} type='item'>
                        {(provided, snapshot) => (
                            <ItemList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                isDraggingOver={snapshot.isDraggingOver}
                            >
                                {props.items.map((item, index) => <DragNDropItems
                                    key={item.id} item={item} amountSw={props.amountSw}
                                    screenSize={props.screenSize}
                                    pictureSw={props.pictureSw}
                                    index={index} />)}
                                {provided.placeholder}
                            </ItemList>
                        )}
                    </Droppable>
                </Container>

            )}
        </Draggable>
    )
}

export default DragNDropColumn