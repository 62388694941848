import "./GameBoardActivities/dragNDrop.css";
import React from 'react';
import styled from 'styled-components';
import DragNDropItems from './drag_n_drop_item_grocery';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const ItemList = styled.div`
    padding: 1rem;
    background-color: ${props => (props.isDraggingOver ? 'transparent' : 'inherit')};
    min-height:100px;
`;
 

function DragNDropColumn(props) {
    var cookieName = props.cookieNamePfx + props.column.id
    var columnObject = {process: props.cookieNamePfx, column: props.column.id, items: props.items}
    sessionStorage.setItem(cookieName, JSON.stringify(columnObject));
    return (
        <div className="container" id={props.column.id} index={props.index}> 
            
                <div className={props.column.className}>
                     
                    <h6 className="text-center text-black">{props.column.title}</h6>
                    <Droppable droppableId={props.column.id} type='item'>
                        {(provided, snapshot) => (
                                
                            <ItemList className={props.column.multColumns}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                
                                isDraggingOver={snapshot.isDraggingOver}
                            >
                                {props.items.map((item, index) => <DragNDropItems
                                    key={item.id} item={item} 
                                    index={index} />)}
                                        {provided.placeholder}
                            </ItemList>
                           
                        )}
                    </Droppable>
                </div>
        </div>
    )
}

export default DragNDropColumn