import React from 'react';
import { withRouter } from "react-router";
import LinkCard from '../shared-components/link_card';
import SetupFunStuffModal from './setup_fun_stuff_modal';
import SetupSavingsModal from './setup_savings_modal';
import SetupCreditCardModal from './setup_credit_card_modal';
import CreditCardStatementModal from './credit_card_statement_modal';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';

class UnlockedFeatures extends React.Component {
    constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
         
        }  
        this.nextProcess = "";
        this.openProcess = false;
        this.screenSize = "wide";
        this.checkProcessSelected();  
    }

checkProcessSelected(){
    this.setDisabledStatus();
    switch (this.props.selected){
        case "setupFunStuff":
            this.setupFunStuffOptions();
            break;
        case "setupCreditCard":
            this.displayCreditCardInfo();
            break;
        case "setupSavings":
            this.setupSavingsPolicy();
                break;
        default:
            break;
    }
    
}

setDisabledStatus(){
    this.savingsOK = false;
    this.creditCardOK = false;
    this.investmentsOK = false;
    for (let i=0; i<this.gameInfo.gameData.unlockedFeatures.length; i++){
          let feature = this.gameInfo.gameData.unlockedFeatures[i];
          switch (feature.featureName) {
                case "funStuff":
                    this.funStuffOK = true;
                    break;
                case "savings":
                    this.savingsOK = true;
                    break;
                case "creditCard":
                    this.creditCardOK = true;
                    break;
                case "investments":
                    this.investmentsOK = true;
                    break;
                default:
                    break;
          }
    }
}


setupFunStuffOptions() {
    if (this.funStuffOK === true) {
        this.nextProcess = "funStuff";
        this.openProcess = true;
    }
}

setupSavingsPolicy() {
    if (this.savingsOK === true) {
        this.nextProcess = "savings";
        this.openProcess = true;
    }
}

displayCreditCardInfo(){
    this.openProcess = true;
    let feature = this.gameInfo.gameData.unlockedFeatures.find(elem=> elem.featureName === "creditCard");
    // when unlocked feature is set to 'none', it means a new lvel has been authorized
    if (feature.setup === "none"){
       if (+feature.level > 1){ 
            this.changeCreditCardPolicy("newLevel");
       }else{
            this.changeCreditCardPolicy("policy");
       }
    }else{
        this.nextProcess = "creditCardStatement";
    }
}

changeCreditCardPolicy(changeType){
    this.openProcess = true;
    this.nextProcess = "creditCardSetup";
    this.changeType = changeType;
}

setupInvestmentPlan() {
    this.nextProcess = "Investments";
    this.openProcess = true;
}

  render() {
      if (this.nextProcess !== ""){
        sessionStorage.setItem("GameViewSubProcess", this.nextProcess);
      }
      let screenParms = setScreenSizeParameters(this.props.screenSize, "gameBoard");
      this.classSize = screenParms.columnClass;
      this.linkSize = screenParms.linkSize;
              
  return <>
            <div className={this.classSize}>
            <div className="card mt-3 mr-5 rounded-corners-lg text-center game-board height-max">
            <h3 className="mt-2 text-center">Change Game Choices</h3>
           
            <span className="row w-100 mx-0 ml-2">
                <span className="col-sm-6 mt-4">
                    {this.funStuffOK ?
                        <div  onClick={() => this.setupFunStuffOptions()}>
                            <LinkCard name="Fun Stuff" imgSrc='./assets/UnlockedFeatures/funStuff.jpg'
                                inSize={this.linkSize} alt="funStuff"  />
                        </div>
                    
                    :
                        <div  className="buttonDisabledImage">
                            <LinkCard name="Fun Stuff" imgSrc='./assets/UnlockedFeatures/funStuff.jpg'
                                inSize={this.linkSize} alt="funStuff"  />
                        </div>
                    }
                </span>
                <span className="col-sm-6 mt-4">
                    {this.savingsOK ?
                        <div  onClick={() => this.setupSavingsPolicy()}>
                            <LinkCard name="Setup or Change Savings Policy" imgSrc='./assets/UnlockedFeatures/savings.png'
                                inSize={this.linkSize} alt=""  />
                        </div>
                    
                    :
                        <div  className="buttonDisabledImage">
                            <LinkCard name="Setup or Change Savings Policy" imgSrc='./assets/UnlockedFeatures/savings.png'
                                inSize={this.linkSize} alt=""  />
                        </div>
                    }
                </span>
                <span className="row w-100 mx-0 ml-3">
                <span className="col-sm-6 mt-5 mb-2">
                    {this.creditCardOK ?
                        <div onClick={() => this.displayCreditCardInfo()}>
                            <LinkCard name="Setup Credit Card" imgSrc='./assets/UnlockedFeatures/creditCard.png' 
                                inSize={this.linkSize} alt=""/>
                                   
                        </div>
                    :
                        <div className="buttonDisabledImage">
                            <LinkCard name="Setup Credit Card" imgSrc='./assets/UnlockedFeatures/creditCard.png' 
                                inSize={this.linkSize} alt=""/>
                           
                        </div>
                    } 
                </span>
                <span className="col-sm-6 mt-5 mb-2">
                    {this.investmentsOK ?
                        <div className="" onClick={() => this.setupInvestmentPlan()}>
                                <LinkCard name="Setup Investments" imgSrc='./assets/UnlockedFeatures/investments.png' 
                                    inSize={this.linkSize} alt=""/>
                        </div>
                    :
                        <div className="buttonDisabledImage" >
                                <LinkCard name="Setup Investments" imgSrc='./assets/UnlockedFeatures/investments.png' 
                                    inSize={this.linkSize} alt=""/>
                        </div>
                    }
                </span>
            </span>
            </span>
            
            {this.nextProcess === "funStuff" ?
                <SetupFunStuffModal show={this.openProcess}
                    screenSize="wide"
                    gameInfo = {() => this.props.gameInfo()}
                    onClose={(returnVal) => this.closeModal(returnVal)}
                    setGameInfo = {(value, propertyName) => this.props.setGameInfo(value, propertyName)}
                    processError = {(errorObj) => this.props.processError(errorObj)}
                />
            :
                null
            }

            {this.nextProcess === "savings" ?
                <SetupSavingsModal show={this.openProcess}
                    screenSize={this.props.screenSize}
                    onClose={(returnVal) => this.closeModal(returnVal)}
                    gameInfo = {() => this.props.gameInfo()}
                    setGameInfo = {(value, propertyName) => this.props.setGameInfo(value, propertyName)}
                    processError = {(errorObj) => this.props.processError(errorObj)}
                />
            :
                null
            }

            {this.nextProcess === "creditCardSetup" ?
                <SetupCreditCardModal show={this.openProcess}
                    screenSize={this.props.screenSize}
                    changeType= {this.changeType}
                    onClose={(returnVal) => this.closeModal(returnVal)}
                    gameInfo = {() => this.props.gameInfo()}
                    setGameInfo = {(value, propertyName) => this.props.setGameInfo(value, propertyName)}
                    processError = {(errorObj) => this.props.processError(errorObj)}
                />
            :
                null
            }
            
            {this.nextProcess === "creditCardStatement" ?
                <CreditCardStatementModal show={this.openProcess}
                    screenSize={this.props.screenSize}
                    onClose={(returnVal) => this.closeModal(returnVal)}
                    gameInfo = {() => this.props.gameInfo()}
                    onChangePolicy={(changeType) => this.changeCreditCardPolicy(changeType)}
                    setGameInfo = {(value, propertyName) => this.props.setGameInfo(value, propertyName)}
                    processError = {(errorObj) => this.props.processError(errorObj)}
                />
            :
                null
            }
           
        </div>
    </div>   
</>
  }

closeModal(returnVal) {
    if (returnVal !== "none" && returnVal !== "back"){
       alert("Well done! The setup has been completed successfully.")
    }
    this.openProcess = false;
    this.nextProcess = "";
    if (returnVal !== "back"){
        this.props.history.push('/game-board');
    }
}


}

export default withRouter(UnlockedFeatures);