import friendVolunteerActivityDetailDialogDataset from '../DatasetsDialog/friend_volunteer_activity_detail_dialog';
import friendVacationActivityDetailDialogDataset from '../DatasetsDialog/friend_vacation_activity_detail_dialog';
import friendHaveFunActivityDetailDialogDataset from '../DatasetsDialog/friend_haveFun_activity_detail_dialog';
import shuffleArray from '../../../objects/CommonUse/shuffleArray';

export const retrieveDetailActivityDialog = (activityCategory, activityPreference, nbrDetailComments) => {
    let dialogDetailComments = "";
    switch (activityCategory){
        case "Volunteer":
            dialogDetailComments = friendVolunteerActivityDetailDialogDataset;
            break;
        case "HaveFun":
            dialogDetailComments = friendHaveFunActivityDetailDialogDataset
            break;
        case "Vacation":
            dialogDetailComments = friendVacationActivityDetailDialogDataset;
            break;
    }
    
    let commentArr = [];
    let detailCommentObj = dialogDetailComments.find(elem => elem.activityPreference === activityPreference);  
    let commentTableShuffled = shuffleArray(detailCommentObj.comments);
    let nbrComments = nbrDetailComments;
    if (commentTableShuffled.length < nbrDetailComments){
        nbrComments = commentTableShuffled.length;
    }
    for (let j=0; j<nbrComments; j++){
        commentArr.push(commentTableShuffled[j]);
    }
    return commentArr;
   
}
export default retrieveDetailActivityDialog;

