import {Component} from 'react';
import ImageCard from '../shared-components/image_card';
import {shuffleArray} from '../../objects/CommonUse/shuffleArray';
import Button from 'react-bootstrap/Button';


class JigsawPuzzleModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         setupComplete: false,
         puzzleChoice: 99,
         pictureArr: [],
         pictureRow: [],
         imgDir: "./assets/Activities/HaveFun/",
         saveClicksCount: 0,
         puzzleName: "",
         puzzleCompleted: false,
         nbrMoves: 0,
         buttonDisabled: false,
         puzzleStarted: false,
         score: this.props.score,
         totalPoints: 0,
         nbrPuzzlesCompleted: 0,
         nbrPuzzlesAttempted: 0,
         bestScore: 0,
         buttonType: "start",
         displayPicture: false,
       };    
  }
 
 selectPuzzle(){
     
      this.state.puzzleStarted = true;
      this.state.nbrPuzzlesAttempted += 1;
      this.congratsMsg = "";
      let maxPuzzles = this.props.puzzleArr.length;
      this.state.puzzleChoice = this.state.puzzleChoice + 1;
      if (this.state.puzzleChoice > maxPuzzles){
           this.state.puzzleChoice = 0;
      }
      this.state.pictureArr = [];
      this.state.pictureRow = [];
      this.state.nbrMoves = 0;
      this.state.puzzleCompleted = false;
      let imgIndx = 0;
      this.chosenPuzzle = this.props.puzzleArr[this.state.puzzleChoice];
      this.puzzleCompletedPicture = this.state.imgDir + this.props.pictureDir + "/" + 
        this.chosenPuzzle.puzzleName;
      this.rowCount = this.chosenPuzzle.rows;
      this.columnCount = this.chosenPuzzle.columns;
      this.state.puzzleName = this.chosenPuzzle.puzzleName;
      this.pictureArray = shuffleArray(this.chosenPuzzle.imageList);
      this.setImageClass();
      for (let i=0; i < this.rowCount; i++){
          for (let j=0; j<this.columnCount; j++){
              let imageURL = this.state.imgDir + this.props.pictureDir + "/" + 
                this.chosenPuzzle.imageList[imgIndx].imageURL
              let pictureObj = {imageURL: imageURL, 
                imageClass: this.imageSize, imageRow: this.pictureArray[imgIndx].row,
                imageColumn: this.pictureArray[imgIndx].column};
              this.state.pictureRow.push(pictureObj);
              imgIndx = imgIndx + 1;
          }
          this.state.pictureArr.push(this.state.pictureRow);
          this.state.pictureRow = [];
      }
      this.state.setupComplete = true;
      this.state.buttonDisabled = true;
      this.props.startTimer();
      }

    setImageClass(){
        let totalNbrPieces = this.rowCount * this.columnCount;
        if (totalNbrPieces <= 16){
            if (this.props.screenSize == "narrow" || this.props.screenSize == "medium"){
              this.imageSize = "small_size_img2";
            }else{
                this.imageSize = "medium_size_img";
            }
        }else{
            if (this.props.screenSize == "narrow" || this.props.screenSize == "medium"){
              this.imageSize = "very_small_img2";
            }else{
                this.imageSize = "small_size_img3";
            }
        }
    }

    onClick(picturesList){
      if (this.props.stopActivity == true){ 
          this.congratsMsg = "Time has runout for this puzzle.  Want to try another one?"
          this.finishPuzzle(); 
          this.state.nbrMoves = 0;
          this.state.saveClicksCount = 0;
      }else{
         if (this.state.puzzleCompleted == false){
            this.processPictureClicked(picturesList);
         }
      }
      if (this.state.nbrPuzzlesCompleted >= 3){
          this.state.buttonType = "Finish"
      }

    }

    processPictureClicked(picturesList){
      this.state.saveClicksCount += 1;
      if (this.state.saveClicksCount == 1){
          this.save1Obj = this.findPicture(picturesList);
          let imageClass = "picture-has-been-clicked " + this.imageSize;
          this.setClass(this.save1Obj, imageClass);
      }
      if (this.state.saveClicksCount == 2){
          this.saveClick2 = picturesList;
          this.save2Obj = this.findPicture(picturesList);
          // if second picture is same as the first picture then reset the
          // opacity on the picture.
          if (this.save1Obj == this.save2Obj){
              this.setClass(this.save1Obj, this.imageSize)
          }else{
              this.state.nbrMoves += 1;
              this.swapPictures();
              this.setClass(this.save1Obj, this.imageSize)
              this.setClass(this.save2Obj, this.imageSize)
              this.checkIfDone();
              if (this.state.puzzleCompleted == true){
                  this.congratsMsg = "Congratulations!! You completed the puzzle in " +
                      this.state.nbrMoves + " moves." 
                  this.finishPuzzle();
                  this.nbrPoints = this.totalNbrPuzzlePieces - (+this.state.nbrMoves/2).toFixed(0);
                  this.state.totalPoints = this.nbrPoints + this.state.totalPoints;
              }
          }
          this.state.saveClicksCount = 0;
      }
  }

  finishPuzzle(){
      this.props.stopTimer();
      this.state.nbrPuzzlesCompleted += 1;
      this.nextPuzzleMsg =  "Click the 'New Puzzle' button to get a new puzzle to solve."
      this.state.buttonDisabled = false;
      this.totalNbrPuzzlePieces = +this.columnCount * +this.rowCount;
      this.state.bestScore = +this.state.bestScore + +(+this.totalNbrPuzzlePieces / 2).toFixed(0);
      this.nbrPoints = 0;
      if (this.props.friendInfo.friendImgURL != undefined){
          this.state.displayPicture = true;
          let indx = this.state.nbrPuzzlesCompleted - 1;
          this.comment = this.props.friendInfo.commentTable[indx];
      }
   
  }

  finishThisActivity(){
      this.state.finishText = "You have completed all puzzles for this round.  Click the finish button."
      this.state.score.type = "finish";
      this.setScoreValues();
      this.props.onGameOver(this.state.score);
  }

  doneButtonClicked(){
      this.state.score.type = "done";
      this.setScoreValues();
      this.props.onGameOver(this.state.score);
  }

  setScoreValues(){
      this.state.score.points = this.state.totalPoints;
      this.state.score.gamesRemaining = this.props.score.gamesRemaining - this.state.nbrPuzzlesCompleted;
      if (this.state.bestScore > this.state.totalPoints){
        this.state.score.maxScore = this.state.bestScore;
      }else{
        this.state.score.maxScore = this.state.totalPoints;
      }
      this.state.score.nbrAttempted = this.state.nbrPuzzlesAttempted;
      if (this.state.score.points >= this.state.score.maxScore &&
          this.state.score.gamesRemaining <= 0){
          this.state.score.newLevelEarned = true;
      }else{
          this.state.score.newLevelEarned = false;
      }
  }

  findPicture(picturesList){
    var pictureRowArr = [];
    var pictureArrObj = '';
    for (let i=0; i < this.rowCount; i++){
      pictureRowArr = this.state.pictureArr[i];
      for (let j=0; j<this.columnCount; j++){
          pictureArrObj = pictureRowArr[j];
          if (pictureArrObj.imageURL == picturesList.imageURL){
              let saveObj = {imageURL: picturesList.imageURL, imageRow: picturesList.imageRow,
                imageColumn: picturesList.imageColumn, currentColIdx: j, currentRowIdx: i};
              return saveObj;
          }
      }
    }
  }

  setClass(pictureObj, newClass){
      var pictureRowArr = [];
      pictureRowArr = this.state.pictureArr[pictureObj.currentRowIdx];
      pictureRowArr[pictureObj.currentColIdx].imageClass = newClass;
      this.state.pictureArr[pictureObj.currentRowIdx] = pictureRowArr;
  }

  swapPictures(){
      var pictureRow = [];
      // move 1st selected picture info to 2nd picture position
      pictureRow = this.state.pictureArr[this.save2Obj.currentRowIdx];
      pictureRow[this.save2Obj.currentColIdx].imageURL = this.save1Obj.imageURL;
      pictureRow[this.save2Obj.currentColIdx].imageRow = this.save1Obj.imageRow;
      pictureRow[this.save2Obj.currentColIdx].imageColumn = this.save1Obj.imageColumn;
      this.state.pictureArr[this.save2Obj.currentRowIdx] = pictureRow;
      // move 2nd selected picture info to 1st picture position
      pictureRow = this.state.pictureArr[this.save1Obj.currentRowIdx];
      pictureRow[this.save1Obj.currentColIdx].imageURL = this.save2Obj.imageURL;
      pictureRow[this.save1Obj.currentColIdx].imageRow = this.save2Obj.imageRow;
      pictureRow[this.save1Obj.currentColIdx].imageColumn = this.save2Obj.imageColumn;
      this.state.pictureArr[this.save1Obj.currentRowIdx] = pictureRow;
   }
 
  checkIfDone(){
      this.state.puzzleCompleted = true;
      var pictureRowArr = [];
      for (let i=0; i<this.rowCount; i++){
          pictureRowArr = this.state.pictureArr[i];
          for(let j=0; j<this.columnCount; j++){
              if (pictureRowArr[j].imageRow != i+1 ||
                  pictureRowArr[j].imageColumn != j+1){
                    this.state.puzzleCompleted = false;
                    break;
              }
          }
      }
  }
 
  render() { 
     
      this.pictureOutput = [];
      for (let i=0; i<this.state.pictureArr.length; i++){
          this.rowOfPictures = this.state.pictureArr[i];
          this.pictureDisplay= this.rowOfPictures.map((picturesList, key)  =>
              <ImageCard imageInfo={picturesList} key={key}
               onClick={() => this.onClick(picturesList)}/> 
              );
          this.pictureOutput.push(this.pictureDisplay)
      }
   
  return <>
   <span>
        <div>{this.state.finishText}</div>
        <div className="row">
          <div className="col-sm-2">
             {this.state.buttonType == "start" ?
                <Button className=" p-0 text-right" variant="primary"  disabled={this.state.buttonDisabled} 
                    onClick={() => this.selectPuzzle()}>
                        New Puzzle   
                </Button>
              :
              <span>
               
                <Button className="p-0 text-right" variant="primary" 
                    onClick={() => this.finishThisActivity()}>
                            Finish
                </Button>
              </span>
            }
          </div>
          <div className="col-sm-2">
              <Button className="p-0 text-right" variant="primary" 
                  onClick={() => this.doneButtonClicked()}>
                    Done
              </Button>
          </div>
          <div className="col-sm-7">
              <div className="font-weight-bold mt-2">{this.congratsMsg}</div>
          </div>
        </div>
        {this.state.puzzleStarted ?
          <span>
            
            <div className="font-weight-bold mt-2">Nbr Points Earned: {this.nbrPoints} &nbsp;&nbsp;&nbsp;
              Cumulative Points: {this.state.totalPoints}&nbsp;&nbsp;&nbsp;
              Puzzles Completed: {this.state.nbrPuzzlesCompleted}</div>
                <div className="col-sm-9">
                  <div>
                    {this.pictureOutput[0]}
                  </div>
                  <div>  
                    {this.pictureOutput[1]}
                  </div>
                  {this.chosenPuzzle.rows > 2 ?
                      <div>
                        {this.pictureOutput[2]}
                      </div>
                  :
                      null
                  }
                  {this.chosenPuzzle.rows > 3 ?
                      <div>
                          {this.pictureOutput[3]}
                      </div>
                  :
                      null
                  }
                   {this.chosenPuzzle.rows > 4 ?
                      <div>
                          {this.pictureOutput[4]}
                      </div>
                  :
                      null
                  }
              
            </div>
            <div>
                <img src={this.puzzleCompletedPicture} 
                     className="puzzle-completed-picture-position  completed-picture-size" alt="puzzle picture"/>
            </div>
          </span>
        :
            null
        }

        
        {this.state.displayPicture   ?
                  <span>
                      <div>
                          <img src={this.props.friendInfo.friendImgURL}  className="friend-puzzle-position-img" 
                              alt="friend picture"/>
                      </div>  
                      <div className="speech-puzzle-activity right" >
                          {this.comment}
                      </div>
                  </span>
                :
                    null
                }
           
      </span>
    </>
  }

 
}
export default JigsawPuzzleModal;