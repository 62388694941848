
export const setBlackOutSpaces = (activeBoard, goodPath, blockOutImg, gridWidth, gridHeight) => {
    var elementCheck;
    var cell = "";
    for (let row=1; row <= gridHeight; row++){
        for (let column = 1; column <= gridWidth; column++){
              cell = row + "|" + column;
              if (goodPath.includes(cell)){
                elementCheck = document.getElementById(`${cell}`);
                if (elementCheck !== undefined &&
                    elementCheck !== null ){
                      elementCheck.classList.add(blockOutImg);
                      let removeIdx = activeBoard.indexOf(cell);
                      activeBoard.splice(removeIdx,1);
                }
              }else{
                //  check to see if all blocks surrounding this one are blocked
                let topCell = ""
                if (row > 1){
                  topCell = (+row - 1) + "|" + column;
                }
                let leftCell = "";
                if (column > 1){
                  leftCell = row + "|" + (+column - 1);
                }
                let rightCell = "";
                if (column < gridWidth){
                  rightCell = row + "|" + (+column + 1);
                }
                let bottomCell = "";
                if (row < gridHeight){
                  bottomCell = (+row + 1) + "|" + column;
                }
                if ((goodPath.includes(topCell) || topCell == "") &&
                    (goodPath.includes(leftCell) || leftCell == "") &&
                    (goodPath.includes(rightCell) || rightCell == "") &&
                    (goodPath.includes(bottomCell) || bottomCell == "")){
                      elementCheck = document.getElementById(`${cell}`);
                      if (elementCheck !== undefined &&
                          elementCheck !== null ){
                            elementCheck.classList.add(blockOutImg);
                            let removeIdx = activeBoard.indexOf(cell);
                            activeBoard.splice(removeIdx,1);
                      }
                }
              }
                  
        }
    }
    return activeBoard;
      
  }
   
   


