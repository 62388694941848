import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {retrieveGameChoiceDialog} from './objects/retrieve_game_choice_dialog';
import {getDialogForFriendList} from './objects/get_dialog_for_friendList';
import FriendAdviceDisplayCard from './friend_advice_display_card';
import { setFriendDisplayClasses } from '../../objects/CommonUse/set_friend_display_classes';
import {splitActivityByFriend} from './objects/split_activity_by_friend';
import {retrieveActivityDialog} from './objects/retrieve_activity_dialog';

class FriendDisplayModal extends Component {
  constructor(props) {
    super(props);
    this.gameInfo = this.props.gameInfo();
    this.state={
      loadingDialog: true,
      dialogData: null,
     
          };    
      this.getPicturesForFriends();
      this.getGameChoiceDialogForFriends();
  }

  getPicturesForFriends(){
       this.friendTable = this.gameInfo.friendInfo;
       this.friendArr = [];
       let friendNbr = 0;
       for (let i=0; i<this.friendTable.length; i++){
        let playerFriendInfo = this.gameInfo.gameData.friendList;
        const playerFriend = playerFriendInfo.find(elem => elem.name === this.friendTable[i].friendName);
        if (playerFriend.introDate != ""){
              friendNbr = friendNbr + 1;  
              let displayClasses = setFriendDisplayClasses(friendNbr);
              let activityPreference = this.friendTable[i].activityPreference; 
              let friendObj = {friendName: this.friendTable[i].friendName, 
                  friendImgURL: this.friendTable[i].friendImgURL,
                  comment: "", acceptDecline: "", activityPreference: activityPreference, opinionSw: false, 
                  className1: displayClasses.displayClass1, className2: displayClasses.displayClass2, 
                  opinionClass: displayClasses.opinionClass, commentTable: []};
              this.friendArr.push(friendObj);
          }
       } 
  }

  getGameChoiceDialogForFriends(){
      if (this.props.gameProcess == "GameViews"){
          debugger
          let dialogTable = retrieveGameChoiceDialog(this.props.askAFriendInfo);
          debugger
          this.friendArr = getDialogForFriendList(dialogTable, this.friendArr);
      }else{
          let dialogTable = retrieveActivityDialog(this.props.askAFriendInfo.activityCategory);
          this.friendArr = splitActivityByFriend(dialogTable, this.friendArr); 
      }
  }
 
  displayFriendOpinion(friendNbr){
       for (let i=0; i<this.friendArr.length; i++){
          this.friendArr[i].opinionSw = false;
       }
       this.friendArr[friendNbr].opinionSw = true;
  }
  
  render() { 
        this.friendDisplay = this.friendArr.map((friend, index) =>
          <FriendAdviceDisplayCard index={index} friendDisplay={friend} 
              key={index}
              onSelected={() => this.displayFriendOpinion(index)}
              onFriendActivitySelected={() => this.props.onSelectFriend(index)}/>
    );

  return <>
        <Modal show={this.props.show} onHide={() => this.props.onClose()} size="med" animation={false}>
          <Modal.Header className="gameModalHdrFtrBackground mb-0" closeButton>
              <Modal.Title>
                    <div>Ask a Friend</div>
                    <h6>{this.props.title} - {this.props.roommates}</h6>
              </Modal.Title>
          </Modal.Header>
          <Modal.Body >
              <span className="friend-display-area">
                {this.friendDisplay}   
                <div className="friend-display-area"></div>

              </span>
           </Modal.Body>
          <Modal.Footer className="gameModalHdrFtrBackground text-right">
          <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-0"/>
            <Button className="mr-1" variant="secondary"   onClick={() => this.props.onClose()}>
                  Close
            </Button>
          </Modal.Footer>
          </Modal>  
    
    </>
  }

 
}
export default FriendDisplayModal;