import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Component} from 'react';


class LetterCard extends Component {
       
    constructor(props) {
        super(props);
        this.state = {underscore: "./assets/Special/Underscore.png"
        }; 
        
    }
           
    render() {
    return <>
        <span className={this.props.imageInfo.letterClass}>
            {this.props.imageInfo.letter}
        </span>
           
    </>
    }

    

}

export default LetterCard;