import { editText } from './editText';
import { isNumeric } from '../../../objects/CommonUse/checkIfNumeric';
import { editClosureText } from './editClosureText';
import { editResponseCategory } from './editResponseCategory';
import { editOnLit } from './editOnLit';
import { editType } from './editType';
import { editPossibleValues } from './editPossibleValues';
import {editCondName} from "./editCondName"
import { editConditionConnector } from './editConditionConnector';
import { editConditionClass } from './editConditionClass';
import { editSelectMonthArr } from './editSelectMonthArr';
import EventCategoryList from '../../game-views/datasets/event_category_list';
import { determineTextMaxLength } from './determineTextMaxLength';
import { editHeaderBackground } from './editHeaderBackground';
import EventsImageDirectoryDataset from '../datasets/events_image_directory_dataset';

export const editEventsCollection = (eventsData, allConditionNames, careerConditionNames) => {

    var editErrorArray=[];
    var errorObj="";
    var i;
     
    //edit all values on the event header data
   errorObj = editText(eventsData.title, "Header", "Event Title", 50);
    if (errorObj.length !== 0){
      editErrorArray.push(errorObj);
    }

    let buttonList = [];
    for (let i=0; i<eventsData.responses.length; i++){
         buttonList.push(eventsData.responses[i].btnText);
    }
    let textLengthObj = determineTextMaxLength(eventsData.title, buttonList );
    let descriptionMaxLength = textLengthObj.descriptionMaxLength;
    let closureTextMaxLength = textLengthObj.closureTextMaxLength;
    errorObj = editText(eventsData.description, "Header", "Event Description", descriptionMaxLength);
    if (errorObj.length !== 0){
      editErrorArray.push(errorObj);
    }
    let validFrequency = isNumeric(eventsData.frequency);
    if (validFrequency === false){
       errorObj = {fieldClass:"Header", fieldName: "Frequency", 
         fieldValue: eventsData.frequency,
         message: "Frequency must be numeric"};
       editErrorArray.push (errorObj);
    }
      
    errorObj = editConditionConnector(eventsData.conditionConnector, eventsData.conditionArr, "Header");
    if (errorObj.length !== 0){
        editErrorArray.push(errorObj)
    }

   
    for (i=0; i < eventsData.conditionArr.length; i++){
        errorObj = editCondName(eventsData.conditionArr[i], allConditionNames, false, "Header", "Batch", "Condition"); 
        if (errorObj.length !== 0){
              editErrorArray.push(errorObj);
        }
    }
      
    for (i=0; i< eventsData.skipConditionArr.length; i++){
        errorObj = editCondName(eventsData.skipConditionArr[i], allConditionNames, false, "Header", "Batch", "Skip Condition"); 
        if (errorObj.length !== 0){
          editErrorArray.push(errorObj);
        }
      }
    
     if (eventsData.careerTag !== undefined){
        errorObj = editCondName(eventsData.careerTag, careerConditionNames, false, "Header", "batch", "Career Tag");
        if (errorObj.length !== 0){
            editErrorArray.push(errorObj);
        }
    }else{
        errorObj = {fieldClass:"Header", fieldName: "CareerTag", 
        fieldValue: eventsData.careerTag,
        message: "CareerTag is Undefined"};
        editErrorArray.push (errorObj);
    }

    errorObj =  editHeaderBackground(eventsData.backgroundType, 'batch', "Header");
    if (errorObj.length !== 0){
       editErrorArray.push(errorObj);
     }
   
    if (eventsData.imageDir !== undefined && eventsData.imageDir !== ""){
        let validImageDirList = EventsImageDirectoryDataset;
        let imageDirectoryObj = validImageDirList.find(elem => elem.value === eventsData.imageDir);
        if (imageDirectoryObj == undefined){ 
            let errorObj = {fieldClass: "Header", fieldName: "Image Directory", fieldValue: eventsData.imageDir,
                  message: "Image Directory not found on validImageDirList"};
            editErrorArray.push(errorObj);
        }
    }

    errorObj = editSelectMonthArr(eventsData.selectMonthArr, "Header");
    if (errorObj.length !== 0){
        editErrorArray.push(errorObj);
    }

    //  edit eventCategory
    if (eventsData.eventCategory === undefined){
        let errorObj = {fieldClass: "Header", fieldName: "Event Category", fieldValue: eventsData.eventCategory,
        message: "Event Category is undefined."};
          editErrorArray.push(errorObj);
    }else{
        let eventCategories = EventCategoryList;
        let categoryObj = eventCategories.find(elem => elem.category === eventsData.eventCategory);
        if (categoryObj === undefined) {
            let errorObj = {fieldClass: "Header", fieldName: "Event Category", fieldValue: eventsData.eventCategory,
                message: "Must be in the Event Category List dataset."};
            editErrorArray.push(errorObj);
    };
  }

    if (eventsData.gamePhase === "all" ||
        eventsData.gamePhase === "1" ||
        eventsData.gamePhase === "2"){
          //   the gamePhase is valid
    }else{
          let errorObj = {fieldClass: "Header", fieldName: "Game Phase", fieldValue: eventsData.gamePhase,
            message: "Must be '1', '2', or 'all'"};
            editErrorArray.push(errorObj);
   }
    
    //edit all values on the responses 'main' object
    for (i=0; i<eventsData.responses.length; i++){

      let responses = eventsData.responses[i];
      let responseMain = "response";
      errorObj = editText(responses.btnText, "Button Text", 50, responseMain);
      if (errorObj.length !== 0){
         editErrorArray.push(errorObj);
      }
    
      let rltnshpScore = 0
      if (responses.rltnshpScore !== undefined){
          rltnshpScore = responses.rltnshpScore;
      }
      if (rltnshpScore === 0 && eventsData.imageDir === "friends"){
          let errorObj = {fieldClass: "Button Text", fieldName: "Relationship Points", fieldValue: rltnshpScore,
              message: "Cannot be 0 when image directory is friends"};
          editErrorArray.push(errorObj);
     }
      
       
      // edit all values on the responses.possibleEffects object
      var j;
      for (j=0; j<responses.possibleEffects.length; j++){
         let possible = responses.possibleEffects[j];
         let responseLit = "response" + (j+1);
         errorObj = editClosureText(possible.closureText, possible.possibleValues, responseLit, closureTextMaxLength);
         if (errorObj.length !== 0){
            editErrorArray.push(errorObj);
          }
         errorObj =  editResponseCategory(possible.category, 'batch', responseLit);
         if (errorObj.length !== 0){
            editErrorArray.push(errorObj);
          }
         errorObj = editOnLit(possible.on, possible.category, 'batch', responseLit);
         if (errorObj.length !== 0){
            editErrorArray.push(errorObj);
          }
         errorObj = editType(possible.type, 'batch', responseLit);
         if (errorObj.length !== 0){
            editErrorArray.push(errorObj);
          }
         let validDuration = isNumeric(possible.duration);
         if (validDuration === false){
            errorObj = {fieldClass: responseLit, fieldName: "Duration", 
               fieldValue: possible.duration,
               message: "Duration must be numeric"};
               editErrorArray.push (errorObj);
         }
         let validOdds = isNumeric(possible.odds);
         
         if (validOdds === false){
            errorObj = {fieldClass: responseLit, fieldName: "Odds", 
               fieldValue: possible.odds,
               message: "Odds must be numeric"};
               editErrorArray.push (errorObj);
         }
         
        errorObj = editCondName(possible.triggerCondition, allConditionNames, "Response Trigger Condition",
           false, "Response"); 
        if (errorObj.length !== 0){
          editErrorArray.push(errorObj);
        }
        
        errorObj = editCondName(possible.deleteCondition, allConditionNames, "Response Delete Condition", 
          false, "Response"); 
        if (errorObj.length !== 0){
          editErrorArray.push(errorObj);
        }
        
        errorObj = editConditionClass(possible.deleteClass, "Response"); 
        if (errorObj.length !== 0){
          editErrorArray.push(errorObj);
        }
         
        errorObj = editPossibleValues(possible.possibleValues, responseLit);
        if (errorObj.length !== 0){
            editErrorArray.push(errorObj);
        }

        if (possible.moodPoints === undefined){
            let errorObj = {fieldClass: "Response", fieldName: "Mood Points", fieldValue: possible.moodPoints,
                message: "Mood Points are undefined"};
            editErrorArray.push(errorObj);
        }else{
            let validNumeric = isNumeric(possible.moodPoints);
            if (validNumeric === false){
                let errorObj = {fieldClass: "Response", fieldName: "Mood Points", fieldValue: possible.moodPoints,
                    message: "Must be numeric"};
                editErrorArray.push(errorObj);
            }else{
               if (+possible.moodPoints > 10 || +possible.moodPoints < -10){
                    let errorObj = {fieldClass: "Response", fieldName: "Mood Points", fieldValue: possible.moodPoints,
                      message: "Mood Points outside range of valid values."};
                    editErrorArray.push(errorObj);
               }
            }
     }
        
      } 
   }
            
   return editErrorArray;
   
}





