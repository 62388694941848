import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button';
import DragNDropColumnCommon from './drag_n_drop_column_common'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { findDataObject } from '../../objects/CommonUse/find_data_object';
import { calculateDNDScore } from "./objects/calculateDNDScore";
import { createNewDragNDropDataset } from './objects/createNewDragNDropDataset';

const Container = styled.div`display : flex;`

const DragNDropCommonModal = (props) => {
  
  const [data, setData] = useState(createNewDragNDropDataset(props.DNDParameters, props.dataset, 
      props.pictureDir));
  const [button, setButton] = useState("check");
  const [message, setMessage] = useState("");
  const [correctMsg]=useState(props.correctMsg);
  const [msgClass, setMsgClass] = useState("");
  const [score, setScore]=useState(props.score.points);
  const [nbrGames, setNbrGames]=useState(props.nbrGames - props.score.gamesRemaining);
  const [maxNbrGames] = useState(props.nbrGames);
  const [nbrWrong, setNbrWrong] = useState(0);
  const [totalNbrWrong, setTotalNbrWrong] = useState(0);
  const [scoreObj, setScoreObj] = useState(findDataObject("activityScore").score);
  const [displayPicture, setDisplayPicture] = useState(false);
  const [commentTable] = useState(props.friendInfo.commentTable);
  const [commentNbr, setCommentNbr] = useState(0);
  const [comment, setComment] = useState("");
  const [maxScore, setMaxScore] = useState(props.score.maxScore);
  const [nbrItemsComplete, setNbrItemsComplete] = useState((props.nbrGames - props.score.gamesRemaining) * 
      props.DNDParameters.nbrColumns);
  const [incrementNbr] = useState(props.DNDParameters.nbrColumns)
    
  const newData = () =>{
    let data = createNewDragNDropDataset(props.DNDParameters, props.dataset, props.pictureDir);
    setData(data);  
    setButton("done");
    setMessage("Keep up the good work!");
    setMsgClass("text-green font-weight-bold");
    setDisplayPicture(false);
}

const calcScore = () => {
   let messageObj = calculateDNDScore(data, props.cookieNamePfx, props.scoreType, props.modalType);
   let totNbrGames = 0;
   let allGamesNbrWrong = 0;
   let totalScore = 0;
 
   if (messageObj.correct == true){
       setMsgClass("text-green font-weight-bold");
       let finishMessage = messageObj.message + " " + correctMsg;
       setMessage(finishMessage);
       setButton("start");
       let newScore = messageObj.score - (nbrWrong * 2);
       setScore(score + newScore);
       totalScore = maxScore + messageObj.score;
       setMaxScore(totalScore);
       totNbrGames = nbrGames + 1;
       allGamesNbrWrong = totalNbrWrong + nbrWrong;
       setTotalNbrWrong(allGamesNbrWrong);
       setNbrGames(nbrGames + 1);
       let itemsComplete = (totNbrGames * incrementNbr);
       setNbrItemsComplete(itemsComplete);
       if (commentTable != undefined){
          setComment(commentTable[commentNbr]);
          if (commentNbr < commentTable.length - 1){
              setCommentNbr(commentNbr + 1);
          }else{
              setCommentNbr(0);
          }
          setDisplayPicture(true);
        }
   }else{
       setMessage(messageObj.message);
       setMsgClass("text-danger font-weight-bold")
       setButton("check");
       setNbrWrong(nbrWrong + 1);
   }
   if (totNbrGames >= maxNbrGames){
        if (totNbrGames >= maxNbrGames + 1){
            if (allGamesNbrWrong == 0){
                scoreObj.newLevelEarned = true;
            }else{
                scoreObj.newLevelEarned = false;
            }  
            let outScore = score + messageObj.score;
            scoreObj.type = "finish";
            scoreObj.points = outScore;
            scoreObj.maxScore = totalScore;
            scoreObj.gamesRemaining = maxNbrGames - totNbrGames;
            scoreObj.nbrAttempted = totNbrGames;
            setScoreObj(scoreObj);
            props.onGameOver(scoreObj);
        }
   }
}

function endGame(){
  scoreObj.type = "done";
  scoreObj.points = score;
  scoreObj.maxScore = maxScore;
  scoreObj.gamesRemaining = maxNbrGames - nbrGames;
  scoreObj.nbrAttempted = nbrGames;
  setScoreObj(scoreObj);
  props.onGameOver(scoreObj);
  
}

  const onDragEnd = result => {
    
    //if freeze flag is true, then no moves are allowed
    if (props.freezeFlag != undefined && props.freezeFlag == true){
          return
    }
   
    const { destination, source, draggableId, type } = result;
    //If there is no destination
    if (!destination) { return }

    //If source and destination is the same
    if (destination.droppableId === source.droppableId && destination.index === source.index) { return }

    //Anything below this happens if you're dragging loan description
    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    //reset if dragging a column
    if (type === 'column') {
      return;
    }
    
    //If dropped inside the same column
    if (start === finish) {
      const newTaskIds = Array.from(start.itemIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);
      const newColumn = {
        ...start,
        itemIds: newTaskIds
      }
      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn
        }
      }
      setData(newState)
      return;
    }

    //If dropped in a different column
    const startTaskIds = Array.from(start.itemIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      itemIds: startTaskIds
    }

    const finishTaskIds = Array.from(finish.itemIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      itemIds: finishTaskIds
    }

    //if more than 'maxNbr' items dragged to a column then return (except column 1)
    let maxNbr = parseFloat(props.maxNbr);
    if (!isNaN(maxNbr) &&
        finishTaskIds != undefined && finish.id != "column-1" && finishTaskIds.length > maxNbr) {
          return; 
    } 

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    setData(newState)
  }
  
  return (
       <div>
          
          <h3 className="w-100">{props.modalTitle} - Level {props.levelNbr} </h3>
           
                <span>
                   <div className="row">
                        <div className="col-8">
                              <div>{props.introText}</div>
                              <div className={msgClass}>{message}</div>
                        </div>
                        <div className="col-4"> 
                          <div className = "row">
                                <div className="col-6 font-weight-bold">Total Score:</div>
                                <div className="col-4">{score}</div>
                          </div>
                          <div className = "row">
                                <div className="col-6 font-weight-bold">{props.completedLit}:</div>
                                <div className="col-4">{nbrItemsComplete}</div>
                          </div>   
                          {button == "start" ?
                               <Button className="text-right" variant="primary"  onClick={() => newData()}>
                                    Start
                                </Button>
                          :
                                <Button className="text-right" variant="primary"  onClick={() => calcScore()}>
                                    Check
                                </Button>
                          }
                          <Button className="text-right ml-4" variant="primary"  onClick={() => endGame()}>
                              Done
                          </Button>
                        </div>
                    </div> 
                </span>
             
                         
               <div className={props.backgroundClass}>

                 
                              
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='all-columns' direction='horizontal' type='column'>
                      {(provided) => (
                        <Container {...provided.droppableProps} ref={provided.innerRef}>
                          {data.columnOrder.map((id, index) => {
                            const column = data.columns[id]
                            const items = column.itemIds.map(itemId => data.items[itemId])
                          return <DragNDropColumnCommon key={column.id} column={column} items={items} 
                              index={index} cookieNamePfx={props.cookieNamePfx} itemClass={props.itemClass}
                              columnClass={props.columnClass} columnTextClass={props.columnTextClass}
                              multipleColumns={props.multipleColumns}
                              amountSw={props.amountSw} pictureSw={props.pictureSw}/>
                            })}
                          {provided.placeholder}
                        </Container>
                   )}
                  </Droppable>
                </DragDropContext>

                {displayPicture   ?
                  <span>
                      <div>
                          <img src={props.friendInfo.friendImgURL}  className="friend-position-img" alt="friend picture"/>
                      </div>  
                      <div className="speech-activity right" >
                          {comment}
                      </div>
                  </span>
                :
                    null
                }
              </div>
      </div>
      );
}
  

export default DragNDropCommonModal