import React from 'react';
import { withRouter } from "react-router";
import LinkCard from '../shared-components/link_card';
import MoneyMgmtBasics from './money_mgmt_basics';
import Priorities from './priorities';
import ChoosingCareer from './choosing_career';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';


class Level1Challenges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextProcess: "",
            modalOpen: false,
            prevProcess: this.props.prevProcess,
            category1Complete: false,
            category2Complete: false,
            category3Complete: false,
            category1Name: "MoneyMgmt",
            category2Name: "Priorities",
            category3Name: "ChoosingCareer"
        }    
        this.setCompleted();
    }


setCompleted(){
    let exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.state.category1Name);
    if (exerciseInfo != undefined && exerciseInfo.complete == true){
        this.state.category1Complete = true;
    }
    exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.state.category2Name);
    if (exerciseInfo != undefined && exerciseInfo.complete == true){
        this.state.category2Complete = true;
    }
    exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.state.category3Name);
    if (exerciseInfo != undefined && exerciseInfo.complete == true){
        this.state.category3Complete = true;
    }
}

selectMoneyMgmtChallenge() {
    if (this.state.category1Complete == false){
        this.state.nextProcess = this.state.category1Name;
        this.state.modalOpen = true;
    }
}

selectPriorityChallenge() {
    if (this.state.category2Complete == false){
        this.state.nextProcess = this.state.category2Name;
        this.state.modalOpen = true;
    }
}

selectChoosingCareerChallenge() {
    if (this.state.category3Complete == false){
        this.state.nextProcess = this.state.category3Name;
        this.state.modalOpen = true;
    }
}

closeModal(){
    this.state.modalOpen = false;
}

  render() {
           if (this.state.nextProcess != ""){
                sessionStorage.setItem("GameViewSubProcess", this.state.nextProcess)
           }
           this.className = "mb-5 input-page-base-card";
           let screenParms = setScreenSizeParameters(this.props.screenSize, "extraCredit");
           this.className = screenParms.columnClass;
           this.linkSize = screenParms.linkSize;
          
  return <>
           <div className={this.className}>
           <div className="card mt-3 rounded-corners-lg text-center height-max">
                <button className="pages-back-button ml-4 mt-4" 
                    onClick ={()=> this.props.goBack()}>
                                    Back
                </button>

               <h3 className="mt-0 text-center">Basic Challenges</h3>
           
            <span>
            <span className="row w-100 ml-4 mx-0">
                <span/>
                {this.state.category1Complete ?
                    <span className="col-sm-5 mx-2 mt-4 buttonDisabledImage" >
                        <div>
                            <LinkCard name="Money Mgmt" imgSrc='./assets/ExtraCredit/moneyMgmt.png'
                                inSize={this.linkSize} alt=""
                        />
                        </div>
                    </span>
                :
                    <span className="col-sm-5 mx-2 mt-4"
                        onClick={() => this.selectMoneyMgmtChallenge()}>
                        <div>
                        <LinkCard name="Money Mgmt" imgSrc='./assets/ExtraCredit/moneyMgmt.png'
                            inSize={this.linkSize} alt=""
                            />
                        </div>
                    </span>
                }
                {this.state.category2Complete ?
                    <span className="col-sm-5 mx-2 mt-4 buttonDisabledImage" >
                        <div>
                        <LinkCard name="Priorities" imgSrc='./assets/ExtraCredit/priorities.png' 
                            inSize={this.linkSize} alt=""/>
                         </div>
                    </span>
                :
                    <span className="col-sm-5 mx-2 mt-4" onClick={() => this.selectPriorityChallenge()}>
                        <div>
                        <LinkCard name="Priorities" imgSrc='./assets/ExtraCredit/priorities.png' 
                            inSize={this.linkSize} alt=""/>
                        </div>
                    </span>
                }
            </span>
            <span className="row w-100 mx-0 ml-4">
                <span/>
                {this.state.category3Complete ?
                    <span className="col-sm-5 mx-2 mt-4 buttonDisabledImage">
                        <div>
                            <LinkCard name="Choosing a Career" imgSrc='./assets/ExtraCredit/chooseCareer.png'
                                inSize={this.linkSize} alt=""
                        />
                        </div>
                    </span>
                :
                    <span className="col-sm-5 mx-2 mt-4"
                        onClick={() => this.selectChoosingCareerChallenge()}>
                        <div>
                        <LinkCard name="Choosing a Career" imgSrc='./assets/ExtraCredit/chooseCareer.png'
                            inSize={this.linkSize} alt=""
                            />
                        </div>
                    </span>
                }
              
            </span>
            </span>
            {this.state.nextProcess == this.state.category1Name ?
                <MoneyMgmtBasics
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.state.category1Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
             {this.state.nextProcess == this.state.category2Name ?
               <Priorities 
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.state.category2Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
            
            {this.state.nextProcess == this.state.category3Name ?
                <ChoosingCareer
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.state.category3Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
        </div>
       
    </div>   
</>
  }
  
challengeCompleted(processName) {
    this.props.updateAmounts();
    switch (processName){
        case this.state.category1Name:
            this.state.category1Complete = true;
            break;
        case this.state.category2Name:
            this.state.category2Complete = true;
            break;
        case this.state.category3Name:
            this.state.category3Complete = true;
            break;
        default:
            break;
    }
       
    if (this.state.category1Complete == true && this.state.category2Complete == true &&
        this.state.category3Complete == true){
        this.props.completed(); 
        this.props.history.push('/extra-credit-main');
    }else{
        this.state.modalOpen = false;
    }
}

}

export default withRouter(Level1Challenges);