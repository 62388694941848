import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import ConfigFile from '../../datasets/config_file';
import CreditCardPaymentPolicy from './datasets/credit_card_payment_policy';
import CreditCardParameters from './datasets/credit_card_parameters';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import DollarAmountCard from '../getting-started/dollar_amount_card';
import DropDownOptionCard from '../shared-components/drop_down_option_card';
import { calcNbrMonthsBtwnDates } from '../../objects/CommonUse/nbr_months_btwn_dates_calc';
import setModalSize from '../../objects/CommonUse/set_modal_size';

class CreditCardStatementModal extends Component {
            constructor(props) {
              super(props);
              this.gameInfo = this.props.gameInfo();
              this.state={
                updateEnabled: false,   
                step: "display",
                displayInfo: true,
                initialize: true,
                defaultClass: "font-weight-bold ",
                statementDateOptions: [],
                statementDate: "",
                currentPeriodDate: "",
                prevPeriodDate: ""
           }   
    }

    getStatementDates(){
         this.statementDateArr = [];
         let monthlyCharges = this.gameInfo.creditCardInfo.monthlyCreditCharges;
         for (let i=0; i<monthlyCharges.length; i++){
             //  skip first occurence if it is not the first month the credit card was activated
             //  because cannot determine previous payment or previous month balance
             //  also only allow statements to be viewed for last 3 months
            let nbrMonthsMaintained = +ConfigFile.unlockedFeatures.periodsCreditHistory;
            if ((monthlyCharges[i].date == this.gameInfo.creditCardInfo.creditCardStartDate &&
                monthlyCharges.length < nbrMonthsMaintained) || i>0){
                let optionObj = {index: i, value: monthlyCharges[i].date, selection: "Statement Date - " +
                    monthlyCharges[i].date};
                this.statementDateArr.push(optionObj);
            }
         }
    }

    processStatementDate(dateSlct){
        this.state.statementDate = dateSlct;
        this.initializeFields();
    }
    
    initializeFields(){
        this.prePopulateAmounts();
        this.state.displayInfo = false;
        this.displayMonth = 0;
        this.findDisplayCurrentMonthStatement();
        this.state.initialize = false;
    }

    prePopulateAmounts(){
        this.currentOutstandingBalance = +this.gameInfo.creditCardInfo.totalOutstandingBalance;
        this.paymentPolicyCode = this.gameInfo.creditCardInfo.paymentPolicyCode;
        let paymentPolicyObj = 
                CreditCardPaymentPolicy.find(elem => elem.paymentPolicyCode === this.paymentPolicyCode);
        if (paymentPolicyObj == undefined){
            this.paymentPolicy = "none";
        }else{
            this.paymentPolicy = paymentPolicyObj.desc;
        }
    
        let minPayPct = +ConfigFile.unlockedFeatures.creditCardMinimumPayPct;
        let nbrMonths = 1;
        if (this.gameInfo.gamePhase == "2"){
              nbrMonths = 3;
        }
        this.minimumPaymentAmt = this.currentOutstandingBalance * minPayPct * nbrMonths;
         //   this.creditCardInterest = this.gameInfo.creditCardInfo.totalIntBilled;
        let unlockedFeature = 
            this.gameInfo.gameData.unlockedFeatures.find(elem => elem.featureName === "creditCard");
        this.cardLevel = +unlockedFeature.level;
        this.currentCreditLimitAmt = CreditCardParameters[this.cardLevel - 1].maxCreditCardAmt;
        this.creditCardInterest = +this.gameInfo.creditCardInfo.totalIntBilled;
    }

    findDisplayCurrentMonthStatement(){
        let monthlyCharges = this.gameInfo.creditCardInfo.monthlyCreditCharges;
        let indx = 0;
        for (let i=0; i<monthlyCharges.length; i++){
            if (monthlyCharges[i].date == this.state.statementDate){
                indx = i;
                break;
            }
        }
        this.state.currentPeriodDate = 
            JSON.parse(JSON.stringify(this.gameInfo.creditCardInfo.monthlyCreditCharges[indx].date));
        this.currentChargesArr = [];
      
        if (indx > 0){
            let prevIndx = indx - 1;
            this.state.prevPeriodDate = 
                JSON.parse(JSON.stringify(this.gameInfo.creditCardInfo.monthlyCreditCharges[prevIndx].date));
            this.determineMonthOrQuarter();
            if (+this.gameInfo.creditCardInfo.monthlyCreditCharges[prevIndx].monthlyOutstandingAmt > 0){
                let previousBalance = +this.gameInfo.creditCardInfo.monthlyCreditCharges[prevIndx].monthlyOutstandingAmt;
                let chargeItem = {item: "Previous " + this.periodLit, desc: "Amount Billed for Previous " + this.periodLit,
                    amount: previousBalance, className: "text-danger"};
                this.currentChargesArr.push(chargeItem);
            }
            if (+this.gameInfo.creditCardInfo.monthlyCreditCharges[prevIndx].monthlyAmtPaid > 0){
                let previousAmtPaid = +this.gameInfo.creditCardInfo.monthlyCreditCharges[prevIndx].monthlyAmtPaid;
                let chargeItem = {item: "Previous Payment", desc: "Thank You For Your Payment!", 
                    amount: previousAmtPaid};
                this.currentChargesArr.push(chargeItem);    
            }
            if (+this.gameInfo.creditCardInfo.monthlyCreditCharges[prevIndx].monthlyIntAmt > 0){
                let interestAmtPaid = +this.gameInfo.creditCardInfo.monthlyCreditCharges[prevIndx].monthlyIntAmt;
                let chargeItem = {item: "Interest Billed", desc: "Interest Charged on Previous Balance", 
                    amount: interestAmtPaid, className: "text-danger"};
                this.currentChargesArr.push(chargeItem);    
            }
        }else{
            let previousBalance = 0;
            this.state.prevPeriodDate = 
                JSON.parse(JSON.stringify(this.gameInfo.creditCardInfo.monthlyCreditCharges[0].date));
            this.determineMonthOrQuarter();
            let chargeItem = {item: "Previous " + this.periodLit, desc: "Amount Billed for Previous " + this.periodLit,
            amount: previousBalance, className: "text-danger"};
            this.currentChargesArr.push(chargeItem);
            let previousAmtPaid = 0;
            chargeItem = {item: "Previous Payment", desc: "Thank You For Your Payment!", 
                amount: previousAmtPaid};
            this.currentChargesArr.push(chargeItem); 
            let interestAmtPaid = 0;
            chargeItem = {item: "Interest Billed", desc: "Interest Charged on Previous Balance", 
                    amount: interestAmtPaid, className: "text-danger"};
            this.currentChargesArr.push(chargeItem);    
        }
       
        if (this.displayMonth == 0 && indx >= 0){
            if (+this.gameInfo.creditCardInfo.monthlyCreditCharges[indx].monthlyLateFee > 0){
                let lateFeeAmt  = +this.gameInfo.creditCardInfo.monthlyCreditCharges[indx].monthlyLateFee;
                let chargeItem = {item: "Late Fee Charged", desc: "No Payment Received for Previous " + this.periodLit, 
                    amount: lateFeeAmt, className: "text-danger"};
                this.currentChargesArr.push(chargeItem);
            }
        }
                 
        this.currentMonthCharges(indx);
        if (this.displayMonth == 0 && indx >= 0){
           
            let currentBalance = +this.gameInfo.creditCardInfo.monthlyCreditCharges[indx].monthlyOutstandingAmt;
            let chargeItem = {item: "Current " + this.periodLit, desc: "Total Amount Billed for Current " + this.periodLit, 
                    amount: currentBalance, className: "text-danger"};
            this.currentChargesArr.push(chargeItem);
            if (+this.gameInfo.creditCardInfo.monthlyCreditCharges[indx].monthlyAmtPaid > 0){
                let currentAmtPaid = +this.gameInfo.creditCardInfo.monthlyCreditCharges[indx].monthlyAmtPaid;
                chargeItem = {item: "Current Payment", desc: "Thank You For Your Payment!", 
                    amount: currentAmtPaid};
                this.currentChargesArr.push(chargeItem);    
            }
            if (+this.gameInfo.creditCardInfo.monthlyCreditCharges[indx].monthlyIntAmt > 0){
                let interestAmtPaid = +this.gameInfo.creditCardInfo.monthlyCreditCharges[indx].monthlyIntAmt;
                let chargeItem = {item: "Interest Billed", desc: "Interest Charged on Current Balance", 
                    amount: interestAmtPaid, className: "text-danger"};
                this.currentChargesArr.push(chargeItem);    
            }
        }
    }

    determineMonthOrQuarter(){
        let nbrMonthsBtwnPeriods = 
            calcNbrMonthsBtwnDates(this.state.prevPeriodDate, this.state.currentPeriodDate);
        if (nbrMonthsBtwnPeriods > 1){
            this.periodLit ="Quarter";
        }else{
            this.periodLit = "Month";
        }
    }

    currentMonthCharges(indx){
        let selectedMonth = this.gameInfo.creditCardInfo.monthlyCreditCharges[indx];
        //   need to add/display the total interest billed for the month
        let monthDate = selectedMonth.date;
        this.creditCardMonthDate = monthDate;
        if (selectedMonth.creditChargesArr.length > 0){
            let creditCardItems = selectedMonth.creditChargesArr;
            for (let i=0; i<creditCardItems.length; i++){
                let chargeItem = {item: creditCardItems[i].category,
                    desc: creditCardItems[i].desc, amount: creditCardItems[i].amount, className: "text-danger"}
                this.currentChargesArr.push(chargeItem);
            }
        }else{
            let chargeItem = {item: "", 
                desc: "No charges on the credit card for this " + this.periodLit, amount: 0};
            this.currentChargesArr.push(chargeItem);
        }
              
    }
     
    render() {
        if (this.state.statementDateOptions.length == 0){
               this.getStatementDates();
               this.state.statementDateOptions = this.statementDateArr.map((optionsList,index) =>
                <DropDownOptionCard options={optionsList} key={index} />
           );
        }
        if (this.currentChargesArr != undefined){
            this.monthlyChargeList = this.currentChargesArr.map((creditAmt, index) =>
                <DollarAmountCard  amtType="general" amountInfo={creditAmt} 
                    key={index}
                    className={this.currentChargesArr[index].className}
                    gameDate={this.gameInfo.gameData.currentGameDate}
                />
            );
        }
        this.gutters = setModalSize(this.props.screenSize);
                    
    return <>
      
      <Modal show={this.props.show}  onHide={() => this.props.onClose("none")} size="lg" scrollable="true"
            dialogClassName={this.gutters}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title className="text-center">View Credit Card Statement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <span className="ml-4">
                <span>
                <div className="col-sm-10 text-left">Statement Date to View:</div>
                    <div className="col-sm-2 text-right">
                        <select onChange={(val) => this.processStatementDate(val.target.value)} 
                            className="btn btn-sm btn-outline-secondary dropdown-toggle">
                                <option value="0">no option slctd</option>
                                {this.state.statementDateOptions}
                        </select>
                    </div>
                {!this.state.initialize ?
                    <span>
                    <table>
                        <tbody>
                            <tr>
                                <th className="col-sm-4">Statement Date:</th>
                                <td className="col-sm-3 text-right">    
                                    {reformatDateToDisplay(this.creditCardMonthDate)}</td>
                                <th className="col-sm-4 ml-2">Outstanding Amount:</th>
                                <td className="col-sm-3 text-right">
                                    {doubleToStringMonthly(this.currentOutstandingBalance)}</td>
                            </tr>
                            <tr>
                            <th className="col-sm-4">Credit Card Maximum:</th>
                                <td className="col-sm-3 text-right">
                                    {doubleToStringMonthly(this.currentCreditLimitAmt)}</td>
                                <th className="col-sm-4 ml-2">Estimated Min Pay Amt:</th>
                                <td className="col-sm-3 text-right">
                                    {doubleToStringMonthly(this.minimumPaymentAmt)}</td> 
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <th className="col-sm-3">Payment Policy:</th>
                                <td className="col-sm-9">{this.paymentPolicy}</td>
                            </tr>
                        </tbody>
                    </table>
             
                    <div className="ml-3 mt-4">
                        <div className="font-weight-bold text-center">
                            ------------------------Current  {this.periodLit}  Charges---------------------- </div>  
                        <table>
                            <tbody>
                                <tr>
                                    <th>Charge Category</th>
                                    <th>Charge Description</th>
                                    <th>Charge Amount</th>
                                </tr>
                                    {this.monthlyChargeList}
                            </tbody>
                        </table>
                    </div>
                </span>
                :
                    null
                }
                    </span>
                </span>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom">
            <div>{this.message}</div>
                {+this.gameInfo.creditCardInfo.totalOutstandingBalance > 0 ?
                    <Button variant="info"  onClick={() => this.props.onChangePolicy("payment")} >
                        Make a Payment
                    </Button>
                :
                    null
                }
                <Button variant="secondary"  onClick={() => this.props.onChangePolicy("policy")} >
                    Change Credit Card Policy
                </Button>
                       
            <Button variant="primary"  onClick={() => this.props.onClose("none")}>
                     Close
            </Button>
           
        </Modal.Footer>
        </Modal>
        </>
    }

}

export default CreditCardStatementModal;