import {Component} from 'react';
import FurnitureCard from './furniture_card';
import { withRouter } from "react-router";
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class FurnitureInput extends Component {
  
    constructor(props) {
        super(props);
        props.onOpen();
        this.state = {
             furnitureList: [],
             loading: true,
             headers: setHeadersForFetch(),
             environment: Config.dataURL.currentEnv,
             subProcess: "FurnitureInput",
                        
        }
        this.getFurnitureList();         
    }

    render() {
         let furnitureList = this.state.furnitureList.map((furnishings) =>
            <FurnitureCard selectedFurnitureId={this.state.selection} 
                key={furnishings.id} furnitureInfo={furnishings}        
            selected={() => this.onFurnitureSelected(furnishings)}/>
         );
         let screenParms = setScreenSizeParameters(this.props.screenSize, "gettingStarted2");
         this.className = screenParms.columnClass;
         
         return <>
            <div className={this.className}>
                <div className="card mt-3 mx-0 rounded-corners-lg text-center height-max">
                <h3 className="mt-2">Choose Furniture</h3>
                    {this.state.loading ?
                       <WaitTimeSpinner />                        

                    :
                        <div className="mt-2 mb-5 overflow-scroll">
                                {furnitureList}
                        </div>
                    }
                </div>
            </div>
            
        </>
    }

    getFurnitureList() {
        const funcName = "furnitureList";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
            .then(response => { 
                if (response.ok){
                  response.json().then(data => {
                      const output = JSON.parse(data);
                      const statusCode = output.statusCode;
                      if (statusCode == 200){
                        this.setState({furnitureList: output.body});
                        let selectedFurniture = sessionStorage.getItem("furnitureChoice");
                        if (selectedFurniture !== undefined) {
                            this.setState({selection: selectedFurniture});
                        }
                        this.setState({loading: false});
                      }else{
                          const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                          this.props.processError(errorObj);
                      }
                  });
              };
        });      
     }

    onFurnitureSelected(furnishings) {
        sessionStorage.setItem("furnitureChoice", furnishings.id);
        sessionStorage.setItem("furnitureName", furnishings.title); 
        sessionStorage.setItem("furnitureCost", furnishings.cost);
        this.props.completed();
        this.props.history.push('/choose-car');
    }

}

export default withRouter(FurnitureInput);