import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CarValuesEstimatorArray from '../../datasets/car_values_dataset'
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import {monthlyLoanAmt} from '../../objects/AssetsExpenses/loanAmtCalc_function';
import {transportCostCalcAmt} from '../../objects/monthlyCalc/transportCostCalc';
import ConfigFile from '../../datasets/config_file';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import FriendDisplayModal from '../shared-components/friend_display_modal';
import { calcNbrFriendsMet } from './objects/calc_nbr_friends_met';
import { calcMonthlyIncome } from '../../objects/CommonUse/calcMonthlyIncome';
import determineMoodPoints from '../../objects/CommonUse/determine_mood_points';
import createMoodPointArr from '../shared-components/objects/create_mood_point_arr';
import TwoColumnTableCard from './two_column_table_card';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';


class CarChangeModal extends Component {
  constructor(props) {
    super(props);
    this.gameInfo = this.props.gameInfo();
    this.state={
         entryTab: [],
         loanType: "Car",
         headers: setHeadersForFetch(),
         data: null,
         gameData: null,
         gameDataSw: false,
         newCarCost: 0,
         newCarDesc: "",   
         newCarId: "",
         newCarMPG: 0,
         newCarYearAdj: 0,
         newCarSize: 0,
         newCarAgeNbr: 0,
         currentCarId: null,
         carData: null,
         carDataLoaded: false,
         loanProcessFee: ConfigFile.carItems.carLoanProcessFee,
         carIntRate: ConfigFile.carItems.carInterest,
         carLoanTerm: ConfigFile.carItems.carLoanTerm,
         openFriendModal: false,
         emptyArr: [],
         modalType: "",
         subProcess: "carChangeModal",
         environment: Config.dataURL.currentEnv,
    };    
    this.getCurrentCarData();
  }

  determineCarChangeAmts(){
      this.getNewCarData();
      this.getGameData();
      this.determineNewCarYear();
     
      if (this.state.currentCarId == this.state.newCarId &&
          this.currentCarYear == this.newCarYear)  {  
          this.setState({modalType: "SameCar"});
      }else{
          this.calculateNewTotalCarLoanAmt();
          this.calculateNewMonthlyPayAmt();
          this.determineCarChangeMoodPoints();
          this.setState({modalType: "CalculationsComplete"});
      }
  }
  
  getNewCarData() {
    this.state.newCarCost = sessionStorage.getItem("carCost");
    this.state.newCarDesc = sessionStorage.getItem("carName");
    const carDescArr = this.state.newCarDesc.split(' - ');
    this.newCarType = carDescArr[0];
    this.state.newCarId = sessionStorage.getItem("carChoice");
    this.state.newCarMPG = sessionStorage.getItem("carMPG");
    this.state.newCarYearAdj = sessionStorage.getItem("carYearAdj");
    this.state.newCarSize = sessionStorage.getItem("carSize");
    this.state.newCarAgeNbr = sessionStorage.getItem("carAgeNbr");
  }
  
  getGameData() {
      this.state.gameData = this.gameInfo.gameData;
   //   this.props.setGameInfo(this.state.gameData, "gameData");  
      this.loanTable = this.state.gameData.loanTable;
      this.expenseTable = this.state.gameData.expenseTable;
      this.currentCarYear = this.state.gameData.carInfo.carYear; 
      this.currentGameDate = this.state.gameData.currentGameDate; 
      var yearMonthVals = this.currentGameDate.split('-'); 
      this.currentGameYear = +yearMonthVals[0];
      var yearMonthVals = this.state.gameData.startGameDate.split('-');
      this.startGameYear = +yearMonthVals[0];
      this.startGameDate = this.state.gameData.startGameDate;
      this.state.gameDataSw = true;
  }

  determineNewCarYear(){
      const currentGameYear = this.currentGameDate.substr(0,4);
      this.newCarYear = (+currentGameYear - +this.state.newCarYearAdj).toString();
      sessionStorage.setItem("changeCarYear", this.newCarYear);
      let changeCarAgeNbr = (+currentGameYear - +this.newCarYear) * 12;
      sessionStorage.setItem("changeCarAgeNbr", changeCarAgeNbr);
  }

  calculateNewTotalCarLoanAmt(){
       this.calculateTradeInValue();
       this.loanProcessFee = 0;
       this.determineCurrentCarLoanAmt();
          
       this.newCarLoanAmt = this.state.newCarCost - this.tradeInAmt + 
          this.totalCurrentCarLoanAmt; 
      
       this.loanProcessAmt = +this.state.newCarCost * +this.state.loanProcessFee;
       this.newCashAmt = (+this.state.gameData.currentCash - this.loanProcessAmt).toFixed(2); 
       
       this.refundAmt = 0;
       if (this.newCarLoanAmt < 0){
          this.newCashAmt += (-1 * this.newCarLoanAmt);
          this.refundAmt = -1 * this.newCarLoanAmt;
          this.newCarLoanAmt = 0;
       }
       sessionStorage.setItem("changeCarNewCashAmt", this.newCashAmt);
  
  }

  calculateTradeInValue(){
    this.tradeInArray = CarValuesEstimatorArray;
    let nbrYearsOld = parseFloat(this.currentGameDate.substr(0,4)) - +this.currentCarYear;
    if (nbrYearsOld < 0){
      nbrYearsOld = 0;
    }
    this.tradeInAmt = 0;
    var i;
    var sizeFoundInTable = false;
    for (i=0; i<this.tradeInArray.length && sizeFoundInTable == false; i++) {
       if (this.carDataCurrent.sizeNbr == this.tradeInArray[i].carSizeNbr) {
           sizeFoundInTable = true;
             if (nbrYearsOld <= this.tradeInArray[i].pctTradeInArr.length) {
               let tradeInArray = this.tradeInArray[i].pctTradeInArr;
               this.tradeInAmt = this.carDataCurrent.cost * tradeInArray[nbrYearsOld];
             }
       }
     }
     if (sizeFoundInTable == false){
         this.tradeInAmt = this.carDataCurrent.cost * .90;
     }
  }

  determineCurrentCarLoanAmt() {
      var i;
      this.currentLoanSw = false;
      this.totalCurrentCarLoanAmt = 0;
      for (i=0; i<this.loanTable.length; i++) {
           // assumes only one active car loan for any given player.
           if (this.loanTable[i].loanType == "Car Loan" && 
              this.loanTable[i].loanNbrMonths != "0") {
             if (+this.loanTable[i].loanNbrMonths > 0) {
                this.currentLoanSw = true;
                this.currentCarLoanAmt = this.loanTable[i].loanAmt;
                this.totalCurrentCarLoanAmt = this.loanTable[i].loanAmt * +this.loanTable[i].loanNbrMonths;
               
              }
           }
      }
  }

  calculateNewMonthlyPayAmt(){
      if (this.newCarLoanAmt > 0){
          let loanAmt = this.newCarLoanAmt;
          let nbrYears = this.state.carLoanTerm;
          let intRate = this.state.carIntRate;
          let loanType = "car";
          this.monthlyLoanAmt = monthlyLoanAmt(loanAmt, intRate, nbrYears, loanType)
      }else{
          this.monthlyLoanAmt = 0;
      }
      let monthlyLoanDifferenceAmt = +this.monthlyLoanAmt - +this.currentCarLoanAmt;
      let incomeObj = calcMonthlyIncome(this.gameInfo.gameData.assetTable,
            this.gameInfo.gameData.expenseTable, this.gameInfo.gameData.loanTable);
      this.monthlyIncome = incomeObj.income;
      if (this.monthlyIncome < monthlyLoanDifferenceAmt){
            this.warningMessage = "Warning: The monthly cost of this car (together with other " +
              "monthly expenses) will exceed your monthly net income.";
      }
   }
    
  determineCarChangeMoodPoints(){
    let gameChoiceArr = [];
    this.startingMoodPoints = ConfigFile.gameChoice.carChangeMood;
    gameChoiceArr = createMoodPointArr("car");
    this.moodPointStartArr = determineMoodPoints(gameChoiceArr);
    this.totalMoodPoints = +this.startingMoodPoints;
    for (let i=0; i<this.moodPointStartArr.length; i++){
         this.totalMoodPoints += +this.moodPointStartArr[i].number;
    }
    if (this.totalMoodPoints > 0){
        this.moodPointText = "Your choice of a new car will increase your total mood points."
    }else{
        this.moodPointText = "You are happy to get a different car, but your choice of car " +
          "will not increase your mood points."
    }
    sessionStorage.setItem("carChangeMoodPoints", this.totalMoodPoints);
  }
   
   createNewTableObjects() {
      this.createNewLoanTableObject();
      this.createNewExpenseTableObject();
      this.props.onConfirmCarChange();
   }

   createNewLoanTableObject() {
    var i;
    this.newLoanTable = this.loanTable;
    for (i=0; i<this.newLoanTable.length; i++) {
        if (this.newLoanTable[i].loanType == "Car Loan" && 
            this.newLoanTable[i].loanNbrMonths != "0") {
               this.newLoanTable[i].loanNbrMonths = 0;          
        }
    }
    let loanNbrMonths = this.state.carLoanTerm * 12;
    let newLoanObj = {loanType: "Car Loan", loanDesc: this.state.newCarDesc, 
        loanAmt: this.monthlyLoanAmt, loanStartDate: this.currentGameDate, 
        loanNbrMonths: loanNbrMonths, loanTotAmt: this.newCarLoanAmt.toFixed(2), 
        loanIntRate: this.state.carIntRate, loanTerm: this.state.carLoanTerm};
    this.newLoanTable.push(newLoanObj);
    sessionStorage.setItem("changeCarLoanDataObjects", JSON.stringify(this.newLoanTable));
   }

   createNewExpenseTableObject(){
      var i;
      this.newExpenseTable = this.expenseTable;
      for (i=0; i<this.newExpenseTable.length; i++){
          if (this.newExpenseTable[i].expItem == "Transportation" && 
              this.newExpenseTable[i].expNbrMonths != "0") {
                this.newExpenseTable[i].expNbrMonths = 0;
          }
      }
      this.newTransportationExpenseObj = transportCostCalcAmt(this.state.newCarMPG, this.currentGameDate);
      this.newExpenseTable.push(this.newTransportationExpenseObj);
      sessionStorage.setItem("changeCarExpenseDataObjects", JSON.stringify(this.newExpenseTable));
  }
  

  render() { 
    if (this.state.carDataLoaded == true && this.state.modalType != "CalculationsComplete" &&
        this.state.modalType != "SameCar"){
        this.determineCarChangeAmts();
    }
    if (this.state.modalType == "CalculationsComplete"){
        this.nbrFriendsMet = calcNbrFriendsMet(this.gameInfo.gameData.friendList); 
        this.moodPointList = this.moodPointStartArr.map((moodPoints,key) =>
          <TwoColumnTableCard key={key} infoType="label-number" outInfo={moodPoints}  />
    );
    }
            
  return <>
    {this.state.modalType == "SameCar" ?
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" scrollable="true">
          <Modal.Header closeButton>
            <Modal.Title>Car Change - Make Another Choice</Modal.Title> 
          </Modal.Header>
          <Modal.Body>
            <div>
                <p>The car that you have selected is the same type and the same year
                   as your current car.  Please click on the 'close' button and then select
                   another option.
                </p>
              </div>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
               Close
            </Button>
          </Modal.Footer>
          </Modal>
    :
          null
    } 
    
    {this.state.modalType == "TrainVideo"  ?
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" animation={false}>
        <Modal.Body>
          <span>
              <div className="text-white mt-4">xxxxxx</div>
              <div className="text-white mt-4">xxxxxx</div>
                        <WaitTimeSpinner />
              <div className="text-white mt-4">xxxxxx</div>
              <div className="text-white mt-4">xxxxxx</div>
              <div className="text-white mt-4">xxxxxx</div>
          </span>
      </Modal.Body>
      </Modal>
    :
      null  
    }
     
    {this.state.modalType == "CalculationsComplete" ?
      <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" scrollable="true">
        <Modal.Header  className="gameChoiceModalHdrFtrBackground" closeButton>
          <Modal.Title>Cost Information for Changing Cars -
            {this.newCarType} - {this.newCarYear}</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          {this.currentLoanSw  ?
              <div className="ml-2 mr-2 mt-1">
                  <p>Your current car loan will be closed out and a new loan will be created. </p>
              </div>
          :
              null
          }
          <p className="ml-2 mr-2">Here is your new loan information. Click the 'Continue/Accept' button to 
            accept the terms of this new loan.</p>  
          
            <table className="table table-hover">
            <tbody>
                {this.currentLoanSw ?
                    <tr>
                      <th className="p-0 text-left">Current Car Loan Monthly Pay Amt</th>
                      <th className="p-0"></th>
                      <td className="text-right p-0 ">{doubleToStringMonthly(this.currentCarLoanAmt)}</td>
                    </tr>
                :
                    null
                } 
               <tr>
                <th className="p-0 text-left mb-2">Current Monthly Income</th>
                <th className="p-0 mb-2"></th>
                <td className="text-right p-0 mb-2">{doubleToStringMonthly(this.monthlyIncome)}</td>
              </tr>  
              <tr>
                <th className="text-left p-0">Cost of the car selected</th>
                <th className="p-0"></th>
                <td className="text-right p-0">{doubleToStringMonthly(this.state.newCarCost)}</td>
              </tr>
              <tr>
                <th className="text-left p-0">Current Loan Balance</th>
                <th className="p-0">+</th>
                <td className="text-right p-0">{doubleToStringMonthly(this.totalCurrentCarLoanAmt)}</td>
              </tr>
              <tr>
                <th className="text-left p-0">Trade-in value of current car</th>
                <th className="p-0 text-danger">-</th>
                <td className="text-right text-danger p-0">{doubleToStringMonthly(this.tradeInAmt)}</td>
              </tr> 
              
              <tr>
                <th className="text-left p-0">Total amt of new loan</th>
                <th className="p-0">=</th>
                <td className="text-right p-0">{doubleToStringMonthly(this.newCarLoanAmt)}</td>
              </tr>
              <tr>
                <th className="pl-0 text-left mb-2">New Car Loan Monthly Pay Amt</th>
                <th className="mt-0 mb-2"></th>
                <td className="text-right pr-0 mb-2">{doubleToStringMonthly(this.monthlyLoanAmt)}</td>
              </tr>
             
              <tr>
                <th className="text-left p-0 text-danger">Loan processing fee (subtract from cash)</th>
                <th className="p-0"></th>
                <td className="text-right p-0 text-danger">{doubleToStringMonthly(this.loanProcessAmt)}</td>
              </tr>
              {this.refundAmt != 0 ?
                <tr>
                    <th className="text-left p-0">Trade-In More than Loan Balance (add to cash)</th>
                    <th className="p-0"></th>
                    <td className="text-right p-0">{doubleToStringMonthly(this.refundAmt)}</td>
                  </tr>
                :
                  null
              }
            </tbody>
          </table>

          <h5>Mood Point Calculation</h5>
             <div>{this.moodPointText}</div>
                <table className="table table-hover">
                  <tbody className="row-100">
                    <tr>
                      <th className="text-left p-0">Description</th>
                      <th className="text-right p-0">Mood Points</th>
                    </tr>
                    <tr>
                      <th className="text-left p-0">Base Car Change Mood Points</th>
                      <th className="text-right p-0">{this.startingMoodPoints}</th>
                    </tr>
                    {this.moodPointList}
                  
                    <tr>
                      <th className="text-left p-0">Mood Point Change</th>
                      <th className="text-right p-0">{this.totalMoodPoints}</th>
                    </tr>
                  </tbody>
                </table>

          <div class="text-danger my-2">
               {this.warningMessage}
          </div>

        
            
          {this.state.openFriendModal ?
              <FriendDisplayModal  show={this.state.openFriendModal} 
                  title={this.state.newCarDesc}
                  gameProcess="GameViews"
                  askAFriendInfo={this.askAFriendInfo}
                  friendData={this.state.emptyArr}
                  onClose={() => this.closeFriendModal()} 
                  gameInfo = {() => this.props.gameInfo()}
                  />
        :
            null
          }
        </Modal.Body>
          <Modal.Footer  className="gameChoiceModalHdrFtrBackground">
            {this.nbrFriendsMet > 0 ?
                <Button variant="info" className="textLeft"  onClick={() => this.displayFriendModal()}>
                    Ask a Friend
              </Button>
            :
                null
            } 
            <Button variant="secondary"   onClick={() => this.props.onClose()}>
                Cancel/Close
            </Button>
            <Button variant="primary"  onClick={() => this.createNewTableObjects()}>
                Continue/Accept
            </Button>
          </Modal.Footer>
        </Modal>
      :
        <div></div>
      }
    </>
  }
  
  displayFriendModal(){
    this.state.openFriendModal = true;  
    this.askAFriendInfo = {topic: "carChange", carAgeType: this.state.newCarAgeNbr,
      carSelectType: this.state.newCarSize}
  }

  closeFriendModal(){
    this.state.openFriendModal = false;
  }
  
  getCurrentCarData() {
    this.state.modalType = "TrainVideo";
    this.state.currentCarId = this.gameInfo.gameData.carInfo.carID; 
    const funcName = "carInfo";
    const urlParm = "?carId=" + this.state.currentCarId;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'GET', headers: this.state.headers})
        .then(response => {
          if (response.ok){
              response.json().then(data => {
              const output = JSON.parse(data);
              const statusCode = output.statusCode;
              if (statusCode == 200){
                  this.carDataCurrent = output.body;
                  this.setState({carDataLoaded: true}); 
              }else{
                  const errorObj={function: funcName, subProcess: this.state.subProcess, 
                      status: statusCode, message: output.body.message};
                  this.props.processError(errorObj);
              }
          });
      }
      });
  }
 
}
export default CarChangeModal;