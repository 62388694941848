import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import financialAdvisorListEndGame from './Datasets/financial_advisor_list_endGame';
import financialAdvisorBeginText from './Datasets/financial_advisor_begin_text';
import financialAdvisorTextStatus from './Datasets/financial_advisor_text_status';
import financialAdvisorTextEndGame from './Datasets/financial_advisor_text_endGame';
import { findRemoveTableItem } from '../../objects/AssetsExpenses/find_remove_table_item';
import ConfigFile from '../../datasets/config_file';
import { calcInflationAmt } from '../../objects/CommonUse/calc_inflation_amt';
import replaceTextInString from '../../objects/CommonUse/replace_text_in_string';
import TopicListCard from '../shared-components/topic_list_card';
import calcRandomNumberWithMax from '../../objects/CommonUse/calc_random_number_with_max';

class FinancialAdvisorModal extends Component {
        constructor(props) {
            super(props);
            this.gameInfo = this.props.gameInfo();
            this.state={
                beginText: financialAdvisorBeginText,
                advisorList: "",
                advisorText: "",
                displayAdvice: false,
                takeHomePay: 0,
                textArr: [],
        }
        this.initialization();
    }
   
    initialization(){
        if (this.props.adviceType == "status"){
            this.state.advisorText = financialAdvisorTextStatus;
            this.title = "Financial Advisor Assistance"
            this.buttonText = "End Advice"
            this.setAdvisor();
        }else{
            this.title = "Tips For The Next Time You Play"
            this.state.advisorText = financialAdvisorTextEndGame;
            this.state.advisorList = financialAdvisorListEndGame;
            this.buttonText = "End Game Tips"
            this.selectAdvisor();
        }
        const beginTextArr = this.state.beginText;
        const beginTextObj = beginTextArr.find(elem => elem.adviceType === this.props.adviceType);
        this.beginText = beginTextObj.text;
        this.textObj = {topic: "", text: ""};
    }
          
    setAdvisor(){
        this.advisorNbr = this.props.advisorObj.advisorNbr;
        this.advisorName = this.props.advisorObj.advisorName;
        this.introText = this.props.advisorObj.introText;
        this.advisorURL = this.props.advisorObj.advisorURL;
    }

    selectAdvisor(){
        this.advisorList = financialAdvisorListEndGame;
        this.advisorNbr = calcRandomNumberWithMax(8,true,1);
        this.advisorName = this.advisorList[this.advisorNbr].name;
        this.introText = this.advisorList[this.advisorNbr].introText;
        this.advisorURL = './assets/MonthlyStatus/' + this.advisorList[this.advisorNbr].imageURL;
    }

    getAdviceText(adviceTopic){ 
        this.adviceTopic = adviceTopic;
        this.state.textArr = [];
        this.endOfText = false;
        this.adviceText = "";
        switch (adviceTopic){
            case "income":         
                this.setIncomeText();
                break;
            case "expenses":
                this.setExpenseText();
                break;
            case "loans":
                this.setLoanText();
                break;
            case "general":
                this.setGeneralText();
                break;
        }
        if (this.adviceText == ""){
            if (this.adviceHeader == ""){
                this.adviceHeader = "Everything Looks Good";
            }
            this.addToAdviceText("default");
        }
        const textObj = {text: this.adviceText, topic: this.adviceHeader}
        this.state.textArr.push(textObj);
        this.endOfText = true;
        this.state.displayAdvice = true;
        this.setState({displayAdvice: true});
    }

    setIncomeText(){
        this.adviceHeader = "Salary Amount";
        this.newAdviceHeader = true;
        const salaryObj = findRemoveTableItem(this.gameInfo.gameData.assetTable, "salary", false);
        let condition = "";
        if (+salaryObj.assetAmt > 7000){
            condition = "level3";
        }else{
            if (+salaryObj.assetAmt > 3800){
                condition = "level2";
            }else{
                condition = "level1";
            }
        }
        this.addToAdviceText(condition);
        if (condition == "level1" || condition == "level2"){
            this.addToAdviceText("otherIncomeSources");
        }
        this.checkPendingJob(+salaryObj.assetAmt);
    }

    checkPendingJob(salaryAmt){
        let condition = "";
        if (this.gameInfo.gameData.pendingJob != undefined && this.gameInfo.gameData.pendingJob.jobInfo != undefined){
            this.newAdviceHeader = true;
            this.adviceHeader = "Pending Job"
            const jobInfo = this.gameInfo.gameData.pendingJob.jobInfo;
            if (jobInfo.newMonthlySalary > salaryAmt){
                condition = "pendingJobMoreSalary";
            }else{
                condition = "pendingJobLessSalary";
            }
            this.addToAdviceText(condition);
            const replaceObj={string: jobInfo.careerName}
            this.adviceText = replaceTextInString(this.adviceText, replaceObj, "jobName", "string");
        }
    }
    
    setExpenseText(){
        let condition = "";
        this.newAdviceHeader = true;
        let expenseObj = findRemoveTableItem(this.gameInfo.gameData.expenseTable, "rent", false);
        let rentAmt = 0;
        if (expenseObj != undefined){
            rentAmt = expenseObj.expAmount;
        }
        let maxRentAmt = 99999;
        if (this.state.takeHomePay == 0){
            this.calcTakeHomePay();
            maxRentAmt = this.state.takeHomePay * .30;
        }
        if (rentAmt > maxRentAmt){
            this.adviceHeader = "Reduce Monthly Rent";
            this.addToAdviceText("rentOver30");
            this.checkRoommateOption();
        }
        this.newAdviceHeader = true;      
        this.calculateMiscExpenseMultiplier();
        // baseline Multiplier is the constant multiplied times base expense to determine misc expense for the player
        if (this.baseLineMultiplier > 1.30){
              condition = "miscExpLvl1";
        }else{
            if (this.baseLineMultiplier > 1.00){
                condition = "miscExpLvl2";
            }else{
                condition = "miscExpLvl3";
                
            }
        }
        this.addToAdviceText(condition);
        this.adviceHeader = "Misc Expenses"
        const totalNetIncome = +this.props.monthlyTotalAmt;
        if (totalNetIncome < +500 && this.gameInfo.gameData.funStuffInfo != undefined){
            this.newAdviceHeader = true;
            for (let i=0; i<this.gameInfo.gameData.funStuffInfo.itemArr.length; i++){
                let itemArr = this.gameInfo.gameData.funStuffInfo.itemArr[i];
                if (itemArr.category == "horse"){
                    this.addToAdviceText("sellHorse");
                }
                if (itemArr.category == "Boat"){
                    this.addToAdviceText("sellBoat");
                }
            }
           
            this.adviceHeader = "Other Expense Items";
        }
        if (totalNetIncome < +1000){
            this.checkCharities();
        }
        if (this.adviceText == ""){
            this.adviceHeader = "General";
            this.addToAdviceText("default");
            
        }
    }

    calculateMiscExpenseMultiplier(){
        const baseLineExpenses = ConfigFile.gameBoard.baseLineExpenses;
        let annualBaseLine = baseLineExpenses * 12;
        const nbrMonthsPlayed = +this.state.totalGameMonths - +this.gameInfo.gameData.gameNbrMonths;
        const nbrYearsInGame = (nbrMonthsPlayed / 12).toFixed(0);
        const inflatedAnnualBaseLine = calcInflationAmt(annualBaseLine, nbrYearsInGame);
        let monthlyBaseLine = (+inflatedAnnualBaseLine / 12).toFixed(2);
        let miscAmount = 0;
        const miscExpObj = findRemoveTableItem(this.gameInfo.gameData.expenseTable, "misc", false);
        if (miscExpObj != undefined){
            miscAmount = +miscExpObj.expAmount;
        }
        this.baseLineMultiplier = +miscAmount / +monthlyBaseLine;
    }
    
    checkCharities(){
        const salaryObj = findRemoveTableItem(this.gameInfo.gameData.assetTable, "salary", false);
        let maxCharity = 9999;
        if (salaryObj.assetAmt != undefined){
            maxCharity = +salaryObj.assetAmt * .05;
        }
        const saveEarthObj = findRemoveTableItem(this.gameInfo.gameData.expenseTable, "saveEarth", false);
        const saveAnimalsObj = findRemoveTableItem(this.gameInfo.gameData.expenseTable, "saveAnimals", false);
        const feedWorldObj = findRemoveTableItem(this.gameInfo.gameData.expenseTable, "feedWorld", false);
        let totalCharityAmt = 0;
        if (saveEarthObj.expAmount != undefined){
            totalCharityAmt += +saveEarthObj.expAmount;
        }
        if (saveAnimalsObj.expAmount != undefined){
            totalCharityAmt += +saveAnimalsObj.expAmount;
        }
        if (feedWorldObj.expAmount != undefined){
            totalCharityAmt += +feedWorldObj.expAmount;
        }
        if (totalCharityAmt > maxCharity){
            this.newAdviceHeader = true;
            this.adviceHeader = "Charity Contributions";
            this.addToAdviceText("tooMuchCharity");
            const replaceObj={amount: totalCharityAmt}
            this.adviceText = replaceTextInString(this.adviceText, replaceObj, "charityAmt", "amount");
        }
    }

    setLoanText(){
        this.getMortgageAmounts();
        this.newAdviceHeader = true;
        if (this.mortgageAmt > this.maxMortgageAmt){
            if (this.gameInfo.gameData.homeInfo.roommates == 0){
                this.adviceHeader="Reduce Mortgage Amount";
                this.addToAdviceText("mortgageTooHigh");
                this.checkRoommateOption();
            }else{
                this.adviceHeader="Mortgage Amt Caution";
                this.addToAdviceText("mortgageWithRoommate");
            }
        }
      
        let carLoanAmt = 0;
        const carLoanObj = findRemoveTableItem(this.gameInfo.gameData.loanTable, "car", false);
        if (carLoanObj != undefined){
            carLoanAmt = +carLoanObj.loanAmt;
        }
        this.maxCarLoanAmt = +this.state.takeHomePay * .10;
        if (carLoanAmt > this.maxCarLoanAmt){
            this.newAdviceHeader = true;
            if (this.gameInfo.gameData.carInfo.carType == "400"){      
                // this is a sports car
                this.addToAdviceText("sportsCar");
                this.adviceHeader = "Sell Sports Car";
            }else{
                this.addToAdviceText("carLoanTooMuch");
                this.adviceHeader = "Get a Different Car"
            }
        }
    }

    getMortgageAmounts(){
        this.mortgageAmt = 0;
        this.maxMortgageAmt = 9999;
        this.maxCarLoanAmt = 9999;
        if (this.state.takeHomePay == 0){
            this.calcTakeHomePay();
        }
        this.maxMortgageAmt = +this.state.takeHomePay * .30;
        const mortgageObj = findRemoveTableItem(this.gameInfo.gameData.loanTable, "mortgage", false);
        if (mortgageObj != undefined){
            this.mortgageAmt = +mortgageObj.loanAmt;
            this.ownHome = true;
        }
        const mortgagePMIObj = findRemoveTableItem(this.gameInfo.gameData.loanTable, "mortgagePMI", false);
        if (mortgagePMIObj.loanAmt != undefined){
            this.mortgageAmt = this.mortgageAmt + +mortgagePMIObj.loanAmt;
        }
        
    }
    
    setGeneralText(){
        let condition = "";
        this.newAdviceHeader = true;
        this.adviceHeader = "Net Income (Salary Minus Expenses)"
        switch (true){
            case (this.props.monthlyTotalAmt > 1500):
                this.addToAdviceText("incomeGreat");
                break;
            case (this.props.monthlyTotalAmt > 800):
                this.addToAdviceText("incomeGood");
                break;
            case (this.props.monthlyTotalAmt > 300):
                this.addToAdviceText("incomeOK");
                break;
            case (this.props.monthlyTotalAmt >= 0):
                this.addToAdviceText("incomeNotOK");
                break;
            case (this.props.monthlyTotalAmt < 0):
                this.addToAdviceText("incomeNegative");
                break;
        }
        this.ownHome = false;
        const mortgageObj = findRemoveTableItem(this.gameInfo.gameData.loanTable, "mortgage", false);
        if (mortgageObj != undefined){
            this.mortgageAmt = +mortgageObj.loanAmt;
            this.ownHome = true;
        }
        // check to see if savings feature has been unlocked for this player
        const savingsObj = 
            this.gameInfo.gameData.unlockedFeatures.find(elem => elem.featureName === "savings");
        if (+this.props.monthlyTotalAmt > 1000 && savingsObj != undefined){
            this.newAdviceHeader = true;
            if (this.ownHome == false){
                this.addToAdviceText("savingForHome");
            }
            if (this.gameInfo.savingsInfo.monthlySavingsAmt != undefined){
                const liquidCashAmt = +this.props.monthlyTotalAmt - +this.gameInfo.savingsInfo.monthlySavingsAmt;
                if (liquidCashAmt >= 500 && liquidCashAmt <= 1000){
                    this.addToAdviceText("goodJobSavings");
                }else{
                    if (liquidCashAmt > 1000){
                        this.addToAdviceText("increaseSavings");
                        this.addToAdviceText("startSavingYoung");
                    }else{
                        this.addToAdviceText("decreaseSavings");
                    }
                }
            }else{
                this.addToAdviceText("beginSavings");
                this.addToAdviceText("startSavingYoung");
            }
            this.adviceHeader = "Savings Amount Suggestions";
        }
        if (this.gameInfo.creditCardInfo.gameID != undefined){
            const paymentPolicyCode = this.gameInfo.creditCardInfo.paymentPolicyCode;
            if (paymentPolicyCode != "autoPayFull"){
                this.newAdviceHeader = true;
                if (paymentPolicyCode == "autoPayMin"){
                    this.addToAdviceText("creditCardMin");
                    if (+this.gameInfo.creditCardInfo.totalOutstandingBalnce > 1000 ){
                        condition = "creditCardMinIssue";
                    }else{
                        condition = "creditCardMinOK";
                    }
                    this.addToAdviceText(condition);
                    this.adviceHeader = "Credit Card Minimum Payment Policy";
                }else{
                    if (paymentPolicyCode == "manualPay"){
                        if (+this.gameInfo.creditCardInfo.totalOutstandingBalnce > 1000 ){
                            condition = "creditCardManualIssue";
                        }else{
                            condition = "creditCardManualOK";
                        }
                        this.addToAdviceText(condition);
                        this.adviceHeader = "Credit Card Manual Payment Policy";
                    }
                }
                this.addToAdviceText("ccIntPenalty");
                let ccLateFee = +ConfigFile.unlockedFeatures.creditCardLateFee;
                const replaceObj={string: ccLateFee + "%"}
                this.adviceText = replaceTextInString(this.adviceText, replaceObj, "cclatefee", "string");
            }
        }
        if (this.gameInfo.gamePurchases.purchaseTable.length > 2){
            let totalCost = 0;
            for (let i=0; i<this.gameInfo.gamePurchases.purchaseTable.length; i++){
                  const purchaseObj = this.gameInfo.gamePurchases.purchaseTable[i];
                  totalCost += +purchaseObj.itemCost;
            }
            if (totalCost > 10000){
                this.newAdviceHeader = true;
                if (+totalCost > +this.gameInfo.gameData.currentCash){
                    this.addToAdviceText("tooManyPurchases");
                   
                }else{
                    this.addToAdviceText("watchPurchases");
                }
                this.adviceHeader = "Too Many Game Purchases"
                const replaceObj={amountWholeDollars: +totalCost };
                this.adviceText = replaceTextInString(this.adviceText, replaceObj, "totalCost", "amountWholeDollars");
            }

        }
    }

    calcTakeHomePay(){
        const salaryObj = findRemoveTableItem(this.gameInfo.gameData.assetTable, "salary", false);
        const monthSalaryAmt = salaryObj.assetAmt;
        const taxesObj = findRemoveTableItem(this.gameInfo.gameData.expenseTable, "taxes", false);
        const monthTaxAmt = taxesObj.expAmount;
        this.state.takeHomePay = (+monthSalaryAmt - +monthTaxAmt);
    }

    checkRoommateOption(){
        if (+this.gameInfo.gameData.homeInfo.roommates == 0){
            this.newAdviceHeader = true;
            this.adviceHeader = "Add a Roommate";
            this.addToAdviceText("roommate", "expenses");
            if (+this.gameInfo.gameData.homeInfo.bedrooms < 2){
                this.addToAdviceText("bedrooms");
            }
        }
        
    }

    addToAdviceText(condition, topic){
        if (topic == undefined){
            topic = this.adviceTopic;
        }
        if (this.newAdviceHeader == true){
            if (this.adviceText != ""){
                this.textObj.text = this.adviceText;
                this.state.textArr.push(this.textObj);
                this.adviceText = "";
            }
            this.textObj = {topic: this.adviceHeader, text: ""};
            this.newAdviceHeader = false;
        }
        let adviceObj = this.state.advisorText.find(elem => (elem.topic === topic && elem.condition === condition));
        if (adviceObj == undefined){
            console.log("condition not found in fin advisor text: ", condition);
        }else{
            this.adviceText += adviceObj.text + " ";
        }
    }
        
    
    closeAdvice(){
            this.state.displayAdvice = false;
            this.endOfText = false;
            this.setState({displayAdvice: false});
    }
   

    render() {
        if (this.state.textArr){
           this.numberedList = this.state.textArr.map((textLine, key) =>
                <TopicListCard key={key} textInfo={textLine} />
          );
    }
          
    return <>
      <Modal show={this.props.modalOpen}  onHide={() => this.props.onClose()} size="md" scrollable="true"
         dialogClassName={this.gutters} className="modal-border" animation={false}>
            <Modal.Header className="backgroundTopBottom" closeButton>
                <Modal.Title className="ml-4">{this.title}</Modal.Title>                   
            </Modal.Header>
            <Modal.Body className="modal-border">
                <span> 
                    <div className="row">
                        <div className="col-sm-3 text-center"> 
                            <img src={this.advisorURL} 
                                className="" alt="advisor"  />
                            <div className="comic-sans-font">{this.advisorName}</div>
                        </div>
                        <div className="col-sm-9">
                            {this.state.displayAdvice  ?
                                <div>
                                    <dl>
                                        {this.numberedList}
                                    </dl>
                                   
                                </div>
                            :
                                <span>
                                    <div>{this.introText} &nbsp;Which topic are you interested in?</div>
                                    <div className="row">
                                        <div className="col-sm-4 mt-2 ml-5">
                                            <Button className="mt-4" variant="primary" 
                                                onClick={() => this.getAdviceText("income")}>
                                                &nbsp;Income&nbsp;&nbsp;</Button>
                                            <Button className="mt-4" variant="primary" 
                                                onClick={() => this.getAdviceText("loans")}>
                                                &nbsp;&nbsp;Loans&nbsp;&nbsp;&nbsp;</Button>
                                        </div>
                                        <div className="col-sm-4 mt-2 ml-4">
                                            <Button className="mt-4" variant="primary" 
                                                onClick={() => this.getAdviceText("expenses")}>
                                                Expenses</Button>
                                            <Button className="mt-4" variant="primary" 
                                                onClick={() => this.getAdviceText("general")}>
                                                &nbsp;General&nbsp;</Button>
                                        </div>
                                    </div>
                                    <div className="mt-4">{this.beginText}</div>
                                </span>
                            }
                        </div>
                    </div>            
                </span>
            </Modal.Body>
            <Modal.Footer className="backgroundTopBottom">
                {this.endOfText ?
                    <Button variant="success" 
                        onClick={() => this.closeAdvice()}>
                            More Advice
                    </Button>
                :
                    null
                }
                <Button variant="primary"  onClick={() => this.props.onClose()}>
                        {this.buttonText}
                </Button>
            </Modal.Footer>
            </Modal>
            
        </>
    }
    
}

export default FinancialAdvisorModal;