import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import './GameBoardActivities/puzzleimg.css';
import Button from 'react-bootstrap/Button';
import UnderCoverPuzzleCard from './undercover_puzzle_card';
import UnderCoverRadioButtons from './undercover_puzzle_radio_buttons';
import calcRandomNumberWithMax from '../../objects/CommonUse/calc_random_number_with_max';
import { determineFriendComment } from './objects/determine_friend_comment';
import { displayFriendComment } from './objects/display_friend_comment';

class UnderCoverPuzzleModal extends Component {
  constructor(props){
    super(props);
    this.state={
        arrayCreated: false,
        seconds:0,
        invisibleArr: [],
        nbrColumns: 6,
        nbrRows: 5,
        imageClass: "",
        totalWidth: 0,
        totalHeight: 0,
        invisibleArr: [],
        nbrOfInvisible: 0,
        avail: false,
        availTime: 0,
        indexArr: [],
        blankOutAllArr: [],
        displayRadioButtons: false,
        imageArr: this.props.imageList,
        oneTimeDone: false,
        displayPictureuzzle: false,
        nbrPictures: 0,
        score: this.props.score,
        startButton: true,
        pictureDir: "./assets/Activities/Vacation/",
        displayFriend: false,
        commentNbr: 0,
        finished: false
                 
    }
  }
  
  preProcess(){
     this.createArray();
     this.selectPicture();
     this.state.startButton = true;
     this.state.displayFriend = false;
    
   }
  
 createArray(){
    this.state.indexArr = [];
    this.state.invisibleArr = [];
    this.state.displayRadioButtons = false;
    this.nbrInArray = this.state.nbrColumns * this.state.nbrRows;
    for (let i=0; i<this.nbrInArray; i++){
          this.state.indexArr.push(i);
    }
    this.state.blankOutAllArr = JSON.parse(JSON.stringify(this.state.indexArr));
    this.state.arrayCreated = true; 
    this.message = "Click Start to Uncover the answer."
  }

  selectPicture(){
      this.newPictureArr = [];
      this.state.nbrPictures = this.props.score.nbrAttempted;
      for (let i=0; i<this.state.imageArr.length; i++){
           if (this.state.imageArr[i].selected == false){
                this.newPictureArr.push(this.state.imageArr[i]);
           }else{
                this.state.nbrPictures += 1;
           }
      }
      if (this.newPictureArr.length == 0){
          this.onClose();
      }else{
          let maxIndex = this.newPictureArr.length - 1;
          this.pictureIndx = calcRandomNumberWithMax(maxIndex);
          this.answer = this.newPictureArr[this.pictureIndx].answer;
          this.label = this.newPictureArr[this.pictureIndx].label;
          this.backgroundURL = this.state.pictureDir + this.newPictureArr[this.pictureIndx].imageSrc;
      }
   }

  setPictureSize(){
    this.speechClass = "speech-vacation-activity right";
    switch (this.props.screenSize){
        case "narrow":
            this.backgroundClass = "under-cover-puzzle-background-narrow";
            this.imgClass = "under-cover-img-narrow";
            this.textPosition = "under-cover-text-position-narrow";
            this.textClass = "mt-2 small-text";
            // totalWidth and totalHeight must equal what is in the css!
            this.state.totalWidth = 360;
            this.state.totalHeight = 250;
            this.friendPosition ="friend-puzzle-positionVacation-img-narrow"; 
            break;
        case "medium":
            this.backgroundClass = "under-cover-puzzle-background-medium";
            this.imgClass = "under-cover-img-medium";
            this.textPosition = "under-cover-text-position-medium";
            this.textClass = "mt-2 small-text";
            this.state.totalWidth = 480;
            this.state.totalHeight = 340;
            this.friendPosition ="friend-puzzle-positionVacation-img-medium"; 
            break;
        default:
            this.backgroundClass = "under-cover-puzzle-background";
            this.imgClass = "under-cover-img";
            this.textPosition = "under-cover-text-position";
            this.textClass = "mt-2";
            this.state.totalWidth = 660;
            this.state.totalHeight = 480;
            this.friendPosition ="friend-puzzle-positionVacation-img"; 
            break;
    }
  }

  onStart=()=>{
    this.message="";
    this.state.displayFriend = false;
    this.setState({seconds:this.state.seconds+1});
    this.state.displayRadioButtons = true;
    this.createInvisibleEntry();
    
 }

 createInvisibleEntry=()=>{
     if (this.state.indexArr.length > 0){
        let maxIndex = this.state.indexArr.length - 1;
        let indx = calcRandomNumberWithMax(maxIndex);
        let invisibleIndx = this.state.indexArr[indx];
        this.state.invisibleArr.push(invisibleIndx);
        this.state.indexArr.splice(indx,1);
    }else{
        this.onChangeValue("timeout");
    }
 }

 timer=()=>{
    this.f=setInterval(this.onStart,500);
    document.getElementById('btn').disabled=true;
 }

 onPause=()=>{
     clearInterval(this.f);
     this.state.startButton = false;
     document.getElementById('btn').disabled=false;
 }

  onChangeValue(slctAnswer) {
      if (slctAnswer == this.answer  || slctAnswer == "timeout"){
          if (slctAnswer == this.answer){
          this.message = "Congratulations, you figured it out.  Click the 'New Picture' button to " +
            "visit another site."; 
          }else{
            this.message = "Time has runout.  The picture you are seeing is: " + this.label;
          }
          this.onPause();
          this.state.invisibleArr = this.state.blankOutAllArr;
          this.setSelectedForImageArr();  
          this.state.displayFriend = displayFriendComment(this.props.activityCategory, this.props.friendInfo);
          if (this.state.displayFriend == true){
                 this.getComment();
          }
          this.forceUpdate();
      }else{
        this.message = "Not quite right, please try again."
      } 
  } 

  setSelectedForImageArr(){
      this.state.nbrPictures += 1;
      for (let i=0; i<this.state.imageArr.length; i++){
          if (this.state.imageArr[i].answer == this.answer){
               this.state.imageArr[i].selected = true;
               break;
          }   
      }
  }

 onClose(){
        if (this.state.nbrPictures == this.state.imageArr.length){
            this.state.score.type="finish";
        }else{
            this.state.score.type="done";
        }
        this.state.score.gamesRemaining = this.state.imageArr.length - this.state.nbrPictures;
        //this.state.score.points = (100 * this.state.nbrPictures/this.state.imageArr.length).toFixed(0);
        this.state.score.points = 100;
        this.state.score.maxScore = 100;
        this.state.score.nbrAttempted = this.state.nbrPictures;
        this.setState({finished: true});
        if (this.state.score.type == "finish"){
            // wait 3 seconds before returning to activity finish
            if(this.state.timeout) clearTimeout(this.state.timeout);
                this.state.timeout = setTimeout(() => {
                    this.props.onGameOver(this.state.score);
            }, 3000);
        }else{
            this.props.onGameOver(this.state.score);
        }
      
  }
  
 getComment(){
      let commentTable = this.props.friendInfo.commentTable;
      let commentObj = determineFriendComment(commentTable, this.state.commentNbr);
      this.comment = commentObj.comment;
      this.state.commentNbr = commentObj.commentNbr;
}

getNewPicture(){
  this.state.arrayCreated = false;
}

 render(){
    
    if (this.state.finished == false){
        if (this.state.arrayCreated == false){
            this.preProcess();
        }
        this.setPictureSize();
        this.coverUpCards = this.state.blankOutAllArr.map((key, index) =>
            <UnderCoverPuzzleCard key={index} index={index} 
                nbrColumns={this.state.nbrColumns} nbrRows={this.state.nbrRows}
                totalWidthPx={this.state.totalWidth} totalHeightPx={this.state.totalHeight}
                screenSize={this.props.screenSize}
                invisibleArr={this.state.invisibleArr}/>
            );
        
        if (this.state.displayRadioButtons == true){
            this.radioButtons = this.state.imageArr.map((imageList, index) =>
                <UnderCoverRadioButtons index={index} vacationSlct={imageList} 
                onSelect={(slctAnswer) => this.onChangeValue(slctAnswer)}            
                />
            );
        }
    }
    
  return(

    <span>
        <div className={this.backgroundClass}>
            
                <div  className={this.imgClass}
                    style={{backgroundImage: `url(${process.env.PUBLIC_URL + this.backgroundURL})`}}>
                {!this.state.finished ?    
                    <div>
                        {this.coverUpCards}
                    </div>
                :
                    null
                }
              
              <div className={this.textPosition}>
                <div>
                  {this.state.startButton ?
                      <Button id='btn' size="sm" variant="primary" onClick={this.timer} 
                        disabled={this.state.finished}>
                          Start
                      </Button>
                  :
                      <Button id='btn' size="sm" variant="primary" onClick={this.getNewPicture()} 
                        disabled={this.state.finished}>
                          New Picture
                      </Button>
                  }
                
                  <Button className="ml-4" size="sm" variant="primary"  onClick={() => this.onClose()}>
                      Done
                  </Button>
               
              </div>
              <div className={this.textClass}>
                    <div className="font-weight-bold">Number of Places Visited so Far:  {this.state.nbrPictures}</div>
                    Can you guess what is in the picture?
                    {this.state.displayRadioButtons ?
                        <span>
                            {this.radioButtons}
                        </span>
                    :
                        null
                    }
                    <div className="mt-2 font-weight-bold">
                       
                        {this.message}
                    </div>
              </div>
              {this.state.displayFriend   ?
                  <div>
                      <div>
                          <img src={this.props.friendInfo.friendImgURL}  
                            className={this.friendPosition} 
                              alt="friend picture"/>
                        
                            <div className="speech-vacation-activity right" >
                                {this.comment}
                            </div>
                      </div>
                  </div>
                :
                    null
                }
          </div>
          </div>
      </div>
    
  </span>              
  )
 }
 
}
export default UnderCoverPuzzleModal;

