import "./EndGame/end-game.css"
import { Button } from "react-bootstrap";
import {Component} from 'react';
import { withRouter } from "react-router";
import ReactPlayer from "react-player";
import LeaderBoardModal from '../game-views/leader_board_modal';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import {calculateMoodRetirementScores} from '../game-views/objects/calculate_mood_retirement_scores';
import MoodRetirementStatusModal from "../game-views/mood_retirement_status_modal";
import { standardNumberFormat } from "../../objects/CommonUse/double_functions";
import {getCurrentDateTime} from "../../objects/CommonUse/get_current_date_time";
import { setScreenSizeParameters } from "../../objects/CommonUse/set_screen_size_parameters";
import WaitTimeSpinner from "../shared-components/wait_time_spinner";
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import { calcMonthlyIncome } from "../../objects/CommonUse/calcMonthlyIncome";
import FinancialAdvisorModal from "../shared-components/financial_advisor_modal";

class EndGame extends Component {
    constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
            playVideo: false,
            modalOpen: false,
            headers: setHeadersForFetch(),
            loadingActivityTracker: true,
            activityTracker: null,
            status: "InitialDisplay",
            moodScore: 0,
            retirementScore: 0,
            combinedScore: 0,
            accountNbr: "game",
            finishType: "fullGame",
            updateLeaderBoard: false,
            dateObj: getCurrentDateTime(),
            subProcess: "endGame",
            environment: Config.dataURL.currentEnv,
            archivesInitiated: false,
            archiveData: false,
            gameArchived: false,
            creditCardArchived: false,
            savingsArchived: false,
            funStuffArchived: false,
            gamePurchasesArchived: false,
            activityTrackerArchived: false,
            deletesInitiated: false,
            deleteData: false,
            gameDeleted: false,
            creditCardDeleted: false,
            savingsDeleted: false,
            funStuffDeleted: false,
            gamePurchasesDeleted: false,
            activityTrackerDeleted: false,
            activityTrackerArchiveCount: 0,
            returnToHomePageInitiated: false,
            openFinModal: true,
        }
        this.initialProcess();
    }    

    initialProcess(){
        this.loadActivityTracker();
        this.determineMoodRetirementScore();
        if (this.props.endGameReason != "TimeElapsed"){
             this.state.finishType = "forceEnd";
        }
    }

    loadActivityTracker(){
        const funcName = "loadAllGameActivityTracker";
        let gameID = this.gameInfo.gameData._id;
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
             {method:'GET', headers: this.state.headers})
             .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        // fetch of game activity tracker was successful;
                        this.state.loadingActivityTracker = false;
                        this.state.activityTracker = output.body;
                    }else{
                        if (statusCode == 250){
                            // fetch successful but no record found;
                            this.state.loadingActivityTracker = false;
                            this.state.activityTracker = "noData";
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
                }
        });
    }

    determineMoodRetirementScore(){
        let gameScores = calculateMoodRetirementScores(this.gameInfo.gameData.currentMoodPoints,
                this.gameInfo.gameData.currentCash, this.gameInfo.savingsInfo.totalSavingsAmt,
                this.gameInfo.gameData.spendingHistory, this.gameInfo.gameData.gameNbrMonths);
        this.state.retirementScore = +gameScores.retirementScore;
        this.state.moodScore = +gameScores.moodScore;
        this.state.combinedScore = +gameScores.combinedScore;
    }

    displayMoodRetirementModal(){
        this.state.status = "DisplayMoodRetirement";
        this.state.modalOpen = true;
    }

    render() {
        if (this.state.archiveData == true & this.state.archivesInitiated == false){
                this.archiveGameRelatedCollections();
        }
       
        if (this.state.deletesInitiated === false && this.state.gameArchived === true &&
            this.state.creditCardArchived === true && this.state.savingsArchived === true &&
            this.state.funStuffArchived === true && this.state.activityTrackerArchived === true &&
            this.state.gamePurchasesArchived === true){
                this.deleteGameRelatedCollections();
        }

        if (this.state.returnToHomePageInitiated === false && this.state.gameDeleted === true &&
            this.state.creditCardDeleted === true && this.state.savingsDeleted === true &&
            this.state.funStuffDeleted === true && this.state.activityTrackerDeleted === true &&
            this.state.gamePurchasesDeleted === true){
                this.returnToHomePage();
        }

        let screenParms = setScreenSizeParameters(this.props.screenSize, "endGame");
        this.className = screenParms.columnClass;
           
    return <>
             <div className={this.className}>
                <div className="card mt-3 mr-3 rounded-corners-lg text-center height-max">
                    <h3 className="mt-2">Finish Playing Your Game</h3> 
                    {this.props.endGameReason === "PlayersChoice" ?
                        <div className="my-1">
                            <h4>Choosing To End Your Game</h4>
                            <div className="mx-4 text-left">
                                You have selected to finish the game.&nbsp;&nbsp;If you continue, your current game
                                will be cleared and you will need to restart a new game.&nbsp;&nbsp;
                                Click the "End My Game" button to continue with the 'finish game' process.&nbsp;&nbsp;
                                Click the "Back to GameBoard" button to go back to playing your 
                                current game.                               
                            </div>
                        </div>
                    :
                        <div></div>
                    }
                    {this.props.endGameReason === "NegativeCash" ?
                        <div>
                            <h4 className="my-2">"Cash Amount is Less Than Zero"</h4>
                            <div className="mx-4 text-left">
                                Your game has ended because you do not have enough cash to continue.&nbsp;
                                Eventhough you do not have any cash, you can celebrate that you played
                                the game and learned a bit about money management along the way.&nbsp;Now
                                that you know more, we hope you will play again with more success.
                            </div>
                        </div>
                    :
                        <div></div>
                    }
                    {this.props.endGameReason === "NegativeMoodPoints" ?
                        <div>
                            <h4 className="my-2">"Mood Points are Less Than Zero"</h4>
                            <div className="mx-4 text-left">
                                Your game has ended because you do not have enough happy mood to continue.&nbsp;
                                Eventhough you do not have any mood points, you can celebrate that you played
                                the game and learned a bit about money management along the way.&nbsp;Now
                                that you know more, we hope you will play again with more success.
                            </div>
                        </div>
                    :
                        <div></div>
                    }
                    {this.props.endGameReason === "TimeElapsed" ?
                        <div>
                            <h4 className="my-2">"Congratulations!  You Are Retiring"</h4>
                            <div className="mx-4 text-left">
                                Your game has ended because it has reached the 30 years time that has
                                been alotted.&nbsp;Please enjoy the celebration!!!&nbsp;We hope that you have
                                learned about money management and we invite you to play the game
                                again.
                            </div>
                        </div>
                    :
                        <div></div>
                    }
                    <div className="mt-4 mx-4 ">
                        <h4>Final Game Scores</h4>
                        <table className="row justify-content-center gameChoiceModalHdrFtrBackground ">
                            <tbody>
                                <tr>
                                    <th className="col-sm-8 text-left">Game Item</th>
                                    <th className="col-sm-4 text-right">Score</th>
                                </tr>
                                <tr>
                                    <td className="col-sm-8 text-left">Mood Score</td>
                                    <td className="col-sm-4 text-right">{standardNumberFormat(this.state.moodScore)}</td>
                                </tr>
                                <tr>
                                    <td className="col-sm-8 text-left">Retirement score</td>
                                    <td className="col-sm-4 text-right">{standardNumberFormat(this.state.retirementScore)}</td>
                                </tr>
                                <tr>
                                    <th className="col-sm-8 text-left">Combined Score</th>
                                    <th className="col-sm-4 text-right">{standardNumberFormat(this.state.combinedScore)}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.state.status == "DisplayMoodRetirement" ?
                        <MoodRetirementStatusModal modalOpen={this.state.modalOpen} 
                            onClose={() => this.closeModal("MoodRetirement")}
                            gameInfo = {() => this.props.gameInfo()}
                        />
                    :
                        null
                    }
                    {this.state.status == "DisplayLeaderBoard" ?
                        <div>
                            <LeaderBoardModal modalOpen={this.state.modalOpen} 
                                playerSelected={true}    
                                accountNbr={this.state.accountNbr}
                                finishType={this.state.finishType}
                                onClose={(updateLeaderBoard) => this.closeLeaderBoardModal(updateLeaderBoard)} 
                                gameInfo = {() => this.props.gameInfo()}
                            />
                        </div>
                    :
                        null
                    }       
                    {this.state.status == "DisplayVideo" ?
                        <span className="mt-0 ml-0">  
                            <div className="videoDisplay">
                                <ReactPlayer className="videoDisplay" 
                                    url="./assets/video.mp4" type="video/mp4" playing 
                                    onClick ={()=> this.turnVideoOff()}
                                    onEnded = {()=>this.turnVideoOff()}
                                />
                           </div>
                       </span>
                    :
                        <div className="mt-4">   
                            <img src="./assets/sidebar_icon.png" className="train_icon_endgame_img mr-5"/>   
                        </div>  
                    }
                    
                    {this.state.status == "InitialDisplay" ?
                        <div className= "text-right mt-4">
                            <Button variant="primary"  onClick={() => this.displayMoodRetirementModal()}>
                                End My Game
                            </Button>
                            {this.props.endGameReason === "PlayersChoice" ?
                                <Button className="mx-2" variant="secondary"  onClick={() => this.goToGameBoard()}>
                                    Back to GameBoard
                                </Button>
                            :
                                null
                            }
                        </div>
                    :   
                            null
                    }

                    {this.state.status == "FinancialAdvice"  ?
                        <FinancialAdvisorModal 
                            modalOpen = {this.state.openFinModal}
                            gameInfo = {() => this.props.gameInfo()}
                            adviceType = {"endGame"}
                            monthlyTotalAmt ={this.monthlyTotalAmt}
                            onClose = {() => this.closeFinancialAdvisorModal()}
                        />
                    :
                        null
                    }

                    {this.state.status == "DisplayTrainVideo" ?
                       <WaitTimeSpinner />
                    :
                        null
                    }
                </div>
                  
            </div>
      </>
    }

turnVideoOff(){
    this.state.playVideo = false;
    this.state.modalOpen = true;
    this.state.status = "displayTrainVideo";
}

goToGameBoard(){
    this.props.history.push('/game-board');
}

closeModal(){
     if (this.state.status == "DisplayMoodRetirement"){
        this.state.status = "DisplayLeaderBoard";
     }
}

closeLeaderBoardModal(updateLeaderBoard){
    if (updateLeaderBoard == "true"){
        this.updateLeaderBoard();
    }
    this.monthlyIncomeObj = calcMonthlyIncome(this.gameInfo.gameData.assetTable,
        this.gameInfo.gameData.expenseTable, this.gameInfo.gameData.loanTable);
    this.monthlyTotalAmt = this.monthlyIncomeObj.income
    this.setState({status: "FinancialAdvice"});
}

closeFinancialAdvisorModal(){
    this.state.status = ""
    if (this.props.endGameReason == "TimeElapsed"){
        this.state.status = "DisplayVideo"
   }else{
       this.state.status = "DisplayTrainVideo";
   }
   this.setState({archiveData: true});
}

archiveGameRelatedCollections(){
    this.state.archivesInitiated = true;
    this.saveArchivedGame();
    if (this.state.activityTracker == "noData"){
        this.setState({activityTrackerArchived: true});
    }else{
        this.activityTrackerCount = this.state.activityTracker.length;
        if (this.state.activityTrackerArchiveCount < this.activityTrackerCount){
            for (let i=0; i<this.activityTrackerCount; i++){
                let activityTracker = this.state.activityTracker[i];
                this.saveArchivedActivityTracker(activityTracker);
            }
        }
    }
    if (this.gameInfo.creditCardInfo.gameID == undefined){
        this.setState({creditCardArchived: true});
    }else{
        this.saveArchivedCreditCardInfo();
    }
    if (this.gameInfo.savingsInfo.gameID == undefined){
        this.setState({savingsArchived: true});
    }else{
        this.saveArchivedSavingsAmounts();
    }
    if (this.gameInfo.funStuffInfo.gameID == undefined){ 
        this.setState({funStuffArchived: true});
    }else{   
        this.saveArchivedFunStuff();
    }
    if (this.gameInfo.gamePurchases.gameID == undefined){ 
        this.setState({gamePurchasesArchived: true});
    }else{   
        this.saveArchivedGamePurchases();
    }
    
}

deleteGameRelatedCollections(){
    this.state.deletesInitiated = true;
    this.deleteGame();
    if (this.state.activityTracker == "noData"){
        this.setState({activityTrackerDeleted: true});
    }else{    
        this.deleteActivityTracker();
    }
    if (this.gameInfo.creditCardInfo.gameID == undefined){
        this.setState({creditCardDeleted: true});
    }else{
        this.deleteCreditCardInfo();
    }
    if (this.gameInfo.savingsInfo.gameID == undefined){
        this.setState({savingsDeleted: true});
    }else{
        this.deleteSavingsAmounts();
    }
    if (this.gameInfo.funStuffInfo.gameID == undefined){
        this.setState({funStuffDeleted: true});
    }else{    
        this.deleteFunStuff();
    }
    if (this.gameInfo.gamePurchases.gameID == undefined){
        this.setState({gamePurchasesDeleted: true});
    }else{    
        this.deleteGamePurchases();
    }
}

returnToHomePage(){
    this.state.returnToHomePageInitiated = true;
    alert("Your game has ended...  thanks for playing!!! " +
        "Click on the 'Game' button if you want to " +
            "start a new game.")
    this.props.history.push('/Homepage');
}

updateLeaderBoard(){
    let currentDateTime = getCurrentDateTime();
    let bodyObj = {
        user: this.gameInfo.gameData.user,
        startGameDate: this.gameInfo.gameData.startGameDate,
        moodScore: this.state.moodScore,
        retirementScore: this.state.retirementScore,
        combinedScore: this.state.combinedScore,
        gameNbrMonths: this.gameInfo.gameData.gameNbrMonths,
        dateUpdated: currentDateTime.date,
    };
    const funcName = "saveLeaderBoard";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(bodyObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                alert(funcName + "was successful");
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

saveArchivedGame() {
    this.gameID = this.gameInfo.gameData._id;
    this.bodyObj = this.gameInfo.gameData;
    const funcName = "saveArchivedGameData";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
            {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(this.bodyObj)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.setState({gameArchived: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                        this.props.processError(errorObj);
                    }
                });
                }
            });
}

saveArchivedActivityTracker(activityTracker){
    const bodyObj = {...activityTracker, user: this.gameInfo.gameData.user};
    const funcName = "saveArchivedActivityTracker";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(bodyObj)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                   this.state.activityTrackerArchiveCount += 1;
                   if (this.state.activityTrackerArchiveCount >= this.activityTrackerCount){ 
                        this.setState({activityTrackerArchived: true});
                   }
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: bodyObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}

saveArchivedCreditCardInfo(){
    let bodyObj = {...this.gameInfo.creditCardInfo, user: this.gameInfo.gameData.user};
    const funcName = "saveArchivedCreditCardInfo";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(bodyObj)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.setState({creditCardArchived: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: bodyObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}

saveArchivedSavingsAmounts(){
    let bodyObj = {...this.gameInfo.savingsInfo, user: this.gameInfo.gameData.user};
    const funcName = "saveArchivedSavingsAmounts";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
        method:'POST',
        headers: this.state.headers,
        body: JSON.stringify(bodyObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                this.setState({savingsArchived: true});
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}


saveArchivedFunStuff(){
    let bodyObj = {...this.gameInfo.funStuffInfo, user: this.gameInfo.gameData.user};
    const funcName = "saveArchivedFunStuff";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
        method:'POST',
        headers: this.state.headers,
        body: JSON.stringify(bodyObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                this.setState({funStuffArchived: true});
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

saveArchivedGamePurchases(){
    let bodyObj = {...this.gameInfo.gamePurchases, user: this.gameInfo.gameData.user};
    const funcName = "saveArchivedGamePurchases";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
        method:'POST',
        headers: this.state.headers,
        body: JSON.stringify(bodyObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                this.setState({gamePurchasesArchived: true});
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

deleteActivityTracker(activityTracker){
    const funcName = "deleteActivityTracker";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'DELETE',
            headers: this.state.headers
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.setState({activityTrackerDeleted: true})
                }else{
                    const parmObj={gameID: this.gameID};
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: parmObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}

deleteCreditCardInfo(){
    const funcName = "deleteCreditCardInfo";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                this.setState({creditCardDeleted: true});
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

deleteSavingsAmounts(){
    const funcName = "deleteSavingsInfo";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                this.setState({savingsDeleted: true});
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}   

deleteFunStuff(){
    const funcName = "deleteFunStuffInfo";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                this.setState({funStuffDeleted: true});
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}   

deleteGamePurchases(){
    const funcName = "deleteGamePurchases";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                this.setState({gamePurchasesDeleted: true});
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}   

deleteGame() {
    const funcName = "endGame";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                this.setState({gameDeleted: true});
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message};
                this.props.processError(errorObj);
            }
        });
        }
    });
}
   
}
export default withRouter(EndGame);