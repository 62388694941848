import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import initializeErrorArray from './objects/initialize_error_array';
import editDate from '../../objects/CommonUse/edit_date';
import FourColumnDisplayCard from '../shared-components/four_column_display_card';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class GameInfoArchiveModal extends Component {
        constructor(props) {
            super(props);
            this.state={
                dataValidSw: true,
                gameArchived: false,
                activityTrackerArchived: false,
                creditCardArchived: false,
                funStuffArchived: false,
                savingsArchived: false,
                processArchives: false,
                headers: setHeadersForFetch(),
                subProcess: "GameInfoArchiveModal",
                environment: Config.dataURL.currentEnv,             
            } 
            this.summaryCountArr = [];
            this.submitType = "initialDisplay";
            this.summaryIdx = -1;
            this.errArray = initializeErrorArray(3);
        }
    
  editInput(){
        this.setState({dataValidSw: true});
        this.errArray = initializeErrorArray(3);
        this.getAndEditFormValues();
        if (this.state.dataValidSw === true){
            this.displayRecordCounts();
        }
  }

  getAndEditFormValues(){
      this.archiveDate = document.getElementById("archiveDate").value;
      this.userName = document.getElementById("userName").value;
      this.maxRcdCount = document.getElementById("maxRcdCount").value;
      if (this.archiveDate === "" && this.userName === ""){
            this.errorMsg = "Must enter Archive Date or User Name";
            this.checkErrorArray(0);
            this.errorMsg = "Must enter Archive Date or User Name";
            this.checkErrorArray(1);
      }else{
            if (this.archiveDate !== ""){
                let dateEditObj = editDate(this.archiveDate);
                if (dateEditObj.dateIsValid === false){
                    this.errorMsg = dateEditObj.errorMsg;
                    this.checkErrorArray(0)
                }else{
                    this.parmEntered = "date";
                }
            }else{
                this.parmEntered = "user";
            }
      }
      if (this.maxRcdCount !== ""){
            if (+this.maxRcdCount < 0 || +this.maxRcdCount > 10){
                 this.errorMsg = "Maximum Record Count must be between 0 and 10";
                 this.checkErrorArray(2);
            }
      }
  }
    
  checkErrorArray(msgIndex){
    if (this.errorMsg !== undefined && this.errorMsg !== ""){
          this.errArray[msgIndex] = {textType: "text-danger", 
            message: this.errorMsg};
        this.setState({dataValidSw: false});
    }
  }
     
    render() {
        if (this.summaryCountArr.length > 0){
            this.submitType = "countsDisplayed"
            this.summaryRecordCounts = this.summaryCountArr.map((counts,index) =>
            <FourColumnDisplayCard key={index} tableInfo={counts} 
                />
            );  
        }
    
    return <>
         <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
            size="med"  scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title>Archive Old Games and Related Info Parameters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form className="text-left">
                <p>Please complete the fields on this form and then click 
                   'Submit'.</p>
                           
                     <div >
                        <label className="font-weight-bold" htmlFor="archiveDate">
                            Archive Date (YYYY/MM/DD):&nbsp;&nbsp;</label>
                        <span className={  this.errArray[0].textType}>
                            &nbsp;{  this.errArray[0].message}</span>
                        <input type="text" className="col-lg" id="archiveDate" name="archiveDate"></input> 
                  
                    </div>
                    <h4 className="text-center">....OR....</h4>
                    <div >
                        <label className="font-weight-bold" htmlFor="userName">
                            User (Player) Name:&nbsp;&nbsp;</label>
                        <span className={  this.errArray[1].textType}>
                            &nbsp;{  this.errArray[1].message}</span>
                        <input type="text" className="col-lg" id="userName" name="userName"></input> 
                  
                    </div>
                    <div >
                        <label className="mt-3 font-weight-bold" htmlFor="maxRcdCount">
                            Max Record Count (optional):&nbsp;&nbsp;</label>
                        <span className={  this.errArray[2].textType}>
                            &nbsp;{  this.errArray[2].message}</span>
                        <input type="text" className="col-sm" id="maxRcdCount" name="maxRcdCount"></input> 
                  
                    </div>
            </form>
            {this.summaryCountArr.length > 0 ?
                <span>
                    <table>
                        <thead>
                            <tr>
                                <th className="p-0 text-left" scope="col">Record Type</th>
                                <th className="p-0 text-center" scope="col">Total Active Records</th>
                                <th className="p-0 text-center" scope="col">Archive Select Count</th>
                                <th className="p-0 text-center" scope="col">Orphan Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.summaryRecordCounts}
                        </tbody>
                    </table>
                    <div>
                        Click the Submit button below to archive and delete records.
                    </div>
                </span>
            :
                null
            }

        </Modal.Body>
        <Modal.Footer className="p-0" >
          {this.state.dataValidSw ?
            <div></div>
          :
            <div className="text-danger">Please Correct errors and try-again.</div>
          }
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
              Close
          </Button>
          {this.submitType === "initialDisplay"   ?
                <Button variant="primary"  onClick={() => this.editInput()}>
                    Submit
                </Button>
          :
            <span>
                {this.submitType === "countsDisplayed" ?
                    <Button variant="primary"  onClick={() => this.archiveDeleteRecords()}>
                        Submit Archive/Delete
                    </Button>
                :
                    null
                }
            </span>
         }
        </Modal.Footer>
        </Modal>
        </>
    }

displayRecordCounts(){
   
    this.summaryCountArr = [];
    this.selectArchiveGameIds();
    this.archiveCreditCardArr = this.checkRelatedData(this.props.creditCardArr);
    this.archiveFunStuffArr =  this.checkRelatedData(this.props.funStuffArr);
    this.archiveSavingsArr = this.checkRelatedData(this.props.savingsArr);
    this.archiveActivityTrackerArr = this.checkRelatedData(this.props.activityTrackerArr);
    this.summaryIdxMax = this.summaryCountArr.length - 1;
     
}

selectArchiveGameIds(){
    this.gameArchiveArr = [];
    this.gameIDArr = [];
    for (let i=0; i<this.props.gameInfoArr.length; i++){
        if (this.parmEntered === "date" && this.props.gameInfoArr[i].dateUpdated < this.archiveDate){
            this.gameArchiveArr.push(this.props.gameInfoArr[i]);
        }else{
            if (this.parmEntered === "user" && this.props.gameInfoArr[i].user === this.userName){
                this.gameArchiveArr.push(this.props.gameInfoArr[i]);
            }
        }
        this.gameIDArr.push(this.props.gameInfoArr[i].gameID);
    }
    const summaryCountObj = {column1: "game", column2: this.props.gameInfoArr.length,
        column3: this.gameArchiveArr.length, column4: 0, totalArchiveCount: this.gameArchiveArr.length};
    this.summaryCountArr.push(summaryCountObj);
}

checkRelatedData(inputData){
    let outputArr = [];
    let updateDateCount = 0;
    let orphanCount = 0;
    let user = "";
    const recordType = inputData[0].recordType;
    console.log("recordType: ", recordType);
    for (let i=0; i<inputData.length; i++){
        let archiveType = "none";
        const gameInfoObj = this.gameArchiveArr.find(elem => elem.gameID === inputData[i].gameID);
        if (gameInfoObj !== undefined){
            user = gameInfoObj.user;
            archiveType = "related";
            updateDateCount += 1;
        }
        if (!this.gameIDArr.includes(inputData[i].gameID)){
            user = "orphan";
            archiveType = "orphan";
            orphanCount += 1;
        }
        if (archiveType !== "none"){
            const outputArrObj = {...inputData[i], archiveType: archiveType, user: user};
            outputArr.push(outputArrObj);
        }
    }
    console.log("outputArr: ", outputArr);
    const totalArchiveCount = updateDateCount + orphanCount;
    const summaryCountObj = {column1: recordType, column2: inputData.length, column3: updateDateCount,
        column4: orphanCount, totalArchiveCount: totalArchiveCount};
    this.summaryCountArr.push(summaryCountObj);
    return outputArr;
}

archiveDeleteRecords(){
    this.summaryIdx = this.summaryIdx + 1;
    if (this.summaryIdx <= this.summaryIdxMax){
        console.log("summaryIdx is: ", this.summaryIdx);
        console.log("record Type in archiveDeleteRecords: ", this.summaryCountArr[this.summaryIdx]);
        if (this.summaryCountArr[this.summaryIdx].totalArchiveCount > 0){
            this.archiveArr = [];
            this.recordType = this.summaryCountArr[this.summaryIdx].column1;
            this.determineArchiveArray();
            this.currentIdx = -1;
            this.maxIndx = this.archiveArr.length - 1;
            this.processArchiveArr();
        }else{
            this.archiveDeleteRecords();
        }
    }else{    
        alert ("All archives are complete!!");
        this.props.onClose();
    }
   
}

determineArchiveArray(){
    switch (this.recordType){
        case "game": 
            this.archiveArr = this.gameArchiveArr;
            break;
        case "creditCard":
            this.archiveArr = this.archiveCreditCardArr;
            break;
        case "funStuff":
            this.archiveArr = this.archiveFunStuffArr;
            break;
        case "savings":
            this.archiveArr = this.archiveSavingsArr;
            break;
        case "activityTracker":
            this.archiveArr = this.archiveActivityTrackerArr;
            break;
        default:
            break;
    }
}
    
processArchiveArr(){
    this.currentIdx += 1;
    this.setMaximumRecords();
    if (this.currentIdx <= this.maxNbrArchiveRcds){
        this.gameID = this.archiveArr[this.currentIdx].gameID;
        this.gameUser = this.archiveArr[this.currentIdx].user;
        switch (this.recordType){
            case "game":
                this.getGame();
                break;
            case "creditCard":
                this.getCreditCard();
                break;
            case "funStuff":
                this.getFunStuff();
                break;
            case "savings":
                this.getSavings();
                break;
            case "activityTracker":
                this.activityCategory = this.archiveArr[this.currentIdx].category;
                this.getActivityTracker();
                break;
            default:
                break;
        }
    }else{
        this.archiveDeleteRecords();
    }
}

setMaximumRecords(){
    //  setting max to 15 due to run time issues.
    if (this.maxIndx > 10){
        this.maxIndx = 15;
    }
    if (this.maxRcdCount !== "" && this.maxRcdCount < this.maxIndx){
        // subtracting 1 from max record count because it is compared to an index (not length)
        this.maxNbrArchiveRcds = this.maxRcdCount - 1;
    }else{
        this.maxNbrArchiveRcds = this.maxIndx;
    }
}

getGame(){
    const funcName = "loadGame";
    const urlParm = "?mode=fullLoad&rcdKey=" + this.gameUser;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
            {method:'GET', headers: this.state.headers})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode === 200){
                            this.gameData = output.body;
                            this.saveArchivedGame();
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    });
                };
            });
}

    
saveArchivedGame() {
    this.gameID = this.gameData._id;
    this.bodyObj = this.gameData;
    const funcName = "saveArchivedGameData";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
            {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(this.bodyObj)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.deleteGame();
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                        this.props.processError(errorObj);
                    }
                });
                }
            });
}

deleteGame() {
    const funcName = "endGame";
    const urlParm = "?rcdKey=" + this.gameUser;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.archiveNextRecordSw = true;
                this.processArchiveArr();
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message};
                this.props.processError(errorObj);
            }
        });
        }
    });
}
    
getCreditCard() {
    const funcName = "loadCreditCardInfo";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        { method: 'GET', headers: this.state.headers })
        .then(response => {
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.creditCardInfo = output.body;
                    this.saveArchivedCreditCardInfo();
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                    
                }
            });
        });
}

saveArchivedCreditCardInfo(){
    let bodyObj = {...this.creditCardInfo, user: this.gameUser};
    const funcName = "saveArchivedCreditCardInfo";
    const urlParm = "?skipCompleteCheck=true";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(bodyObj)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.deleteCreditCardInfo();
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: bodyObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}

deleteCreditCardInfo(){
    const funcName = "deleteCreditCardInfo";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.archiveNextRecordSw = true;
                this.processArchiveArr();
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

getFunStuff() {
    const funcName = "loadFunStuff";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        { method: 'GET', headers: this.state.headers })
        .then(response => {
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.funStuffInfo = output.body;
                    this.saveArchivedFunStuff();
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.processErrorReturn(errorObj);
                }
            });
        });
}

saveArchivedFunStuff(){
    let bodyObj = {...this.funStuffInfo, user: this.gameUser};
    const funcName = "saveArchivedFunStuff";
    const urlParm = "?skipCompleteCheck=true";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
        method:'POST',
        headers: this.state.headers,
        body: JSON.stringify(bodyObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.deleteFunStuff();
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

deleteFunStuff(){
    const funcName = "deleteFunStuffInfo";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.processArchiveArr();
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}   

getSavings() {
    const funcName = "loadSavings";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        { method: 'GET', headers: this.state.headers })
        .then(response => {
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.savingsInfo = output.body;
                    this.saveArchivedSavingsAmounts();
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.processErrorReturn(errorObj);
                    
                }
            });
        });
}


saveArchivedSavingsAmounts(){
    let bodyObj = {...this.savingsInfo, user: this.gameUser};
    const funcName = "saveArchivedSavingsAmounts";
    const urlParm = "?skipCompleteCheck=true";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
        method:'POST',
        headers: this.state.headers,
        body: JSON.stringify(bodyObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.deleteSavingsAmounts();
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

deleteSavingsAmounts(){
    const funcName = "deleteSavingsInfo";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.processArchiveArr();
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}   
 
getActivityTracker(){
    const funcName = "loadGameActivityTracker";
    const urlParm = "?gameID=" + this.gameID + "&category=" + this.activityCategory;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.activityTrackerInfo = output.body;
                        this.saveArchivedActivityTracker();
                    }else{
                        let errMessage = output.body.message + urlParm;
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: errMessage};
                        this.props.processError(errorObj);
                    }
                });
            };
            this.setState({loadingActivityTracker: false});
        });
}

saveArchivedActivityTracker(){
    const bodyObj = {...this.activityTrackerInfo, user: this.gameUser};
    const funcName = "saveArchivedActivityTracker";
    const urlParm = "?skipCompleteCheck=true";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(bodyObj)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.deleteActivityTracker();
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: bodyObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}

deleteActivityTracker(){
    const funcName = "deleteActivityTracker";
    const urlParm = "?gameID=" + this.gameID + "&category=" + this.activityCategory;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'DELETE',
            headers: this.state.headers
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.processArchiveArr();
                }else{
                    const parmObj={gameID: this.gameID};
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: parmObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}

}

export default GameInfoArchiveModal;