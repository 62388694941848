import "./GameBoardActivities/gameBoardActivities.css";
import React from 'react';
import { withRouter } from "react-router";
import { Button } from 'react-bootstrap';
import SideHustleInformationText from './text/side_hustle_information_text';
import VolunteerInformationText from "./text/volunteer_information_text";
import HaveFunInformationText from "./text/have_fun_information_text";
import VacationInformationText from "./text/vacation_information_text";
import TimerDisplayModal from './timer_display_modal';
import WordPuzzleDataset from './Datasets/word_puzzle_dataset';
import ActivityLevelsDataset from "./Datasets/activity_levels";
import {findDataObject} from "../../objects/CommonUse/find_data_object";
import CircusJigsawPuzzleDataset from "./Datasets/circus_jigsaw_puzzle_dataset";
import ComedyClubDataset from "./Datasets/comedy_club_dataset";
import VacationImageDataset from "./Datasets/vacation_image_dataset";
import ZooAnimalsDynamicDataset from "./Datasets/zoo_animals_dynamic_dataset";
import CoatDriveDynamicDataset from "./Datasets/coat_drive_dynamic_dataset";
import shuffleArray from "../../objects/CommonUse/shuffleArray";
import { setScreenSizeParameters } from "../../objects/CommonUse/set_screen_size_parameters";


class StartActivity extends React.Component {
    constructor(props) {
        super(props);
        props.onOpen();
        this.activityInfo = this.props.activityInfo();
        this.state = {
           activityCategory: "",
           activityName: "",
           endActivity: "",
           maxNbrGames: "",
           preProcessComplete: false,
           openModal: false,
           modalType: "",
           activityType: "",
           timerInfo: {},
           wordListArr: [],
           score: findDataObject("activityScore").score,
           nbrAttempts: 0, 
           imageSrc: "",
           dndDataset: "",
        }    
    }

  preDisplayProcess(){
       this.getBasicActivityInfo();
       this.getGameParameters();
       this.updateInformationText();
       this.state.preProcessComplete = true;
  }  

   
  getBasicActivityInfo(){
      this.state.activityCategory = this.activityInfo.slctActivity.activityCategory;
      this.state.activityName = this.activityInfo.slctActivity.activityName;
      this.defaultImgSrc = "./assets/Activities/" + this.activityInfo.slctActivity.activityCategory + "/" +
        this.activityInfo.slctActivity.imageName;
  }
    
  getGameParameters(){
    let activityLevels = ActivityLevelsDataset;
    this.activityParameters = activityLevels.find(elem => elem.activityName === this.activityInfo.slctActivity.shortName);
    if (this.activityParameters == undefined){
         alert ("something happened... not able to find the activity parameter.");
    }else{
           this.determineActivityLevel();
           this.setActivityParameters();
           this.setActivityscoreInfo();
    }
}

determineActivityLevel(){
    let activityTracker = this.activityInfo.activityTracker;
    if (activityTracker == "noData"){
        this.levelNumber = 1;
    }else{
        let activityName = this.activityInfo.slctActivity.shortName;
        const activityObj = activityTracker.activityTable.find(elem => elem.activityName === activityName);
        if (activityObj == undefined){
            this.levelNumber = 1;
        }else{
            this.levelNumber = +activityObj.level;
        }
    }
    let maxLevels = this.activityParameters.levels.length;
    let levelInfo = {levelNbr: this.levelNumber, maxLevels: maxLevels};
    this.props.setActivityInfo(levelInfo, 'levelInfo');
}

setActivityParameters(){
    let slctIndx=this.levelNumber - 1;
    this.parameters = this.activityParameters.levels[slctIndx];
    this.state.maxNbrGames = this.parameters.maxNbrGames;
    this.state.score.nbrCompleted = 0;
    this.state.score.points = 0;
    this.state.score.gamesRemaining = this.parameters.maxNbrGames;
              
    if (this.activityParameters.timerValid == true){
        let minutes = 0;
        if (this.parameters.minutesAllowed != undefined){
             minutes = +this.parameters.minutesAllowed;
        }
        this.state.timerInfo = {timerValid: true, nbrMinutes: +minutes, 
                    nbrSeconds: +this.parameters.secondsAllowed}
    }else{
         this.state.timerInfo = {timerValid: false, nbrMinutes: 0, nbrSeconds: 0}
    }
}

setActivityscoreInfo(){
    if (this.state.score.timeRemaining == undefined){
          this.state.score.timeRemaining = {minutes: 0, seconds: 0};
    }
    this.state.score.timeRemaining.minutes = this.state.timerInfo.nbrMinutes;
    this.state.score.timeRemaining.seconds = this.state.timerInfo.nbrSeconds;
    this.state.score.maxScore = 0;
    this.props.setActivityInfo(this.state.score, 'activityScore');
}    

  updateInformationText(){
    switch(this.state.activityCategory) {
        case "SideHustle":
            this.informationTextArr = SideHustleInformationText;
            break;
        case "Volunteer":
            this.informationTextArr = VolunteerInformationText;
            break;
        case "HaveFun":
            this.informationTextArr = HaveFunInformationText;
            break;
        case "Vacation":
            this.informationTextArr = VacationInformationText;
            break;
    }
    this.getTextForActivitySelected();
  
  }  
  
  getTextForActivitySelected(){
        this.state.endActivity = this.activityInfo.slctActivity.endActivity;
        for (let i=0; i<this.informationTextArr.length; i++){
            if( this.informationTextArr[i].activityName == this.activityInfo.slctActivity.shortName){
                  this.informationText = this.informationTextArr[i].rules;
                  this.rulesText = this.informationTextArr[i].rulesBrief;
                  break;
            }
        }
        let replaceText = "";
        if (this.state.endActivity == "repetitions"){
            replaceText = this.state.maxNbrGames;
        }else{
            let zeroFillNbrSeconds = "";
            if (this.parameters.secondsAllowed < 10){
                  zeroFillNbrSeconds = "0" + this.parameters.secondsAllowed;
            }else{
                  zeroFillNbrSeconds = this.parameters.secondsAllowed;
            }
            replaceText = this.parameters.minutesAllowed + ":" + zeroFillNbrSeconds;
        }
        if (this.informationText != undefined) {
            this.informationText = this.informationText.replace("||nbrTimes||", replaceText);
        }
  }

  startActivity(){
        this.props.setActivityInfo(true, 'activityStarted');
        this.state.openModal = true;
        this.state.modalType = this.activityInfo.slctActivity.shortName;
        this.state.activityType = this.activityInfo.slctActivity.activityType;
        this.modalSize = this.parameters.modalSize;
        // if screen size is narrow, then upsize the modal by 1 size
        if (this.props.screenSize == "narrow"){
             switch (this.modalSize){
                 case "sm":
                    this.modalSize = "md";
                    break;
                case "md":
                    this.modalSize = "lg";
                    break;
                default:
                    this.modalSize = "xl";
                    break;
             };
        }
        if (this.activityInfo.slctActivity.smallImageName != undefined &&
            this.activityInfo.slctActivity.smallImageName != ""){
            this.state.imageSrc = "./assets/Activities/" + this.activityInfo.slctActivity.activityCategory + "/" + 
            this.activityInfo.slctActivity.smallImageName;
        }
        
        let nbrColumns = 0;
        switch(this.state.activityType) {
            case "maze":
                let width = +this.parameters.gridWidth;
                let height = +this.parameters.gridHeight;
                let pctBlocked = +this.parameters.pctBlocked;
                this.mazeParameters = {width: width, height: height, pctBlocked: pctBlocked};
                break;
            case "wordPuzzle":
                this.getWordArr();
                break;
            case "dragNdrop":
                nbrColumns = +this.parameters.nbrColumns;
                let nbrItems = +this.parameters.nbrItems;
                let nbrInList = +this.parameters.nbrInList;
                let dataset = "";
                if (this.state.modalType == "GoToZoo" ){
                    dataset = ZooAnimalsDynamicDataset;
                }
                if (this.state.modalType == "CoatDrive"){
                    dataset = CoatDriveDynamicDataset;
                }
                if (dataset != ""){
                    this.state.dndDataset = dataset.find(elem => elem.gameName === this.parameters.gameName);
                }
                this.DNDParameters = {nbrColumns: nbrColumns, nbrItems: nbrItems, nbrInList: nbrInList};
                break;
            case "jigsawPuzzle":
                nbrColumns = +this.parameters.nbrColumns;
                let pictureDir = this.activityParameters.pictureDir;
                this.getPuzzleOptions();
                this.jigsawParameters = {pictureDir: pictureDir, puzzleArr: this.puzzleArr }
            case "comedyClub":
                this.comedyClubJokes = ComedyClubDataset;
                break;
            case "pictureReveal":
                this.getPictureDatasetForChosenItem();
                break;
        }
  } 

  getWordArr(){
        let wordListDataset = WordPuzzleDataset;
        const wordListObj = wordListDataset.find(elem => elem.listType === this.parameters.wordList);
        if (wordListObj != undefined){
             this.state.wordListArr = wordListObj;
        }
  }

  getPuzzleOptions(){
         this.puzzleArr = [];
         switch (this.state.modalType){
             case "Circus":
                 this.puzzleDataset = CircusJigsawPuzzleDataset;
                 break;
         }
         let puzzleArr = []
         for (let i=0; i<this.puzzleDataset.length; i++){
             if (this.puzzleDataset[i].level == this.levelNumber){
                 puzzleArr.push(this.puzzleDataset[i]);
             }
         }
         this.puzzleArr = shuffleArray(puzzleArr);
  }

  getPictureDatasetForChosenItem(){
        let vacationImageDataset = VacationImageDataset;
        let vacationImageObj = vacationImageDataset.find(elem => elem.vacationName === this.activityInfo.slctActivity.shortName);
        this.imageList = vacationImageObj.imageList;
        for (let i=0; i<this.imageList.length; i++){
                this.imageList[i].selected = false;
        }
    
  }

  render() {
        if (this.state.preProcessComplete == false){
            this.preDisplayProcess();
        }

        let screenParms = setScreenSizeParameters(this.props.screenSize, "activity");
        this.className = screenParms.columnClass;
        this.textClass = screenParms.textSize + " m-4 text-left";
       
  return <>
           <div className={this.className}>
           <div className="card mt-3 rounded-corners-lg text-center activity-board height-max">
            <h3 className="mt-2 text-center">Activities</h3>
            {this.state.activityCategory == "Vacation" ?
                <h5 className="mx-2 mt-0 text-center">{this.state.activityName}</h5>
            :
                <h5 className="mx-2 mt-0 text-center">{this.state.activityName} - Level Nbr {this.levelNumber}</h5>
            }
            <span className="ml-4 row-80">
                    <div className="p-0 text-center">
                            <img src={this.defaultImgSrc} className="activity_start_page_img" alt="activity picture"/>
                    </div>
                    <div className={this.textClass}>
                            {this.informationText} 
                    </div>
                          
                <span> 
                    <div className="mt-4 row mb-4" >
                        <div className="ml-4 col-sm-5">
                            <Button variant="primary"  onClick={() => this.startActivity()}>
                                Begin
                            </Button>
                        </div>
                        <div className="mr-4 col-sm-5">
                            <Button variant="primary"  onClick={() => this.finishActivity()}>
                                Finish Early
                            </Button>
                        </div>
                    
                    </div>
                </span>
            </span>
            {this.state.openModal ?
                <TimerDisplayModal
                     screenSize={this.props.screenSize}
                     levelNbr={this.levelNumber}
                     activityName={this.state.activityName}
                     show ={this.state.openModal}
                     modalType={this.state.modalType}
                     rulesText={this.rulesText}
                     image={this.state.imageSrc}
                     activityType={this.state.activityType}
                     activityCategory={this.state.activityCategory}
                     wordList={this.state.wordListArr}
                     endActivity={this.state.endActivity}
                     dataset={this.groceryShopperDataset}
                     mazeParameters={this.mazeParameters}
                     DNDParameters={this.DNDParameters}  
                     dndDataset={this.state.dndDataset}
                     jigsawParameters={this.jigsawParameters}
                     timerInfo={this.state.timerInfo}
                     nbrGames={this.state.maxNbrGames}
                     score={this.state.score}
                     modalSize={this.modalSize}
                     friendInfo={this.activityInfo.friendInfo}
                     comedyClubJokes={this.comedyClubJokes}
                     imageList={this.imageList}
                     finishActivity={(cumulativeScore) => this.finishActivity(cumulativeScore)}
                     onClose={(scores) => this.closeModal(scores)}
                    /> 
            :
               null
            }
        </div>
        </div>
    </>
  }

  closeModal(score){
    this.state.score = score;
    if (score.type == "finish"){
        this.finishActivity();
    }else{
        if (score.type == "done"){
            if (score.timeRemaining == undefined){
              //  if undefined then do not need to reset the 'this.state' variables                
            }else{
                this.state.timerInfo.nbrMinutes = score.timeRemaining.minutes;
                this.state.timerInfo.nbrSeconds = score.timeRemaining.seconds - 1;
            }
        }
    }
    let endActivity = false;
    if (this.activityInfo.slctActivity.endActivity == "repetitions" &&
        score.gamesRemaining <= 0) {
            endActivity = true;
    } 
    if (this.activityInfo.slctActivity.endActivity == "timer" &&
       (this.state.timerInfo.nbrMinutes == 0 && this.state.timerInfo.nbrSeconds == 0)){
            endActivity = true;
    }
    if (endActivity == true){
        this.props.setActivityInfo(score, 'activityScore');
        this.finishActivity();
    }
    this.state.openModal = false;
  }

 finishActivity(){
    this.props.onComplete();
    this.props.history.push('/finish-activity');
 }

}

export default withRouter(StartActivity);