export const createResultsArray = (columnArr) => {     
    var resultsObjectArray=[];
    var i;
    var j;  
    var k;  
    var resultArrayLine = {};
    var columnItems = "";
       
    for (i=0; i<columnArr.length; i++) {
         columnItems=columnArr[i].items;
         for (j=0; j<columnItems.length; j++) {
               
              if (columnItems[j].result != undefined) {
                    resultArrayLine = columnItems[j].result
                    resultsObjectArray.push(resultArrayLine);
               }
        }    
    }
                
    return resultsObjectArray;
}


