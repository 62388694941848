const GameChoiceMoodPoints = [
   {choiceName: "job", choiceType: "JobSalary",  item:  "Job Salary Amount", number: true,
    parameterList:
        [{lowValue: "3200.00", highValue: "9999", pointsAdjust: 2000,
            desc:  "The monthly salary for your job choice is a large amount"},  
         {lowValue: "2005.01", highValue: "3199.99", pointsAdjust: 1000,
            desc:  "The monthly salary for your job choice is a good amount"},   
         {lowValue: "0", highValue: "2005.00", pointsAdjust: 0,
            desc:  "The monthly salary for your job choice is a lower amount"}]
    },
    {choiceName: "job", choiceType: "JobMatchScore", item: "Job Matches Skills/Interests", 
      number: true,
      parameterList:
         [{lowValue: "1", highValue: "3", pointsAdjust: 4000, 
              desc: "Job is a good match for your skills/interests" },
          {lowValue: "4", highValue: "7", pointsAdjust: 2000, 
              desc: "Job is an OK match for your skills/interests"},
          {lowValue: "8", highValue: "10", pointsAdjust: 0,
              desc: "Job does not seem to be a good match for your skills/interests"}]
    },
    {choiceName: "home", choiceType: "HomeRoommate", item: "Roommate Selection", 
      number: true,
      parameterList:
        [{lowValue: "0", highValue: "0", pointsAdjust: 2000,
              desc: "No roommate selected"},
         {lowValue: "1", highValue: "1", pointsAdjust: 1000,
              desc: "You selected to have 1 roommate"},
         {lowValue: "2", highValue: "2", pointsAdjust: 0,
              desc: "You selected to have 2 roommates"}]
    },
    {choiceName: "home", choiceType: "HomeType", item: "Value of Home Selected", 
      number: true,
      parameterList:
        [{lowValue: "30", highValue: "30", pointsAdjust: 2000,
              desc:  "You have chosen the most expensive home for this type"},
         {lowValue: "20", highValue: "20", pointsAdjust: 1000,
              desc:  "You have chosen a home that has a mid-range price"},
         {lowValue: "10", highValue: "10", pointsAdjust: 0,
              desc:  "You have chosen a home that is cheapest for this type"}]
    },
    {choiceName: "furniture", choiceType: "FurnitureType", item: "Quality of Furniture Selected", 
      number: false,
      parameterList:
        [{lowValue: "Deluxe", highValue: "Deluxe", pointsAdjust: 2000,
            desc: "You have chosen the furniture that is very high quality"},
         {lowValue: "Good Value", highValue: "Good Value", pointsAdjust: 1000,
            desc:  "You have chosen furniture that is good quality"},
         {lowValue: "Cost Saver", highValue: "Cost Saver", pointsAdjust: 0,
            desc:  "You have chosen furniture that is not the best quality"} ]
    },
    {choiceName: "car", choiceType: "CarAge", item: "Age of Car Selected", number: true,
      number: true,
      parameterList:
        [{lowValue: "0", highValue: "0", pointsAdjust: 2000,
            desc:  "You have chosen to buy a brand new car"},
         {lowValue: "1", highValue: "5", pointsAdjust: 1000,
            desc: "You have chosen to buy a car that is 3 to 5 years old"},
         {lowValue: "6", highValue: "9999", pointsAdjust: 0,
            desc:  "You have chosen to buy a car that is more than 5 years old"} ]
    },
    {choiceName: "car", choiceType: "CarType", item: "Type of Car Selected", number: false,
      number: true,
      parameterList:
        [{lowValue: "400", highValue: "400", pointsAdjust: 4000,
            desc:  "You have chosen to buy a sports car"},
         {lowValue: "300", highValue: "300", pointsAdjust: 3000,
            desc:  "You have chosen to buy an SUV"},
         {lowValue: "200", highValue: "200", pointsAdjust: 2000,
            desc:  "You have chosen to buy a passenger car"},
         {lowValue: "100", highValue: "100", pointsAdjust: 0,
            desc:  "You have chosen to buy a small car"} ]
    }
]

export default GameChoiceMoodPoints