import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { calcMonthlyIncome } from '../../objects/CommonUse/calcMonthlyIncome';
import { findRemoveTableItem } from '../../objects/AssetsExpenses/find_remove_table_item';
import {setHeadersForFetch} from '../../objects/CommonUse/set_headers_for_fetch';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import replaceTextInString from '../../objects/CommonUse/replace_text_in_string';
import SavingsSetupIntro from './text/savings_setup_intro_text';
import ConfigFile from '../../datasets/config_file';
import HowToUpdate401KSavings from './text/how_to_update_401k_savings';
import DropDownOptionCard from '../shared-components/drop_down_option_card';
import FriendDisplayModal from '../shared-components/friend_display_modal';
import SavingsPolicyOptions from './datasets/savings_policy_options';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import setModalSize from '../../objects/CommonUse/set_modal_size';


class SetupSavingsModal extends Component {
            constructor(props) {
              super(props);
              this.gameInfo = this.props.gameInfo();
              this.state={
                headers: setHeadersForFetch(),  
                updateEnabled: false,   
                step: "display",
                displayInfo: true,
                displayInstructions: false,
                defaultClass: "font-weight-bold ",
                policyOptionsArr: [],
                openFriendModal: false,
                subProcess: "setupsavingsModal",
                environment: Config.dataURL.currentEnv,
              } 
              this.initializeFields();  
    }
    
    initializeFields(){
        let monthlySalaryObj = findRemoveTableItem(this.gameInfo.gameData.assetTable,
            "salary", false);
        this.monthlySalary = monthlySalaryObj.assetAmt;
        let incomeObj = calcMonthlyIncome(this.gameInfo.gameData.assetTable,
            this.gameInfo.gameData.expenseTable, this.gameInfo.gameData.loanTable);
        this.monthlyIncome = incomeObj.income;
        let unlockedFeature = 
            this.gameInfo.gameData.unlockedFeatures.find(elem => elem.featureName === "savings");
        this.savingsSetup = unlockedFeature.setup
        if (this.gameInfo.savingsInfo.monthlySavingsAmt != undefined){
            this.currentMonthlyAmt = +this.gameInfo.savingsInfo.monthlySavingsAmt;
            this.initialAcceptDecline = this.acceptDecline;
            if (this.currentMonthlyAmt > 0){
                this.buildOptionsArr("existing");
            }else{
                this.buildOptionsArr("new");
            }
        }else{
            this.currentMonthlyAmt = 0;
            this.buildOptionsArr("new");
        }
        if (this.gameInfo.savingsInfo.totalSavings != undefined){
            this.totalSavings = this.gameInfo.savingsInfo.totalSavings;
        }else{
            this.totalSavings = 0;
        }  
        this.introText();
        this.acceptDeclineClass = this.state.defaultClass;
        this.xferAmountClass = this.state.defaultClass;
        this.oneTimeAmountClass = this.state.defaultClass + " col-sm-3";;
        this.monthlyAmtClass = this.state.defaultClass + "col-sm-3 ml-4";
        this.howToInstructions = HowToUpdate401KSavings;
    }

    buildOptionsArr(type){
        this.savingsAccountStatus = type;
        let savingsPolicyOptions = SavingsPolicyOptions;
        let policyType = savingsPolicyOptions.find(elem => elem.type == type);
        this.state.policyOptionsArr = policyType.optionList;
    }

    introText(){
        this.introText1 = SavingsSetupIntro[0];
        let introText2 = SavingsSetupIntro[1];
        this.overageAmt = ConfigFile.unlockedFeatures.savingsOverDraftFee;
        let string = this.overageAmt + "%";
        let replaceObj = {string: string};
        this.introText2 = replaceTextInString(introText2, replaceObj, "overageAmt", "other");
    }
     
    displayFriendModal(){
        this.state.openFriendModal = true;
        this.askAFriendInfo = {topic: "setupSavings"}
    }
    
    closeFriendModal(){
        this.state.openFriendModal = false;
    }

    collapseInfoDisplay(){
        this.state.displayInfo = false;
    }
   
    expandInfoDisplay(){
        this.state.displayInfo = true;
    }

    collapseInstructionDisplay(){
        this.state.displayInstructions = false;
    }

    expandInstructionDisplay(){
        this.state.displayInstructions = true;
    }

    changeSavingsPolicy(){
        this.state.step = "change";
        this.state.displayInfo = false;
    }
     
    editAmounts(){
        this.validAmounts = true;
        this.getFormEntries();
        // edits done in reverse display order so messages will come out in correct order
        this.editMonthlyAmt();
        this.editOneTimeAmount();
        this.editSavingsAccountSetup();
        if (this.totalSavings > 0) {
            this.editXferAmount();
        }else{
            this.xferAmt = 0;
        }
        if (this.validAmounts == true){
            this.confirmSomethingHasChanged();
        }
        if (this.validAmounts == true){
            this.state.step = "confirm";
            this.state.updateEnabled = true;
            this.savingsPolicyComplete = true;
           
            this.message = "Entries valid.  Click Confirm Change to save changes."
            this.msgClass = "";
        }else{
            this.msgClass = "text-danger";
        }
    }

    getFormEntries(){
        this.acceptDecline = document.getElementById("acceptDecline").value;
        if (this.acceptDecline == "decline"){
            this.setupSavingsSw = false;
            this.savingsSetup = "No"
        }else{
            this.setupSavingsSw = true;
            this.savingsSetup = "Yes"
        }
        if (this.totalSavings > 0){
            this.xferAmt = document.getElementById("xferToCashAmt").value;
        }
        this.oneTimeAmt = document.getElementById("oneTimeAmt").value;
        this.monthlyAmt = document.getElementById("monthlyAmt").value;
    }

    editSavingsAccountSetup(){
        this.acceptDeclineClass = this.state.defaultClass;
        if (this.acceptDecline == "none"){
            this.validAmounts = false;
            this.acceptDeclineClass = this.state.defaultClass + "text-danger";
            this.message = "Must accept or decline the setup of a savings account."
        }
    }

    editXferAmount(){     
        this.xferAmountClass = this.state.defaultClass;
        this.xferAmt = +this.xferAmt;
        if (isNaN(this.xferAmt)){
            this.validAmounts = false;
            this.xferAmountClass = this.xferAmountClass + " text-danger";
            this.message = "Amount to Transfer from 401K to Cash Must be Numeric";
        }else{
            if (this.xferAmt < 0 ){
                this.xferAmountClass = this.xferAmountClass + " text-danger";
                this.validAmounts = false;
                this.message = "Transfer amount from 401K cannot be negative"
            }
            if (this.validAmounts == true){
                if (this.xferAmt > 0){
                    this.transferFee = (+this.xferAmt * +this.overageAmt / 100).toFixed(2);
                    let totalWithdraw = +this.xferAmt + +this.transferFee;
                    if (totalWithdraw > +this.totalSavings){
                        this.validAmounts = false;
                        this.xferAmountClass = this.xferAmountClass + " text-danger";
                        this.message = "Transfer amount plus transfer fee must be less than or equal to " +
                            this.totalSavings;
                    }
                }else{
                    this.transferFee = 0;
                }
            }
            if (this.xferAmt > +this.totalSavings){
                this.validAmounts = false;
                this.xferAmountClass = this.xferAmountClass + " text-danger";
                this.message = "Transfer amount from 401K must be less than or equal to " +
                        this.totalSavings;
            }
        }
     }

    editOneTimeAmount(){
        this.oneTimeAmountClass = this.state.defaultClass + " col-sm-3";
        this.oneTimeAmt = +this.oneTimeAmt;
        this.oneTimeTextType = "";
        if (this.setupSavingsSw == true){
            if (isNaN(this.oneTimeAmt)){
                this.validAmounts = false;
                this.message = "One Time Amount Must be Numeric";
                this.oneTimeAmountClass = this.oneTimeAmountClass + " text-danger";
            }else{
                if (this.oneTimeAmt == 0){
                    //
                }else{
                    if (this.oneTimeAmt < 9.99 || this.oneTimeAmt > this.gameInfo.gameData.currentCash){
                        this.validAmounts = false;
                        this.oneTimeAmountClass = this.oneTimeAmountClass + " text-danger";
                        this.message = "One Time Amount must be between 10.00 and  " +
                            this.gameInfo.gameData.currentCash + " dollars";
                    }
                    if (this.acceptDecline == "decline"){
                        this.validAmounts = false;
                        this.oneTimeAmountClass = this.oneTimeAmountClass + " text-danger";
                        this.message = "You must setup a 401K account in order to deposit a one-time amount."    
                    }
                }
            }
        }        
    }
    
    editMonthlyAmt(){
        if (this.acceptDecline == "retain") {
            if (this.monthlyAmt != "" &&
                this.monthlyAmt != this.currentMonthlyAmt) {
                    this.validAmounts = false;
                    this.monthlyAmtClass = this.monthlyAmtClass + " text-danger";
                    this.message = "Cannot change Amount if policy is No-Change";
            }else{
                this.monthlyAmt = this.currentMonthlyAmt;
            }    
        }
        this.monthlyAmt = +this.monthlyAmt;
        this.monthlyAmtClass = this.state.defaultClass + " col-sm-3 ml-4";
        if (this.setupSavingsSw == true){
            if (isNaN(this.monthlyAmt)){
                this.validAmounts = false;
                this.monthlyAmtClass = this.monthlyAmtClass + " text-danger";
                this.message = "Monthly Amount Must be Numeric";
            }else{
                if (this.monthlyAmt == 0){
                    this.validAmounts = false;
                    this.monthlyAmtClass = this.monthlyAmtClass + "text-danger";
                    this.message="Savings Policy must be 'Decline' if Monthly Amount is $0."
                }else{
                    if (this.monthlyAmt < 9.99 || this.monthlyAmt > this.monthlyIncome){
                        this.validAmounts = false;
                        this.monthlyAmtClass = this.monthlyAmtClass + " text-danger";
                        this.message = "Monthly Amount must be between 10.00 and " +
                                this.monthlyIncome + " dollars";
                    }
                }
                if (this.acceptDecline == "decline" && this.monthlyAmt > 0){
                    this.validAmounts = false;
                    this.monthlyAmtClass = this.monthlyAmtClass + " text-danger";
                    this.message = "You must setup a 401K account in order to deposit a monthly amount."  
                }
            }
        }
    }

    confirmSomethingHasChanged(){
        if (this.acceptDecline == "decline"){ 
            if ((this.gameInfo.savingsInfo.currentlyActive != undefined &&
                this.gameInfo.savingsInfo.currentlyActive == true) ||
                this.xferAmt != 0 || this.oneTimeAmt != 0){
                //     this is a valid change
            }else{
                this.validAmounts = false;
                this.message = "No changes have been entered."
            }
        }
        if (this.acceptDecline == "retain"){
            if (this.gameInfo.savingsInfo.currentlyActive != undefined &&
                this.gameInfo.savingsInfo.currentlyActive == true &&
                this.xferAmt > 0){
                //     this is a valid change
            }else{
                this.validAmounts = false;
                this.message = "No changes have been entered."
            }
        }
        if (this.acceptDecline == "decline" || this.acceptDecline == "retain"){
                // conditions checked above
        }else{
            if (this.monthlyAmt == this.currentMonthlyAmt &&
           
                this.xferAmt == 0 &&
                this.oneTimeAmt == 0){
                    this.validAmounts = false;
                    this.message = "No changes to current policy have been entered."
            }
        }
    }

    render() {
            this.savingsPolicyOptions = this.state.policyOptionsArr.map((optionsList,index) =>
                <DropDownOptionCard options={optionsList} key={index} />
            );
            this.gutters = setModalSize(this.props.screenSize);
            this.textClass = "mx-2 mt-2"

    return <>
      
      <Modal show={this.props.show}  onHide={() => this.props.onClose("none")} size="lg" scrollable="true"
            dialogClassName={this.gutters}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title className="text-center">Setup 401K (Savings) Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <span>
                 {this.state.displayInfo ?
                    <span>
                        <div className="mx-2 mt-2">
                            {this.introText1}
                        </div>
                        <div className={this.textClass}>
                            {this.introText2}
                        </div>
                    </span>
                :
                    null
                }
                {this.state.displayInfo ?
                    <div className="mt-0 mb-2 text-right">
                        <Button variant="outline-info" size="sm"
                            onClick={() => this.collapseInfoDisplay()}>
                            Collapse 
                        </Button>
                    </div>
                :
                    <div className="p-0 mb-2 text-right">
                        <Button variant="outline-info" size="sm"
                            onClick={() => this.expandInfoDisplay()}>
                              Learn More
                        </Button>
                    </div>
                }    
                <span className="mt-4 row-100">
                    <table>
                        <tbody>
                            <tr>
                                <th className="col-sm-4">Current Cash:</th>
                                <td className="col-sm-3 text-right">
                                    {doubleToStringMonthly(this.gameInfo.gameData.currentCash)}</td>
                                <th className="col-sm-6">Monthly Salary:</th>
                                <td className="col-sm-3 text-right">    
                                    {doubleToStringMonthly(this.monthlySalary)}</td>
                            </tr>
                            <tr>
                                <th className="col-sm-4">Total 401K Savings:</th>
                                <td className="col-sm-3 text-right">
                                    {doubleToStringMonthly(this.totalSavings)}</td>
                                    <th className="col-sm-6">Monthly Net Income:</th>
                                <td className="col-sm-3 text-right">
                                    {doubleToStringMonthly(this.monthlyIncome)}</td> 
                            </tr>
                            <tr>
                                <th className="col-sm-4">Current Policy:</th>
                                <td className="col-sm-3 text-right">
                                    {this.savingsSetup}</td> 
                                <th className="col-sm-6">Monthly 401K Savings Amt:</th>
                                <td className="col-sm-3 text-right">{doubleToStringMonthly(this.currentMonthlyAmt)}</td>
                            </tr>
                        </tbody>
                    </table>
                </span>
               
                {this.state.step == "change" ?
                    <span>
                        {this.totalSavings > 0 ?
                            <div className="ml-4 mt-4">
                                <label className={this.xferAmountClass} htmlFor="xferToCashAmt">
                                    One Time Transfer from 401K to Cash:&nbsp;&nbsp;</label>
                                <input type="text" className="col-med" id="xferToCashAmt"
                                     name="xferToCashAmt"></input> 
                            </div>
                        :
                            null
                        }       
                        {this.transferFee > 0 ?
                            <div className="font-weight-bold ml-4 mt-4">
                                  Transfer Fee:&nbsp;&nbsp;
                            {doubleToStringMonthly(this.transferFee)}
                            </div>
                        :
                            null
                        }
                        <div className="font-weight-bold mt-4 text-center">
                            ----------------------------------401K Savings Amounts -------------------------------------
                        </div> 
                        {this.state.displayInstructions ?
                            <span>
                            {this.howToInstructions}
                            <div className="mt-0 mb-2 text-right">
                                <Button variant="outline-info" size="sm"
                                    onClick={() => this.collapseInstructionDisplay()}>
                                    Collapse 
                                </Button>
                            </div>
                            </span>
                        :
                            <span>
                            <p>Enter the fields below and then click the "Submit Change"
                                button to setup/update your 401K Savings plan.  </p>
                                <div className="p-0 mb-2 text-right">
                                    <Button variant="outline-info" size="sm"
                                        onClick={() => this.expandInstructionDisplay()}>
                                            Learn More
                                    </Button>
                                </div>
                            </span>
                        }    
                        <div className="mt-4 mx-4">
                            <label className={this.acceptDeclineClass} htmlFor="acceptDecline">
                                What do you want to Do? (Savings Policy):&nbsp;&nbsp;</label>
                            <select className="btn btn-sm btn-outline-secondary dropdown-toggle" 
                                id="acceptDecline">&nbsp;&nbsp;(
                                    {this.savingsPolicyOptions}
                            </select>
                        </div>            
                        <div className="ml-2 mt-4 row">
                            <div>
                                <label className={this.oneTimeAmountClass} htmlFor="oneTimeAmt">
                                    One Time Amount:</label>
                                <input className="col-sm-2 p-0" type="text" id="oneTimeAmt" 
                                     name="oneTimeAmt"></input> 
                                <label className={this.monthlyAmtClass} htmlFor="monthlyAmt">
                                    Monthly Amount:</label>
                                <input className="col-sm-2 text-right" type="text" id="monthlyAmt" 
                                    name="monthlyAmt"></input> 
                            </div>
                           
                        </div>
                    </span>
                :
                    null
                }

                {this.state.step == "confirm" ?
                    <span className="ml-4 mt-4">
                        {this.totalSavings > 0 ?
                            <span className="row-100">
                                <div className="font-weight-bold ml-3">One-Time Transfer...</div>
                                <table className="ml-0">
                                    <tbody>
                                        <tr>
                                            <th className="col-sm-4">From 401K to Cash:</th>
                                            <td className="col-sm-3 mr-1 text-right">    
                                                {doubleToStringMonthly(this.xferAmt)}</td>
                                            <th className="col-sm-6">Transfer Fee:</th>
                                            <td className="col-sm-3 text-right">
                                                {doubleToStringMonthly(this.transferFee)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </span>
                        :
                            null
                        }
                        <div className="font-weight-bold mt-2 text-center">
                            ------------------------------------- Savings Amounts ---------------------------------------
                        </div>  
                        <p>If the information displayed below is correct, click the "Confirm Change"
                           button.  If you wish to make changes to the amounts displayed, click the "Redo Change"
                           button.  Click "Close" to abandon the 401K Savings Setup process. </p> 
                        <div className="row ml-2 mt-2">
                            <div className="col-sm-6 font-weight-bold">
                                Setup/retain a 401K Savings Account? :</div>
                            <div className="col-sm-2">{this.savingsSetup}</div>   
                        </div>         
                        <div className="row ml-2 mt-2">
                            <div className="col-sm-4 font-weight-bold">One Time Amount:</div>
                            <div className="col-sm-2">{doubleToStringMonthly(this.oneTimeAmt)}</div>
                            <div className="col-sm-4 font-weight-bold">Monthly Amount:</div>
                            <div className="col-sm-2">{doubleToStringMonthly(this.monthlyAmt)}</div>
                        </div>
                     
                    </span>
                :
                    null
                }

                {this.state.openFriendModal ?
                    <FriendDisplayModal  show={this.state.openFriendModal} 
                        title={this.state.newCarDesc}
                        gameProcess="GameViews"
                        askAFriendInfo={this.askAFriendInfo}
                        friendData={this.state.emptyArr}
                        onClose={() => this.closeFriendModal()} 
                        gameInfo = {() => this.props.gameInfo()}
                    />
                :
                    null
                }
         
            </span>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom">
            <div className={this.msgClass}>{this.message}</div>
            {this.state.step == "display" ?
                <span>
                    <Button className="mr-2" variant="info"  onClick={() => this.displayFriendModal()} >
                        Ask a Friend
                    </Button>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.changeSavingsPolicy()} >
                        Change Savings Policy
                    </Button>
                </span>
            :
                null
            }
            {this.state.step == "change" ?
                <Button variant="secondary"  onClick={() => this.editAmounts()} >
                    Submit Change
                </Button> 
            :
                null
            }
            {this.state.step == "confirm" ?
                <span>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.saveSavingsPolicy()} >
                        Confirm Change
                    </Button>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.changeSavingsPolicy()} >
                        Redo Change
                    </Button>
                </span>
            :
                null
            }
            <Button variant="primary"  onClick={() => this.props.onClose("none")}>
                     Close
            </Button>
           
        </Modal.Footer>
        </Modal>
        </>
    }

saveSavingsPolicy(){
    this.updateUnlockedFeatures();
    this.updateSavingsInfoData();
    this.props.onClose("complete");
}

updateUnlockedFeatures(){  
    let cashAmt = (+this.gameInfo.gameData.currentCash - +this.oneTimeAmt + +this.xferAmt).toFixed(2);
    let index = this.gameInfo.gameData.unlockedFeatures.findIndex( element => {
        if (element.featureName === 'savings') {
          return true;
        }
      });
    let unlockedFeaturesObj = this.gameInfo.gameData.unlockedFeatures[index];
    let activeInactive = "inactive";
    if (this.acceptDecline == "accept" || this.acceptDecline == "change" 
        || this.acceptDecline == "retain"){
        activeInactive = "active";
    }
    unlockedFeaturesObj.setup = activeInactive;
    let iconTable = this.gameInfo.gameData.iconTable;
    let startIdx = iconTable.length - 1;
    for (let i=startIdx; i>=0; i--){
        if (iconTable[i].iconName == "savingsUnlocked" ||
            iconTable[i].iconName == "savingsGTIncome" ||
            iconTable[i].iconName == "savingsTransfer"){
           iconTable.splice(i,1);
           break;
        }
    }
    this.gameInfo.gameData.unlockedFeatures[index] = unlockedFeaturesObj;
    this.gameInfo.gameData.currentCash = cashAmt;
    this.gameInfo.gameData.iconTable = iconTable;
    this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
    this.bodyObj = {
        "currentCash": cashAmt,
        "unlockedFeatures": this.gameInfo.gameData.unlockedFeatures,
        "iconTable": iconTable
    };
    const funcName = "updateUnlockedFeatures";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
       {method:'PUT', headers: this.state.headers,
       body: JSON.stringify(this.bodyObj)})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                 // "update of unlocked features was successful";
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

updateSavingsInfoData(){
    if (this.gameInfo.savingsInfo.gameID == undefined){
        this.newSavingsAmtEntry = true;
    }else{
        this.newSavingsAmtEntry = false;
    }
    this.determineGameInfoSavingsAmts();
    this.setBodyObj();
    this.updateSavingsAmounts();
    this.gameInfo.savingsInfo = this.savingsObj;
    this.props.setGameInfo(this.gameInfo.savingsInfo, 'savingsInfo');
}

determineGameInfoSavingsAmts(){
    this.gameID = this.gameInfo.gameData._id;
    let totalOverDraftFees = this.gameInfo.savingsInfo.totalOverDraftFees;
    if (this.newSavingsAmtEntry == true){
        let savingsObj = {gameID: this.gameID, monthlySavingsAmt: 0,
            currentlyActive: false, currentIntRate: "", intRateStartDate: "",
            savingsStartDate: "", totalDeposits: "", 
            totalWithdrawals: "", totalOverDraftFees: "0.00",  totalIntAmt: "0.00",
            totalSavings: "0.00" }
        this.gameInfo.savingsInfo = savingsObj; 
        totalOverDraftFees = "0.00";
    }else{
        if (this.transferFee > 0){
            totalOverDraftFees = +totalOverDraftFees + +this.transferFee;
        }
    }
    let currentlyActive = false;
    if (this.acceptDecline == "accept" || this.acceptDecline == "change"
        || this.acceptDecline == "retain"){
        currentlyActive = true;
        if (this.gameInfo.savingsInfo.currentlyActive == false ||
            this.gameInfo.savingsInfo.savingsStartDate == undefined){
            this.startDate = this.gameInfo.gameData.currentGameDate;
        }else{
            this.startDate = this.gameInfo.savingsInfo.savingsStartDate;
        }
    }else{
            this.startDate = "";
    }
    let totalDeposits = (+this.gameInfo.savingsInfo.totalDeposits + this.oneTimeAmt).toFixed(2);
    let totalIntAmt = this.gameInfo.savingsInfo.totalIntAmt;
    let totalWithdrawals = (+this.gameInfo.savingsInfo.totalWithdrawals + +this.xferAmt).toFixed(2);
    this.gameInfo.savingsInfo.currentlyActive = currentlyActive;
    this.gameInfo.savingsInfo.monthlySavingsAmt = this.monthlyAmt.toFixed(2);
    this.gameInfo.savingsInfo.savingsStartDate = this.startDate;
    this.gameInfo.savingsInfo.intRateStartDate = this.gameInfo.savingsInfo.intRateStartDate;
    this.gameInfo.savingsInfo.totalDeposits = totalDeposits;
    this.gameInfo.savingsInfo.totalIntAmt = totalIntAmt;
    this.gameInfo.savingsInfo.totalWithdrawals = totalWithdrawals;
    this.gameInfo.savingsInfo.totalSavings = +totalDeposits - +totalWithdrawals + 
        +totalIntAmt - +totalOverDraftFees;
    if (this.gameInfo.savingsInfo.totalSavings == 0){
        this.gameInfo.savingsInfo.totalSavings = "0.00";
    }
    this.gameInfo.savingsInfo.totalOverDraftFees = totalOverDraftFees;
}

setBodyObj(){
    let currentDateTime = getCurrentDateTime();
    let date = currentDateTime.date;
    var dateCreated;
    if (this.newSavingsAmtEntry == true){
         dateCreated = date;
    }else{
         dateCreated = this.gameInfo.savingsInfo.dateAdded;
    }
    this.savingsObj = {
        "gameID": this.gameID,
        "monthlySavingsAmt": this.gameInfo.savingsInfo.monthlySavingsAmt,
        "currentlyActive": this.gameInfo.savingsInfo.currentlyActive,
        "currentIntRate": this.gameInfo.savingsInfo.currentIntRate,
        "intRateStartDate": this.gameInfo.savingsInfo.intRateStartDate,
        "savingsStartDate": this.gameInfo.savingsInfo.savingsStartDate,
        "totalDeposits": this.gameInfo.savingsInfo.totalDeposits,
        "totalWithdrawals": this.gameInfo.savingsInfo.totalWithdrawals,
        "totalOverDraftFees": this.gameInfo.savingsInfo.totalOverDraftFees,
        "totalIntAmt": this.gameInfo.savingsInfo.totalIntAmt,
        "totalSavings": this.gameInfo.savingsInfo.totalSavings,
        "dateAdded": dateCreated,
        "dateUpdated": date,
        };
}

updateSavingsAmounts(){
    const funcName = "updateSavingsAmts";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'PUT', headers: this.state.headers, 
        body: JSON.stringify(this.savingsObj)})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                     // "update of savings amts Info was successful";
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}
    
}

export default SetupSavingsModal;