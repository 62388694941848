const StatusDisplayItemOrder =[
    {type: "asset", item: "Job Salary", order: 10},
    {type: "expense", item: "Payroll taxes", order: 10},
    {type: "expense", item: "Apartment Rent", order: 20},
    {type: "expense", item: "Health Insurance", order: 30},
    {type: "expense", item: "Automobile Insurance", order: 40},
    {type: "expense", item: "Renter's Insurance", order: 25},
    {type: "expense", item: "Home Owners Insurance", order: 26},
    {type: "expense", item: "Transportation", order: 41},
    {type: "expense", item: "Misc Expenses", order: 50},
    {type: "expense", item: "Phone", order: 55},
    {type: "expense", item: "Streaming Movies", order: 56},
    {type: "loan", item: "Home Loan (Mortgage)", order: 10},
    {type: "loan", item: "Mortgage Insurance (PMI)", order: 15},
    {type: "loan", item: "Car Loan", order: 20},
    {type: "loan", item: "College Loan", order: 30}
];

export default StatusDisplayItemOrder