import {Component} from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import ResponseCategoryList from './datasets/response_category_dataset';
import EventOnLitList from './datasets/event_onLit_dataset';
import { isNumeric } from '../../objects/CommonUse/checkIfNumeric';
import { editClosureText } from './editEventCollectionProcesses/editClosureText';
import {editResponseCategory} from './editEventCollectionProcesses/editResponseCategory';
import { editOnLit } from './editEventCollectionProcesses/editOnLit';
import { editType } from './editEventCollectionProcesses/editType';
import { editPossibleValues } from './editEventCollectionProcesses/editPossibleValues';
import {editCondName} from './editEventCollectionProcesses/editCondName';
import {initializeErrorArray} from './objects/initialize_error_array';
import findDataObject from '../../objects/CommonUse/find_data_object';
import DropDownOptionCard from '../shared-components/drop_down_option_card';

class EventsRspPossibleModal extends Component {
            constructor(props) {
              super(props);
              this.state={
                dataValidSw: true,
                validTriggerCondName: true,
                validDeleteCondName: true,
               }   
               this.errArray = initializeErrorArray(12); 
              
     }
 
  editInput(){
    this.dataValid = true;
    this.errArray = initializeErrorArray(12);
    this.getValuesFromForm();
    this.editFormInputValues();
    if (this.dataValid === true){
        this.saveResponseInfo();
        this.props.onSubmit(this.possibleObj);
        this.setState({dataValidSw: true});
    }else{
      this.setState({dataValidSw: false});
    }
  }

  getValuesFromForm(){
      this.closureText = document.getElementById("closureText").value;
      this.category = document.getElementById("category").value;
      this.onLit = document.getElementById("onLit").value;
      this.type = document.getElementById("type").value;
      this.duration = document.getElementById("duration").value;
      this.odds = document.getElementById("odds").value;
      this.replaceAmtSw = document.getElementById("replaceAmtSw").value;
      if (this.replaceAmtSw === "false"){
          this.replaceAmtSw = false;
      }else{
        if (this.replaceAmtSw === "true"){
            this.replaceAmtSw = true;
        }
      }
      const possibleValues = document.getElementById("possibleValues").value;
      this.possibleValuesArray = possibleValues.split(','); 
      this.triggerCond = document.getElementById("triggerCond").value;
      this.deleteCond = document.getElementById("deleteCond").value;
      this.deleteClass = document.getElementById("deleteClass").value;
      this.moodPoints = document.getElementById("moodPoints").value;
      
  }
  
  editFormInputValues(){
    var errorChk;
    
    errorChk=editClosureText(this.closureText, this.possibleValuesArray, 290);
    this.errorMsg = errorChk.message;
    this.checkErrorArray(0);
    errorChk=editResponseCategory(this.category, "online");
    this.errorMsg = errorChk.message
    this.checkErrorArray(1);
    errorChk=editOnLit(this.onLit, this.category, "online");
    this.errorMsg = errorChk.message
    this.checkErrorArray(2);
    errorChk=editType(this.type, "online");
    this.errorMsg = errorChk.message
    this.checkErrorArray(3);
    this.editDuration();
    this.editOdds();
    errorChk=editPossibleValues(this.possibleValuesArray);
    this.errorMsg = errorChk.message;
    this.checkErrorArray(6);
    this.editTriggerCondition();
    this.editDeleteCondition();
    this.editMoodPoints();
       
  }

  editDuration(){
    const validDuration = isNumeric(this.duration);
    if (validDuration === false){
      this.errorMsg = "Duration must be numeric";
      this.checkErrorArray(4);
    }
  }
  
  editOdds(){
    const validOdds = isNumeric(this.odds);
    if (validOdds === false){
      this.errorMsg = "Odds must be numeric";
      this.checkErrorArray(5);
    }
  }
  
  editTriggerCondition(){
    this.possibleTriggerCondNames = "";
    this.setState({validTriggerCondName: true});
    let returnObj=editCondName(this.triggerCond, this.props.condNames, 
          "false", "Header", "online")
    if (returnObj.errorArray !== ""){
         this.setState({validTriggerCondName: false});
         this.errorArray = returnObj.errorArray;
         this.possibleTriggerCondNames = this.getPossibleCondNames(returnObj.saveIdx);
         this.errorMsg = this.errorArray.message;
         this.checkErrorArray(8)
    }else{
        if (this.triggerCond === undefined || this.triggerCond === ""){
            this.triggerCondName = "";
        }else{
            let slctIdx = returnObj.matchIdx
            this.triggerCondName = this.props.condNames[slctIdx];
        }
    }
 }
  
 editDeleteCondition(){
  this.possibleDeleteCondNames = "";
  this.setState({validDeleteCondName: true});
  let returnObj=editCondName(this.deleteCond, this.props.condNames,
        "false", "Header", "online")
  if (returnObj.errorArray !== ""){
       this.setState({validDeleteCondName: false});
       this.errorArray = returnObj.errorArray;
       this.possibleDeleteCondNames = this.getPossibleCondNames(returnObj.saveIdx);
       this.errorMsg = this.errorArray.message;
       this.checkErrorArray(9);
  }else{
      if (this.deleteCond === undefined || this.deleteCond === ""){
          this.deleteCondName = "";
      }else{
        let slctIdx = returnObj.matchIdx
        this.deleteCondName = this.props.condNames[slctIdx];
      }
   }
}

  getPossibleCondNames(saveIdx){
      var stopIdx;
      var startIdx = 0;
      let possibleCondNames = "";
      if (saveIdx !== undefined){
        startIdx = saveIdx - 3;
        stopIdx = saveIdx + 5;
        let nbrCondNames = this.props.condNames.length
        if (stopIdx >= nbrCondNames){
              startIdx = startIdx - (stopIdx - nbrCondNames);
              stopIdx = nbrCondNames - 1;
        }
        if (startIdx < 0){
            startIdx = 0;
            stopIdx = startIdx + 8;
        }
        var i;
        for (i=startIdx; i<stopIdx; i++){
            possibleCondNames = possibleCondNames + ", " +
              this.props.condNames[i];
        }
      }
      return possibleCondNames;
  }
  
  editMoodPoints(){
    const validMoodPoints = isNumeric(this.moodPoints);
    if (validMoodPoints === false){
      this.errorMsg = "MoodPoints must be numeric";
      this.checkErrorArray(11);
    }
  }
   
  checkErrorArray(msgIndex){
      if (this.errorMsg !== undefined && this.errorMsg !== ""){
          this.errArray[msgIndex] = {textType: "text-danger", 
              message: this.errorMsg};
          this.dataValid = false;
      }
   }
 
    saveResponseInfo(){
        let possibleEffectsObj = findDataObject("eventPossibleEffects").possibleObj
        possibleEffectsObj.category = this.category;
        possibleEffectsObj.closureText = this.closureText;
        possibleEffectsObj.on = this.onLit;
        possibleEffectsObj.type = this.type;
        possibleEffectsObj.duration = this.duration;
        possibleEffectsObj.odds = this.odds;
        possibleEffectsObj.replaceAmtSw = this.replaceAmtSw;
        possibleEffectsObj.triggerCondition = this.triggerCondName;
        possibleEffectsObj.deleteCondition = this.deleteCondName;
        possibleEffectsObj.deleteClass = this.deleteClass;
        possibleEffectsObj.possibleValues = this.possibleValuesArray;
        possibleEffectsObj.moodPoints = this.moodPoints;
        this.possibleObj =JSON.parse(JSON.stringify(possibleEffectsObj));
    }
   
    render() {
        this.categoryOptions = ResponseCategoryList.map((optionsList,index) =>
          <DropDownOptionCard options={optionsList} key={index} />
        );
        this.onLitOptions = EventOnLitList.map((optionsList,index) =>
          <DropDownOptionCard options={optionsList} key={index} />
        );
                               
    return <>
      
      <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
            size="med" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title>Events Collection Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div className="text-left">
                <p>Please complete all fields on this form and then click 
                   'Submit'.</p>
                <div >
                   <label className="font-weight-bold" htmlFor="closureText">
                        ClosureText:&nbsp;&nbsp;</label>
                    <span className={this.errArray[0].textType}>
                          &nbsp;{this.errArray[0].message}</span>
                   <textarea className="form-control" rows="3" id="closureText"></textarea> 
                </div>
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="category">Category:&nbsp;&nbsp;</label>
                  <select className="btn btn-sm btn-outline-secondary dropdown-toggle" id="category">
                        <option value="No-Category">no category slctd</option>
                        {this.categoryOptions}
                    
                  </select>
                  <span className={this.errArray[1].textType}>
                          &nbsp;{this.errArray[1].message}</span>
                </div>
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="onLit">On Item Name:&nbsp;&nbsp;
                   </label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="onLit">
                        <option value="No-OnLit">no on lit slctd</option>
                        {this.onLitOptions}
                  </select>
                  <span className={this.errArray[2].textType}>
                          &nbsp;{this.errArray[2].message}</span>
                </div>
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="type">Type:&nbsp;&nbsp;
                  </label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="type">
                        <option value="No-Type">no type slctd</option>
                        <option value="flat">Flat Amount</option>
                        <option value="percent">Percentage Amount</option>
                  </select>
                  <span className={this.errArray[3].textType}>
                          &nbsp;{this.errArray[3].message}</span>
                </div>
                <div className="mt-2">
                   <label className="font-weight-bold" htmlFor="duration">
                        Duration:&nbsp;&nbsp;</label>
                   <input type="text" className="col-small" id="duration" name="duration"></input> 
                   <span className={this.errArray[4].textType}>
                          &nbsp;{this.errArray[4].message}</span>
                </div>
                <div className="mt-1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Total Odds So Far:&nbsp;&nbsp;{this.props.totalOdds}
                </div>
                <div className="mt-0">
                   <label className="font-weight-bold" htmlFor="odds">
                        Odds:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                   <input type="text" className="col-small" id="odds" name="odds"></input> 
                   <span className={this.errArray[5].textType}>
                          &nbsp;{this.errArray[5].message}</span>
                </div>
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="possibleValues">
                    Possible Values:&nbsp;&nbsp;</label>
                  <span className={this.errArray[6].textType}>
                          &nbsp;{this.errArray[6].message}</span>
                  <input type="text" className="col-small" id="possibleValues" name="possibleValues"></input>   
                </div>
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="replaceAmtSw">Replace Amt ? :&nbsp;&nbsp;</label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="replaceAmtSw">
                        <option value="false">Do not replace amount</option>
                        <option value="true">Replace Amount</option>
                  </select>
                  <span className={this.errArray[7].textType}>
                            &nbsp;{this.errArray[7].message}</span>
                </div>   
                <div className="mt-2">
                    <label className="font-weight-bold" htmlFor="triggerCond">
                        Trigger Condition:&nbsp;&nbsp;</label>
                    <span className={this.errArray[8].textType}>
                          &nbsp;{this.errArray[8].message}</span>
                    <input type="text" className="col-lg" id="triggerCond" name="triggerCond"></input> 
                  
                </div> 
                {this.state.validTriggerCondName ?
                    <div></div>
                :
                    <div>
                        <div>Possible Options:</div>
                        <div>{this.possibleTriggerCondNames}</div>
                    </div>
                }
                      
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="deleteCond">
                        Delete Condition:&nbsp;&nbsp;</label>
                    <span className={this.errArray[9].textType}>
                          &nbsp;{this.errArray[9].message}</span>
                   <input type="text" className="col-lg" id="deleteCond" name="deleteCond"></input> 
                  
                </div> 
                {this.state.validDeleteCondName ?
                    <div></div>
                :
                    <div>
                        <div>Possible Options:</div>
                        <div>{this.state.possibleDeleteCondNames}</div>
                    </div>
                }
                
                 <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="deleteClass">Delete Condition Class? :&nbsp;&nbsp;</label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="deleteClass">
                        <option value="">no delete class for this event</option>
                        <option value="car">Delete car conditions</option>
                        <option value="job">Delete job conditions</option> 
                        <option value="home">Delete home conditions</option>
                        <option value="roommate">Delete roommate conditions</option>
                  </select>
                  <span className={this.errArray[10].textType}>
                          &nbsp;{this.errArray[10].message}</span>
                </div>   
                <div className="mt-2">
                   <label className="font-weight-bold" htmlFor="moodPoints">
                        Mood Points:&nbsp;&nbsp;</label>
                   <input type="text" className="col-small" id="moodPoints" name="moodPoints"></input> 
                   <span className={this.errArray[11].textType}>
                          &nbsp;{this.errArray[11].message}</span>
                </div>
               
              </div>
           </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
          {this.state.dataValidSw ?
            <div></div>
          :
            <div className="text-danger">Please Correct errors and try-again.</div>
          }
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="primary"  onClick={() => this.editInput()}>
              Submit
          </Button>
        </Modal.Footer>
        </Modal>
      
        </>
    }
   
}

export default EventsRspPossibleModal;