
export const rptMoodPointsByEvent = (eventsData, lowPoint, highPoint ) => {
    let rptArr = [];
    
    for (let i=0; i<eventsData.length; i++){
        if (i === 0){
            let headingLine = {title: "Event Title", description: "Event Description",
                eventCategory: "Event Category", subCategory: "Event Sub-Category", gamePhase: "Game Phase",
                btnText: "Btn Text", closureTxt: "Closure Text",
                onString: "On", type: "Type", possibleValues: "PossibleValues", triggerCond: "Trigger Condition",
                moodPoints: "Mood Points", relationshipPoints: "Relationship Points" }; 
             rptArr.push(headingLine);
        }
        for (let j=0; j<eventsData[i].responses.length; j++){
            let response = eventsData[i].responses[j];
            if (eventsData[i].eventCategory ===  "miscExpenses" ){
                for (let k=0; k<response.possibleEffects.length; k++){
                    let possible = response.possibleEffects[k];
            //      if (+possible.moodPoints < lowPoint || +possible.moodPoints > highPoint){
                        let possibleValues = possible.possibleValues.join(", ");
                        let rptLine = {title: eventsData[i].title, description: eventsData[i].description,
                            eventCategory: eventsData[i].eventCategory,
                            subCategory: eventsData[i].careerTag, gamePhase: eventsData[i].gamePhase, 
                            btnText: response.btnText, closureTxt: possible.closureText,
                            onString: possible.on, type: possible.type, possibleValues: possibleValues,
                            triggerCond: possible.triggerCondition, moodPoints: +possible.moodPoints,
                            relationshipPoints: response.rltnshpPoints};
                        rptArr.push(rptLine);
            //      } 
                }
            }
        }
    }
         
    return rptArr;
   
}
export default rptMoodPointsByEvent;

