
export const setScreenSizeParameters = (screenSize, processName, commonClass) => {

      let wideClass = "col-sm-7";
      let mediumClass = "";
      let narrowClass = "";
      switch (processName){
            case "gameBoard":
                  mediumClass = "col-sm-8";
                  narrowClass = "col-sm-8";
                  break;
            case "gettingStarted2":
                  mediumClass = "col-sm-8 mx-0";
                  narrowClass = "col-sm-8 mx-0";
                  break;
            default:
                  mediumClass = "col-sm-8";
                  narrowClass = "col-sm-8";
      }
      
      let columnClass = "";
      let linkSize = "";
      let textSize = "";
      let imageSize = "";
      switch (screenSize){
            case "narrow":
                columnClass = narrowClass + " " + commonClass;
                linkSize = "verySmall";
                textSize = "small-text";
                imageSize = "very_small_img";

                break;
            case "medium":
                columnClass = mediumClass + " " + commonClass;
                linkSize = "small";
                textSize = "small-text2";
                imageSize = "small_size_img";
                break;
            default:
                columnClass = wideClass + " "  + commonClass;
                linkSize = "medium";
                imageSize = "medium_size_img";
                break; 
      }
      let returnObj={columnClass: columnClass, linkSize: linkSize, textSize: textSize, imageSize: imageSize};
   
   return returnObj;
}






