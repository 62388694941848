
export const clearMazeBoard = (board, gridHeight, gridWidth, obstructGif, pointsGif, avatarGif, finishGif) => {
    var elementCheck;
    var cell = "";
    var overlay = "";
    if (obstructGif == ""){
        obstructGif = "XXXXXX"
    }
    if (finishGif == ""){
        finishGif = "XXXXXX";
    }
    if (pointsGif == ""){
        pointsGif = "XXXXXX";
    }
    if (avatarGif == ""){
        avatarGif = "XXXXXX"
    }
    for (let row=1; row <= gridHeight; row++){
     for (let column = 1; column <= gridWidth; column++){
           cell = row + "|" + column;
           if (board.includes(cell)){
             elementCheck = document.getElementById(`${cell}`);
             if (elementCheck !== undefined &&
                 elementCheck !== null &&
                 elementCheck.classList != "plain" ){
                   if (elementCheck.classList.value == pointsGif){
                        elementCheck.classList.remove(pointsGif);
                   }
                   if (elementCheck.classList.value == obstructGif){
                        elementCheck.classList.remove(obstructGif);
                   }
                   overlay = "plain " + obstructGif;
                   if (elementCheck.classList.value == overlay){
                        elementCheck.classList.remove(obstructGif);
                   }
                   if (elementCheck.classList.value == avatarGif){
                        elementCheck.classList.remove(avatarGif);
                   }
                   if (elementCheck.classList.value == finishGif){
                       elementCheck.classList.remove(finishGif);
                   }
                   overlay = obstructGif + " " + avatarGif;
                   if (elementCheck.classList.value == overlay){
                        elementCheck.classList.remove(avatarGif);
                        elementCheck.classList.remove(obstructGif);
                   }
                   overlay = finishGif + " " + avatarGif;
                   if (elementCheck.classList.value == overlay){
                        elementCheck.classList.remove(avatarGif);
                        elementCheck.classList.remove(finishGif);
                   }
               }
           }
     }
   } 
    
    return board;
}

