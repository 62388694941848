const HomePurchaseText ={textTable: [
    {textType: "notEnoughCash", allowSubmitBtn: false, 
        text: "You do not currently have enough cash in the bank to cover " +
        "the one-time costs for this home.  You need to make a different home choice."
    },
    {textType: "transferCashLit1", allowSubmitBtn: false, 
        text:  "You do not currently have enough cash in the bank to cover " +
        "the one-time costs for this home.  " +
        "You need to reduce the amount of the down payment ...OR... make a different "  +
        "home choice."
    },
    {textType: "transferCashLit2", allowSubmitBtn: true, 
        text: "...OR...  transfer money from your savings account to your " +
        "bank account.  To transfer money, close this pop-up.  Then " +
        "click on the Unlocked Features button, click on the setup or change Savings " +
        "Button and enter the amount you wish to transfer. " +
        "Remember to transfer more than the amount you need to cover " +
        "the transfer fee and to leave enough cash in the bank for unexpected expenses."
    },
    {textType: "loanNotApprovedText", allowSubmitBtn: false, 
        text: "Your home loan has not been approved.  The lender has determined " +
        "that your monthly income is not enough to cover the expenses of the new home.  You " +
        "can reduce the mortgage by increasing the amount of your down payment, " + 
        "select 1 or more roommates, ....OR...." +
        "select another home that is not so expensive."
    },
    {textType: "monthlyIncomeError", allowSubmitBtn: false, 
        text: "Your monthly income is not large enough to cover the monthly costs " +
        "with the selected number of roommates. If you still wish to choose this home, " +
        "you can go back and add 1 or more roommates to assist with the monthly costs."
    },
]
}
    
export default HomePurchaseText;