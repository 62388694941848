import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {standardNumberFormat} from '../../objects/CommonUse/double_functions';
import {reformatDateToDisplay} from '../../objects/CommonUse/reformatDateToDisplay_function';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { calculateMoodRetirementScores } from './objects/calculate_mood_retirement_scores';
import FourColumnTableCard from './four_column_table_card';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class LeaderBoardModal extends Component {
            constructor(props) {
              super(props);
              this.gameInfo = this.props.gameInfo();
              this.state={
                headers: setHeadersForFetch(),
                leaderData: null,
                leaderBoardLoaded: false,
                leaderBoard: [],
                displayLeaderBoard: [],
                currentCash: 0,
                currentUser: "",
                startGameDate: "",
                processingDoneSw: false,
                insertSpot: 10,
                nbrLines: 0,
                updateLeaderBoard: false,
                subProcess: "leaderBoard",
                environment: Config.dataURL.currentEnv,
                currentUser: {user: "", startGameDate: "", moodScore: "",
                    retirementScore: "", combinedScore: ""},
            };   
        this.getLeaderBoardData();
    }

    processLeaderBoard(){
        if (this.props.accountNbr == "game"){
             this.classLit = "";
             this.getCurrentGameData();
             this.insertCurrentGameIntoLeaderBoardList();
        }else{
             this.classLit = "- Your Students";
             this.state.displayLeaderBoard = JSON.parse(JSON.stringify(this.state.leaderBoard));
        }
        if (this.props.finishType == "fullGame" || 
            this.props.finishType == "forceEnd"){
            this.finishGameLit = "End of Game - "
                if (this.state.insertSpot < 10){
                    this.determineIfScoreAddedToLeaderBoard();
                }                    
        }
        this.state.processingDoneSw = true;
        this.setState({processingDoneSw: true});
        
    }

    getCurrentGameData() {
        let currentUser = this.gameInfo.gameData.user;
        this.state.currentCash = this.gameInfo.gameData.currentCash;
        this.state.startGameDate = this.gameInfo.gameData.startGameDate;
        let savingsAmt = 0;
        if (this.gameInfo.savingsInfo != undefined &&
            this.gameInfo.savingsInfo.totalSavings != undefined){
            savingsAmt = this.gameInfo.savingsInfo.totalSavings;
        }
        this.scoreObj = calculateMoodRetirementScores(this.gameInfo.gameData.currentMoodPoints,
            this.state.currentCash, savingsAmt, this.gameInfo.gameData.spendingHistory,
            this.gameInfo.gameData.gameNbrMonths);
        this.state.currentUser = {user: currentUser, startGameDate: this.gameInfo.gameData.startGameDate, 
            moodScore: this.scoreObj.moodScore,
            retirementScore: this.scoreObj.retirementScore, combinedScore: this.scoreObj.combinedScore,
            };
        
    }
    
    insertCurrentGameIntoLeaderBoardList() {
        this.state.displayLeaderBoard = JSON.parse(JSON.stringify(this.state.leaderBoard));

        this.state.insertSpot = this.state.displayLeaderBoard.length;
        for (let i=0; i<this.state.displayLeaderBoard.length; i++){
            if (+this.state.currentUser.combinedScore > +this.state.displayLeaderBoard[i].combinedScore){
                this.state.insertSpot = i;
                break;
            }
        }
        if (this.state.insertSpot <= this.state.displayLeaderBoard.length){
            // insert the current game into the spot where score is less than score on leaderBoard
            if (this.state.insertSpot == this.state.displayLeaderBoard.length){
                this.state.displayLeaderBoard.push(this.state.currentUser);
            }else{
                this.state.displayLeaderBoard.splice(this.state.insertSpot, 0, this.state.currentUser);
            }
            if (this.state.displayLeaderBoard.length > this.nbrEntries){
                this.state.leaderBoard.pop();
            }
        }
           
     }

    determineIfScoreAddedToLeaderBoard(){
        if (this.props.finishType == "fullGame"){
            this.message1 = "Congratulations!"
            this.message2 = "You will be added to the leader board."
            this.state.updateLeaderBoard = true;
        }else{
            this.message1 = ""
            this.message2 = "You did very well!  But, your score will not be added to the leader board " +
                "because you ended the game before the full number of years."
            this.state.updateLeaderBoard = false;
        }
    }

    render() {
        if (this.state.leaderBoardLoaded == true && this.state.processingDoneSw == false){
            this.processLeaderBoard();
        }
        this.leaderBoardList = this.state.displayLeaderBoard.map((scores,index) =>
            <FourColumnTableCard key={index} tableInfo={scores} 
            accountNbr={this.props.accountNbr} currentUser={this.state.currentUser.user}/>
            );  
             
    return <>
      {!this.state.processingDoneSw ?  
            <WaitTimeSpinner />
      :   
      <Modal show={this.props.modalOpen}  onHide={() => this.props.onClose()} size="md" scrollable="true"
        animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title>
              <div >{this.finishGameLit} Top Scores {this.classLit}</div>
              </Modal.Title>
        </Modal.Header>
        <Modal.Body className="leaderboardBackground2">
            <span className="width-100">
            <table className="width-100 m-0 leaderboardContent">
                      
                {this.props.accountNbr == "game" ?
                    <thead>
                        <tr >
                            <th className="p-0 text-left" scope="col">Name</th>
                            <th className="p-0 text-center" scope="col">Game Date</th>
                            <th className="p-0 text-center" scope="col">Mood Score</th>
                            <th className="p-0 text-center" scope="col">Retirement Score</th>
                            <th className="p-0 text-right" scope="col">Combined Score</th>
                        </tr>
                    </thead>
                :
                    <thead>
                        <tr >
                            <th className="p-0 text-left" scope="col">Name</th>
                            <th className="p-0 text-center" scope="col">Nbr Game Months</th>
                            <th className="p-0 text-center" scope="col">Mood Score</th>
                            <th className="p-0 text-center" scope="col">Retirement Score</th>
                            <th className="p-0 text-right" scope="col">Combined Score</th>
                        </tr>
                    </thead>
                }
                <tbody>
                        {this.leaderBoardList}
                </tbody> 
                {this.props.accountNbr == "game" ?
                <tbody>
                        <tr>
                            <th className="text-white" colSpan="5">This</th>
                        </tr>
                        <tr>
                            <th className="text-center font-weight-bold myTable-row-highlight" colSpan="5">
                                Your Current Game</th>
                        </tr>
                        <tr>
                            <th className="p-0" scope="row">{this.state.currentUser.user}</th>
                            <th className="p-0 text-center">
                                {reformatDateToDisplay(this.state.currentUser.startGameDate)}</th>
                            <th className="text-right p-0">{standardNumberFormat(this.state.currentUser.moodScore)}</th>
                            <th className="text-right p-0">{standardNumberFormat(this.state.currentUser.retirementScore)}</th>
                            <th className="text-right p-0">{standardNumberFormat(this.state.currentUser.combinedScore)}</th>
                        </tr>
                    </tbody>
                :
                    null
                }
            </table>
            </span>
        </Modal.Body>
        <Modal.Footer className="p-0 backgroundTopBottom">
            <span className="font-weight-bold">
                <div className="text-denter">{this.message1}</div>
                <div className="mx-4">{this.message2}</div>
            </span>
            <Button variant="primary"  onClick={() => this.props.onClose(this.state.updateLeaderBoard)}>
                      Close
            </Button>
        </Modal.Footer>
        </Modal>
       }
        </>
    }

    getLeaderBoardData() {
        // the following ensures that leaderboard is initialized before doing fetch
        this.state.leaderBoard = [];
        this.state.leaderBoard.splice(0,this.state.leaderBoard.length)
        if (this.props.accountNbr == "game"){
             this.nbrEntries = 10;
        }else{
             this.nbrEntries = 999;
        }
        const funcName = "leaderBoardList";
        const urlParm = "?nbrEntries=" + this.nbrEntries + "&accountNbr=" + this.props.accountNbr;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.state.leaderBoard = output.body;
                        this.setState({leaderBoardLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
      
    }
}

export default LeaderBoardModal;