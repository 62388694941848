import {Component} from 'react';
import ReactTooltip from "react-tooltip";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CalculatorLineDisplayCard from './calculator_line_display_card';
import ConfigFile from '../../datasets/config_file';
import CalculatorText from '../for-parents/text/calculator_text';
import CreditCardExercises from '../for-parents/text/credit_card_exercises';
import NumberedListCard from '../game-views/numbered_list_card';

class CreditCardCalculatorModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         entryTab: [],
         calcComplete: false,
         displayFullInstructions: true,
         exercise1: true,
         exerciseDisplay: false
    };    
    this.initializeAmounts();
  }

  initializeAmounts(){
    this.infoMessage = "";
    this.creditCardLineArr = [];
    this.creditCardIntRate = +ConfigFile.unlockedFeatures.creditCardInterest;
    this.minPaymentPercent = +ConfigFile.unlockedFeatures.creditCardMinimumPayPct;
    this.creditCardIntRateDisplay = (+this.creditCardIntRate * 100).toFixed(0);
    this.minPaymentPercentDisplay = (+this.minPaymentPercent * 100).toFixed(0);
    const creditCardTextObj = CalculatorText.find(elem => elem.type === "CreditCard");
    this.creditCardText = creditCardTextObj.reportText;
  }

  calculateCreditCardAmounts(){
    this.state.calcComplete = false;
    this.getFormValues();
    if (this.errorFound == false){
        this.calculateLineAmts();
    }
    if (this.errorFound == false){
        this.creditCardLineArr.push(this.creditCardObj);
        if (this.creditCardLineArr.length > 5){
            this.creditCardLineArr.splice(0,1);
            this.infoMessage = 
              "Max of 5 calculations, the first entry has rolled off.  Click on the 'Clear/Reset' " +
                " button if you wish to clear all entries and start again."
        }else{
          this.infoMessage = "";
        }
    }
    this.setState({calcComplete: true});
  }

  getFormValues(){
      this.errorFound = false;
      this.errorMsg = "";
      this.initialAmtError = "";
      this.initialAmt = document.getElementById("initialAmt").value;
      if (isNaN(+this.initialAmt)){
          this.errorFound = true;
          this.initialAmtError = "Initial Amount must be numeric. Correct entry and then " +
                "click the 'Calculate' button again."
      }
    
      if (this.errorFound == false){
          this.monthlyPaymentOption = this.paymentOption.value;
          this.nbrYearsParse = parseFloat(this.nbrYears.value);
          this.monthlyChargeAmt = parseFloat(this.monthlyChargeAmt.value);
          this.totalMonths = +this.nbrYearsParse * 12;
      }
  }

  calculateLineAmts(){
      let nbrIterations = 0;
      let outOfPocket = 0;
      let totalInterest = 0;
      let currentBalance = +this.initialAmt;
      let monthPayAmt = 0;
      if (this.monthlyPaymentOption == "full"){
        currentBalance = 0;
        outOfPocket = (+this.initialAmt + (+this.monthlyChargeAmt * this.totalMonths)).toFixed(2);
      }else{
          const monthlyIntRate = (this.creditCardIntRate / 12).toFixed(6);
          while (nbrIterations < this.totalMonths){
              currentBalance = +currentBalance + +this.monthlyChargeAmt; 
              let minPayAmt = (+currentBalance * +this.minPaymentPercent).toFixed(2);
              if (this.monthlyPaymentOption == "min"){
                  monthPayAmt = minPayAmt;
              }else{
                  monthPayAmt = +this.monthlyPaymentOption;
              }
              if (minPayAmt > monthPayAmt){
                  this.errorFound = true;
                  this.errorMsg = "Increase the Monthly Pay Amount.  It must be greater than minimum pay amount ($" +
                      minPayAmt + ")";
                  break;
              }
              if (monthPayAmt > currentBalance){
                  this.errorFound = true;
                  this.errorMsg = "Reduce the Monthly Pay Amount.  It is greater than the credit card balance for one or more months.";
                  break;
              }
              currentBalance = +currentBalance - +monthPayAmt;
              outOfPocket += +monthPayAmt;
              let monthlyIntAmt = (currentBalance * monthlyIntRate).toFixed(2);
              totalInterest = +totalInterest + +monthlyIntAmt;
              currentBalance = +currentBalance + +monthlyIntAmt;
              nbrIterations += 1;
          }
      }
      const endingBalance = currentBalance;
      let monthlyPayAmt = null;
      if (this.monthlyPaymentOption == "min" || this.monthlyPaymentOption == "full"){
          monthlyPayAmt = (outOfPocket / this.totalMonths).toFixed(2);
      }else{
          monthlyPayAmt = +this.monthlyPaymentOption;
      }
      const nbrYearsLit = this.nbrYearsParse + " Years";
      const totalAmtNewCharges = (+this.monthlyChargeAmt * +this.totalMonths);
      this.creditCardObj={beginCreditCardBalance: this.initialAmt, monthlyPayAmt: monthlyPayAmt, 
        payOption: this.monthlyPaymentOption, monthlyChargeAmt: this.monthlyChargeAmt, nbrYears: nbrYearsLit, 
        outOfPocket: outOfPocket, totalNewChargeAmt: totalAmtNewCharges, totalInterest: totalInterest, 
        endCreditCardBalance: endingBalance}       
  }
  
  onClear(){
    this.creditCardLineArr = [];
    this.infoMessage = "";
  }

  displayExerciseInstructions(exerciseVar){
      const exerciseTextObj = CreditCardExercises.find(elem => elem.exerciseName === exerciseVar);
      this.exerciseName = exerciseTextObj.exerciseName;
      this.exerciseGeneralText = exerciseTextObj.exerciseGeneral;
      this.exerciseTable = exerciseTextObj.exerciseArr;
      this.setState({exerciseDisplay: true});
  }
 
 
  render() { 
    this.gutters = this.props.gutters;
    if (this.props.screenSize == "narrow" || this.props.screenSize == "medium"){
       this.gutters = "modal-95";
    }
    this.calculatorLines = this.creditCardLineArr.map((creditCardInfo, index) =>
      <CalculatorLineDisplayCard calcLineInfo={creditCardInfo}  key={index} index={index}
         />
          );
    if (this.exerciseTable){
        this.numberedList = this.exerciseTable.map((textLine, key) =>
                <NumberedListCard key={key} text={textLine} />
        );
    }
  return <>
    <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="xl" scrollable="true"
         dialogClassName={this.gutters} animation={false}>
      <Modal.Header className="backgroundTopBottom" closeButton>
        <Modal.Title>Credit Card Calculator</Modal.Title> 
      </Modal.Header>
      <Modal.Body>
          
          <div className="mx-2 mt-1">
            <p>{this.creditCardText}</p>
            <p>Here are some other factors that impact the calculations.  These factors are based on amounts
               that are typically applied by banks that administer credit cards. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <b>Minimum Payment Percent:</b>&nbsp;&nbsp; {this.minPaymentPercentDisplay}%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <b>Credit Card Interest:</b>&nbsp;&nbsp; {this.creditCardIntRateDisplay}%
            </p>
        </div>  
        {this.state.exercise1  ?
            <div className="mx-2 mb-2">{this.creditCardExercise1Text}</div>
        :
            null
        }
       
        <div >
            <a target="_blank" data-tip="The amount of money that is currently outstanding on the credit card. 
                   (Note:  This initial balance can be $0.)" 
                    rel="noopener noreferrer">
                  <ReactTooltip globalEventOff="click" />
                      <label className="font-weight-bold" htmlFor="initialAmt">
                        Initial Credit Card Balance:&nbsp;&nbsp;</label>
                      <input type="text" className="col-med" id="initialAmt" name="initialAmt"></input> 
                      <span className="text-danger">
                                &nbsp;{this.initialAmtError}</span>                   
            </a>
           
        </div>
   
        <span className="row w-100 mt-1 ml-0 mr-0">
          <a target="_blank" data-tip="How do you wish to pay off the credit card balance?" 
                  rel="noopener noreferrer">
                  <ReactTooltip globalEventOff="click" />
                      <div className="p-0">
                      <div><b>Payment Option:&nbsp;</b> 
                        <select id = "dropdown" ref = {(input)=> this.paymentOption = input}>
                            <option value="full">Pay Full Balance Each Month</option>
                            <option value="min">Pay Minimum Amt Each Month</option>
                            <option value="200">Pay $200 Each Month</option>
                            <option value="500">Pay $500 Each Month</option>
                            <option value="750">Pay $750 Each Month</option>
                            <option value="1000">Pay $1000 Each Month</option>
                            <option value="1250">Pay $1250 Each Month</option>
                            <option value="1500">Pay $1500 Each Month</option>
                            <option value="2000">Pay $2000 Each Month</option>
                            <option value="2500">Pay $2500 Each Month</option>
                            <option value="3000">Pay $3000 Each Month</option>
                            <option value="3500">Pay $3500 Each Month</option>
                            <option value="4000">Pay $4000 Each Month</option>   
                            <option value="4500">Pay $4500 Each Month</option>
                            <option value="5000">Pay $5000 Each Month</option>
                        </select> 
                      </div>
                    </div>
            </a>

            <a target="_blank" data-tip="How much money will be added to the credit card each month?" 
                  rel="noopener noreferrer">
                  <ReactTooltip globalEventOff="click" />  
                    <div className="ml-5 p-0">  
                      <p><b>&nbsp;&nbsp;Avg Amt Charged Each Month:&nbsp;</b>
                      <select id = "dropdown" ref = {(input)=> this.monthlyChargeAmt = input}>
                          <option value="0">$0</option>
                          <option value="200">$200</option>
                          <option value="500">$500</option>
                          <option value="750">$750</option>
                          <option value="1000">$1000</option>
                          <option value="1250">$1250</option>
                          <option value="1500">$1500</option>
                          <option value="1750">$1750</option>
                          <option value="2000">$2000</option>
                          <option value="2500">$2500</option>
                          <option value="3000">$3000</option>
                          <option value="4000">$4000</option>
                          <option value="5000">$5000</option>
                      </select></p>
                    </div>
            </a>

            <a target="_blank" data-tip="How many years should these factors be applied?" 
                  rel="noopener noreferrer">
                  <ReactTooltip globalEventOff="click" />  
                    <div className="ml-5 p-0">
                      <p><b>Nbr of Years:&nbsp;</b>
                      <select id = "dropdown" ref = {(input)=> this.nbrYears = input}>
                          <option value="1">1 Year</option>
                          <option value="2">2 Years</option>
                          <option value="3">3 Years</option>
                          <option value="5">5 Years</option>
                          <option value="10">10 Years</option>
                          <option value="15">15 Years</option>
                          <option value="20">20 Years</option>
                      </select></p>
                    </div>  
            </a>
          </span> 
          <table className="table table-hover">
            <thead>
              <tr className="thead-light">
                <th></th>
                
                <th className="py-0 text-center" scope="col">
                    <a target="_blank" data-tip="Amt of money initially outstanding on the credit card." 
                      rel="noopener noreferrer">
                      <ReactTooltip globalEventOff="click" /> Beginning Balance
                      </a>
                </th>
                <th className="py-0 text-center" scope="col">
                    <a target="_blank" data-tip="This is determined based on the 'Payment Option' field." 
                      rel="noopener noreferrer">
                      <ReactTooltip globalEventOff="click" />  Amt Paid Each Month
                    </a>      
                </th>
                <th className="py-0 text-center" scope="col">
                    <a target="_blank" data-tip="This is equal to the 'Avg Amt Charged Each Month' factor." 
                      rel="noopener noreferrer">
                      <ReactTooltip globalEventOff="click" />Monthly Charge Amt
                    </a>
                </th>
                <th className="py-0 text-center" scope="col">
                    <a target="_blank" data-tip="This is equal to the 'Number of Years' factor." 
                      rel="noopener noreferrer">
                      <ReactTooltip globalEventOff="click" />Amt of Time
                    </a>
                </th>
                <th className="py-0 text-center" scope="col">
                  <a target="_blank" data-tip="The total amount added to the card balance 
                      for the specified number of years." 
                      rel="noopener noreferrer">
                      <ReactTooltip globalEventOff="click" />Total Amt Charged
                  </a>
                </th>
                <th className="py-0 text-center" scope="col">
                  <a target="_blank" data-tip="The total amount of interest that is added due to any 
                      unpaid amounts in a given month." 
                      rel="noopener noreferrer">
                      <ReactTooltip globalEventOff="click" />Total Interest Charged
                  </a>
                </th>
                <th className="py-0 text-center" scope="col">
                  <a target="_blank" data-tip="The total that you have actually paid 'out-of-pocket'." 
                      rel="noopener noreferrer">
                      <ReactTooltip globalEventOff="click" />Total Amount Paid
                  </a>
                </th>
                <th className="py-0 text-center" scope="col">
                  <a target="_blank" data-tip="The total amount that is owed on the credit card 
                      after the factors are applied." 
                      rel="noopener noreferrer">
                      <ReactTooltip globalEventOff="click" />Ending Balance
                  </a>
                </th>
              </tr>
            </thead>
            {this.calculatorLines}
           
        </table>
   
        {this.state.exerciseDisplay ?
          <div>
            <b>{this.exerciseName}:&nbsp;&nbsp;</b>
              {this.exerciseGeneralText}
              <ol>
              {this.numberedList}
              </ol>
          </div>
        :
          <Button variant="info"  onClick={() => this.displayExerciseInstructions("Exercise1")}>
              Exercise #1
          </Button>
        }

        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom">
          <p className="text-right">{this.infoMessage}</p>
          <p className="text-danger">{this.errorMsg}</p>
          <Button variant="info"  onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="secondary"   onClick={() => this.onClear()}>
              Clear/Reset
          </Button>
          <Button variant="primary"  onClick={() => this.calculateCreditCardAmounts()}>
              Calculate
          </Button>
         
        </Modal.Footer>
      </Modal>

  

      
    </>
  }

 
}
export default CreditCardCalculatorModal;