import { applyToCreditCard } from "./apply_to_credit_card";

export const applyMonthlyMiscExpToCreditCard = (expenseTable, creditCardInfo, nbrMonths) => {
   
    for (let i=0; i<expenseTable.length; i++){
          if (expenseTable[i].expItem == "Misc Expenses" ||
              expenseTable[i].expItem == "Transportation" ){
                let expAmount = expenseTable[i].expAmount * nbrMonths;
                creditCardInfo = applyToCreditCard(creditCardInfo, expAmount, expenseTable[i].expDesc,
                    expenseTable[i].expItem, "planned");
          }
   }  
   return creditCardInfo;
   
}

