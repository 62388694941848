const CreditCardExercises =[
      {exerciseName: "Exercise1",
            exerciseGeneral: "Enter 25000 ($25,000) in the 'Initial Credit Card Balance' field.  Enter the values " +
               "listed below in the other entry fields and click 'Calculate' after all entries made for the " +
               "numbered item.", 
            exerciseArr: ["Payment Option: 'Pay Full Balance Each Month', Avg Amt Charged Each Month: $0, Nbr of Years: 2",
               "Change Payment Option to: 'Pay Minimum Balance Each Month', leave other values the same",  
               "Change Nbr Years to 20 Years, leave other values the same", 
               "Change Payment Option to: $500 each Month, change Nbr Years back to 2, leave Avg Amt Charged Each " +
               "Month the same ($0).",
               "Change Payment Option to $1000 each Month, leave the other values the same."]
      },     
 
]

export default CreditCardExercises;