const CalculatorText =[
       {type: "CreditCard", 
            reportText: "This tool allows you to determine the best way to pay off your credit card balance.  It is " +
            "recommended that you use this tool in conjunction with the 'Discussion Guide' (link provided " +
            "at the bottom of the Player Reports Page).  You can hover over the input factors and/or the report " +
            "column headers in this tool in order to get a definition of the field. Click on the Exercise buttons to display " +
            "some recommended activities that are referenced in the discussion guide.", 
           
       },
 
]

export default CalculatorText;