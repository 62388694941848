
export const checkIfCreditCard = (chargeAmt, creditCardInfo, objectName) => {
   
      let useCreditCard = false;
      if (objectName != "cashOnly" &&
         creditCardInfo.currentlyActive === true &&
          +chargeAmt < -5 &&
           +creditCardInfo.totalOutstandingBalance < 
             (+creditCardInfo.creditMaxAmount + +chargeAmt)){
                  useCreditCard = true;
      }
              
    return useCreditCard;
   
}
