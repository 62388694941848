import React from "react";
import "./GettingStarted/gettingStarted.css";
import SkillsSurveyFoundModal from "./skills_survey_found_modal";
import SkillsWelcomeModal from "./skills_welcome_modal";
import { withRouter } from "react-router";
import { setHeadersForFetch } from "../../objects/CommonUse/set_headers_for_fetch";
import { setScreenSizeParameters } from "../../objects/CommonUse/set_screen_size_parameters";
import WaitTimeSpinner from "../shared-components/wait_time_spinner";
import buildUrl from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class SkillsInterestsInput extends React.Component {
skillsArr;

constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.prevQuestion = this.prevQuestion.bind(this);
    this.state = {
        loading: true, 
        selected: null, 
        questionIndex: 0,
        skillSurvey: "",
        openModalSw: false,
        skillSurveyDone: false,
        welcomeModalSw: false,
        environment: Config.dataURL.currentEnv,
        subProcess: "SkillsInterestsInput",
    };
    this.getQuestionList(); 
    this.setPictureURL();
    props.onOpen();

}

render() { 
    if (this.state.skillSurveyDone == false){
         this.processSkillSurvey();
    }
    
    let screenParms = setScreenSizeParameters(this.props.screenSize, "gettingStarted");
    this.className = screenParms.columnClass;
       
return <>
    <div className={this.className}>
        <div className="card mt-2 mx-0 rounded-corners-lg text-center height-max">
            <button className="pages-back-button ml-4 mt-4" 
                            onClick ={()=> this.props.goBack()}>
                                    Back
            </button>
            <h3 className="mt-0">Skills/Interests</h3>
            {this.state.welcomeModalSw ?
                <SkillsWelcomeModal show={this.state.welcomeModalSw}
                    onClose = {() => this.closeWelcomeModal()} />
            :
                    null
            }
            {this.state.openModalSw  && !this.state.welcomeModalSw  ?
                 <SkillsSurveyFoundModal show={this.state.openModalSw} 
                        fromProcess = {this.props.fromProcess}
                        onUsePreviousSurvey={() => this.usePreviousSkillSurvey()}
                        onDoNotUsePreviousSurvey={() => this.doNotUsePreviousSkillSurvey()} 
                        displayGameHelp={() => this.displayGameHelp()}
                        onClose = {() => this.closeSurveyFoundModal()}
                 />
            :
            <span>
            {this.state.loading ?
               <WaitTimeSpinner/>
            :
            <span>
                <h6 className="mx-4 my-4">
                    Please use the buttons below to indicate how well the following statement describes you.    
                    Answer each question as honestly as possible to ensure that job choices are the best for you.
                </h6>
                <p className="font-italic my-4 mx-3 question-color font-weight-bold larger-text-size" 
                    id="questionContainer">
                    {this.skillsArr[this.state.questionIndex].questionStr}
                </p>
                <span className="h-100 w-100">
                    <span className="w-100">
                        <div className="btn-group mb-4 mx-3 w-90" role="group">
                            <button id="btn1" type="button" value="1" className={this.state.selected == 1 ?
                                "btn btn-primary border-white"
                                :
                                "btn btn-secondary border-white"}
                                onClick={this.handleClick}>1 (Not at all)</button>
                            <button id="btn2" type="button" value="2" className={this.state.selected == 2 ?
                                "btn btn-primary border-white"
                                :
                                "btn btn-secondary border-white"} onClick={this.handleClick}>2 (A little bit)</button>
                            <button id="btn3" type="button" value="3" className={this.state.selected == 3 ?
                                "btn btn-primary border-white"
                                :
                                "btn btn-secondary border-white"} onClick={this.handleClick}>3 (Somewhat)</button>
                            <button id="btn4" type="button" value="4" className={this.state.selected == 4 ?
                                "btn btn-primary border-white"
                                :
                                "btn btn-secondary border-white"} onClick={this.handleClick}>4 (Usually)</button>
                            <button id="btn5" type="button" value="5" className={this.state.selected == 5 ?
                                "btn btn-primary border-white"
                                :
                                "btn btn-secondary border-white"} onClick={this.handleClick}>5 (All the time)</button>
                        </div>
                        <div className="text-right mb-4 mx-4 w-90">
                                <button type="button" id="backBtn" className={this.state.questionIndex > 0 ?
                                    "btn btn-primary border-dark mr-3"
                                    :
                                    "btn btn-secondary disabled border-dark mr-3"}
                                    onClick={this.prevQuestion}>Back</button>
                                <button type="button" id="nextBtn" className={this.state.selected !== null && this.state.questionIndex <= this.skillsArr.length - 1 ?
                                    "btn btn-primary border-dark mr-3"
                                    :
                                    "btn btn-secondary disabled border-dark mr-3"}
                                    onClick={this.nextQuestion}>Next</button>                                   
                        </div>
                        <div>
                            <img src={this.skillsPictureURL} 
                                className="skills-interest-picture" alt={this.skillsPictureURL}  />
                        </div>
                    </span>
                </span>
            </span>
            }
            </span>
            }
        </div>
    </div>
</>
}

processSkillSurvey(){
    this.state.skillSurvey = [];
    if (this.props.skillSurvey != undefined){
        this.state.skillSurvey = this.props.skillSurvey;
        this.state.openModalSw = true;
    }else{
        let skillSurvey = (sessionStorage.getItem('skillsInterestsInput'));
        if (skillSurvey != null){
            this.state.skillSurvey = skillSurvey.split(',');
            this.state.openModalSw = true;
        }
    }
    this.state.skillSurveyDone = true;
    if (this.state.openModalSw == false){
        this.state.welcomeModalSw = true;
    }
 }

 displayGameHelp(){
    this.setState({welcomeModalSw: true});
 }

closeWelcomeModal(){
    this.state.welcomeModalSw = false;
}

closeSurveyFoundModal(){
    this.state.openModalSw = false;
    if(this.props.fromProcess == "GameView"){
        this.props.history.push('/game-choices');
    }else{
        this.props.history.push('/');
    }
}

usePreviousSkillSurvey(){
    this.state.openModalSw = false;
    this.onSubmit();
}

doNotUsePreviousSkillSurvey(){
    this.state.openModalSw = false;
    this.state.skillSurvey = "";
}

getQuestionList() {
    let headers = setHeadersForFetch();
    const funcName = "skillsQuestions";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'GET',
         headers: headers
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.skillsArr = output.body;
                        let skillsInterestChoices = sessionStorage.getItem('skillsInterestsInput');
                        if (skillsInterestChoices && skillsInterestChoices.length === this.skillsArr.length) {
                            for (let i = 0; i < this.skillsArr.length; ++i) {
                                this.skillsArr[i].responseValue = skillsInterestChoices[i];
                            }
                            this.setState({selected: skillsInterestChoices[0]});
                        }
                        else if (skillsInterestChoices?.length === this.skillsArr?.length)
                        {
                            sessionStorage.removeItem('skillsInterestsInput');
                        }
                        this.setState({loading: false});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
    });
}

handleClick(e) {
   this.setState({selected: e.target.value});
}

nextQuestion(e) {
    if (!document.getElementById("nextBtn").classList.contains("disabled") && this.state.questionIndex < this.skillsArr.length - 1) {
        this.skillsArr[this.state.questionIndex].responseValue = this.state.selected;
        this.setState({questionIndex: ++this.state.questionIndex});
        this.setPictureURL();
        if (this.skillsArr[this.state.questionIndex].responseValue !== 0) {
            this.setState({selected: this.skillsArr[this.state.questionIndex].responseValue});
        }
        else {
            if (this.skillsArr[this.state.questionIndex].responseValue >0
                && this.skillsArr[this.state.questionIndex].responseValue <=5)
            {
                this.setState({selected: this.skillsArr[this.state.questionIndex].responseValue});
            }
            else
            {
                this.setState({selected: null});
            }
        }

        if (this.state.questionIndex === this.skillsArr.length - 1) {
            document.getElementById("nextBtn").innerText = "Submit";
        }
    }
    else if (this.state.questionIndex === this.skillsArr.length - 1) {
        for (let i = 1; i <= 5; ++i) {
            let btn = document.getElementById(`btn${i}`);
            if (btn.classList.contains("btn-primary")) {
                this.skillsArr[this.state.questionIndex].responseValue = i;
            }
        }

        if (this.skillsArr[this.state.questionIndex - 1].responseValue !== 0) {
            this.onSubmit();
        }
    }
}

prevQuestion(e) {
    if (this.state.questionIndex > 0) {
        this.skillsArr[this.state.questionIndex].responseValue = this.state.selected;
        this.setState({questionIndex: --this.state.questionIndex});
        this.setPictureURL();
        document.getElementById("nextBtn").innerText = "Next";
        if (this.skillsArr[this.state.questionIndex].responseValue !== 0) {
            this.setState({selected: this.skillsArr[this.state.questionIndex].responseValue});
        }
        else {
            this.setState({selected: this.skillsArr[this.state.questionIndex].responseValue});
        }

        document.getElementById("questionContainer").innerText = this.skillsArr[this.state.questionIndex].questionStr;
    }
}

setPictureURL(){
    let questionNbr = this.state.questionIndex + 1;
    let questionLit = "question";
    if (questionNbr < 10){
        questionLit += "0";
    }
    this.skillsPictureURL = "./assets/skillsInterests/" + questionLit + questionNbr + ".png"; 
}

onSubmit() {
    const careerChoice = sessionStorage.getItem('careerChoice');
    let skillsInterestChoices = [];
    
    if (this.state.skillSurvey != ""){
        skillsInterestChoices = this.state.skillSurvey;
    }else{
        for (const skill of this.skillsArr) {
            skillsInterestChoices.push(skill.responseValue);
        }
    }
    sessionStorage.setItem('skillsInterestsInput',skillsInterestChoices);
    this.props.completed();            
    if (this.props.fromProcess == undefined || this.props.fromProcess != "GameView"){
        this.props.history.push('/choose-career');
    }
 }

}

export default withRouter(SkillsInterestsInput);