import React from "react";
import { withRouter } from "react-router";

class OneThruFiveButtons extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state={
           selected: [],
                           
        };   
    }
    
render() { 
return <>
    <span className="h-100 w-100">
        <span className="w-100">
            <div className="my-2 mx-3" id="questionContainer">
                <a>{this.props.questionNbr}. {this.props.questionText} &nbsp;&nbsp;</a>
                <a className="text-danger">{this.props.errorMsg}</a> 
            </div>
                       
            <div className="btn-group mb-3 mx-3 w-90" role="group">

                <button id="btn1" type="button" value="1" className={this.props.selected == 1 ?
                    "btn btn-primary border-white"
                    :
                    "btn btn-secondary border-white"} onClick={() => this.handleClick(1)}>1 (Strongly Disagree)</button>
                <button id="btn2" type="button" value="2" className={this.props.selected == 2 ?
                    "btn btn-primary border-white"
                    :
                    "btn btn-secondary border-white"} onClick={() => this.handleClick(2)}>2 (Disagree)</button>
                <button id="btn3" type="button" value="3" className={this.props.selected == 3 ?
                    "btn btn-primary border-white"
                    :
                    "btn btn-secondary border-white"} onClick={() => this.handleClick(3)}>3 (Not Sure)</button>
                <button id="btn4" type="button" value="4" className={this.props.selected == 4 ?
                    "btn btn-primary border-white"
                    :
                    "btn btn-secondary border-white"} onClick={() => this.handleClick(4)}>4 (Agree)</button>
                <button id="btn5" type="button" value="5" className={this.props.selected == 5 ?
                    "btn btn-primary border-white"
                    :
                    "btn btn-secondary border-white"} onClick={() => this.handleClick(5)}>5 (Strongly Agree)</button>
                               
            </div>
                        
         </span>
    </span>
          
</>
}

handleClick(btnNbr){
    this.state.selected[this.props.questionNbr] = btnNbr;
    let answerObj = {questionNbr: this.props.questionNbr, answer: btnNbr};
    this.props.onSelected(answerObj);
}

}

export default OneThruFiveButtons;