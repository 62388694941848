import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './GameBoardActivities/puzzleimg.css';
import {Component} from 'react';


class UnderCoverPuzzleCard extends Component {
       
    constructor(props) {
        super(props);
        this.state = {
            invisibleArr: [],
            layoutComplete: false,
            rowOffset: -2,
            columnOffset: 0,
            
        }; 
     }
    

   determineTopLeftPosition(){
        let row = Math.floor(+this.props.index / this.props.nbrColumns);
        let column = +this.props.index - (this.props.nbrColumns * row);
        this.top = row * this.props.totalHeightPx/this.props.nbrRows - this.state.rowOffset + "px";
        this.left = column * this.props.totalWidthPx/this.props.nbrColumns - 
            this.state.columnOffset + "px";
    }
       
    render() 
    {
        if (this.state.layoutComplete == false){
            this.determineTopLeftPosition();
        }
        if (this.props.invisibleArr.includes(this.props.index)){
              this.imageClass = 0;
        }else{
              this.imageClass = 100;
        }
        switch (this.props.screenSize){
            case "narrow":
                this.imgClass = "cover-up-square-narrow";
                break;
            case "medium":
                this.imgClass = "cover-up-square-medium";
                break;
            default:
                this.imgClass = "cover-up-square";
                break;
        }
        
                
    return <>
        <div style={{position: "absolute", left: this.left, top: this.top, opacity: this.imageClass}}>

            <div className="d-flex text-color-white h-100">

                <img className={this.imgClass}  
                     src="./assets/Activities/Vacation/coverUpSquare.png" alt="my image" 
                         />
                </div>
            
        </div>
           
    </>
    }

    

}

export default UnderCoverPuzzleCard;