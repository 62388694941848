import "./GameBoardActivities/dragNDrop.css";
import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';

const Container = styled.div`
       background-color:${props => (props.isDragging ? 'transparent' : 'transparent')};   
`
function DragNDropItems(props) {
    return (
        <Draggable draggableId={props.item.id} index={props.index}
            isDragDisabled={props.item.disabled}>
            {(provided, snapshot) => (
                <Container className={props.item.className}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                >
                        {props.item.type == "amount" ?
                            <div className="row w-100">
                                    <div className="col-sm-10 pl-2" >
                                        {props.item.content}</div>
                                    <div className="col-sm-2 text-right p-0">
                                        {doubleToStringMonthly(props.item.amount)}
                                    </div>
                            </div>   
                        :       
                          null
                        }
                        {props.item.type == "image" ?
                            <div >    
                                  <img src={props.item.picture} className="drag_n_drop_thumbnail_img" />
                            </div>
                               
                        :
                            null
                        }
                        {props.item.type == undefined || props.item.type == "content" ?
                                       
                            <div className="m-1">    
                                {props.item.content} 
                            </div>
                        :
                            null                                 
                        }
                </Container>
            )}
        </Draggable>
    )
}

export default DragNDropItems
