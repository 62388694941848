import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {savingsOverTimeAmt} from '../../objects/CommonUse/savingsOverTimeCalc_function';
import SixColumnDisplayCard from '../shared-components/six_column_display_card';

class SavingsOverTimeModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         entryTab: [],
         message: " ",
    };    
  }

  calculateSavingsOverTimeAmounts(){
    let errorFound = false;
    this.initialAmtError = "";
    if (this.props.initialAmt != undefined && this.props.initialAmt == true){
         var initialAmt = document.getElementById("initialAmt").value;
         if (isNaN(+initialAmt)){
            errorFound = true;
            this.initialAmtError = "Initial Amount must be numeric. Correct entry and then " +
              "click the 'Calculate' button again."
         }
    }else{
        initialAmt = 0;
    }
    if (errorFound == false){
        var monthlySavingsAmtParse = parseFloat(this.savingsAmount.value);
        var nbrYearsParse = parseFloat(this.nbrYears.value);
        var intRateParse = parseFloat(this.intRate.value);
        var totalSavingsWithInterest = savingsOverTimeAmt(monthlySavingsAmtParse, intRateParse, 
          nbrYearsParse, initialAmt) 
        let intRateLit = (+intRateParse * 100).toFixed(0);
        intRateLit = intRateLit + "%"
        var nbrYearsLit = (nbrYearsParse + " years")
        var annualSavings = (monthlySavingsAmtParse * 12).toFixed(2);
        var totalSavingsAmount = annualSavings * nbrYearsParse;
        var loanInfoString={monthlySavingsAmt: monthlySavingsAmtParse, yearlySavingsAmt: +annualSavings,
          intRate: intRateLit, years: nbrYearsLit, totalSavingsAmt: totalSavingsAmount,
          totalSavingsWithInt: totalSavingsWithInterest}       
        this.state.entryTab.push(loanInfoString);
        if (this.state.entryTab.length > 5){
              this.state.entryTab.splice(0,1);
              this.state.message = 
                "Max of 5 calculations, the first entry has rolled off.  Click on the 'Clear/Reset' " +
                  " button if you wish to clear all entries and start again."
        }
    }
  }

  onClear(){
    var i;
    for (i=0; i<=this.state.entryTab.length; i++){
        delete this.state.entryTab[i];
    }
    this.state.entryTab.length = 0;
  }
 
  render() { 
    this.gutters = this.props.gutters;
    if (this.props.screenSize == "narrow" || this.props.screenSize == "medium"){
       this.gutters = "modal-95";
    }
    this.summaryRecordCounts = this.state.entryTab.map((counts,index) =>
      <SixColumnDisplayCard key={index} tableInfo={counts} index={index} tableType={"savings"}
          />
    );  
  return <>
    <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="lg" scrollable="true"
         dialogClassName={this.gutters} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Savings Over Time Calculator</Modal.Title> 
      </Modal.Header>
      <Modal.Body>
        {this.props.calculatorSw == false  ?
        <span>
          <div className="row-100 ml-2 mr-2 mt-1">
            <p>There are three factors that affect the amount that you save over time.  The factors are:  The
               amount that you save each month, the extra amount that you will get paid (interest rate) as
               an incentive, and how long you save the money.  As a general rule, the longer that you
               can save money, the more the money will earn in interest.  </p>
            <p>  
               Use the savings calculator to experiment with how these factors affect the
               amount saved.  Here are some specific things to understand (scroll down to see the result of
               the selections that you make.)  When you are done experimenting with the calculator, click 
               the 'Close' button.
            </p>
            <ol className="text-left m-2" type="1">
               <li>
                  If you save less per month but save it over a long period (25 years) do you end up 
                  making more money in the long term than if you save a lot for short period of time (5 years)?
                  Keep the interest rate the same as you test this out.
               </li>
               <li>
                  How does changes to interest rate affect the total amount saved if you save the same 
                  amount over the same number of years?
               </li>
               <li>
                  If you keep the amount saved each month and the interest rate the same, how much does the
                  number of years saved affect the total amount?
               </li>
             </ol>
          </div>  
        </span>
        :
            null
         }
        {this.props.initialAmt ?
                <div >
                  <label className="font-weight-bold" htmlFor="initialAmt">
                    Initial Deposit Amount:&nbsp;&nbsp;</label>
                  <input type="text" className="col-med" id="initialAmt" name="initialAmt"></input> 
                  <span className="text-danger">
                      &nbsp;{this.initialAmtError}</span>
                </div>
              :
                null
        }

        <span className="row w-100 mt-1 ml-0 mr-0">
            <div className="p-0">
            
              <div><b>Monthly Savings Amt:&nbsp;</b> 
              <select id = "dropdown" ref = {(input)=> this.savingsAmount = input}>
                  <option value="50">$50</option>
                  <option value="100">$100</option>
                  <option value="200">$200</option>
                  <option value="250">$250</option>
                  <option value="300">$300</option>
                  <option value="400">$400</option>
                  <option value="500">$500</option>
                  <option value="750">$750</option>
                  <option value="800">$800</option>
                  <option value="1000">$1000</option>
                  <option value="1500">$1500</option>
                  <option value="2000">$2000</option>   
                  <option value="5000">$5000</option>
                  <option value="10000">$10000</option>
                  <option value="20000">$20000</option>               
              </select> 
              </div>
            </div>
              
            <div className="ml-5 p-0">  
              <p><b>&nbsp;&nbsp;Interest Rate:&nbsp;</b>
              <select id = "dropdown" ref = {(input)=> this.intRate = input}>
                  <option value=".01">1%</option>
                  <option value=".02">2%</option>
                  <option value=".03">3%</option>
                  <option value=".04">4%</option>
                  <option value=".05">5%</option>
                  <option value=".06">6%</option>
                  <option value=".07">7%</option>
                  <option value=".08">8%</option>
                  <option value=".10">10%</option>
                  <option value=".15">15%</option>
                  <option value=".20">20%</option>
                  <option value=".50">50%</option>
              </select></p>
            </div>

            <div className="ml-5 p-0">
              <p><b>Nbr Years:&nbsp;</b>
              <select id = "dropdown" ref = {(input)=> this.nbrYears = input}>
                  <option value="1">1 Year</option>
                  <option value="2">2 Years</option>
                  <option value="5">5 Years</option>
                  <option value="10">10 Years</option>
                  <option value="15">15 Years</option>
                  <option value="20">20 Years</option>
                  <option value="30">30 Years</option>
                  <option value="40">40 years</option>
              </select></p>
            </div>  
          </span> 
          <table className="table table-hover">
            <thead>
              <tr className="thead-light">
                <th></th>
                <th className="p-0 text-center" scope="col">Monthly Savings Amount</th>
                <th className="p-0 text-center" scope="col">Yearly Savings Amount</th>
                <th className="p-0 text-center" scope="col">Interest Rate</th>
                <th className="p-0 text-center" scope="col">Number of Years</th>
                <th className="p-0 text-center" scope="col">Total Savings Amount</th>
                <th className="p-0 text-center" scope="col">Total Savings With Interest</th>
              </tr>
            </thead>
          <tbody>
            {this.summaryRecordCounts}
          </tbody>
        </table>

        </Modal.Body>
        <Modal.Footer>
          <p className="text-right">{this.state.message}</p>
          <Button variant="info"  onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="secondary"   onClick={() => this.onClear()}>
              Clear/Reset
          </Button>
          <Button variant="primary"  onClick={() => this.calculateSavingsOverTimeAmounts()}>
              Calculate
          </Button>
        </Modal.Footer>
      </Modal>

      
    </>
  }

 
}
export default SavingsOverTimeModal;