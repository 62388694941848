import React from "react";
import { withRouter } from "react-router";
import { setHeadersForFetch } from "../../objects/CommonUse/set_headers_for_fetch";
import ExtraCreditConfigFile from './Datasets/extra_credit_config_file';
import ExtraCreditResultsModal from "./extra_credit_results_modal";
import { getCurrentDateTime } from "../../objects/CommonUse/get_current_date_time";
import { setScreenSizeParameters } from "../../objects/CommonUse/set_screen_size_parameters";
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';

class ExtraCreditMain extends React.Component {
    
    constructor(props) { 
        super(props);
        this.gameInfo = this.props.gameInfo();
        props.onOpen();
        this.state = {
            headers: setHeadersForFetch(),
            previousProcess:  "",
            allCompleteText: "",
            openExtraCreditResultsModal: false,
            test: false,
            subProcess: "ExtraCreditMain",
            environment: Config.dataURL.currentEnv,
        }
        this.setDisplayText();
    }
   
    render() {
    
       let screenParms = setScreenSizeParameters(this.props.screenSize, "extraCredit",
                "mb-5 mt-4 input-page-base-card");
       this.className = screenParms.columnClass;
       this.textClass = screenParms.textSize;
                                                  
    return <>
        <div className={this.className}>
        <div className="card mb-0 rounded-corners-lg height-max">
            <button className="pages-back-button ml-4 mt-4" 
                onClick ={()=> this.props.goBack()}>
                                    Back
            </button>
            <h3 className="mt-0 text-center">Extra Credit</h3>
            <h5 className="text-center">Main Page</h5>
            <span className="mt-2 ml-4 mr-4">
            <div className="mx-4">This area allows you to learn about some basic money management 
                 concepts.  If you can successfully complete the challenges, you 
                 will be able to earn dollars to increase the amount of your
                 game cash and salary. </div>
           
                    <div className="m-0 p-0 text-center">
                    {!this.gameInfo.gameExists && this.props.playerRole == "player" ?
                        <div className="mt-3 mx-4 text-left">
                        You have not yet completed the Getting Started part of the game.  Not to worry....Any dollars 
                        that you earn by doing the extra credit challenges now, will be added to your game once you
                        finish the Getting Started part of the game.  To complete the Getting Started part of the 
                        game return to the main (home) page and click on "Game" square.</div>
                    :
                        <div></div>
                    }
            
                    {this.gameInfo.extraCreditExists ?
                                                  
                        <div className="text-center">
                            <button className="good-looking-button-extraCredit mt-4" 
                                onClick ={()=> this.openResultsModal()}>
                                Review Extra Credit Amounts Earned
                            </button>
                        </div>
                    :
                        <div className="mt-2">You have not completed any of the extra credit processes at this point.</div>       
                    }

                    {this.state.allCompleteText != "" ?
                        <span>
                            <h6 className="mt-2 text-center font-weight-bold">Congratulations!</h6>  
                            <div>{this.state.allCompleteText}</div> 
                            <div className="mt-4"></div>
                            <a className="" onClick ={()=>this.goToMainPage()}>Return to the game to continue.</a> 
                        </span>
                    :
                        <div className="font-weight-bold mt-4">Click on a button (topic) to the right to continue. </div>
                    }
                    
                    {this.state.openExtraCreditResultsModal ?
                        <ExtraCreditResultsModal show={this.state.openExtraCreditResultsModal} 
                            gameInfo = {() => this.props.gameInfo()}
                            onClose={() => this.closeResultsModal()} 
                        />
                    :
                        <div></div>
                    }
                </div>
            </span>
            </div>
                 
        </div>
     
     </>
    }

    
componentDidMount()
{
    let playerRole = sessionStorage.getItem("userRole");
    this.props.setGameInfo(playerRole, 'playerRole');
    sessionStorage.removeItem("GameViewSubProcess");
}

setDisplayText(){
    var extraCreditTable = [];
    if (this.gameInfo.extraCredit.extraCreditTable != undefined){
        extraCreditTable = this.gameInfo.extraCredit.extraCreditTable;
    }
    this.totalChallengesCompleted = extraCreditTable.length;
   
    let challengePoints = ExtraCreditConfigFile.challengePoints;
    let pointsObj = challengePoints.find(elem => elem.level === "total");
    this.totalPoints = pointsObj.points;

    if (this.totalChallengesCompleted == this.totalPoints) {
        this.state.allCompleteText = "You have completed all of the extra credit challenges.";
    
    }else {
        const checkComplete = sessionStorage.getItem("extraCreditChallengeCompleted");
        if (checkComplete != null){
            this.openResultsModal();
            sessionStorage.removeItem("extraCreditChallengeCompleted");
        }
    } 
}

goToMainPage(){
    this.props.history.push('/Homepage');
}

openResultsModal(){
    this.state.openExtraCreditResultsModal = true;
}

closeResultsModal(){
    this.state.openExtraCreditResultsModal = false;
}

}


export default withRouter (ExtraCreditMain);