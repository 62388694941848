import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {homeRentAmt} from '../../objects/monthlyCalc/homeRentCalc';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { findRemoveTableItem} from "../../objects/AssetsExpenses/find_remove_table_item";
import {createTableItem} from "../../objects/AssetsExpenses/create_table_item";
import ConfigFile from '../../datasets/config_file';
import TwoColumnTableCard from './two_column_table_card';
import ThreeColumnTableCard from './three_column_table_card';
import ChangeHomeText from './text/change_home_text';
import homeInsAmountsDataset from './datasets/home_ins_amounts';
import FriendDisplayModal from '../shared-components/friend_display_modal';
import HomePurchaseText from './text/home_purchase_text';
import { calculatePMITerm } from './objects/calculate_PMI_term';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';
import determineMoodPoints from '../../objects/CommonUse/determine_mood_points';
import createMoodPointArr from '../shared-components/objects/create_mood_point_arr';
import HomeDownPaymentText from './text/home_downpayment_text';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class HomeChangeModal extends Component {
  constructor(props) {
    super(props);
    this.gameInfo = this.props.gameInfo();
    this.state={
         entryTab: [],
         current: {HomeId: "", CashAmt: 0, RentAmt: "", RentInsAmt: "", HomeInsAmt: "",
            HomeHOAAmt: "", FullRentAmt: "", Roommates: 0, Bedrooms: 0, HomeType: "",
            RoommateRentAmt: 0, RoommateShareOfMortgage: 0, MortgageAmt: "", HomeFullCost: 0,
            PMIMonthly: "", MonthlyLoanAmt: 0, totLoanAmt: 0, GameDate: ""},
         newhome: {HomeId: "", CashAmt: 0, RentAmt: 0, RentInsAmt: 0, HomeInsAmt: 0,
            HomeHOAAmt: 0, FullRentAmt: 0, Roommates: 0, Bedrooms: 0, HomeType: "",
            RoommateRentAmt: 0, RoommateShareOfMortgage: 0, MortgageAmt: "", HomeFullCost: 0,
            PMIMonthly: "", MonthlyLoanAmt: 0, totLoanAmt: 0, HomeLevel: "", HomeFullCost: 0},
         data: null,
         gameData: null,
         gameDataSw: false,
         homeData: null,
         homeDataSw: false,
         loadingHomeData: true,
         movingCost: 0,
         calculationsCompleteSw: false,
         monthlyCostArr: [],
         oneTimeCostArr: [],
         depositRefundAmt: 0,
         amtErrorsFound: false,
         allowSubmitBtn: true,
         modalType: "trainVideo",
         downPaymentText: HomeDownPaymentText,
         downPaymentError: "",
         downPaymentMax: 0,
         status: "initial",
         downPaymentTextExpanded: false,
         totalMonthlyCost: 0,
         totalOneTimeCost: 0,
         timeout: 0,
         headers: setHeadersForFetch(),
         subProcess: "homeChangeModal",
         environment: Config.dataURL.currentEnv,
    };   
    this.getCurrentHomeData(); 
  }

  calculateAmounts(){
      this.changeType = sessionStorage.getItem("homeChangeType");  
      this.state.downPaymentMax = +this.gameInfo.gameData.currentCash + +this.props.homeProfit;
      if (this.gameInfo.savingsInfo.totalSavings != undefined){
            this.state.downPaymentMax += +this.gameInfo.savingsInfo.totalSavings;
      }
      if (this.changeType != "roommate-change"){
        this.getNewHomeData();
      }
      this.getGameData();
      if (this.changeType == "roommate-change"){
            this.state.newhome.Roommates = sessionStorage.getItem("homeRoommates");
            this.state.newhome.HomeId = this.state.current.HomeId;
            if (this.state.current.HomeType == "apartment"){
                let currentRentObj = findRemoveTableItem(this.gameInfo.gameData.expenseTable, 
                         "rent", false);
                if (currentRentObj.expAmount != undefined){
                    this.state.current.FullRentAmt = +currentRentObj.expAmount * 
                            (+this.state.current.Roommates + 1);
                    this.state.current.RoommateRentAmt = +this.state.current.FullRentAmt - 
                             +currentRentObj.expAmount;
                }else{
                    this.state.current.FullRentAmt = 0;
                }
                this.state.newhome.FullRentAmt = this.homeDataCurrent.rent;
            }
      }
      
      this.selectionOK = true;
      if (this.state.current.HomeId == this.state.newhome.HomeId){ 
          if (this.state.current.Roommates == this.state.newhome.Roommates) {  
             this.sameRoommateSw = true;
             this.selectionOK = false;
             this.state.modalType = "SameHouse";
          }else{
             this.sameRoommateSw = false;
             if (this.changeType != "roommate-change"){
                  this.state.modalType = "SameHouse";  
                  this.selectionOK = false;
             }
          }
      }
      if (this.selectionOK == true){  
            if (this.state.current.HomeType == "apartment"){
                this.getCurrentRent(); 
            }
            this.processHomeChange();
            this.prepareInfoForModal(); 
            this.editAmounts();
            if (this.state.amtErrorsFound == false){
                if (+this.downPaymentAmt > 0  ||
                    this.state.newhome.HomeType == "apartment" ||
                    this.changeType == "roommate-change"){
                        this.state.calculationsCompleteSw = true;
                }
            }
            this.state.modalType = "CalculationsComplete";
      }
      this.setState({modalType: this.state.modalType});
  }

  getNewHomeData(){
        this.state.newhome.HomeId = sessionStorage.getItem("homeChoice");
        this.state.newhome.HomeType = sessionStorage.getItem("homeType");
        this.state.newhome.HomeLevel = sessionStorage.getItem("homeLevel");
        this.state.newhome.Roommates = sessionStorage.getItem("homeRoommates");
        if (this.state.newhome.HomeType == "house"){
          this.state.newhome.HomeFullCost = sessionStorage.getItem("homeCost");
          this.state.newhome.HomeHOAAmt = sessionStorage.getItem("homeHOA");
        }else{
          this.state.newhome.FullRentAmt = sessionStorage.getItem("homeCost");
          this.state.newhome.HomeFullCost = "";
        }
     
        this.state.newhome.Bedrooms =  sessionStorage.getItem("homeBedrooms");
        this.state.newhome.HomeName= sessionStorage.getItem("homeName");
       
  }

  getGameData() {
      this.state.gameData = this.gameInfo.gameData;
      this.assetTable = this.state.gameData.assetTable;
      this.expenseTable = this.state.gameData.expenseTable;
      this.loanTable = this.state.gameData.loanTable;
      this.state.current.HomeType = this.state.gameData.homeInfo.homeType;
      this.state.current.Roommates = this.state.gameData.homeInfo.roommates;
      this.state.current.Bedrooms = this.state.gameData.homeInfo.bedrooms;
      this.state.current.GameDate = this.state.gameData.currentGameDate; 
      this.startGameDate = this.state.gameData.startGameDate;
      this.state.current.CashAmt = this.state.gameData.currentCash;
      this.state.gameDataSw = true;
  }
 
  getCurrentRent(){
      this.state.current.RentAmt = 0;
      let currentRentObj = findRemoveTableItem(this.expenseTable, "rent", false);
      if (currentRentObj.expAmount != undefined){
        this.state.current.RentAmt = +currentRentObj.expAmount;
        if (this.state.current.Roommates > 0){
            this.state.current.FullRentAmt =
                 +this.state.current.RentAmt * (+this.state.current.Roommates + 1);
        }else{
            this.state.current.FullRentAmt = +this.state.current.RentAmt;
        }
      }    
  }
T
  processHomeChange(){
    this.updateDefaultValues();
    this.calculateNewMonthlyPaymentAmt();
    if (this.changeType != "roommate-change"){
        this.determineOneTimeCosts();
    }    
    this.determineHomeChangeMoodPoints();
    this.saveUpdatedValues();
  }  

  updateDefaultValues(){
     this.state.newCashAmt = JSON.parse(JSON.stringify(this.state.current.CashAmt));
     this.newExpenseTable = JSON.parse(JSON.stringify(this.expenseTable));
     this.newLoanTable = JSON.parse(JSON.stringify(this.loanTable));
     this.newAssetTable = JSON.parse(JSON.stringify(this.assetTable));
     if (this.changeType == undefined){
        this.changeType = this.state.current.HomeType + "-" + this.state.newhome.HomeType;
     }
  }
  
  calculateNewMonthlyPaymentAmt(){
      this.profitFromHomeSale = this.props.homeProfit;
      this.homeInflationCost = this.props.currentHomeSellingPrice;
      this.state.current.totLoanAmt = this.props.currentLoanOutstandingAmt;
      this.state.current.MortgageAmt = 0;
      if (this.downPaymentAmt == undefined){
        this.downPaymentAmt = 0;
      }  
            
      if (this.changeType == "apartment-apartment"){
            let currentRentObj = findRemoveTableItem(this.newExpenseTable, "rent", true);
            if (currentRentObj.expAmount != undefined){
                this.state.current.FullRentAmt = +currentRentObj.expAmount * 
                        (+this.state.current.Roommates + 1);
                this.state.current.RoommateRentAmt = +this.state.current.FullRentAmt - 
                         +currentRentObj.expAmount;
            }else{
                this.state.current.FullRentAmt = 0;
            }
            this.createNewRentExpense();
      }
       
      if (this.changeType == "apartment-house"){
            // leave following in place, currentRentObj is not being used but the findRemoveTable is required
            let currentRentObj = findRemoveTableItem(this.newExpenseTable, "rent", true);
            let currentInsObj = findRemoveTableItem(this.newExpenseTable, "rentersIns", true);
            this.state.current.RentInsAmt = currentInsObj.expAmount;
            this.createNewLoanObj();
            this.createNewHomeInsObj();
            if (this.state.newhome.Roommates > 0){
                this.createNewAssetItem();
            }
            if (this.state.newhome.HomeHOA > 0){
                this.createNewHOAExpense();
            }
      }

      if (this.changeType == "house-house"){
            this.closeOutCurrentHomeLoan();
            let currentInsObj = findRemoveTableItem(this.newExpenseTable, "homeIns", true);
            this.state.current.HomeInsAmt = currentInsObj.expAmount;
            this.getCurrentHOAAmount(true);
            this.createNewLoanObj();
            this.createNewHomeInsObj();
            this.getCurrentRoommateRent();
            if (this.state.newhome.Roommates > 0){
                this.createNewAssetItem();
            }
            if (this.state.newhome.HomeHOA > 0){
                this.createNewHOAExpense();
            }
      }

      if (this.changeType == "house-apartment"){
         this.closeOutCurrentHomeLoan();
         let currentInsObj = findRemoveTableItem(this.newExpenseTable, "homeIns", true);
         this.state.current.HomeInsAmt = currentInsObj.expAmount;
         this.state.current.FullRentAmt = "";
         this.getCurrentHOAAmount(true);
         this.getCurrentRoommateRent();
         this.createNewRentExpense();
         this.state.newhome.RentInsAmt = ConfigFile.insurance.rentersInsAmt;
         let rentInsObj = createTableItem("rentersIns", "", this.state.current.GameDate, 
            this.state.newhome.RentInsAmt, "", false);
         this.newExpenseTable.push(rentInsObj);
         this.state.current.RentAmt = +this.state.current.MortgageAmt - 
                +this.state.current.RoommateShareOfMortgage + +this.state.current.PMIMonthly +
                +this.state.current.HomeInsAmt;
      }
      if (this.changeType == "roommate-change"){
         this.state.newhome.CashAmt = this.gameInfo.gameData.currentCash;
         this.state.newhome.HomeName = this.homeDataCurrent.title;
         this.updateSessionStorage();
         if (this.state.current.HomeType == "apartment"){  
            let currentRentObj = findRemoveTableItem(this.newExpenseTable, "rent", true);
            this.state.newhome.RentAmt = +currentRentObj.expAmount *
                 (+this.state.current.Roommates + 1);
            this.createNewRentExpense();
         }else{
            this.getCurrentRoommateRent();
            this.getCurrentHOAAmount(false);
            let mortgageObj = findRemoveTableItem(this.newLoanTable, "mortgage", false);
            this.state.current.MortgageAmt = mortgageObj.loanAmt;
            this.state.newhome.HomeHOAAmt = this.state.current.HomeHOAAmt;
            this.state.newhome.MortgageAmt = mortgageObj.loanAmt;
            if (this.state.newhome.Roommates > 0){
                this.createNewAssetItem();
            }
         }
      }
    }
    
    createNewRentExpense(){
        let newExpObj = homeRentAmt(this.state.newhome.FullRentAmt, this.state.newhome.Roommates,
            this.state.newhome.Bedrooms, this.state.newhome.HomeName, this.state.current.GameDate);
        this.newExpenseTable.push(newExpObj);
        this.state.newhome.RentAmt = newExpObj.expAmount;
        this.state.newhome.RoommateRentAmt = this.state.newhome.FullRentAmt - 
            this.state.newhome.RentAmt;
    }

    getCurrentRoommateRent(){
        if (+this.state.current.Roommates > 0){
            let currentRentObj = findRemoveTableItem(this.newAssetTable, "roommateRent", true);
            this.state.current.RoommateShareOfMortgage = currentRentObj.assetAmt;
            
        }else{
            this.state.current.RoommateShareOfMortgage = 0;
        }
    }

    getCurrentHOAAmount(removeHOAObj){
        let currentHOAObj = findRemoveTableItem(this.newExpenseTable, "homeHOA", removeHOAObj);
        if (currentHOAObj.expAmt != undefined){
              this.state.current.HomeHOAAmt = currentHOAObj.expAmount;
        }else{
              this.state.current.HomeHOAAmt = 0;
        }
    }

    createNewHomeInsObj(){
        this.homeInsRates = homeInsAmountsDataset;
        this.state.newhome.HomeInsAmt = 0;
        for (let i=0; i<this.homeInsRates.length; i++){
            if (this.homeInsRates[i].homeCost >= this.state.newhome.HomeFullCost){
                this.state.newhome.HomeInsAmt = this.homeInsRates[i].monthInsAmt;
                break;
            }
        }
        let homeInsObj = createTableItem("homeIns", "", this.state.current.GameDate, 
            this.state.newhome.HomeInsAmt, "", false);
        this.newExpenseTable.push(homeInsObj);
    }

    closeOutCurrentHomeLoan(){
        let loanObj = findRemoveTableItem(this.newLoanTable, "mortgage", true);
        this.state.current.MortgageAmt = loanObj.loanAmt;
        this.state.current.HomeFullCost = loanObj.loanTotAmt;
        // following is done to clear out the mortgage PMI 
        let loanPMIObj = findRemoveTableItem(this.newLoanTable, "mortgagePMI", true);
        if (loanPMIObj.loanAmt == undefined){
            this.state.current.PMIMonthly = 0;
        }else{
            this.state.current.PMIMonthly = loanPMIObj.loanAmt;
        }
    }

    createNewLoanObj(){
        this.state.newhome.totLoanAmt = (+this.state.newhome.HomeFullCost - +this.downPaymentAmt).toFixed(2);
        let loanObj = createTableItem("mortgage", this.state.newhome.HomeName, 
                this.state.current.GameDate, this.state.newhome.totLoanAmt, 0, false);
        this.state.newhome.MortgageAmt = loanObj.loanAmt;
        this.loanIntRate = loanObj.loanIntRate;
        this.newLoanTable.push(loanObj);
                  
        this.downPaymentPct = 0;
        this.state.newhome.PMIMonthly = 0;
        if (this.downPaymentAmt != 0 && !isNaN(this.downPaymentAmt)){
            this.downPaymentPct = ((this.downPaymentAmt / this.state.newhome.HomeFullCost) * 100).toFixed(1);
            if (this.downPaymentPct < 20){
                this.calculateMortgageInsurance();
            }
        }
    }

    calculateMortgageInsurance(){
      //  calculate PMI
      // pmi annual = loan amount * interest rate
      // pmi monthly = annual / 12
      // when your loan balance is less than 20% of home value, then pmi goes away
        let PMIRate = ConfigFile.homeItems.PMIRate;
        let PMIAnnual = (this.state.newhome.HomeFullCost * PMIRate/100).toFixed(2);
        this.state.newhome.PMIMonthly = +PMIAnnual / 12;
        // % paid off * total Loan amt / payAmt= nbrMonths
        let PMIObj = calculatePMITerm(this.state.current.MortgageAmt, this.loanIntRate, 
            this.baseLoanAmt, 200);
        this.PMINbrMonths = PMIObj.nbrMonths;
        let totalPMIAmt = (this.state.newhome.PMIMonthly * this.PMINbrMonths).toFixed(2);
        this.PMIYears = (this.PMINbrMonths / 12).toFixed(1);
        let PMILoanObj = {loanType: "Mortgage Insurance (PMI)", 
            loanDesc: "Mortgage Insurance for " + this.state.newhome.HomeName,
            loanAmt: this.state.newhome.PMIMonthly, loanStartDate: this.gameInfo.gameData.currentGameDate,
            loanNbrMonths: this.PMINbrMonths, loanTotAmt: totalPMIAmt, 
            loanIntRate: this.loanIntRate, loanTerm: this.PMIYears}
        this.newLoanTable.push(PMILoanObj);
    }
        
    createNewAssetItem(){
        let baseRentAmt = ConfigFile.homeItems.baseRentAmt;
        let totalRentAmt = +baseRentAmt * +this.state.newhome.Roommates;
        let assetDesc = "Monthly amount roommate pays you for rent.";
        if (this.state.newhome.Roommates > 1){
            assetDesc = "Monthly amount roommates pay you for rent.";
        }
        let assetObj = createTableItem("roommateRent", assetDesc, this.state.current.GameDate, totalRentAmt,
            this.startGameDate, true);
        this.state.newhome.RoommateShareOfMortgage = assetObj.assetAmt;
        this.newAssetTable.push(assetObj);
    }

    createNewHOAExpense(){
        let expHOAObj = createTableItem("homeHOA", "", this.state.current.GameDate, this.state.newhome.HomeHOA);
        this.newExpenseTable.push(expHOAObj);
    }

  determineOneTimeCosts(){
    this.newFurnitureCost = 0;
    this.newTools = 0;
    let baseMovingCost = +ConfigFile.homeItems.baseMovingCost;
    let movingCostPerBedroom = +ConfigFile.homeItems.costPerBedroom;
    this.movingCost = baseMovingCost + (movingCostPerBedroom * +this.state.newhome.Bedrooms);
    this.damageDeposit = 0;
    this.downPayment = 0;
    this.state.depositRefundAmt = 0;
    this.state.depositRefundAmt = sessionStorage.getItem("ChangeHomeDepositRefund");
    let checkDepositRefund = false;
    if (this.state.depositRefundAmt == null){
        checkDepositRefund = true;
    }else{
         checkDepositRefund = isNaN(+this.state.depositRefundAmt);
    }
    if (this.state.current.HomeType == "apartment" && checkDepositRefund == true){ 
       let randomNbr = (Math.floor(Math.random() * 11))/10;
       this.state.depositRefundAmt = (this.state.current.RentAmt * randomNbr).toFixed(2);
       sessionStorage.setItem("ChangeHomeDepositRefund", this.state.depositRefundAmt);
    }
    
    let nbrNewBedrooms = +this.state.newhome.Bedrooms - +this.homeDataCurrent.bedrooms;  
    if (nbrNewBedrooms > 0){
       this.newFurnitureCost = 300 * nbrNewBedrooms;
    }

    if (this.state.newhome.HomeType == "apartment"){ 
       this.damageDeposit = this.state.newhome.RentAmt;
    }else{
      //  new tools - lawn equip, shovels, hand tools, small power tools, etc. needed for a home
       if (this.state.current.HomeType != "house"){
            this.newTools = 500;
       } 
    }
    this.state.totalOneTimeCost = +this.movingCost + +this.newFurnitureCost + +this.damageDeposit - 
      +this.state.depositRefundAmt + this.newTools + this.downPaymentAmt - this.profitFromHomeSale;

    this.state.newhome.CashAmt = (this.state.current.CashAmt - this.state.totalOneTimeCost).toFixed(2);
   }
   
  determineHomeChangeMoodPoints(){
    let gameChoiceArr = [];
    if (this.changeType == "roommate-change"){
        this.startingMoodPoints = ConfigFile.gameChoice.roommateChangeMood;
        gameChoiceArr = createMoodPointArr("roommate");
    }else{
        this.startingMoodPoints = ConfigFile.gameChoice.homeChangeMood;
        gameChoiceArr = createMoodPointArr("home");
    }
   
    this.moodPointStartArr = determineMoodPoints(gameChoiceArr);
    this.totalMoodPoints = +this.startingMoodPoints;
    for (let i=0; i<this.moodPointStartArr.length; i++){
         this.totalMoodPoints += +this.moodPointStartArr[i].number;
    }
    this.determineMoodPointText();
    sessionStorage.setItem("homeChangeMoodPoints", this.totalMoodPoints);
  }

  determineMoodPointText(){
    if (this.changeType == "roommate-change"){
        if (this.totalMoodPoints == 0){
            this.moodPointText = "Changing the number of roommates that you are going to have will help " +
                "to pay the rent, but it doesn't increase your mood score."
        }else{
            this.moodPointText = "Changing the number of roommates will increase your mood score."
        }
    }else{
        if (this.totalMoodPoints == 0){
            this.moodPointText = "You are excited to be changing your home but the choices " +
                "that you have made will not increase your mood score.";
        }else{
            this.moodPointText = "You are very excited to be moving to a new home.  This " +
                "will increase your mood score."
        }
    }
  }

   updateSessionStorage(){
        sessionStorage.setItem("homeChoice", this.state.current.HomeId);
        sessionStorage.setItem("homeType", this.state.current.HomeType);
        sessionStorage.setItem("homeBedrooms", this.state.current.Bedrooms);
        let homePhotosArr = this.homeDataCurrent.additionalPhotosArr;
     //   sessionStorage.setItem("homeDesc", this.newHomeDesc);
        sessionStorage.setItem("homeDirectory", this.homeDataCurrent.directory)
        sessionStorage.setItem("homeAdditionalPhotosArr", JSON.stringify(homePhotosArr));
   }

   saveUpdatedValues(){
        sessionStorage.setItem("changeHomeExpenseObjects", JSON.stringify(this.newExpenseTable));
        sessionStorage.setItem("changeHomeLoanObjects", JSON.stringify(this.newLoanTable));
        sessionStorage.setItem("changeHomeAssetObjects", JSON.stringify(this.newAssetTable));
        sessionStorage.setItem("changeHomeNewCashAmt", this.state.newhome.CashAmt);
   } 

   editAmounts(){
        this.state.amtErrorsFound = false;
        this.amountErrorText1 = "";
        this.amountErrorText2 = "";
        
        if (this.state.newhome.HomeType == "house" && this.changeType != "roommate-change"){ 
            if (+this.downPaymentAmt == 0){
                this.amountErrorText1 = "Down Payment Amount is required and cannot be 0.";
                this.state.amtErrorsFound = true;
            }else{
                let downPaymentPct = 0;
                if (isNaN(this.downPaymentAmt)){
                    this.amountErrorText1 = "Down Payment Must be Numeric (no commas or $).";
                    this.state.amtErrorsFound = true;
                }else{
                    downPaymentPct = ((+this.downPaymentAmt / 
                        (+this.state.newhome.HomeFullCost - +this.downPaymentAmt)) 
                        * 100).toFixed(1);
                    if (+downPaymentPct < 5){
                        this.amountErrorText1 = "Down Payment Amount must be greater than 5%";
                        this.state.amtErrorsFound = true;
                    }
                    if (+this.downPaymentAmt > +this.state.downPaymentMax){
                        this.amountErrorText1 = "Down Payment Amount exceeds amount available.";
                        this.state.amtErrorsFound = true;
                    }
                }
            }
            if (this.state.amtErrorsFound == true){
                this.amountErrorText2 = "Enter/correct the down payment amount and then click " +
                    "the 'Submit' or 'Accept/Continue' button."
            }
        }
        if (this.state.amtErrorsFound == false){
            if (+this.state.current.CashAmt < +this.state.totalOneTimeCost){
                if (this.state.newhome.HomeType == "apartment"){
                    this.determineErrorText("notEnoughCash", 1);
                }
                if (this.state.newhome.HomeType == "house" &&
                        this.downPaymentAmt < this.state.downPaymentMax){
                        this.determineErrorText("transferCashLit1", 1);
                }
                if (this.state.amtErrors = true &&
                    this.gameInfo.savingsInfo.totalSavings != undefined){
                        this.determineErrorText("transferCashLit2", 2);
                }
            }
            let monthlyIncome = this.props.monthlyIncomeMinusHomeCosts;
            if (this.changeType == "roommate-change"){
                monthlyIncome = this.props.monthlyIncomeMinusRoommateAmts;
            }
            if (this.state.totalMonthlyCost > monthlyIncome){ 
                if (this.changeType == "roommate-change" ||
                    this.state.newhome.HomeType != "house"){
                    this.determineErrorText("monthlyIncomeError", 1)
                }else{ 
                    if (this.downPaymentAmt != 0){
                        this.determineErrorText("loanNotApprovedText", 1)
                    }
                }
            }
        }
       
   }
  
   prepareInfoForModal(){
        this.assembleMonthlyCosts();
        if (this.changeType != "roommate-change"){
            this.assembleOneTimeCosts();
        }
        this.determineText();
   }
    
   assembleMonthlyCosts(){
        this.state.monthlyCostArr = [];
        this.state.totalMonthlyCost = 0;
        if ((this.changeType == "roommate-change" && this.state.current.HomeType == "apartment") ||
            this.changeType == "apartment-apartment"){
                this.addToMonthlyCostArr("Monthly Rent Amt", this.state.current.FullRentAmt,
                    this.state.newhome.FullRentAmt, 1, "red");
                if (this.state.current.Roommates > 0 || this.state.newhome.Roommates > 0){
                    this.addToMonthlyCostArr("Amount Roommate(s) Pays For Rent", 
                        this.state.current.RoommateRentAmt, this.state.newhome.RoommateRentAmt, 
                        3, "black");
                    this.addToMonthlyCostArr("Rent Amount Paid by You",
                        this.state.current.RentAmt, this.state.newhome.RentAmt, 4, "red")
                }
                this.state.totalMonthlyCost = +this.state.newhome.RentAmt;
        }
        if (this.changeType == "roommate-change" && this.state.current.HomeType == "house"){
            this.addToMonthlyCostArr( "Monthly Mortgage Amt",  this.state.current.MortgageAmt,
                this.state.current.MortgageAmt, 1, "red");
            this.monthlyRentAmtPaidByRoommate();
            this.state.totalMonthlyCost = +this.state.newhome.MortgageAmt - 
                +this.state.newhome.RoommateShareOfMortgage;
        }
             
        if (this.changeType == "apartment-house"){
                this.addToMonthlyCostArr("Monthly Rent Amt", this.state.current.RentAmt,
                     "", 1, "red");
                this.mortgageCostsForHome();
                this.addToMonthlyCostArr("Monthly HOA Amount",  "",
                     this.state.newhome.HomeHOAAmt, 10, "red");
                this.addToMonthlyCostArr("Renter's/Home Insurance Amount", 
                     this.state.current.RentInsAmt,
                     this.state.newhome.HomeInsAmt,  11, "red"); 
                let currentTotalMonthlyCost = +this.state.current.RentAmt +
                    + +this.state.current.RentInsAmt;
                this.state.totalMonthlyCost = +this.state.newhome.MortgageAmt + 
                    +this.state.newhome.HomeInsAmt + +this.state.newhome.PMIMonthly + 
                    +this.state.newhome.HomeHOAAmt - +this.state.newhome.RoommateShareOfMortgage;
                this.addToMonthlyCostArr("Total Monthly Costs", currentTotalMonthlyCost,
                    this.state.totalMonthlyCost,  30, "red");
        }
        
        if (this.changeType == "house-house"){
                this.currentLoanRolledIntoNewLoan();
                this.addToMonthlyCostArr( "Home HOA Amount", this.state.current.HomeHOAAmt,
                        this.state.newhome.HomeHOAAmt, 6, "red");  
                this.addToMonthlyCostArr("Home Insurance Amount", 
                    this.state.current.HomeInsAmt,
                    this.state.newhome.HomeInsAmt,  7,  "red");  
                this.mortgageCostsForHome();
                
                this.state.totalMonthlyCost = +this.state.newhome.MortgageAmt +
                    +this.state.newhome.PMIMonthly + +this.state.newhome.HomeHOAAmt - 
                    +this.state.newhome.RoommateShareOfMortgage +
                    +this.state.newhome.HomeInsAmt;
        }
        if (this.changeType == "house-apartment"){
                this.state.newhome.RoommateShareOfMortgage = +this.state.newhome.RentAmt *
                    +this.state.newhome.Roommates;
                this.mortgageCostsForHome();
                if (+this.state.current.HomeHOAAmt > 0){
                    this.addToMonthlyCostArr("Home HOA Amount", 
                        this.state.current.HomeHOAAmt, "",  7, "red");  
                }   
                this.addToMonthlyCostArr("Monthly Rent Amt", "",
                    this.state.newhome.FullRentAmt, 20, "red");
                this.addToMonthlyCostArr("Insurance Amount", this.state.current.HomeInsAmt,
                     this.state.newhome.RentInsAmt, 21, "red");
                let totalMonthlyAptAmt = +this.state.newhome.RentAmt +
                    +this.state.newhome.RentInsAmt;
                this.addToMonthlyCostArr("Total Monthly Amount", 
                    this.state.current.RentAmt, totalMonthlyAptAmt, 30, "red");
                              
                this.state.totalMonthlyCost = +this.state.newhome.RentAmt + 
                    +this.state.newhome.RentInsAmt;
        }
        this.state.monthlyCostArr.sort((a, b) => (a.order > b.order) ? 1 : -1);
  }

  mortgageCostsForHome(){
        this.addToMonthlyCostArr("Original Cost of Home", this.state.current.HomeFullCost,
             this.state.newhome.HomeFullCost,  1, "red");
        if (this.state.current.HomeType == "house"){
            this.addToMonthlyCostArr("Market Value of Current Home", this.homeInflationCost,
                 "", 2,  "black");
        }
        this.addToMonthlyCostArr("Monthly Mortgage Payment", this.state.current.MortgageAmt,
            this.state.newhome.MortgageAmt, 4, "red");
        if (+this.state.current.PMIMonthly > 0 || +this.state.newhome.PMIMonthly > 0){
            let PMIMonthly = this.state.current.PMIMonthly;
            if (this.state.current.HomeType == "apartment"){
                PMIMonthly = ""
            }
            this.addToMonthlyCostArr("Mortgage Insurance (PMI) Amt", PMIMonthly,
                    this.state.newhome.PMIMonthly, 5, "red");
        }
        if (+this.state.current.RoommateShareOfMortgage > 0 ||
            +this.state.newhome.RoommateShareOfMortgage > 0) {
            this.addToMonthlyCostArr("Monthly Rent Paid by Roommate(s)", 
                this.state.current.RoommateShareOfMortgage,
                this.state.newhome.RoommateShareOfMortgage, 22, "black");
        }
  }

  currentLoanRolledIntoNewLoan(){
        this.addToMonthlyCostArr("Total Outstanding Loan Amt", this.state.current.totLoanAmt, 
             this.state.newhome.totLoanAmt, 3, "red");
        let currentMonthlyHomeCosts = +this.state.current.MortgageAmt + +this.state.current.PMIMonthly + 
            +this.state.current.HomeHOAAmt - +this.state.current.RoommateShareOfMortgage +
            +this.state.current.HomeInsAmt;
        let newMonthlyHomeCosts = +this.state.newhome.MortgageAmt + +this.state.newhome.PMIMonthly +
            +this.state.newhome.HomeHOAAmt - +this.state.newhome.RoommateShareOfMortgage +
            +this.state.newhome.HomeInsAmt;
        this.addToMonthlyCostArr("Total Monthly Amount (Mortgage, Mortgage Insurance, HOA, Roommate Rent)", 
            currentMonthlyHomeCosts, newMonthlyHomeCosts, 40, "red");
     }

  monthlyRentAmtPaidByRoommate(){
    this.addToMonthlyCostArr("Monthly Amount Paid by Roommate(s)",
        this.state.current.RoommateShareOfMortgage, this.state.newhome.RoommateShareOfMortgage,
         11, "black");
    let currentMonthlyRevenueLoss = +this.state.current.RoommateShareOfMortgage - 
        +this.state.current.MortgageAmt - +this.state.current.HomeHOAAmt;
    let newMonthlyRevenueLoss =  +this.state.newhome.RoommateShareOfMortgage - 
        +this.state.newhome.MortgageAmt - +this.state.newhome.HomeHOAAmt;
    this.addToMonthlyCostArr("Mortgage Amount Deducted each Month", 
            currentMonthlyRevenueLoss,
            newMonthlyRevenueLoss, 12, "black");
  }

  addToMonthlyCostArr(label, amount1, amount2, order, positiveColor){
        // positiveColor is the color to use for positive amounts
        let negativeColor = "";
        if (positiveColor == "black"){
             negativeColor = "red";
        }else{
             negativeColor = "black";
        }
        let color1 = positiveColor;
        if (amount1 != ""  && +amount1 < 0){
            amount1 = -1 * +amount1;
            color1 = negativeColor;
        }
        let color2 = positiveColor;
        if (amount2 != "" && +amount2 < 0){
            amount2 = -1 * +amount2;
            color2 = negativeColor;
        }
        let displayMonthlyCostObj = {label: label, amount1: amount1, amount2: amount2, 
              order: order, color1: color1, color2: color2};
        this.state.monthlyCostArr.push(displayMonthlyCostObj);
     
  }

  assembleOneTimeCosts(){
        this.state.oneTimeCostArr = [];
        let oneTimeObj = "";
        oneTimeObj = {label: "Moving Cost", amount: this.movingCost, order: 2, color: "red"};
        this.state.oneTimeCostArr.push(oneTimeObj);
        if (this.newFurnitureCost > 0){
            oneTimeObj = {label: "New Furniture", amount: this.newFurnitureCost, order: 3, color: "red"};
            this.state.oneTimeCostArr.push(oneTimeObj);
        }
        if (this.state.current.HomeType == "apartment"){
            oneTimeObj = {label: "Damage Deposit Refund (current apt)", 
                    amount: this.state.depositRefundAmt, order: 4, color: "black"};
            this.state.oneTimeCostArr.push(oneTimeObj);
        }
        if (this.profitFromHomeSale > 0){
            oneTimeObj = {label: "Profit From Sale of Current Home", 
                amount: this.profitFromHomeSale, order: 5, color: "black"};
            this.state.oneTimeCostArr.push(oneTimeObj);
        }
        if (this.state.newhome.HomeType == "apartment"){
            oneTimeObj = {label: "Damage Deposit New Apartment",
                 amount: this.damageDeposit, order: 6, color: "red"};
            this.state.oneTimeCostArr.push(oneTimeObj);
        }else{
            oneTimeObj = {label: "Down Payment on Home", amount: this.downPaymentAmt, 
                order: 1, color: "red"};
            this.state.oneTimeCostArr.push(oneTimeObj);
        }
        if (this.newTools > 0){
            oneTimeObj = {label: "New Tools (garden and home)", amount: this.newTools, 
                order: 6, color: "red"};
            this.state.oneTimeCostArr.push(oneTimeObj);
        }
        if (this.state.totalOneTimeCost > 0){
            oneTimeObj = {label: "Total One Time Cost", amount: this.state.totalOneTimeCost, 
                order: 99, color: "red"};
        }else{
            let amtAddedToCash = +this.state.totalOneTimeCost * -1;
            oneTimeObj = {label: "Total Amt Added to Cash", amount: +amtAddedToCash, 
            order: 99, color: "black"};
        }   
        this.state.oneTimeCostArr.push(oneTimeObj);
        oneTimeObj = {label: "Total Amt of Cash in Bank", amount: this.state.current.CashAmt,
            order: 0, color: "black"}; 
        this.state.oneTimeCostArr.push(oneTimeObj);
        this.state.oneTimeCostArr.sort((a, b) => (a.order > b.order) ? 1 : -1);
    }

    determineText(){
        let changeHomeModalText = ChangeHomeText;
        for (let i=0; i<changeHomeModalText.length; i++){
            if(this.changeType == changeHomeModalText[i].changeHomeType){
                this.changeHomeText = changeHomeModalText[i].text;
                break;
            }
        }
        if (this.changeType == "roommate-change"){
            let roommateText = "You have requested to change the number of roommates from " +
                this.state.current.Roommates + " to " + this.state.newhome.Roommates + ". ";
            this.changeHomeText = roommateText + this.changeHomeText;
        }
    }

    determineErrorText(textType, textNbr){
        let homePurchaseText = HomePurchaseText.textTable;
        const textObj =  homePurchaseText.find(elem => elem.textType === textType);
        this.state.allowSubmitBtn = textObj.allowSubmitBtn;
        this.state.amtErrorsFound = true;
        if (textNbr == 1){
            this.amountErrorText1 = textObj.text;
        }else{
            this.amountErrorText2 = textObj.text;
        }
    }
  
  render() { 
     if (this.state.homeDataSw == true && this.state.modalType != "CalculationsComplete"){
        this.calculateAmounts();
     }
 
     if (this.state.modalType == "CalculationsComplete"){
        let infoType = "label-amount-amount";
        this.monthlyAmounts = this.state.monthlyCostArr.map((monthAmts, key) =>
            <ThreeColumnTableCard key={key} outInfo={monthAmts} 
                infoType={infoType}   />
            );
        infoType = "label-amount"
        this.oneTimeAmounts = this.state.oneTimeCostArr.map((oneTimeAmts, key) =>
            <TwoColumnTableCard key={key} outInfo={oneTimeAmts} 
                infoType={infoType}  />
            );
        if (this.totalMoodPoints != this.startingMoodPoints){
            this.moodPointList = this.moodPointStartArr.map((moodPoints,key) =>
                <TwoColumnTableCard key={key} infoType="label-number" outInfo={moodPoints}  />
            );
        }
    }
                       
  return <>

  
    {this.state.modalType == "trainVideo"  ?
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="lg" 
            animation={false}>
        <Modal.Header  className="gameChoiceModalHdrFtrBackground" closeButton>
            <Modal.Title>Home Change Costs</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <span>
                <div className="text-white mt-4">xxxxxx</div>
                    <WaitTimeSpinner />
                <div className="text-white mt-4">xxxxxx</div>
                <div className="text-white mt-4">xxxxxx</div>
                <div className="text-white mt-4">xxxxxx</div>
                <div className="text-white mt-4">xxxxxx</div>
          </span>
        </Modal.Body>
        </Modal>
    :
      null  
    }

    {this.state.modalType == "CalculationsComplete" ?
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="lg" scrollable="true">
        <Modal.Header  className="gameChoiceModalHdrFtrBackground" closeButton>
            <Modal.Title>Home Change Costs</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
                <div>
                    <div className="font-weight-bold text-center">
                        <div>New Home Choice: </div>
                        <div> 
                            {this.state.newhome.HomeName} - &nbsp;&nbsp;{this.state.newhome.Roommates} roommate(s)
                        </div>
                    </div>
                    <div className="mt-2">
                        {this.changeHomeText}
                    </div>
                    {this.state.calculationsCompleteSw ?
                        <div className="mt-2">Click the 'Continue/Accept' button to accept the 
                            terms of this new home choice.
                        </div> 
                    :   
                        <span>
                            {this.state.newhome.HomeType == "house" ?
                               <div className="mt-2">Enter a downpayment amount and then click the 
                                    "Submit" button to calculate amounts. 
                                </div>
                            :
                                <div className="mt-2">Click the "close" button to make
                                    another home selection or click "Return to GameBoard" to 
                                    cancel the roommate change and continue playing the game
                                </div>
                            }
                        </span>
                    }
                </div>
                {this.state.newhome.HomeType == "house" && this.changeType != "roommate-change" ?
                  
                    <div className="mt-2" >
                   
                        <label className="mt-2 font-weight-bold" htmlFor="downPayment">
                            Down Payment Amt:&nbsp;&nbsp;</label>
                    <span>
                    {this.state.downPaymentTextExpanded ?
                        <div className="m-2 text-left">
                            {this.state.downPaymentText}  &nbsp;&nbsp;&nbsp;
                            <Button variant="outline-info" size="sm"
                                onClick={() => this.collapseDownPaymentText()}>
                                    Collapse
                            </Button>
                        </div>
                    :                       
                        <span>
                            <Button variant="outline-info" size="sm"
                                onClick={() => this.expandDownPaymentText()}>
                                    What's This?
                            </Button>
                        </span>
                    }
                    </span>
                    <div>
                        <div><b>Amount Available for Down Payment: &nbsp;&nbsp;</b>
                            {doubleToStringMonthly(this.state.downPaymentMax)}&nbsp;&nbsp;&nbsp;&nbsp;
                            <b>Current Cash:</b>&nbsp;&nbsp; {doubleToStringMonthly(this.gameInfo.gameData.currentCash)}
                        </div>
                        <div>(Amount Available for Down Payment is Current Cash plus
                            savings plus profit from sale of current home.)</div>
                        <input type="text" className="col-med" id="downPayment" name="downPayment"
                            onChange={(val) => this.handleDownPaymentChange(val.target.value)}>
                            </input>
                        <span>&nbsp;&nbsp;&nbsp;{this.downPaymentPct}%</span> 
                       </div>
                    </div>
                :
                    null
                }
      
            {this.state.amtErrorsFound  ?
                <span>
                    <div className="text-danger">
                        {this.amountErrorText1}
                    </div>
                    <div className="text-danger mt-1">
                        {this.amountErrorText2}
                    </div>
                </span>
            :
                null
            }
            
            <table className="table table-hover">
                <tbody>
                    <tr>
                        <th className="text-center">Monthly Amounts</th>
                        <th className="text-right px-0">Current Home</th>
                        <th className="text-right px-0">New Home Choice</th>
                    </tr>
                    {this.monthlyAmounts}
                </tbody>
            </table>

            {this.state.oneTimeCostArr.length > 0 ?
                <div>
                    <div className="text-left font-weight-bold">One-Time Amounts (will reduce Current Cash Amount)</div>
                    <table className="table table-hover">
                        <tbody>
                            {this.oneTimeAmounts}
                        </tbody>
                    </table>
                </div>
            :
                null
            }

            {this.state.openFriendModal ?
            <FriendDisplayModal  show={this.state.openFriendModal} 
                    gameProcess="GameViews"
                    title={this.state.newhome.HomeName}
                    roommates={this.state.newhome.Roommates + " roommate(s)"}
                    askAFriendInfo={this.askAFriendInfo}
                    onClose={() => this.closeFriendModal()} 
                    gameInfo = {() => this.props.gameInfo()}
                    />
            :
                null
            }
             <h5>Mood Point Calculation</h5>
             <div>{this.moodPointText}</div>
             {this.totalMoodPoints == this.startingMoodPoints  ?
                <table className="table table-hover">
                    <tbody className="row-100">
                        <tr>
                            <th className="text-left p-0">Mood Point Change</th>
                            <th className="text-right p-0">{this.totalMoodPoints}</th>
                        </tr>
                    </tbody>
                </table>
              :
                <table className="table table-hover">
                  <tbody className="row-100">
                    <tr>
                      <th className="text-left p-0">Description</th>
                      <th className="text-right p-0">Mood Points</th>
                    </tr>
                    <tr>
                      <th className="text-left p-0">Base Home Change Mood Points</th>
                      <th className="text-right p-0">{this.startingMoodPoints}</th>
                    </tr>
                    {this.moodPointList}
                  
                    <tr>
                      <th className="text-left p-0">Mood Point Change</th>
                      <th className="text-right p-0">{this.totalMoodPoints}</th>
                    </tr>
                  </tbody>
                </table>
            }
            
            </Modal.Body>
            <Modal.Footer  className="gameChoiceModalHdrFtrBackground">
                <Button variant="info"  onClick={() => this.displayFriendModal()}>
                    Ask a Friend
                </Button>
               
                {this.state.calculationsCompleteSw ? 
                     <Button variant="primary"  onClick={() => this.confirmHomeChange("Confirm")}>
                         Continue/Accept
                    </Button>
                :
                    <div>
                        {this.state.allowSubmitBtn ?
                            <Button variant="primary"  onClick={() => this.confirmHomeChange("Submit")}>
                                Submit
                            </Button>
                        :
                            null
                        }
                    </div>
                }
                
                <Button variant="secondary"   onClick={() => this.props.onClose()}>
                    Close
                </Button>
                <Button variant="secondary"   onClick={() => this.props.onCancel()}>
                    Return To GameBoard
                </Button>
                
            </Modal.Footer>
        </Modal>
      :
        <div></div>
      }
    </>
  }
    
    displayFriendModal(){
        this.state.openFriendModal = true;
        this.askAFriendInfo = {topic: "homeChange", roommates: this.state.newhome.Roommates,
            bedRooms: this.state.newhome.Bedrooms, homeType: this.state.newhome.HomeType, 
            homeLevel: this.state.newhome.HomeLevel}
    }

    closeFriendModal(){
        this.state.openFriendModal = false;
    }

    
    expandDownPaymentText(){
        this.state.downPaymentTextExpanded = true;
    }

    collapseDownPaymentText(){
        this.state.downPaymentTextExpanded = false;
    }

    handleDownPaymentChange(downPaymentAmt){
        if(this.state.timeout) clearTimeout(this.state.timeout);
            this.state.timeout = setTimeout(() => {
                this.state.allowSubmitBtn = true;
            this.downPaymentAmt = +downPaymentAmt;
            this.setState({calculationsCompleteSw:  false});
            this.setState({modalType: "trainVideo"});
        }, 500);
    }

    confirmHomeChange(buttonName){
        this.state.amtErrorsFound = false;
        this.amountErrorText1 = "";
        if (buttonName == "Confirm"){
              this.props.onConfirmHomeChange();
        }else{
              this.state.modalType = "";
             
        }

    }
  
    getCurrentHomeData() {
        this.state.current.HomeId = this.gameInfo.gameData.homeInfo.homeID;  
        const funcName = "homeInfo";
        const urlParm = "?homeId=" + this.state.current.HomeId;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
            {method:'GET', headers: this.state.headers})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.homeDataCurrent = output.body;
                        this.state.homeDataSw = true;
                        this.setState({loadingHomeData: false});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            }
    });
    }
 
}
export default HomeChangeModal;