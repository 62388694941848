import {Component} from 'react';
import { Button } from 'react-bootstrap';
import housingOption from '../../objects/dataDefinitions/housingOption';
import HomeCard from './home_card';
import { withRouter } from "react-router";
import {calcInflationAmt} from '../../objects/CommonUse/calc_inflation_amt';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import HomeRoommateCard from './home_roommate_card';
import HomeButtonCard from './home_button_card';
import HomeSelectText from './SharedComponents/text/home_select_text';
import replaceTextInString from "../../objects/CommonUse/replace_text_in_string";
import { monthlyLoanAmt } from "../../objects/AssetsExpenses/loanAmtCalc_function";
import ConfigFile from "../../datasets/config_file";
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from './wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';

class HomeInput extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            housingOptionArr: [
                new housingOption ("1", "1 Bedroom Apartment", "1", "apartment", 0, 0, false),
                new housingOption ("2", "2 Bedroom Apartment", "2", "apartment", 1, 0, ["TEMP"], false),
                new housingOption ("3", "3 Bedroom Home", "3", "house", 2, 300000, ["TEMP"], false),
                new housingOption ("4", "4 Bedroom Home", "4", "house", 3, 416000,  ["TEMP"], false),
            ],
            housingOptionSelected: false,
            homeList: [],
            housingArr: [],
            selection: null,
            loading: true,
            roommate: 0,
            bedrooms: 0,
            homeType: "",
            openHomeSliderModal: true,
            roommateOptions: undefined,
            roommateSelected: false,
            errorMsg: "",
            homeIntroTextIsExpanded: false,
            newHomeList: [],
            eligibleCheckDone: false,
            roommateOnlyAllowed: true, 
            headers: setHeadersForFetch(),
            environment: Config.dataURL.currentEnv,
            subProcess: "HomeInput",
            homePurchaseIsExpanded: false,
        }
   }
    
    handleRoommateChange(nbrRoommates) {
        if (nbrRoommates == "")
        {
            this.homeChoices = "";
            this.state.roommateSelected = false;
        }else{
            this.state.roommate = +nbrRoommates;
            if (this.props.nbrRoommates == this.state.roommate ||
                this.props.nbrBedrooms <= this.state.roommate){
                    this.state.roommateOnlyAllowed = false;
            }else{
                this.state.roommateOnlyAllowed = true;
            }
            this.determineHomeSelectOptions();
            this.state.roommateSelected = true;
        }
    }

    preProcess(){
        this.getGameYears();
        this.getHomeSelectText();
        this.determineHomeSelectOptions();             
    }

    getGameYears(){
        var yearMonthVals = 0;
        if (this.props.currentGameDate == undefined){
            let today = new Date();
            this.currentGameYear = today.getFullYear();
            this.yearsDiff = 0;      
        }else{
            yearMonthVals = this.props.currentGameDate.split('-'); 
            this.currentGameYear = +yearMonthVals[0];
            yearMonthVals = this.props.startGameDate.split('-'); 
            this.startGameYear = +yearMonthVals[0];
            this.yearsDiff = this.currentGameYear - this.startGameYear;
        }
    }

   getHomeSelectText(){
        let textType = "";
        if (this.props.gameStarted == true){
             textType = "GameInProgress"
        }else{
             textType = "Initial"
        }
        let displayText = HomeSelectText;
        let homeSelectText = "";
        for (let i=0; i<displayText.length; i++){
             if (textType == displayText[i].type){
                 homeSelectText = displayText[i].shortText;
                 this.homeSelectIntroText = displayText[i].text;
                 break;
             }
        }
        if (textType == "Initial"){
             this.homeSelectText = homeSelectText;
        }else{
             let downPaymentPct = (+ConfigFile.homeItems.downPayMinPct * 100);
             let replaceObj = {savings: this.props.savings, 
                number: downPaymentPct, income: this.props.monthlyIncome};
             this.homeSelectText = replaceTextInString(homeSelectText, replaceObj);
        }
    }
    
    determineHomeSelectOptions(){
        this.interestRate = ConfigFile.homeItems.mortgageInterest;
        this.downPayMinPct = ConfigFile.homeItems.downPayMinPct;
        this.loanTerm = ConfigFile.homeItems.mortgageLoanTerm;
        this.state.housingArr = JSON.parse(JSON.stringify(this.state.housingOptionArr));
        let startIdx = this.state.housingArr.length - 1; 
        let selectEntry = false;
        for (let i=startIdx; i>=0; i--){
            selectEntry = false;
            if (this.state.housingArr[i].maxRoommates >= this.state.roommate){
                if (this.state.housingArr[i].homeAptInd == "apartment"){
                        selectEntry = true;
                }else{
                    if (this.props.gameStarted == true){
                        this.calcDownPaymentAndMortgage(i);
                        if (+this.props.savings >= +this.minimumDownPayment &&
                            +this.props.monthlyIncome >= +this.mortgageAmt){
                                selectEntry = true;
                        }
                    }
                }
            }
            if (selectEntry == false){
                this.state.housingArr.splice(i,1);
            }
        }
        if (this.state.housingArr.length == 1 && this.state.roommateOnlyAllowed == false){
            let slctHouse = JSON.parse(JSON.stringify(this.state.housingArr[0]));
            this.selectHousingOption(slctHouse);
            this.state.housingArr.splice(0,1);
        }
   }

   calcDownPaymentAndMortgage(indx){
        this.state.housingArr[indx].minHomeCost =
           this.state.housingArr[indx].minHomeCost * (1 + (.10 * this.yearsDiff));
        this.minimumDownPayment = this.state.housingArr[indx].minHomeCost * this.downPayMinPct;
        this.mortgageAmt =
            monthlyLoanAmt(this.state.housingArr[indx].minHomeCost, this.interestRate, this.loanTerm);
   }

    expandHomeIntroText(){
        this.state.homeIntroTextIsExpanded = true;
        if (this.homeSelectIntroText.includes("||")){
            let downPaymentPct = (+ConfigFile.homeItems.downPayMinPct * 100);
            let replaceObj = {savings: this.props.savings, 
               number: downPaymentPct, income: this.props.monthlyIncome};
            this.homeSelectFullText = replaceTextInString(this.homeSelectIntroText, replaceObj);
        }else{
            this.homeSelectFullText = this.homeSelectIntroText;
        }
    }

    collapseHomeIntroText(){
        this.state.homeIntroTextIsExpanded = false;
    }

    buyHomeText(displayOption){
        if (displayOption == "expand"){
            this.setState({homePurchaseIsExpanded: true})
        }else{
            this.setState({homePurchaseIsExpanded: false});
        }
    }
         
    render() {
        if (this.state.roommateSelected == false){
            this.preProcess(); 
            this.state.roommateOptions = this.state.housingArr.map((houseSlct, key) =>
                <HomeRoommateCard key={key} slctHome={houseSlct}/>
            );
        }
              
        this.housingList = "";
        if (this.state.roommateSelected == true){ 
            this.homeChoices = this.state.housingArr.map((houseSlct, key) =>
                <HomeButtonCard key={key} slctHome={houseSlct} 
                   onClick={(selectedHome) => this.selectHousingOption(selectedHome)}/>
            );
        }
        this.housingList = this.state.homeList.map((house, key) =>
            <HomeCard selectedHomeID={this.state.selection} key={key} houseInfo={house} 
                savings={this.props.savings} netIncome={this.props.monthlyIncome}
                onSelected={() => this.onHomeSelected(house)}
                pictureArr={this.pictureArr}/>
            );
                  
        this.housingOptionsString = "Bedrooms: " + this.state.bedrooms + ", "  +
                "Roommates: " + this.state.roommate;

        let screenParms = setScreenSizeParameters(this.props.screenSize, "gettingStarted2");
        this.className = screenParms.columnClass;
                                          
    return <>
           <div className={this.className}>
                <div className="card mt-3 mx-0 rounded-corners-lg height-max">
                    <h3 className="mt-2 text-center">Choose a Home</h3>
                    <div className="overflow-scroll mx-4 mb-2">
                
                {this.props.waitState ? 
                        <WaitTimeSpinner/>
                    :
                        null
                    }

                {!this.state.housingOptionSelected ?
                    <span>
                    {this.state.homeIntroTextIsExpanded ?
                        <div className="mx-4 text-left">
                            {this.homeSelectFullText}  &nbsp;&nbsp;&nbsp;
                            <Button variant="outline-info" size="sm"
                                onClick={() => this.collapseHomeIntroText()}>
                                    Collapse
                            </Button>
                        </div>
                    :                       
                        <div className="mx-4 text-left">
                            {this.homeSelectText} &nbsp;&nbsp;&nbsp; 
                            <Button variant="outline-info" size="sm"
                                onClick={() => this.expandHomeIntroText()}>
                                    Learn More
                            </Button>
                        </div>
                    }

                    {this.props.gameStarted == true ?
                        <div className="mx-4 mt-2">
                            <span>
                                <b>Interested in buying a home?</b>
                            </span>
                            {this.state.homePurchaseIsExpanded ?
                                <span>
                                    <div className="mx-4">
                                        In order to buy a home, you must have
                                        enough cash (including savings) to pay the down-payment. Also,
                                        your monthly income needs to be large enough to afford the monthly
                                        home loan amount (mortgage).  While the market values of the homes available change
                                        from time to time, the minimum values for downpayment and net income at the current 
                                        time are shown below.
                                        <Button variant="outline-info" size="sm"
                                            onClick={() => this.buyHomeText("collapse")}>
                                                Collapse
                                        </Button> 
                                        <div className="ml-4">
                                            <b>Minimum Down Payment Amount is:</b> {doubleToStringMonthly(this.minimumDownPayment)}
                                        </div>
                                        <div className="ml-4">
                                            <b>Minimum Net Income:</b> {doubleToStringMonthly(this.mortgageAmt)}
                                        </div>
                                       
                                    </div>
                                </span>
                            :
                                <Button variant="outline-info" size="sm"
                                    onClick={() => this.buyHomeText("expand")}>
                                            Learn More
                                </Button> 
                            } 
                        </div>
                    :
                        null
                    }
                        <div className="mt-2 mx-4">
                            <label className="font-weight-bold" htmlFor="roommate">
                                Do you want to have a Roommate? :&nbsp;&nbsp;</label>
                            <select onChange={(val) => this.handleRoommateChange(val.target.value)} 
                                className="btn btn-sm btn-outline-secondary dropdown-toggle" id="roommate">
                                <option value="">No Roommate Selection Made</option>
                                    {this.state.roommateOptions}
                            </select>
                        </div> 
                    </span>
                :
                    null
                }   
               
                {this.state.roommateSelected && !this.state.housingOptionSelected ?
                    <span className="ml-4 text-center">
                            {this.props.gameStarted ?  
                                <div className="text-center">
                                    {this.state.roommateOnlyAllowed ?
                                        <button className="btn btn-primary w-75 text-center mt-2"
                                            onClick={() => this.selectHousingOption("roommate-change")}>
                                                Keep Current Home Choice (roommate change only)
                                        </button>    
                                    :
                                        null
                                    }
                                </div> 
                            :
                               null
                            }
                            <div className="mb-4">
                                {this.homeChoices}
                            </div>
                            
                    </span>
                    :
                        null
                }
                     
                {this.state.housingOptionSelected === true ?
                     <div>
                        {this.state.loading  || this.props.waitState  ?
                            <WaitTimeSpinner />
                        :
                            <div className="mt-0">
                            <h4 className="text-center p-0">{this.housingOptionsString}</h4>
                                <button className="btn btn-primary text-left ml-3" onClick={() => this.backToOptionSelection()}>
                                    Back
                                </button>
                                   
                                <div>
                                    {this.housingList}
                                </div>
                                {this.state.homeList.length == 0 ?
                                    <div className="mt-4 mx-4">
                                        The only home of this size that you can currently
                                        afford is your current home.  Click on the
                                        back button and select a different home option.
                                    </div>
                                :
                                    null
                                }
                                                                    
                            </div>
                        }
                        </div>
                        :
                            null
                    }
                                        
                </div>
            </div>
            </div>
        </>
    }

    selectHousingOption(selectedHome) {
        sessionStorage.removeItem("homeChangeType");
        if (selectedHome == "roommate-change"){
            sessionStorage.setItem("homeChangeType", "roommate-change");
            sessionStorage.setItem("homeRoommates", this.state.roommate);
            let homeNameArr = this.props.homeName.split(" - ");
            let homeTitle = homeNameArr[0];
            this.addRoommateToHomeName(homeTitle);
            sessionStorage.setItem("homeDesc", this.homeName);
            this.props.onSelected();
        }else{
            this.getHouseList(selectedHome.bedrooms, this.state.roommate,
                selectedHome.homeAptInd);
            this.setState({housingArr: this.state.housingArr, housingOptionSelected: true});
            this.state.homeType = selectedHome.homeAptInd;
            this.state.bedrooms = selectedHome.bedrooms;
        }        
    }
 
    getHouseList(beds, roommates, homeType) {
        this.interestRate = ConfigFile.homeItems.mortgageInterest;
        this.loanTerm = ConfigFile.homeItems.mortgageLoanTerm;
        this.downPayMinPct = ConfigFile.homeItems.downPayMinPct;
        let calcObject = "";
        if (this.props.gameStarted == true){
            calcObject = "&nbrYears=" +  +this.yearsDiff + "&downPayMinPct=" + +this.downPayMinPct +
                "&interest=" + this.interestRate + "&loanTerm=" + +this.loanTerm + 
                "&savingsAmt=" + +this.props.savings + "&monthlyAmt=" + +this.props.monthlyIncome
                + "&currentHomeID=" + this.props.currentHomeID;
        }
        const funcName = "homeList";
        const urlParm = "?beds=" + beds + "&roommates=" + roommates + "&homeType=" + homeType + 
            "&calcParms=" + calcObject;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
            .then(response => { 
              if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.state.homeList = output.body;
                        let selectedHome = sessionStorage.getItem("homeChoice");
                        if (selectedHome !== undefined) {
                            this.setState({selection: selectedHome});
                        }
                        this.setState({loading: false});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
    }

    onHomeSelected(house) { 
        sessionStorage.setItem("homeChoice", house.id);
        sessionStorage.setItem("homeName", house.title);
        sessionStorage.setItem("homeType", house.homeType);
        if (house.homeType == "house"){
            sessionStorage.setItem("homeCost", house.homeCost);
            sessionStorage.setItem("homeHOA", house.rent);
        }else{
            sessionStorage.setItem("homeCost", house.rent);
        }
        sessionStorage.setItem("homeRoommates", this.state.roommate);
        this.addRoommateToHomeName(house.title);
        sessionStorage.setItem("homeDesc", this.homeName);
        sessionStorage.setItem("homeBedrooms", house.bedrooms);
        sessionStorage.setItem("homeLevel", house.level);
        sessionStorage.setItem("homeDirectory", house.directory);
        sessionStorage.setItem("homeAdditionalPhotosArr", JSON.stringify(house.additionalPhotosArr));
        this.props.onSelected();
    }

    addRoommateToHomeName(houseTitle){
        if (this.state.roommate == 0){  
            this.homeName = houseTitle + " - no roommate";
        }else{
            if (this.state.roommate == 1){
              this.homeName = houseTitle + " - " + this.state.roommate + " roommate";
            }else{
              this.homeName = houseTitle + " - " + this.state.roommate + " roommates";
            }
        }
    }
   
    backToOptionSelection()
    {
        this.setState({housingOptionSelected: false});
        this.state.loading = true;
    }
}

export default withRouter(HomeInput);