const CommentProcessCodeDesc =[
  {
    "code": 1,
    "description": "No Additional Action Required",
  },
  {
    "code": 2,
    "description": "This Has Been Fixed",
  },
  {
    "code": 3,
    "description": "Added to Near-Term App Changes",
  },
  {
    "code": 4,
    "description": "Added to Mid-Term App Changes",
  },
  {
    "code": 5,
    "description": "Added to Future (Long Term) List"
  },
  {
    "code": 6,
    "description": "Added to Data Changes List"
  },
  {
    "code": 7,
    "description": "Will Consider For Future Releases"
  },
  {
    "code": 9,
    "description": "This Change Will Not Be Pursued",
  },
];

export default CommentProcessCodeDesc;
