import getFriendImageURL from "./get_friend_image_URL";
import replaceTextInString from "../../../objects/CommonUse/replace_text_in_string";
import determineItemName from "./determine_item_name";
import PurchaseItemEventText from "../datasets/PurchaseItemEventText";

export const determineWhichFriendToDisplay = (friendList, friendInfo, currentCard, currentGameDate, screenSize,
        purchaseArr) => {
    let cardImageObj="";
    let searchString = "";
    let friendObj = getFriendImageURL(friendList, friendInfo, currentCard, currentGameDate);
    let imagePosition = "friend-position";
    if (screenSize == "narrow"){
        imagePosition = "friend-position-narrow";
    }
    let imageClass = "mx-auto d-block friend-game-img"
    let additionalInfo = {friendName: friendObj.friendName};
    let itemName = "";
    debugger
    if (currentCard.description.includes("||purchaseEventDesc||")){
        itemName = determineItemName(purchaseArr);
        debugger
        if (itemName != ""){
               let textObj =  PurchaseItemEventText.find(elem => elem.itemName === itemName);
               if (textObj !== undefined){
                      additionalInfo = {...additionalInfo, string: textObj.descText};
                      searchString = "purchaseEventDesc";
               }
        }
 }
    currentCard.description = 
            replaceTextInString(currentCard.description, additionalInfo, searchString, "string");
      
    cardImageObj = { cardImageURL: friendObj.cardImageURL, imagePosition: imagePosition, imageClass: imageClass,
            friendName: friendObj.friendName, cardImage: true, itemName: itemName};
               
    return cardImageObj;
   
}
export default determineWhichFriendToDisplay;

